<template>
  <div>
    <v-card dark>
      <div class="padding">
        <h3>You are invited to guess the city</h3>
        <div class="game-item">
          <h4 class="padding" v-on:click="acceptGame()">Accept</h4>
        </div>
        <div class="game-item">
          <h4 class="padding" v-on:click="rejectGame()">Reject</h4>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { acceptGameInvite } from '../../../../assets/js/webrtc';

export default {
  name: 'GameInvitation',
  methods: {
    ...mapActions('$_canvas', [
      'commitSetGameInvitationActive',
      'commitSetScoreBoardGTCActive',
    ]),
    acceptGame() {
      console.log('You accepted');
      acceptGameInvite({
        accepted: true,
        creatorId: this.gameData.creatorId,
      });
      this.commitSetGameInvitationActive();
      this.commitSetScoreBoardGTCActive();
    },
    rejectGame() {
      acceptGameInvite({
        accepted: false,
        creatorId: this.gameData.creatorId,
      });
      console.log('You rejected');
      this.commitSetGameInvitationActive();
    },
  },
  computed: {
    ...mapGetters('$_canvas', ['gameData']),
  },
};
</script>

<style scoped>
 .padding {
    padding: 2vh;
  }
  .game-item {
    cursor: pointer;
  }
  .game-item:hover {
    background: grey;
  }
</style>
