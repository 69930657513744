<template>
  <div @click="checkActive()">
    <vue-drag-resize :isResizable="false"
                     :isDraggable="this.canDragg"
                     v-on:mousedown="draggOn"
                     v-on:mouseup="draggOn"
                      :x="-450" :y="100">
      <v-card dark class="mx-auto pa-4" min-width="400">
        <CloseButton :method="closeSettingsComponent" class="close_button" />
        <v-row>
          <v-col>
            <h3>{{ room }}</h3>
          </v-col>
        </v-row>
        <v-card-actions v-if="!pictureTaken" class="justify-center padding">
          <div class="placeholder">
            <video id="vidEl" height="100%" autoplay muted ref="myCurrentVideo"/>
          </div>
        </v-card-actions>
        <div v-else class="padding">
          <v-img :src="image" max-width="240" max-height="240" class="center-image" />
        </div>
        <v-btn v-if="!pictureTaken" dark elevation="8" large v-on:click="capturePhoto"
        class="center-image">
            New profile picture
        </v-btn>
        <v-btn v-else dark elevation="8" large v-on:click="backToVideo" class="center-image">
            Show video
        </v-btn>
        <v-card-actions class="padding">
          <v-text-field label="Name"
                        v-model="newname"
                        required
                        filled
                        rounded
                        hide-details
                        prepend-inner-icon="mdi-account-circle" />
        </v-card-actions>
        <v-select :items="cameras" return-object v-on:change="changeTheCamera"
                  label="Select Camera" class="dropdown_width mx-auto" />
        <v-select :items="microphones" return-object
                  v-on:change="changeTheMicrophone"
                  label="Select microphone"
                  class="dropdown_width mx-auto padding" />
        <v-progress-linear rounded v-model="meterValue" height="10" color="red" /><br />
        <h3>Proximity sound distance</h3>
        <v-slider v-on:mousedown="draggOff"
                  v-on:mouseup="draggOn"
                  max="100" min="0" v-model="proximitySoundDistance"></v-slider>
      </v-card>
    </vue-drag-resize>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VueDragResize from 'vue-drag-resize';
import {
  changeCamera,
  changeMicrophone,
  changeName,
  changeProfilePic,
  takeProfilePic,
  getBase64Image,
  adjustVolumeAll,
} from '../../../assets/js/webrtc';
import CloseButton from './CloseButton.vue';

export default {
  name: 'SettingBox',
  components: {
    CloseButton, VueDragResize,
  },
  data() {
    return {
      newname: '',
      pictureTaken: false,
      image: {},
      cameras: [],
      microphones: [],
      meterValue: 0,
      proximitySoundDistance: 50,
      canDragg: true,
    };
  },
  mounted() {
    this.proximitySoundDistance = 100 * (window.mingly.board.room.soundDecay / 3);
    window.AudioContext = window.AudioContext || window.webkitAudioContext;
    window.mingly.updateVid = this.getVideo;
    window.mingly.onChangeVideo = () => {
      window.mingly.updateVid();
    };
    this.getVideoSetup();
  },
  computed: {
    ...mapGetters('$_userdata', [
      'username', 'room',
    ]),
  },
  methods: {
    ...mapActions('$_userdata', ['commitProfilePicture', 'commitSetUsername', 'username']),
    ...mapActions('$_canvas', [
      'commitSetSettingMenuActive',
    ]),
    closeSettingsComponent() {
      this.commitSetSettingMenuActive();
    },
    getVideoSetup() {
      this.getSources();
      this.getVideo();
    },
    checkActive() {
      window.mingly.board.move_keyboard = false;
    },
    draggOff() {
      this.canDragg = false;
    },
    draggOn() {
      this.canDragg = true;
    },
    backToVideo() {
      this.pictureTaken = false;
      // the setTimeout is not elegant
      // but it did not work withouth it
      setTimeout(() => {
        this.$refs.myCurrentVideo.srcObject = window.mingly.board.me.videoElement.srcObject;
      }, 100);
    },
    changeTheName() {
      localStorage.setItem('minglyUsername', this.newname);
      this.commitSetUsername(this.newname);
      changeName(this.newname);
    },
    adjustSlider() {
      // 3 is arbitrary here really. It corresponds to a normal decay of 1.5 which works fine
      window.mingly.board.room.soundDecay = 3 * (this.proximitySoundDistance / 100);
      adjustVolumeAll();
    },
    capturePhoto() {
      const profilePicCanvas = document.createElement('Canvas');
      this.image.src = takeProfilePic(
        profilePicCanvas,
        window.mingly.board.me.videoElement,
        240,
      ).src;
      const imgData = getBase64Image(profilePicCanvas);
      localStorage.setItem('imgData', imgData);
      this.pictureTaken = true;
      this.commitProfilePicture(this.image);
      changeProfilePic(imgData);
    },
    getVideo() {
      this.$refs.myCurrentVideo.srcObject = window.mingly.board.me.videoElement.srcObject;
      // this.setAudioMeter();
      this.setAudioMeter();
      // this.$refs.myCurrentVideo.canplay = this.setAudioMeter;
      this.$refs.myCurrentVideo.muted = true;
    },
    setAudioMeter() {
      // const mediaStream = new MediaStream();
      // mediaStream = this.$refs.myCurrentVideo.captureStream();
      const stream = window.mingly.streamLowRes;
      const audioContext = new AudioContext();
      const mediaStreamSource = audioContext.createMediaStreamSource(stream);
      const meter = this.createAudioMeter(audioContext);
      mediaStreamSource.connect(meter);
    },
    getSources() {
      if (navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices.enumerateDevices()
          .then((devices) => {
            devices.forEach((device) => {
              if (device.kind === 'videoinput') {
                this.cameras.push({
                  text: device.label,
                  value: device.deviceId,
                });
              } else if (device.kind === 'audioinput') {
                this.microphones.push({
                  text: device.label,
                  value: device.deviceId,
                });
              }
            });
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(`error: ${err.name}: ${err.message}`);
          });
      } else {
        // todo: alert the user that we didn't find any cameras?
      }
    },
    changeTheCamera(cameraSelection) {
      changeCamera(cameraSelection.value);
    },
    changeTheMicrophone(microphoneSelection) {
      changeMicrophone(microphoneSelection.value);
    },
    createAudioMeter(audioContext, clipLevel, averaging, clipLag) {
      // todo: we're using deprecated functions!
      // https://developer.mozilla.org/en-US/docs/Web/API/BaseAudioContext/createScriptProcessor
      const processor = audioContext.createScriptProcessor(512);
      const self = this; // eslint-disable-line no-unused-vars

      processor.onaudioprocess = function (event) {
        const buf = event.inputBuffer.getChannelData(0);
        const bufLength = buf.length;
        let sum = 0;
        let x;

        // Do a root-mean-square on the samples: sum up the squares...
        /* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */
        for (let i = 0; i < bufLength; i++) {
          x = buf[i];
          if (Math.abs(x) >= this.clipLevel) {
            this.clipping = true;
            this.lastClip = window.performance.now();
          }
          sum += x * x;
        }

        // ... then take the square root of the sum.
        const rms = Math.sqrt(sum / bufLength);

        // Now smooth this out with the averaging factor applied
        // to the previous sample - take the max here because we
        // want "fast attack, slow release."
        this.volume = Math.max(rms, this.volume * this.averaging);
        // meterComponent = this.volume * 100;
        // console.log(Math.trunc(this.volume * 100));
        self.setMeter(Math.trunc(this.volume * 100));
      };

      processor.clipping = false;
      processor.lastClip = 0;
      processor.volume = 0;
      processor.clipLevel = clipLevel || 0.98;
      processor.averaging = averaging || 0.95;
      processor.clipLag = clipLag || 750;

      // this will have no effect, since we don't copy the input to the output,
      // but works around a current Chrome bug.
      processor.connect(audioContext.destination);

      processor.checkClipping = function () {
        if (!this.clipping) {
          return false;
        }

        if ((this.lastClip + this.clipLag) < window.performance.now()) {
          this.clipping = false;
        }

        return this.clipping;
      };

      processor.shutdown = function () {
        this.disconnect();
        this.onaudioprocess = null;
      };

      return processor;
    },
    setMeter(value) {
      // the times 5 is not good but might do the job
      this.meterValue = value * 5;
    },
  },
  created() {
    this.newname = this.username;
  },
  updated() {
    this.changeTheName();
    this.adjustSlider();
  },
};
</script>

<style scoped>
  .dropdown_width {
    width: 80%;
  }
  .padding_bot {
    padding-bottom: 2vh;
  }
  .padding {
    padding-top: 2vh;
  }
  .center-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
  }
  .placeholder {
    border: grey solid 1px;
    background-color: lightgray;
    width: 320px;
    height: 240px;
  }
  .close_button {
    position: absolute;
    right: 0.2vh;
    top: 0.2vh;
  }
</style>
