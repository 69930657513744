<template>
  <div class="header" style="position: absolute; z-index: 1; width: 100%;">
    <!-- <v-app-bar color="#203e5f" dark> -->
  <v-app-bar color="transparent" flat dark>
  <v-toolbar-side-icon>
    <v-img @click="goToHome"
    class="mr-3" src="../../../assets/RandomLogoNoBackGround.png"
    height="50px" width="100px">
    </v-img>
  </v-toolbar-side-icon>
  <v-toolbar-items class="hidden-sm-and-down">
    <v-btn text link @click="goToWhyMingly" class="menu-link">Why Mingly?</v-btn>
    <v-btn text link @click="goToAbout" class="menu-link">About</v-btn>
    <v-btn text link @click="goToNewsLetter" class="menu-link">Newsletter</v-btn>
    <v-btn text link @click="goToContact" class="menu-link">Contact us</v-btn>
    <!-- <v-btn text link @click="goToLoginMyPage" class="menu-link">Sign in</v-btn> -->
  </v-toolbar-items>
  <v-spacer></v-spacer>
  <v-toolbar-items class="hidden-sm-and-down">
    <v-btn
    color= "#203e5fff"
    dark link @click="goToLogin" class="menu-link rounded-pill mybutton">Join a meeting</v-btn>
  </v-toolbar-items>
  <v-toolbar-items class="hidden-md-and-up">
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <v-btn text v-on="on">Menu</v-btn>
      </template>
      <v-list>
        <v-list-item @click="goToWhyMingly">
          <v-list-item-title>Why Mingly?</v-list-item-title>
        </v-list-item>
        <v-list-item @click="goToAbout">
          <v-list-item-title>About</v-list-item-title>
        </v-list-item>
        <v-list-item @click="goToNewsLetter">
          <v-list-item-title>Newsletter</v-list-item-title>
        </v-list-item>
        <v-list-item @click="goToContact">
          <v-list-item-title>Contact us</v-list-item-title>
        </v-list-item>
        <!-- <v-list-item @click="goToLoginMyPage" >
          <v-list-item-title>Sign in</v-list-item-title>
        </v-list-item> -->
        <v-list-item @click="goToLogin" >
          <v-list-item-title>Join a meeting</v-list-item-title>
        </v-list-item>
        <!-- ... rest of your items -->
      </v-list>
    </v-menu>
  </v-toolbar-items>
</v-app-bar>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Header',
  // data: () => ({
  //   drawer: false,
  // }),
  computed: {
    ...mapGetters('$_userdata', ['room']),
  },
  methods: {
    goToAbout() {
      this.$router.push({ name: 'About' });
    },
    goToNewsLetter() {
      this.$router.push({ name: 'Newsletter' });
    },
    goToWhyMingly() {
      this.$router.push({ name: 'WhyMingly' });
    },
    goToContact() {
      this.$router.push({ name: 'Contact' });
    },
    goToHome() {
      this.$router.push({ name: 'LandingPage' });
    },
    goToLogin() {
      this.$router.push({ name: 'Login' });
    },
    goToLoginMyPage() {
      this.$router.push({ name: 'MyRoom' });
    },
    // goToTutorial() {
    //   this.$router.push({ name: 'Tutorial' });
    // },
    logout() {
      // standard route
      // this.$router.push({ name: 'Login' });

      // Temporary for testing
      if (this.room === 'TestRoom0') {
        this.$router.push({ name: 'Questionnaire0' });
      } else if (this.room === 'TestRoom1') {
        this.$router.push({ name: 'Questionnaire1' });
      } else if (this.room === 'TestRoom2') {
        this.$router.push({ name: 'Questionnaire2' });
      } else if (this.room === 'TestRoom3') {
        this.$router.push({ name: 'Questionnaire3' });
      } else if (this.room === 'TestRoom4') {
        this.$router.push({ name: 'Questionnaire4' });
      } else if (this.room === 'TestRoom5') {
        this.$router.push({ name: 'Questionnaire5' });
      } else if (this.room === 'TestRoom6') {
        this.$router.push({ name: 'Questionnaire6' });
      } else if (this.room === 'TestRoom7') {
        this.$router.push({ name: 'Questionnaire7' });
      } else if (this.room === 'TestRoom8') {
        this.$router.push({ name: 'Questionnaire8' });
      } else if (this.room === 'TestRoom9') {
        this.$router.push({ name: 'Questionnaire9' });
      } else if (this.room === 'TestRoom10') {
        this.$router.push({ name: 'Questionnaire10' });
      } else {
        this.$router.push({ name: 'LandingPage' });
      }
    },
  },
};
</script>

<style scoped>
.header:hover {
  background-color: #203e5fff;
}
.mybutton:hover {
  background-color: #eac252 !important;
  color: #203e5fff;
}
</style>
