export default class CanvasObject {
  constructor(x, y, width, height, source, canvas, type, board, active = true) {
    this.uuid = null;
    this.name = null;
    this.groupName = null;
    this.x = x;
    this.y = y;
    this.dx = 0;
    this.dy = 0;
    this.width = width;
    this.height = height;
    this.text = null;
    this.type = type || 'picture';
    this.board = board || null;
    // this.me = this.board.me;
    this.source = null;
    this.canvas = canvas;
    this.onClick = null; // function if clicking on object
    this.onHover = {
      hover: false,
      fun: null,
    }; // when hovering over object
    this.isWalkable = true; // can you place yourself on top of the object
    this.soundDecay = false; // if you want to sound to decay
    this.ctx = canvas.getContext('2d');
    this.childUuid = [];
    this.reqAnimation = null;
    this.vidReady = null;
    this.isActive = active; // if not active it will not display
    this.dat = null; // a possibility to pass other data to functions
    if (source.muted) {
      this.muted = source.muted;
    } else {
      this.muted = false;
    }
    // if (source.loop) {
    //   this.loop = source.loop;
    // } else {
    //   this.loop = false;
    // }
    this.setObject(source);
  }

  drawObject() {
    switch (this.type) {
      case 'picture':
        // Check if the image is already loaded
        if (this.isActive) {
          if (this.source.complete && this.source.naturalHeight !== 0) {
            // Image is loaded, draw it
            this.ctx.drawImage(this.source, this.x, this.y, this.width, this.height);
          } else {
            // Image is not loaded, wait for the load event
            this.source.onload = () => {
              this.ctx.drawImage(this.source, this.x, this.y, this.width, this.height);
            };

            // In case the source is set after the drawObject method is called
            // Consider adding an error handler for cases where the image fails to load
            this.source.onerror = (error) => {
              console.error('Error loading the image', error);
            };
          }
        }
        break;
      case 'definedArea':
        // really nothing here
        break;
      case 'canvasDrawing':
        // might be tricky to do it this way
        // need to make sure it has the 4 inputs
        // console.log('drawing drawObject!!!!!');
        this.source(this.x, this.y, this.width, this.height, this.text, this.ctx, this.dat);
        break;
      case 'canvasVideo':
        // this is not the right way to do it either!
        if (this.isActive) {
          this.drawVideo(this.source);
        }
        break;
      case 'rtcVideo':
        this.drawVideo(this.source);
        break;
      default:
        break;
    }
  }

  setVideo(source) {
    this.source = document.createElement('video');
    this.source.setAttribute('playsinline', '');
    // this.source.setAttribute('autoplay', '');
    this.source.src = source.src;
    this.source.addEventListener('canplay', () => {
      this.vidReady = true;
      if (this.isActive) {
        this.source.play();
        this.source.muted = this.muted;
        this.drawVideo(this.source);
        this.adjustVolume();
      }
    });
    // this.source.addEventListener('ended', () => {
    //   console.log('THE VIDEO ENDED!!!');
    //   cancelAnimationFrame(this.reqAnimation);
    //   this.board.updateAll();
    //   // if (loop) {
    //   //   if (this.isActive) {
    //   //     this.source.play();
    //   //     this.source.muted = this.muted;
    //   //     this.drawVideo(this.source);
    //   //     this.adjustVolume();
    //   //   }
    //   // }
    // });
  }

  setRTCVideo(source) {
    this.source = source;
    this.vidReady = true;
    this.drawVideo(this.source);
  }

  stopVideo() {
    this.vidReady = false;
    cancelAnimationFrame(this.reqAnimation);
  }

  deleteVideo() {
    this.vidReady = false;
    cancelAnimationFrame(this.reqAnimation);
  }

  setObject(source) {
    switch (this.type) {
      case 'picture':
        this.source = new Image();
        this.source = source;
        break;
      case 'definedArea':
        this.source = source;
        break;
      case 'canvasDrawing':
        this.source = source;
        break;
      case 'canvasVideo':
        this.setVideo(source, this.loop);
        break;
      case 'rtcVideo':
        this.setRTCVideo(source);
        break;
      default:
        break;
    }
  }

  drawVideo() {
    // console.log('in drawVideo Canvas');
    if (!this.type === 'canvasVideo' || !this.vidReady) {
      return;
    }
    const v = this.source;
    if (v.paused || v.ended || v == null) {
      cancelAnimationFrame(this.reqAnimation);
      this.clear();
      return;
    }
    const w = this.width;
    const h = (v.videoHeight / v.videoWidth) * w;
    this.ctx.drawImage(v, this.x, this.y, w, h);
    this.reqAnimation = requestAnimationFrame(
      this.drawVideo.bind(this, this.source),
    );
  }

  pointOverObject(lastX, lastY) {
    let isOver = false;
    const transformMat = this.ctx.getTransform();
    const leftC  = (transformMat.a * this.x) + transformMat.e; // eslint-disable-line no-multi-spaces,max-len
    const rightC = (transformMat.a * (this.x + this.width)) + transformMat.e; // eslint-disable-line no-multi-spaces,max-len
    const uppC   = (transformMat.d * this.y) + transformMat.f; // eslint-disable-line no-multi-spaces,max-len
    const lowC   = (transformMat.d * (this.y + this.height)) + transformMat.f; // eslint-disable-line no-multi-spaces,max-len
    if ((lastX < rightC) && (leftC < lastX)
        && (lastY < lowC) && (uppC < lastY) ) { // eslint-disable-line space-in-parens
      isOver = true;
    }
    return isOver;
  }

  mouseCursorOnObjectForHover(lastX, lastY) {
    // console.log('stuff');
    // console.log(this.x);
    // console.log(this.y);
    // console.log(this.height);
    // console.log(this.width);
    const transformMat = this.ctx.getTransform();
    const leftC  = (transformMat.a * this.x) + transformMat.e; // eslint-disable-line no-multi-spaces,max-len
    const rightC = (transformMat.a * (this.x + this.width)) + transformMat.e; // eslint-disable-line no-multi-spaces,max-len
    const uppC   = (transformMat.d * this.y) + transformMat.f; // eslint-disable-line no-multi-spaces,max-len
    const lowC   = (transformMat.d * (this.y + this.height)) + transformMat.f; // eslint-disable-line no-multi-spaces,max-len
    if ((lastX < rightC) && (leftC < lastX)
        && (lastY < lowC) && (uppC < lastY) ) { // eslint-disable-line space-in-parens
      try {
        this.onHover.fun();
      } catch {
        window.mingly.log('noHover');
      }
    } else {
      try {
        this.onHover.nofun();
      } catch {
        window.mingly.log('nonoHover');
      }
    }
  }

  mouseCursorOnObjectForClick(lastX, lastY) {
    const transformMat = this.ctx.getTransform();
    const leftC  = (transformMat.a * this.x) + transformMat.e; // eslint-disable-line no-multi-spaces,max-len
    const rightC = (transformMat.a * (this.x + this.width)) + transformMat.e; // eslint-disable-line no-multi-spaces,max-len
    const uppC   = (transformMat.d * this.y) + transformMat.f; // eslint-disable-line no-multi-spaces,max-len
    const lowC   = (transformMat.d * (this.y + this.height)) + transformMat.f; // eslint-disable-line no-multi-spaces,max-len
    if ((lastX < rightC) && (leftC < lastX)
        && (lastY < lowC) && (uppC < lastY) ) { // eslint-disable-line space-in-parens
      if (this.onClick) {
        this.onClick();
      }
    }
  }

  clear() {
    const adjust = 10;
    this.ctx.clearRect(this.x - adjust, this.y - adjust,
      this.width + 2 * adjust, this.height + 2 * adjust);
    return true;
  }

  moveTo(targetX, targetY) {
    const newX = parseInt(targetX, 10);
    const newY = parseInt(targetY, 10);
    this.clear();
    this.x = newX;
    this.y = newY;
    this.drawObject();
    this.adjustVolume();
  }

  adjustVolume() {
    const { board } = window.mingly;
    const { me } = board;
    if (!this.soundDecay) return;
    const stream = this.source;
    if (stream) {
      let distance;
      // check if the next one is correct?
      if (board.me.noSound) {
        // could set muted property as well here, but not sure if that is necessary
        if (board.isIpad && stream.gainNode) {
          // todo: should have a cleaner selection.
          // Did && this.gainNode since it sometime does not work and therefore not allocated
          stream.gainNode.gain.value = 0;
        } else {
          // eslint-disable-next-line no-lonely-if
          stream.volume = 0;
        }
      } else {
        if (stream.muted) {
          stream.muted = false;
        }
        const pos = {
          x: this.x + 0.5 * this.width,
          y: this.y + 0.5 * this.height,
        };
        // if (this.x < me.x && this.y < me.y) {
        //   console.log('this is the width');
        //   console.log(this.width);
        //   console.log(this.height);
        //   console.log('after');
        //   if (me.x < this.x + this.width) {
        //     console.log('the first');
        //     console.log(this.x + this.width);
        //     // pos.x = me.x;
        //   } else {
        //     console.log('the second');
        //     console.log(me.x - this.x - this.width);
        //     // pos.x = me.x - this.x - this.width;
        //   }
        //   // if (me.y < this.y + this.height) {
        //   //   pos.y = me.y;
        //   // } else {
        //   //   pos.y = me.y - this.y - this.height;
        //   // }
        // }
        distance = me.distanceTo(pos);
        if (distance > 0) {
          if (board.isIpad && stream.gainNode) {
            // todo: should have a cleaner selection.
            // Did && this.gainNode since it sometime does not work and therefore not allocated
            stream.gainNode.gain.value = (1 / distance) ** (board.room.soundDecay);
          } else {
            // eslint-disable-next-line no-lonely-if
            stream.volume = (1 / distance) ** (board.room.soundDecay);
          }
        } else {
          console.error('Participant.adjustVolume: onGetUniformSoundVolumeLevel not set');
        }
      }
    }
  }
}
