<template>
   <div class="main-container">
   <div class="background">
   </div> <!-- Separate div for the background -->
   <div>
      <v-row justify="center">
         <v-col cols="12" sm="8" md="6">
            <WhyCard title="Why Mingly?"
            color="spatialTop"
            description="Mingly provides a different experience
            than traditional video chat applications. Here we
            give you 8 reasons that we think make Mingly unique
            and give  you a whole different experience than the
            alternatives."></WhyCard>
            <WhyCard title="Spatial"
            video="https://www.dropbox.com/s/ru89d9yjp8uia98/Why%20-%20Spatial%20Audio.mp4?raw=1"
            color="spatial1"></WhyCard>
            <WhyCard title="Intuitive navigation"
               video="https://www.dropbox.com/s/x2k1bk2q5iwfyyi/Why%20-%20Easy%20Navigation.mp4?raw=1"
               color="spatial2"></WhyCard>
            <WhyCard title="Personalized backgrounds"
               video="https://www.dropbox.com/s/r2bva9b92u94uqb/Why%20-%20Fun%20Background.mp4?raw=1"
               color="spatial1"></WhyCard>
            <WhyCard title="Interactive backgrounds"
               video="https://www.dropbox.com/s/k0ffpaufw0x5q7x/Why%20-%20Dynamic%20Backgrounds.mp4?raw=1"
               color="spatial2"></WhyCard>
            <WhyCard title="Dynamic presentations"
               video="https://www.dropbox.com/s/7ti1b0cjujfctj2/Why%20-%20Presenations.mp4?raw=1"
               color="spatial1"></WhyCard>
            <WhyCard title="Spatial polling"
               video="https://www.dropbox.com/s/zqwy2ddpzrcgsld/Why%20-%20Polling.mp4?raw=1"
               color="spatial2"></WhyCard>
            <WhyCard title="Spatial quiz"
               video="https://www.dropbox.com/s/ka0xwywoyo3e7j5/Why%20-%20Guess%20the%20City.mp4?raw=1"
               color="spatial1"></WhyCard>
            <WhyCard title="Dynamic meeting controls"
               video="https://www.dropbox.com/s/p0gzl78f5x7wn6x/Why%20-%20Host%20Controls.mp4?raw=1"
               color="spatial2"></WhyCard>
         </v-col>
      </v-row>
   </div>
</div>
 </template>
<script>
import { mapGetters } from 'vuex';
import WhyCard from './_components/WhyCard.vue';

export default {
  name: 'index',
  components: {
    WhyCard,
  },
  computed: {
    ...mapGetters('$_userdata', ['backgroundImageUrl']),
  },
};
</script>

 <style scoped>
.background {
  background: url('https://www.dropbox.com/s/eeaq8lkpwlvhaio/IMG_2775.jpg?raw=1');
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
 @media (max-width: 600px) {
   .background {
     padding: 0;
   }
 }
 </style>
