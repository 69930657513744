import {
  SET_CHAT_BOX_ACTIVE,
  SET_PARTICIPANT_ACTIVE,
  SET_SETTING_MENU_ACTIVE,
  SET_CANVAS_VIEW_ACTIVE,
  SET_VIDEO_SHARE_ACTIVE,
  SET_SCREEN_SHARE_ACTIVE,
  SET_MUTE_ACTIVE,
  SET_DEAFEN_ACTIVE,
  SET_SLEEP_ACTIVE,
  SET_SHARE_MEDIA_ACTIVE,
  SET_SHOW_PARTICIPANT_VIDEO_ACTIVE,
  SET_BROADCAST_ACTIVE,
  SET_POLL_ACTIVE,
  SET_ANSWER_POLL_ACTIVE,
  SET_STAT_CREATOR_POLL_ACTIVE,
  SET_STAT_DISPLAY_POLL_ACTIVE,
  SET_GAMES_ACTIVE,
  SET_GAME_INVITE_ACTIVE,
  SET_GAME_INVITATION_ACTIVE,
  SET_GAME_DATA,
  ADD_PLAYER_DATA,
  SET_SCORE_BOARD_GTC_ACTIVE,
  SET_ENTER_VIDEO_ACTIVE,
  SET_SIDE_CANVAS_ACTIVE,
  SET_BROADCAST_MENU_ACTIVE,
  SET_BROADCAST_MINGLY_MODE_ACTIVE,
  SET_CHAT_BEHAVIOR_ACTIVE,
  SET_CHAT_DRAGGABLE_ACTIVE,
  SET_FOCUS_VIEW_ACTIVE,
  SET_HOST_MENU_ACTIVE,
  SET_HOST_SETTINGS_ACTIVE,
  SET_LOCK_POSITION_ACTIVE,
  SET_MUTE_ALL_ACTIVE,
  SET_NO_VIDEO_ALL_ACTIVE,
  SET_BROADCAST_ME_ACTIVE,
  SET_FOLLOW_MY_VIEW_ACTIVE,
  SET_NO_HOST_MENU_ACTIVE,
  SET_RAISE_HAND_ACTIVE,
  ADD_RAISED_HAND,
  REMOVE_RAISED_HAND,
  SET_RAISED_HAND_OVERVIEW_ACTIVE,
  SET_ASK_TO_RELOAD_ACTIVE,
} from './mutations';

export const commitSetChatBoxActive = 'commitSetChatBoxActive';
export const commitSetParticipantActive = 'commitSetParticipantActive';
export const commitSetSettingMenuActive = 'commitSetSettingMenuActive';
export const commitSetCanvasViewActive = 'commitSetCanvasViewActive';
export const commitSetVideoShareActive = 'commitSetVideoShareActive';
export const commitSetScreenShareActive = 'commitSetScreenShareActive';
export const commitSetMuteActive = 'commitSetMuteActive';
export const commitSetDeafenActive = 'commitSetDeafenActive';
export const commitSetSleepActive = 'commitSetSleepActive';
export const commitSetShareMediaActive = 'commitSetShareMediaActive';
export const commitSetShowParticipantVideoActive = 'commitSetShowParticipantVideoActive';
export const commitSetBroadCastActive = 'commitSetBroadCastActive';
export const commitSetPollActive = 'commitSetPollActive';
export const commitSetAnswerPollActive = 'commitSetAnswerPollActive';
export const commitSetStatCreatorPollActive = 'commitSetStatCreatorPollActive';
export const commitSetStatDisplayPollActive = 'commitSetStatDisplayPollActive';
export const commitSetGamesActive = 'commitSetGamesActive';
export const commitSetGameInviteActive = 'commitSetGameInviteActive';
export const commitSetGameInvitationActive = 'commitSetGameInvitationActive';
export const commitSetGameData = 'commitSetGameData';
export const commitAddPlayerData = 'commitAddPlayerData';
export const commitSetScoreBoardGTCActive = 'commitSetScoreBoardGTCActive';
export const commitSetEnterVideoActive = 'commitSetEnterVideoActive';
export const commitSetSideCanvasActive = 'commitSetSideCanvasActive';
export const commitSetBroadcastMenuActive = 'commitSetBroadcastMenuActive';
export const commitSetBroadcastMinglyModeActive = 'commitSetBroadcastMinglyModeActive';
export const commitSetChatBehaviorActive = 'commitSetChatBehaviorActive';
export const commitSetChatDraggableActive = 'commitSetChatDraggableActive';
export const commitSetFocusViewActive = 'commitSetFocusViewActive';
export const commitSetHostMenueActive = 'commitSetHostMenueActive';
export const commitSetHostSettingsActive = 'commitSetHostSettingsActive';
export const commitSetLockPositionActive = 'commitSetLockPositionActive';
export const commitSetMuteAllActive = 'commitSetMuteAllActive';
export const commitSetNoVideoAllActive = 'commitSetNoVideoAllActive';
export const commitSetBroadCastMeActive = 'commitSetBroadCastMeActive';
export const commitSetFollowMyViewActive = 'commitSetFollowMyViewActive';
export const commitSetNoHostMenuActive = 'commitSetNoHostMenuActive';
export const commitRaiseHandActive = 'commitRaiseHandActive';
export const commitAddRaisedHand = 'commitAddRaisedHand';
export const commitRemoveRaisedHand = 'commitRemoveRaisedHand';
export const commitSetRaisedHandOverviewActive = 'commitSetRaisedHandOverviewActive';
export const commitAskToReload = 'commitAskToReload';

export default {
  [commitSetChatBehaviorActive](context, data) {
    context.commit(SET_CHAT_BEHAVIOR_ACTIVE, data);
  },
  [commitAskToReload](context) {
    context.commit(SET_ASK_TO_RELOAD_ACTIVE);
  },
  [commitSetChatDraggableActive](context, data) {
    context.commit(SET_CHAT_DRAGGABLE_ACTIVE, data);
  },
  [commitSetBroadcastMinglyModeActive](context) {
    context.commit(SET_BROADCAST_MINGLY_MODE_ACTIVE);
  },
  [commitSetBroadcastMenuActive](context) {
    context.commit(SET_BROADCAST_MENU_ACTIVE);
  },
  [commitSetChatBoxActive](context) {
    context.commit(SET_CHAT_BOX_ACTIVE);
  },
  [commitSetParticipantActive](context) {
    context.commit(SET_PARTICIPANT_ACTIVE);
  },
  [commitSetSettingMenuActive](context) {
    context.commit(SET_SETTING_MENU_ACTIVE);
  },
  [commitSetCanvasViewActive](context) {
    context.commit(SET_CANVAS_VIEW_ACTIVE);
  },
  [commitSetVideoShareActive](context) {
    context.commit(SET_VIDEO_SHARE_ACTIVE);
  },
  [commitSetScreenShareActive](context) {
    context.commit(SET_SCREEN_SHARE_ACTIVE);
  },
  [commitSetMuteActive](context) {
    context.commit(SET_MUTE_ACTIVE);
  },
  [commitSetDeafenActive](context) {
    context.commit(SET_DEAFEN_ACTIVE);
  },
  [commitSetSleepActive](context) {
    context.commit(SET_SLEEP_ACTIVE);
  },
  [commitSetShareMediaActive](context) {
    context.commit(SET_SHARE_MEDIA_ACTIVE);
  },
  [commitSetShowParticipantVideoActive](context, data) {
    context.commit(SET_SHOW_PARTICIPANT_VIDEO_ACTIVE, data);
  },
  [commitSetBroadCastActive](context, data) {
    context.commit(SET_BROADCAST_ACTIVE, data);
  },
  [commitSetPollActive](context) {
    context.commit(SET_POLL_ACTIVE);
  },
  [commitSetAnswerPollActive](context) {
    context.commit(SET_ANSWER_POLL_ACTIVE);
  },
  [commitSetStatCreatorPollActive](context) {
    context.commit(SET_STAT_CREATOR_POLL_ACTIVE);
  },
  [commitSetStatDisplayPollActive](context) {
    context.commit(SET_STAT_DISPLAY_POLL_ACTIVE);
  },
  [commitSetGamesActive](context) {
    context.commit(SET_GAMES_ACTIVE);
  },
  [commitSetGameInviteActive](context, game) {
    context.commit(SET_GAME_INVITE_ACTIVE, game);
  },
  [commitSetGameInvitationActive](context) {
    context.commit(SET_GAME_INVITATION_ACTIVE);
  },
  [commitSetGameData](context, gameData) {
    context.commit(SET_GAME_DATA, gameData);
  },
  [commitAddPlayerData](context, payload) {
    context.commit(ADD_PLAYER_DATA, payload);
  },
  [commitSetScoreBoardGTCActive](context) {
    context.commit(SET_SCORE_BOARD_GTC_ACTIVE);
  },
  [commitSetEnterVideoActive](context) {
    context.commit(SET_ENTER_VIDEO_ACTIVE);
  },
  [commitSetSideCanvasActive](context) {
    context.commit(SET_SIDE_CANVAS_ACTIVE);
  },
  [commitSetFocusViewActive](context) {
    context.commit(SET_FOCUS_VIEW_ACTIVE);
  },
  [commitSetHostMenueActive](context) {
    context.commit(SET_HOST_MENU_ACTIVE);
  },
  [commitSetHostSettingsActive](context) {
    context.commit(SET_HOST_SETTINGS_ACTIVE);
  },
  [commitSetLockPositionActive](context) {
    context.commit(SET_LOCK_POSITION_ACTIVE);
  },
  [commitSetMuteAllActive](context) {
    context.commit(SET_MUTE_ALL_ACTIVE);
  },
  [commitSetNoVideoAllActive](context) {
    context.commit(SET_NO_VIDEO_ALL_ACTIVE);
  },
  [commitSetBroadCastMeActive](context) {
    context.commit(SET_BROADCAST_ME_ACTIVE);
  },
  [commitSetFollowMyViewActive](context) {
    context.commit(SET_FOLLOW_MY_VIEW_ACTIVE);
  },
  [commitSetNoHostMenuActive](context) {
    context.commit(SET_NO_HOST_MENU_ACTIVE);
  },
  [commitRaiseHandActive](context) {
    context.commit(SET_RAISE_HAND_ACTIVE);
  },
  [commitAddRaisedHand](context, data) {
    context.commit(ADD_RAISED_HAND, data);
  },
  [commitRemoveRaisedHand](context, data) {
    context.commit(REMOVE_RAISED_HAND, data);
  },
  [commitSetRaisedHandOverviewActive](context) {
    context.commit(SET_RAISED_HAND_OVERVIEW_ACTIVE);
  },
};
