<template>
  <div>
    <v-card color="#203e5fff" class="mx-auto camera_box pa-4" max-width="600">
      <v-card-actions v-if="!pictureTaken" class="justify-center padding">
        <CameraVideo ref="CameraVideo" />
      </v-card-actions>
      <div v-else class="padding">
        <v-img :src="image" width="240" height="240" class="center-image" />
      </div>
      <v-select :items="cameras" v-model="camera" return-object v-on:change="changeCamera"
                label="Select Camera" class="dropdown_width mx-auto" />
      <v-card-actions class="justify-center padding_bot">
        <v-dialog v-model="dialog" persistent max-width="290">
          <v-card>
            <v-card-title>Select a file</v-card-title>
          <v-card-text>
            <input type="file" @change="onFileChange">
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="dialog = false">
                  Done
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn v-if="!pictureTaken" color="#eac252" large elevation="8" v-on:click="capturePhoto">
          take photo
        </v-btn>
        <v-btn v-else color="#eac252" elevation="8" large v-on:click="retakePhoto">
          retake photo
        </v-btn>
        <v-btn color="#eac252" elevation="8" large @click="dialog = true">
            Upload picture
        </v-btn>
        <v-btn color="#eac252" elevation="8" large v-on:click="$emit('closeEditProfilePic')">
          Back
        </v-btn>
        <!-- v-btn dark elevation="8" large class="btn_width" v-on:click="capturePhoto">
          Capture photo
          <v-icon right>
            mdi-camera
          </v-icon>
        </v-btn -->
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
// import adapter from 'webrtc-adapter';
import CameraVideo from './CameraVideo.vue';
import { takeProfilePic, getBase64Image } from '../../../assets/js/webrtc';

export default {
  name: 'CameraSelection',
  components: { CameraVideo },
  data() {
    return {
      pictureTaken: false,
      image: {},
      imageURL: '',
      basePic: {},
      dialog: false,
      camera: {
        text: localStorage.getItem('camera_name') || 'default',
        value: localStorage.getItem('camera_id') || 'default',
      },
      cameras: [],
    };
  },
  mounted() {
    this.basePic = new Image();
    this.basePic.src = this.iconUrl;
    this.getCameras();
  },
  computed: {
    ...mapGetters('$_userdata', ['room']),
    iconUrl() {
      // eslint-disable-next-line global-require
      // return require('../../../assets/TempProfile.png');
      // eslint-disable-next-line global-require
      let thePic;
      if (this.room === 'Legelisten') {
        // eslint-disable-next-line global-require
        thePic = require('../../../assets/DefaultProfile.png');
      } else {
        // eslint-disable-next-line global-require
        thePic = require('../../../assets/SquareLogo.png');
      }
      // eslint-disable-next-line global-require
      return thePic;
      // eslint-disable-next-line global-require
      // return require('../../../assets/temporary_profile_img.jpg');
    },
  },
  methods: {
    ...mapActions('$_userdata', ['commitProfilePicture']),
    getCameras() {
      if (navigator.mediaDevices.getUserMedia) {
        // console.log(adapter.browserDetails.browser);
        navigator.mediaDevices.enumerateDevices()
          .then((devices) => {
            devices.forEach((device) => {
              if (device.kind === 'videoinput') {
                this.cameras.push({
                  text: device.label,
                  value: device.deviceId,
                });
              }
            });

            this.changeCamera(this.camera);
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(`error: ${err.name}: ${err.message}`);
          });
      } else {
        // todo: alert the user that we didn't find any cameras?
      }
    },
    changeCamera(cameraSelection) {
      this.$refs.CameraVideo.setCamera(cameraSelection.value);
    },
    retakePhoto() {
      this.pictureTaken = false;
      this.image.src = null;
      this.getCameras();
    },
    capturePhoto() {
      if (this.$refs.CameraVideo.checkReady()) {
        const profilePicCanvas = document.createElement('Canvas');
        this.image.src = takeProfilePic(
          profilePicCanvas,
          document.querySelector('video'),
          240,
        ).src;
        // stopping the media stream
        // otherwise some cameras and browser will use the old constraints
        // with the mediaserver
        const video = document.querySelector('video');
        const mediaStream = video.srcObject;
        const tracks = mediaStream.getTracks();
        tracks.forEach((track) => track.stop());
        // end of stopping tracks
        const imgData = getBase64Image(profilePicCanvas);
        // should call a function that stores the picture in DB
        localStorage.setItem('imgData', imgData);
        this.pictureTaken = true;
        this.commitProfilePicture(this.image);
      } else {
        console.log('Camera not ready');
      }
    },
    onFileChange(e) {
      //  eslint-disable-next-line prefer-destructuring
      this.selectedFile = e.target.files[0];
      this.readFile();
    },
    readFile() {
      if (!this.selectedFile) return;
      const reader = new FileReader();
      reader.onload = (e) => {
        this.basePic.onload = () => {
          this.uploadPicture();
        };
        this.basePic.src = e.target.result;
      };

      reader.readAsDataURL(this.selectedFile);
    },
    uploadPicture() {
      const profilePicCanvas = document.createElement('canvas');
      const context = profilePicCanvas.getContext('2d');
      const widthOfVideo = 240;
      profilePicCanvas.width = widthOfVideo;
      profilePicCanvas.height = widthOfVideo;
      // you can use any shape
      context.arc(widthOfVideo / 2,
        widthOfVideo / 2,
        widthOfVideo / 2,
        0,
        Math.PI * 2,
        true);
      context.clip();
      const ratio = this.basePic.videoWidth / this.basePic.videoHeight;
      if (ratio > 1) {
        const viddiff = this.basePic.videoWidth - this.basePic.videoHeight;
        context.drawImage(this.basePic,
          Math.round(viddiff / 2),
          0,
          this.basePic.videoHeight,
          this.basePic.videoHeight,
          0,
          0,
          widthOfVideo,
          widthOfVideo);
      } else if (ratio < 1) {
        const viddiff = this.basePic.videoHeight - this.basePic.videoWidth;
        context.drawImage(this.basePic,
          0,
          Math.round(viddiff / 2),
          this.basePic.videoWidth,
          this.basePic.videoWidth,
          0,
          0,
          widthOfVideo,
          widthOfVideo);
      } else {
        context.drawImage(this.basePic, 0, 0, widthOfVideo, widthOfVideo);
      }
      // context.arc(120, 120, 120, 0, Math.PI * 2, true);
      // context.clip();
      // context.drawImage(this.basePic, 0, 0, 240, 240);
      const data = profilePicCanvas.toDataURL('image/png');
      this.image.src = data;
      this.pictureTaken = true;
      // should call a function that stores the picture in DB
      const imgData = getBase64Image(profilePicCanvas);
      localStorage.setItem('imgData', imgData);
      this.pictureTaken = true;
      this.commitProfilePicture(this.image);
    },
    // uploadPicture() {
    //   // Code here to upload picture from URL or you computer
    //   const file = event.target.files[0];
    //   const reader = new FileReader();
    //   const profilePicCanvas = document.createElement('Canvas');
    //   profilePicCanvas.width = 240;
    //   profilePicCanvas.height = 240;
    //   const context = profilePicCanvas.getContext('2d');
    //   context.arc(120,
    //     120,
    //     120,
    //     0,
    //     Math.PI * 2,
    //     true);
    //   context.clip();
    //   context.drawImage(this.basePic, 0, 0, 240, 240);
    //   const imgData = getBase64Image(profilePicCanvas);
    //   localStorage.setItem('imgData', imgData);
    //   this.image.src = profilePicCanvas.toDataURL('image/png');
    //   this.commitProfilePicture(this.image);
    // },
  },
};
</script>

<style scoped>
  .camera_box {
    margin-top: 23vh;
  }
  .dropdown_width {
    width: 80%;
  }
  .padding_bot {
    padding-bottom: 2vh;
  }
  .padding {
    padding-top: 2vh;
  }
  .center-image {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
</style>
