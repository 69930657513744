<template>
  <div>
    <vue-drag-resize :isActive="this.isScreenActive"
                     :isResizable="false"
                     :w="this.width" :h="this.height"
                    :x="this.left" :y="this.top"
                    v-on:deactivated="onDeactivated"
                   v-on:activated="onActivated">
    <v-card dark class="box" :max-height="this.height" :max-width="this.width">
      <!-- <CloseButton :method="closeParticipantBox" class="close_button" /> -->
      <v-list>
         <v-list-item>
          <v-col>
            <div>
              Raised hands
            </div>
          </v-col>
        </v-list-item>
        <v-list-item v-for="(entry, index)  in raisedHands" :key="index">
          <v-col>
            <div>
              {{ entry.name }}
            </div>
          </v-col>
          <v-col>
            <v-btn fab dark v-on:click="playPart(entry.id)">
                <v-icon>
                  mdi-play
                </v-icon>
              </v-btn>
             </v-col>
            <v-col>
            <v-btn fab dark v-on:click="stopPart(entry.id)">
              <v-icon>
                 mdi-stop
              </v-icon>
            </v-btn>
          </v-col>
        </v-list-item>
      </v-list>
    </v-card>
  </vue-drag-resize>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VueDragResize from 'vue-drag-resize';
import {
  // eslint-disable-next-line no-unused-vars
  playOrStopHandRaiseBroadcat,
} from '../../../assets/js/webrtc';
// import CloseButton from './CloseButton.vue';

export default {
  name: 'RaisedHands',
  data() {
    return {
      top: 0.15 * window.innerHeight,
      left: 0.02 * window.innerWidth,
      width: 0.3 * window.innerWidth,
      height: 0.3 * window.innerHeight,
      isScreenActive: false,
    };
  },
  // components: {
  //   CloseButton, VueDragResize,
  // },
  components: {
    VueDragResize,
  },
  methods: {
    ...mapActions('$_canvas', [
      'commitSetShowParticipantVideoActive',
      'commitSetParticipantActive',
      'commitRemoveRaisedHand',
      'commitSetRaisedHandOverviewActive',
    ]),
    getRaisedHands(userID) {
      for (let i = 0; i < this.raisedHands.length; i += 1) {
        if (this.raisedHands[i].id === userID) {
          return this.raisedHands[i].name;
        }
      }
      return null;
    },
    onDeactivated() {
      this.isScreenActive = false;
    },
    onActivated() {
      const that = this;
      that.isScreenActive = true;
      window.mingly.board.move_keyboard = false;
      setTimeout(() => {
        that.isScreenActive = false;
      }, 2000);
    },
    playPart(id) {
      playOrStopHandRaiseBroadcat(id);
    },
    stopPart(id) {
      console.log('removing this hand');
      console.log(id);
      if (window.mingly.raisedHandData[id].isBroadCasting) {
        playOrStopHandRaiseBroadcat(id);
      } else {
        playOrStopHandRaiseBroadcat(id, true);
      }
      console.log(this.raisedHands);
      this.commitRemoveRaisedHand({
        id,
        name: '',
      });
      const part = {};
      Object.values(window.mingly.raisedHandData).forEach((hands) => {
        if (hands.uuid !== id) {
          part[hands.uuid] = hands;
        }
      });
      window.mingly.raisedHandData = part;
      // eslint-disable-next-line eqeqeq
      if (this.raisedHands.length == 0) {
        this.commitSetRaisedHandOverviewActive();
      }
    },
  },
  computed: {
    ...mapGetters('$_canvas', ['raisedHands']),
  },
};
</script>

<style scoped>
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #b9b9b9;
  }
  .box {
    overflow: auto;
    white-space: nowrap;
  }
  .avatar {
    margin-right: 1vh;
  }
  .own_color {
    color: green;
  }
  .other_part:hover {
    color: red;
  }
  .close_button {
    z-index: 1;
    position: absolute;
    right: 0;
    top: 0;
  }
</style>
