<template>
  <div class="background">
    <v-container fluid class="content-container mt-16">
      <v-row class="mb-5">
        <!-- First Row - Video on the left, Text on the right -->
        <v-col cols="12" md="6">
          <TextBox
            text="Mingly is a video conference platform where
            the audio volume and video selection depend on
            the distance to your fellow minglers. You can
            choose your location by moving on a virtual map."
        />
        </v-col>
        <v-col cols="12" md="6">
        </v-col>
        <v-col cols="12" md="6">
        </v-col>
        <v-col cols="12" md="6">
          <div class="video-wrapper">
            <video
              autoplay
              muted
              loop
              playsinline
              webkit-playsinline
              class="video-style"
              src="https://www.dropbox.com/s/cyft3py40qs6w60/FirstPageV1.mp4?raw=1"
            />
          </div>
        </v-col>
      </v-row>
      <v-row class="mb-5">
        <!-- Second Row - Text on the left, Video on the right -->
        <v-col cols="12" md="6">
          <TextBox
            text="The map provides an organic way of mingling with other people
            you hear the people that are close to you better than
            those further away. Multiple conversations can happen at
            the same time in the same room."
          />
        </v-col>
        <v-col cols="12" md="6">
        </v-col>
        <v-col cols="12" md="6">
        </v-col>
        <v-col cols="12" md="6">
          <div class="video-wrapper">
            <video
              autoplay
              muted
              loop
              playsinline
              webkit-playsinline
              class="video-style"
              src="https://www.dropbox.com/s/na035ifb4m4oqyk/Front%20Page%20Multiple%20Conversations.mp4?raw=1"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <!-- Third Row - Video on the left, Text on the right -->
        <v-col cols="12" md="6">
          <TextBox
            text="With current video conference solutions, you either hear
            full sound or no sound at all… but as we all know,
            interesting things happen in between
            and these are what Mingly captures and provides."
          />
        </v-col>
        <v-col cols="12" md="6">
        </v-col>
        <v-col cols="12" md="6">
        </v-col>
        <v-col cols="12" md="6">
          <div class="video-wrapper">
            <video
              autoplay
              muted
              loop
              playsinline
              webkit-playsinline
              class="video-style"
              src="https://www.dropbox.com/s/lef9ygumdfyjrt5/First%20Page%20Share%20Screen%20on%20Canvas.mp4?raw=1"
            />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <!-- Fourth Row - Text on the left, Video on the right -->
        <v-col cols="12" md="6">
          <TextBox
            text="Mingly provides a space that is highly customizable
             - Easily change the feel or purpose of the room by changing
              the background, adding media such as videos, audio or pictures
               and share your screen."
          />
        </v-col>
        <v-col cols="12" md="6">
        </v-col>
        <v-col cols="12" md="6">
        </v-col>
        <v-col cols="12" md="6">
          <div class="video-wrapper">
            <video
              autoplay
              muted
              loop
              playsinline
              webkit-playsinline
              class="video-style"
              src="https://www.dropbox.com/s/i4e1atw01k6d01s/First%20Page%20N2.mp4?raw=1"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import TextBox from './_component/TextBox.vue';

export default {
  name: 'index',
  components: { TextBox },
};
</script>

<style scoped>
  /* Your existing styles remain unchanged */

  /* Add the following styles for text boxes */
  .v-col.text-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }

  .v-col.text-box .TextBox {
    max-width: 100%;
  }

  /* Adjust padding/margin as needed to create space between rows and columns */
</style>

<script>
import TextBox from './_component/TextBox.vue';

export default {
  name: 'index',
  components: { TextBox },
};
</script>

<style scoped>
  .background {
    background: url('https://www.dropbox.com/s/eeaq8lkpwlvhaio/IMG_2775.jpg?raw=1');
    background-size: cover;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }

  .video-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    position: relative;
    border-radius: 25px;
    overflow: hidden;
    background-color: #203e5fff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }

  .video-style {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .content-container {
    max-height: calc(100vh - 150px); /* Adjust the height as needed */
    overflow-y: auto;
  }
</style>
