// Temporary disables - we need to enable these further down the cleanup road:
// The idea is to have a more fine masked setup than just disabling linting
// This way we avoid introducing new code that breaks linter rules that we obey to

/* eslint-disable no-console, no-alert */
/* eslint-disable func-names */
/* eslint-disable no-restricted-properties */
/* eslint-disable quote-props */
/* eslint-disable prefer-arrow-callback */
/* eslint-disable object-curly-newline */
/* eslint-disable prefer-object-spread */
/* eslint-disable no-unused-vars */
/* eslint-disable no-use-before-define */
/* eslint-disable no-trailing-spaces */
/* eslint-disable no-plusplus */
/* eslint-disable no-multi-spaces */
/* eslint-disable camelcase */
/* eslint-disable prefer-destructuring */
/* eslint-disable block-scoped-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-var */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */

// consider our use of param reassigns, for now, allow prop changes
/* eslint no-param-reassign: ["error", { "props": false }] */

// Magnus' preferences, up for discussion:
/* eslint-disable brace-style,padded-blocks,operator-linebreak,function-paren-newline */

// we need extensions for this to work in the legacy Mingly setup
// eslint-disable-next-line max-classes-per-file
// import axios from 'axios';
import Participant from './participant.js'; // eslint-disable-line import/extensions
import Room from './room.js'; // eslint-disable-line import/extensions
import roomCustomPartySetup from './room_custom_party.js'; // eslint-disable-line import/extensions
import CanvasObject from './canvas_objects.js'; // eslint-disable-line import/extensions
import Mingly from './mingly_class.js'; // eslint-disable-line import/extensions
// eslint-disable-next-line import/no-cycle
import MinglyRTC from './mingly_rtc.js'; // eslint-disable-line import/extensions
// eslint-disable-next-line import/extensions
import CMD from './wssCMDs.js'; 

// const wsurl = 'wss://mingly.io:8443'; // if running via mingly.io
const wsurlPPlocal = 'wss://localhost:8443'; // if running only localhost
// const wsurlPP = 'wss://soup.mingly.io'; // 'wss://mingly.io:8443'; // if running only localhost
// const wsurlMS = 'wss://soup.mingly.io';
// const wsurlPP = 'wss://soup-test-osl-01.mingly.io:3000'; // 'wss://mingly.io:8443'; // if running only localhost
const wsurlPP = 'wss://mingly.io:8443'; 
const wsurlMS = 'wss://soup-test-osl-01.mingly.io:3000';
// const testURL = 'wss://192.168.4.99:8443';
const testURL = 'wss://localhost:8443';

// const wsurl = 'wss://192.168.1.129:8443'; // if running only localhost
// const wsurl = 'wss://2a4a75852f6e.ngrok.io'; // using ngrok, address changes

const WebSocket_CONNECTING = 0;
const WebSocket_OPEN = 1;
const WebSocket_CLOSING = 2;
const WebSocket_CLOSED = 3;

// Global variables
if (!window.mingly) {
  console.log('Initializing Mingly');
  window.mingly = new Mingly();
  // window.mingly.showConsole = false;
  window.mingly.log('created window.mingly');
} else {
  console.log('windows.mingly already existed');
}

var board;

var cameraSelect = document.getElementById('cameraSelect');
var micSelect = document.getElementById('micSelect');
var speakerSelect = document.getElementById('speakerSelect');

var serverConnection;

function loadJSON(path, success, error) {
  const xhr = new XMLHttpRequest();
  xhr.onreadystatechange = function () {
    if (xhr.readyState === XMLHttpRequest.DONE) {
      if (xhr.status === 200) {
        if (success) {
          success(JSON.parse(xhr.responseText));
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (error) {
          error(xhr);
        }
      }
    }
  };
  xhr.open('GET', path, true);
  xhr.send();
}

function gotVersion(data) {
  try {
    addChatMessage((new Date()), me.name, `joined with client ${data.client}, server ${data.server}`);
  }
  catch {
    console.error('unable to handle version data');
  }
}

var participants = {}; // object that holds remote participants
var canvasObjects = {};
var me;
var BigBoard = 1; // can maybe change this

var videoDJ = true;

// fetch version information and print it in chat
loadJSON('./version',
  function (data) { gotVersion(data); },
  function (xhr) { console.error(xhr); },
);

/*
if (localRoom=='B2') {
  const YT = document.createElement("div");
  // YT.setAttribute('autoplay','');
  // YT.setAttribute('muted','');
  YT.setAttribute('id','player');
  // YT.src = "https://www.youtube.com/embed/nwAaGLo8OE8?autoplay=1";
  YT.hidden = true;
  const adding = document.getElementById("boardArea");
  adding.appendChild(YT);
  const tag = document.createElement('script');
  tag.src = "https://www.youtube.com/iframe_api";
  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  window.onYouTubeIframeAPIReady = function () {
    // iframeId parameter should match your Iframe's id attribute
    const player = new YT.Player('player', {
      width: 1,
      height: 1,
      videoId: 'nwAaGLo8OE8',
      playerVars: {
        playlist: 'nwAaGLo8OE8',
        loop: 1
      },
      events: {
        'onReady': function (event) {
          event.target.setVolume(10);
          event.target.playVideo();
        }
      }
    });
  };

}

*/

var room;

function setAllParameters(config, mingly, board) {
  room = new Room();
  mingly.setUpAll(config);
  room.setUpAll(config);
  console.log('hdhdskfs');
  console.log(mingly);
  console.log('afger');
  board.setUpAll(config);
  if (config.backgroundPictureURL) {
    room.backgroundPictureURL = config.backgroundPictureURL;
    room.backgroundPicture.src = room.backgroundPictureURL;
  }
  board.room = room;
}

function setUpRTC(nPeople, serverConnection) {
  // nPeople are how many are in the room
  // should have initialized mingly, board and room there should be
  // server configs in mingly.configRTC
  // might want to remove serverConnection into Mingly at some point here
  const { mingly } = window;
  const  { room } = mingly.board;
  const { participants } = mingly.board;
  const { limitPerServer } = room;
  const  { me } = mingly.board;
  const hostServer = {
    exists: false,
    pos: 0,
  };
  for (let i = 0; i < mingly.configRTC.length; i += 1) {
    if (mingly.configRTC[i].type === 'host') {
      hostServer.exists = true;
      hostServer.pos = i;
      mingly.configRTC[i].type = 'mediasoup'; // maybe dangerous to reassign?
    }
  }
  if (hostServer.exists) {
    const partConfig = [];
    for (let i = 0; i < mingly.configRTC.length; i += 1) {
      if (i !== hostServer.pos) {
        partConfig.push(mingly.configRTC[i]);
      }
    }
    partConfig.push(mingly.configRTC[hostServer.pos]);
    mingly.configRTC = partConfig; // should maybe do setConfigRTC?
  }
  if (me.isHost && hostServer.exists) {
    me.server.type = 'mediasoup';
    mingly.configRTC[mingly.configRTC.length - 1].send = true;
    me.server.url =  mingly.configRTC[mingly.configRTC.length - 1].wssServer;
    mingly.rtc = new MinglyRTC(mingly.configRTC, room.name,
      serverConnection, me, participants);

  } else {
    mingly.log(nPeople, room.capacity, limitPerServer, mingly.configRTC.length);
    if (nPeople > room.capacity) {
      console.log('Too many people in the room');
    } else {
      // random server (should work ok)
      const serverNumber = Math.floor(Math.random() * mingly.configRTC.length);
      console.log('The server number is', serverNumber);
      mingly.configRTC[serverNumber].send = true;
      me.server.url =  mingly.configRTC[serverNumber].wssServer;
      if (room.mediaserver) {
        me.server.type = 'mediasoup';
      } else {
        me.server.type = 'webRTC';
      }
      mingly.rtc = new MinglyRTC(mingly.configRTC, room.name,
        serverConnection, me, participants);

      // const serverNumber = Math.floor(nPeople / limitPerServer);
      // if (serverNumber > mingly.configRTC.length || nPeople > room.capacity) {
      //   console.log('Too many people in the room'); 
      //   // should call some vue function if external to trigger wait or leave
      // } else {
      //   mingly.configRTC[serverNumber].send = true;
      //   me.server.url =  mingly.configRTC[serverNumber].wssServer;
      //   if (room.mediaserver) {
      //     me.server.type = 'mediasoup';
      //   } else {
      //     me.server.type = 'webRTC';
      //   }
      //   mingly.rtc = new MinglyRTC(mingly.configRTC, room.name,
      //     serverConnection, me, participants);
      // }
    }
  }
}
// var pieceMe = document.getElementById("x"+me.x+"y"+me.y);
// pieceMe.innerHTML = me.name

var DJVol = 0.15;
var DJvideo = true;

var gdmOptions = {
  video: true,
  audio: {
    echoCancellation: false,
    noiseSuppression: false,
  },
};

function waitForImageToLoad(imageElement) {
  return new Promise((resolve) => {
    imageElement.onload = resolve;
  });
}

function addZero(sText, iCount = 2) {
  let res = sText;
  while (res.toString().length < iCount) {
    res = `0${res}`;
  }
  return res;
}

function niceTime(dTimestamp = new Date()) {
  // eslint-disable-next-line prefer-template
  return addZero(dTimestamp.getHours()) + ':' + addZero(dTimestamp.getMinutes()) + ':' + addZero(dTimestamp.getSeconds());
}

function addChatMessage(dTimestamp, sDisplayName, sMessage) {
  // todo: keep track of the date of the last message
  // add an additional line i the chatlog ('---- date ----') when we reach a new day
  // eslint-disable-next-line prefer-template
  const newmessage = document.createTextNode(niceTime(dTimestamp) + ' ' + sDisplayName + ': ' + sMessage);
  const newpost = document.createElement('p');
  newpost.appendChild(newmessage);
  document.getElementById('chatTextArea').prepend(newpost);
}

function chatMessage(message, receiver) {
  const { me } = window.mingly.board;
  const timestamp = new Date();
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.Chat }, me.asJSON(), { 'datetime_utc': timestamp.toUTCString(), 'chatmessage': message, 'dest': receiver })));
}

function processOutgoingChatMessage() {
  const mymessage = document.getElementById('myChatMessage');
  // only actual messages should be sent/displayed
  if (mymessage.value.length !== 0) {
    const timestamp = new Date();

    // todo: consider adding private messages

    // send message to other participants
    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.Chat }, me.asJSON(), { 'datetime_utc': timestamp.toUTCString(), 'chatmessage': mymessage.value, 'dest': 'all' })));

    // update chat area and clean up input box
    addChatMessage(timestamp, me.name, mymessage.value);
    mymessage.value = '';
  }
}

function processSwitchBackGround() {
  const picurl = document.getElementById('backGroundInput');
  // only actual messages should be sent/displayed
  if (picurl.value.length !== 0) {
    HostChangeBackGroundPicture(picurl.value);
    picurl.value = '';
  }
}
function createAudioContainer(participant) {
  const { audioOutSource } = window.mingly;
  const { allowSpeakerSelect } = window.mingly;
  if (!document.getElementById(`remoteAudio_${participant.uuid}`)) {
    const audElement = document.createElement('audio');
    audElement.setAttribute('autoplay', '');
    try {
      if (allowSpeakerSelect && audioOutSource &&
        window.adapter.browserDetails.browser === 'chrome') {
        audElement.setSinkId(audioOutSource);
      }
    }
    catch {
      window.mingly.log('cannot set sinkId');
    }
  
    audElement.srcObject = participant.peerStreams; // need to get audiotracks

    const audContainer = document.createElement('div');
    audContainer.setAttribute('id', `remoteAudio_${participant.uuid}`);
    audContainer.setAttribute('class', 'audioContainer');
    audContainer.appendChild(audElement);

    document.getElementById('audios').appendChild(audContainer);
    // audElement.srcObject.volume = 0;
    // audContainer.childNodes[0].volume = 0;

  }
}

function createVideoContainer(participant) {
  const { audioOutSource } = window.mingly;
  const { allowSpeakerSelect } = window.mingly;

  if (!participant.videoElement) {
    participant.videoElement = document.createElement('video');
    participant.videoElement.setAttribute('id', `vid_${participant.uuid}`); // not sure we need this
    participant.videoElement.setAttribute('playsinline', '');
  }

  const vidContainer = document.createElement('div');
  vidContainer.setAttribute('id', `remoteVideo_${participant.uuid}`);
  vidContainer.setAttribute('class', 'videoContainer');
  vidContainer.appendChild(participant.videoElement);
  vidContainer.appendChild(makeLabel(participant.name));

  vidContainer.addEventListener('click', function (event) {
    if (this.getAttribute('class') === 'videoContainer') {
      this.setAttribute('class', 'videoContainerBig');
      // nameTopOld = nameTop;
      // nameLeftOld = nameLeft;
      // nameTop = 5;
      // nameLeft = 285;
      // document.documentElement.style.setProperty('--nameTop',nameTop.toString()+'px');
      // document.documentElement.style.setProperty('--nameLeft',nameLeft.toString()+'px');
    }
    else {
      this.setAttribute('class', 'videoContainer');
      window.mingly.board.fullscreenNow = false;
      // nameTop = nameTopOld;
      // nameLeft = nameLeftOld;
      // document.documentElement.style.setProperty('--nameTop',nameTop.toString()+'px');
      // document.documentElement.style.setProperty('--nameLeft',nameLeft.toString()+'px');
      if (window.mingly.board.type === 'Canvasboard') {
        document.getElementById('mainCanvas').hidden = false;
        document.getElementById('videoContainerArea').hidden = true;
      }
    }
  },
  false);

  // highlight name (and avatar?) on mouse over
  vidContainer.addEventListener('mouseover', function (event) {
    const peerUuid = this.id.replace('remoteVideo_', '');
    if (peerUuid) {
      const elem = document.getElementById(`listusers_${peerUuid}`);
      if (elem) {
        elem.style.backgroundColor = 'red';
      }
    }
  });

  vidContainer.addEventListener('mouseout', function (event) {
    const peerUuid = this.id.replace('remoteVideo_', '');
    if (peerUuid) {
      const elem = document.getElementById(`listusers_${peerUuid}`);
      if (elem) {
        elem.style.backgroundColor = '';
      }
    }
  });

  document.getElementById('videos').appendChild(vidContainer);

  // participant.videoElement.setAttribute('autoplay', '');
  try {
    if (allowSpeakerSelect && audioOutSource &&
      window.adapter.browserDetails.browser === 'chrome') {
      participant.videoElement.setSinkId(audioOutSource);
    }
  }
  catch {
    window.mingly.log('cannot set sinkId');
  }

  // try {
  //   if (board.type === 'Canvasboard') {
  //     participant.videoElement.addEventListener('play',
  //       participant.onVideoPlay.bind(participant), false)
  //   }
  // }
  // catch {}
}

// create a silent track

// called on me.MoveTo
function meOnMove(participant) {
  // update remote connections
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.Move }, participant.asJSON(), { 'moveit': 1, 'dest': 'all', 'room': participant.board.room.name })));

  // if(this.speedX<2 && this.speedY<2){
  //  updateConnections(); //maybe move this out to do it when keyup or otherwise?
  // Based on old comment. Do low speed. MOVED IT OUT
  // MIGT WANT TO ADJUST VOLUME HERE
  adjustVolumeAll();
}

function meOnCursorMove(participant) {
  // update remote connections
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.MoveCursor }, participant.asJSON(), { 'active': participant.cursor.active,
    'x': participant.cursor.x, 
    'y': participant.cursor.y,
    'dest': 'all', 
    'room': participant.board.room.name })));

  // if(this.speedX<2 && this.speedY<2){
  //  updateConnections(); //maybe move this out to do it when keyup or otherwise?
  // Based on old comment. Do low speed. MOVED IT OUT
  // MIGT WANT TO ADJUST VOLUME HERE
  adjustVolumeAll();
}

function getUniformSoundVolumeLevel() {
  return DJVol;
}

function boardOnUpdateConnections(moveMe) {
  updateConnections();
  if (moveMe) {
    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.Move }, me.asJSON(), { 'moveit': 1, 'dest': 'all', 'room': me.board.room.name })));
  }
}

function boardAskToFollowView(participant) {
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.AskToFollowView }, me.asJSON(), { 'dest': participant.uuid })));  
}

function boardLeadingView() {
  const { board } = window.mingly;
  if (me.following.isLeading) {
    const { ctx } = window.mingly.board;
    const { ctx1 } = window.mingly.board;
    const { me } = window.mingly.board;
    const mat1 = {
      a: ctx.getTransform().a,
      b: ctx.getTransform().b,
      c: ctx.getTransform().c,
      d: ctx.getTransform().d,
      e: ctx.getTransform().e,
      f: ctx.getTransform().f,
    };
    const mat2 = {
      a: ctx1.getTransform().a,
      b: ctx1.getTransform().b,
      c: ctx1.getTransform().c,
      d: ctx1.getTransform().d,
      e: ctx1.getTransform().e,
      f: ctx1.getTransform().f,
    };
    // dim is the viewpoint of the sender in terms
    // of the size of the browser window
    const dim = {
      height: window.innerHeight,
      width: window.innerWidth,
    };
    // This is for the objects so we update
    const groupLevels = {};
    Object.values(board.canvasObjects).forEach((object) => {
      if (object.type === 'control') {
        groupLevels[object.groupName] = {
          name: object.groupName,
          level: object.currentLevel,
        };
      } 
    });
    let test = false;
    // if only one element of participants in following
    if (me.following.participants === 'all') {
      test = true;
    }
    Object.values(me.following.participants).forEach((participant) => {
      if (participant === 'all') {
        test = true;
      }
    });
    if (test) {
      serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.LeadingView }, me.asJSON(), { 'dest': 'all', 'mat1': mat1, 'mat2': mat2, 'dim': dim, 'groupLevels': groupLevels })));  
    } else {
      // sure this can be made better by not sending separate messages to all
      // need to "arrayify" the websocket communication here
      Object.values(me.following.participants).forEach((participant) => {
        serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.LeadingView }, me.asJSON(), { 'dest': participant, 'mat1': mat1, 'mat2': mat2, 'dim': dim, 'groupLevels': groupLevels })));  
      });
    }
  }
}

// function iAmHost() {
//   const { mingly } = window;
//   const { me } = mingly.board;
//   me.isHost = true;
//   if (mingly.onMeIsHost) {
//     try {
//       mingly.onMeIsHost();
//     }
//     catch {
//       mingly.log('Could not trigger I am host');
//     }
//   }
// }
// THE START FUNCTION
function start(board, external, loginName, displayName, roomName, avatar, setViewAndPos = null) {
  console.log('Starting');
  const { mingly } = window;
  const { rooms } = mingly;
  const { videoSource } = mingly;
  const { audioSource } = mingly;
  const { audioOutSource } = mingly;

  // measure message stream over websockets
  if (mingly.measureMessages.active) {
    setInterval(function () {
      const deltaMessage = mingly.measureMessages.messages - mingly.measureMessages.oldMessages;
      const time  = Date.now();
      const deltaTime = (time -  mingly.measureMessages.time) / 1000;
      mingly.measureMessages.time = time;
      mingly.measureMessages.oldMessages = mingly.measureMessages.messages; 
      console.log(deltaMessage / deltaTime); 
    }, 1000);
  }

  mingly.initialPositionAndView = setViewAndPos;

  // set of mediasoup. This should be optional maybe
  // try {
  //   mingly.device = new window.mediaServerClient.mediasoupClient.Device();
  //   mingly.log('setting up Mediasoup');
  // } catch (e) {
  //   if (e.name === 'UnsupportedError') {
  //     mingly.error('browser not supported for video calls');
  //     return;
  //   } // check if correct as used to have else 
  // }
 
  mingly.board = board;
  mingly.board.isExternal = external;

  // Some room for special setting
  if (roomName === 'ROIWAN') {
    mingly.board.maxZoom = 500;
    // setUpEasterEgg();
    setUpGalleryChristian();
  }

  if (roomName === 'TestRoom8') {
    mingly.board.maxZoom = 500;
    setUpEasterEgg();
  }
  if (roomName === 'HaakonHeyer') {
    setUpHaakonsRoom();
  }
  if (roomName === 'ChristiansRoom') {
    setUpChristiansRoom();
  }

  if (roomName === 'FinansiellStyring') {
    setupFinanisellStyring();
  }

  if (roomName === 'Legelisten') {
    setLegelistensRoom();
  }
  if (roomName === 'MinglyASMeeting') {
    setUpMinglyASMeeting();
  }
  
  // might want to check if there is an host?
  setUpHostArea();
  // temporary to test quiz mode
  // mingly.board.quiz.inQuizMode = true;
  // mingly.board.quiz.alternatives = 5;
  
  if (board.type === 'Canvasboard') {
    board.onUpdateConnections = boardOnUpdateConnections;
  }
  board.onLeadingView = boardLeadingView;
  // not sure this works well, but...
  board.onAskToFollowView = (participant) => boardAskToFollowView(participant);

  // var xstart = 10000000;
  // var ystart = 10000000; //starts outside the board
  if (!displayName) {
    mingly.log('start: loading displayName from sessionStorage');
    displayName = sessionStorage.getItem('local_displayname');
    mingly.log(`start: displayName=${displayName}`);
  }

  if (!displayName) {
    // todo: fail in some spectatular way?
    mingly.log('start: overriding displayName name');
    if (localStorage.getItem('displayName')) {
      displayName = localStorage.getItem('displayName');
    } else {
      displayName = 'Mingly';
    }
    mingly.log(`start: displayName=${displayName}`);
  }

  if (!avatar) {
    mingly.log('start: overriding avatar');
    avatar = '0x1F603';
  }

  if (!loginName) {
    mingly.log('start: loading loginName from sessionStorage');
    loginName = sessionStorage.getItem('userName'); // todo: loginName?
    if (!loginName) {
      mingly.log('start: overriding login name');
      loginName = 'Mingly';
    }
    mingly.log(`start: loginName=${loginName}`);
  }

  if (!roomName) {
    // todo: fail with bravado ?
    roomName = 'A1';
    mingly.log(`start: overriding room: ${roomName}`);
  }

  try {
    // changed from imageTaken.length>0 which is Global to check if image is in localstorage
    if (localStorage.getItem('imgData')) {
      me = new Participant(mingly, displayName, localStorage.getItem('imgData'), 0, 0);
    }
    else {
      me = new Participant(mingly, displayName, avatar, 0, 0);
    }
  }
  catch {
    // set tha avatar to standard emoji
    me = new Participant(mingly, displayName, '0x1F603', 0, 0);
    // eslint-disable-next-line no-bitwise
    me.color = `#${((1 << 24) * Math.random() | 0).toString(16)}`; // random color
    // !participant.usepicture
  }

  me.isLocal = true;
  me.uuid = createUUID();
  me.board = board;
  me.videoElement = board.localVideo;
  me.videovar = 1;
  // if (room.mutedOnEntry) {
  //   me.muted = true;
  // }
  // the next might be an issue as we might need a promise 
  if (!me.usepicture) {
    // eslint-disable-next-line no-bitwise
    me.color = `#${((1 << 24) * Math.random() | 0).toString(16)}`;
  }
  // this should maybe be set somewhere else
  me.flipVideo = true;

  // todo: might want to move this into participant class.
  // It is used to take care of cpu and gpu issues when rescaling map etc
  me.playeNone = false;
  // check if I am host
  if (window.mingly.isHost) {
    me.isHost = true;
  }

  me.onMove = meOnMove;
  me.onCursorMove = meOnCursorMove;
  me.onGetUniformSoundVolumeLevel = getUniformSoundVolumeLevel;

  board.isTouch = window.matchMedia('(any-pointer: coarse)').matches;
  board.isIpad = ((navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2));

  if (board.isIpad) {
    // todo: we might use this later
    // only using the if ipad at the moment. This allows for "editing" the audio components
    // const AudioContext = window.AudioContext || window.webkitAudioContext;
  }

  board.me = me;
  board.onMuteDistantParticipants = muteDistantParticipants;
  if (board.room) {
    room = board.room;
  } else {
    // eslint-disable-next-line no-lonely-if
    if (loginName && loginName.substr(0, 12) === 'MinglyTester') {
      room = rooms[roomName.substr(13, roomName.length)];
    }
    else {  
      room = rooms[roomName];
    }
  }

  // room not found? use the default one
  if (!room) {
    room = new Room(roomName, 6, 20, 10000, 1, 10, 10000, true, 1.5, false);
    room.editBandWidth = true;
    room.editVideoBandWidthOnly = true;
    // room.backgroundPicture.src = 'https://www.dropbox.com/s/fk0u89rhzxfkcmi/IMG_7328.jpeg?raw=1';
    room.backgroundPictureURL =  'https://www.dropbox.com/s/eeaq8lkpwlvhaio/IMG_2775.jpg?raw=1';
    room.backgroundPicture.src = room.backgroundPictureURL;
    board.doBackground = true;
    board.videoBackGroundStyle = 2;
    board.videoBorderWidth = 2;
  } else {
    roomCustomPartySetup(roomName, board);
  }

  if (room.scalingFactor) {
    board.setScalingFactor(room.scalingFactor);
  }
 
  // set input background picture
  // if (backPic) {
  //   room.backgroundPicture.src = backPic;
  //   room.currentBackGroundPicture = backPic;
  //   // first person that enters sets the picture
  //   room.backGroundPictureUpdated = true;
  // }

  board.room = room;

  // temporary way of setting host. Should be from server
  if (board.me.name.match('(\\[Mingly Crew\\])')) {
    mingly.log('I am HOST!!!!!');
    board.me.isHost = true;
    if (mingly.onMeIsHost) {
      try {
        mingly.onMeIsHost();
      }
      catch {
        mingly.log('Could not trigger I am host');
      }
    }
  }

  // temprary for Testing
  const testRooms = [
    'TestRoom0',
    'TestRoom1',
    'TestRoom2',
    'TestRoom3',
    'TestRoom4',
    'TestRoom5',
    'TestRoom6',
    'TestRoom7',
    'TestRoom8',
    'TestRoom9',
    'TestRoom10',  
  ];
  Object.values(testRooms).forEach((testroom) => {
    if (testroom === board.room.name) {
      board.room.testing = true;
    }
  });

  // board.room.testing = true;

  if (board.room.name === 'A1' || board.room.name === 'TestingRoomMS'
      ||  board.room.name === 'TestRoom8' || board.room.name === 'F300Spring2021'
      ||  board.room.name === 'TestRoom9' ||  board.room.name === 'TestRoom10'
      || board.room.name === 'F300Spring2022-8243' || board.room.name === 'F300Spring2022-10523'
      || board.room.name === 'VicoteeRoom' || board.room.name === 'SafetykleenRoom'
      || board.room.name === 'TeamMarriott' || board.room.name === 'MinglyStudio'
      || board.room.name === 'MinglyFriends' || board.room.name === 'ROIWAN'
      || board.room.name === 'HaakonHeyer') {
    board.room.mediaserver = true;
  }

  if (board.room.name === 'TeamMarriott' || board.room.name === 'MinglyStudio'
      || board.room.name === 'MinglyFriends' || board.room.name === 'ROIWAN'
      || board.room.name === 'HaakonHeyer') {
    board.room.videoWidth = 640;
    board.room.videoHeight = 480;
  }

  if (board.room.name === 'F300Spring2022-8243' || board.room.name === 'F300Spring2022-10523') {
    setUpF300();
  }

  if (board.room.name === 'MinglyWorld' || board.room.name === 'YCdemoroom') {
    setUpMinglyWorld();
  }

  if (board.room.name === 'MinglyTesting') {
    setUpMinglyBIPresentation();
  }
 
  if (board.room.name === 'MinglyV1Presentation') {
    console.log('test');
    setUpMinglyV1Presentation();
    board.room.toggleViews = [
      {
        mat: {
          a: 10,
          b: 0,
          c: 0,
          d: 10,
          e: -323,
          f: -100,
        },
        dim: {
          height: 2000,
          width: 3000,
        },
        me: {
          x: 200,
          y: 420,
        },
        transition: 'smooth',
      },
      {
        mat: {
          a: 10,
          b: 0,
          c: 0,
          d: 10,
          e: -3823,
          f: -100,
        },
        dim: {
          height: 2000,
          width: 3000,
        },
        me: {
          x: 200,
          y: 420,
        },
        transition: 'smooth',
      },
      {
        mat: {
          a: 10,
          b: 0,
          c: 0,
          d: 10,
          e: -7323,
          f: -100,
        },
        dim: {
          height: 2000,
          width: 3000,
        },
        me: {
          x: 200,
          y: 420,
        },
        transition: 'smooth',
      },
      {
        mat: {
          a: 10,
          b: 0,
          c: 0,
          d: 10,
          e: -10823,
          f: -100,
        },
        dim: {
          height: 2000,
          width: 3000,
        },
        me: {
          x: 200,
          y: 420,
        },
        transition: 'smooth',
      },
      {
        mat: {
          a: 10,
          b: 0,
          c: 0,
          d: 10,
          e: -5500,
          f: -3300,
        },
        dim: {
          height: 2000,
          width: 3000,
        },
        me: {
          x: 200,
          y: 420,
        },
        transition: 'smooth',
      },
      {
        mat: {
          a: 10,
          b: 0,
          c: 0,
          d: 10,
          e: -323,
          f: -5900,
        },
        dim: {
          height: 2000,
          width: 3000,
        },
        me: {
          x: 284,
          y: 669,
        },
        transition: 'smooth',
      },
      {
        mat: {
          a: 10,
          b: 0,
          c: 0,
          d: 10,
          e: -10823,
          f: -5900,
        },
        dim: {
          height: 2000,
          width: 3000,
        },
        me: {
          x: 200,
          y: 420,
        },
        transition: 'smooth',
      },
    ];
  }

  if (board.room.name === 'MinglyBINettstudies') {
    board.room.toggleViews = [
      {
        mat: {
          a: 3.36,
          b: 0,
          c: 0,
          d: 3.36,
          e: -144,
          f: -412,
        },
        dim: {
          height: 1676,
          width: 2228,
        },
        me: {
          x: 179,
          y: 323,
        },
        transition: 'smooth',
      },
      {
        mat: {
          a: 1.15,
          b: 0,
          c: 0,
          d: 1.15,
          e: 270,
          f: 410,
        },
        dim: {
          height: 1676,
          width: 2228,
        },
        me: {
          x: 701,
          y: 402,
        },
        transition: 'smooth',
      },
      {
        mat: {
          a: 4,
          b: 0,
          c: 0,
          d: 4,
          e: -3320,
          f: -733,
        },
        dim: {
          height: 1676,
          width: 2228,
        },
        me: {
          x: 911,
          y: 309,
        },
        transition: 'smooth',
      },
      // {
      //   mat: {
      //     a: 1.15,
      //     b: 0,
      //     c: 0,
      //     d: 1.15,
      //     e: 270,
      //     f: 410,
      //   },
      //   dim: {
      //     height: 1676,
      //     width: 2228,
      //   },
      //   me: {
      //     x: 701,
      //     y: 402,
      //   },
      //   transition: 'smooth',
      // },
      {
        mat: {
          a: 3.5,
          b: 0,
          c: 0,
          d: 3.5,
          e: -614,
          f: -960,
        },
        dim: {
          height: 1676,
          width: 2228,
        },
        me: {
          x: 411,
          y: 530,
        },
        transition: 'smooth',
      },
    ];
  }

  if (board.room.name === 'MinglyPitch') {
    // creating the toggles
    board.room.toggleViews = [
      {
        mat: {
          a: 54.82685583540641,
          b: 0,
          c: 0,
          d: 54.82685583540641,
          e: -6878.85129378897,
          f: -1506.0574246237973,
        },
        dim: {
          height: 1431,
          width: 3167,
        },
        me: {
          x: 152,
          y: 40,
        },
        transition: 'smooth',
      },
    ];
    const NpicLength = 8;
    const NpichHeight = 7;
    for (let i = 0; i < NpichHeight; i++) {
      for (let j = 0; j < NpicLength; j++) {
        const part = {
          mat: {
            a: 12.5,
            b: 0,
            c: 0,
            d: 12.5,
            e: -250 - j * 172 * 12.5,
            f: -250 - i * 100 * 12.5, // aspect ratio of 540/960
          },
          dim: {
            height: 1431,
            width: 3167,
          },
          transition: 'smooth',
        };
        board.room.toggleViews.push(part); 
      }
    }
    board.room.toggleViews[3].me = {
      x: 609,
      y: 40,
    };

    // board.room.toggleViews = [
    //   {
    //     mat: {
    //       a: 54.82685583540641,
    //       b: 0,
    //       c: 0,
    //       d: 54.82685583540641,
    //       e: -6878.85129378897,
    //       f: -1506.0574246237973,
    //     },
    //     dim: {
    //       height: 1431,
    //       width: 3167,
    //     },
    //     me: {
    //       x: 152,
    //       y: 40,
    //     },
    //   },
    //   {
    //     mat: {
    //       a: 12.685701568380171,
    //       b: 0,
    //       c: 0,
    //       d: 12.685701568380171,
    //       e: -233.4482667420359,
    //       f: -170.42558153006803,
    //     },
    //     dim: {
    //       height: 1431,
    //       width: 3167,
    //     },
    //     transition: 'smooth',
    //   },
    //   {
    //     mat: {
    //       a: 12.685701568380171,
    //       b: 0,
    //       c: 0,
    //       d: 12.685701568380171,
    //       e: -2298.448238654807,
    //       f: -170.42558153006803,
    //     },
    //     dim: {
    //       height: 1431,
    //       width: 3167,
    //     },
    //     transition: 'smooth',
    //   },
    // ];
    setUpMinglyPitch();
  }

  if (board.room.name === 'F300' || board.room.name === 'F3007711' || board.room.name === 'F3009644') {
    setUpF300V2();
  }
  const widthpc = 100 / board.width;
  const heightpx = Math.round(480 / board.height);
  let AvatarSize;
  const maxDimBoard = Math.max(board.width, board.height);
  switch (maxDimBoard) {
    case 32:
      AvatarSize = 9;
      break;

    default:
      AvatarSize = 18;
  }
  mingly.log({ widthpc });
  mingly.log({ heightpx });

  document.documentElement.style.setProperty('--bHeight', `${heightpx.toString()}px`);
  document.documentElement.style.setProperty('--bWidth', `${widthpc.toString()}%`);
  document.documentElement.style.setProperty('--AvatarSize', `${AvatarSize.toString()}px`);

  board.participants = participants;
  board.canvasObjects = canvasObjects;
  // start listening for keystrokes
  createKeyboardEventListeners();

  // add listeners for window events

  // todo: move to Canvasboard, listen to parentContainer
  window.addEventListener('resize', function () { window.mingly.board.resizeCanvas(); }, false);

  window.addEventListener('beforeunload', function () {
    localStorage.setItem('displayName', window.mingly.board.me.name);
    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.Leaving }, me.asJSON(), { 'dest': 'all' })));
  });

  CanvasRenderingContext2D.prototype.roundRect = function (x, y, width, height, radius) {
    if (width < 2 * radius) radius = width / 2;
    if (height < 2 * radius) radius = height / 2;
    this.beginPath();
    this.moveTo(x + radius, y);
    this.arcTo(x + width, y, x + width, y + height, radius);
    this.arcTo(x + width, y + height, x, y + height, radius);
    this.arcTo(x, y + height, x, y, radius);
    this.arcTo(x, y, x + width, y, radius);
    this.closePath();
    return this;
  };
  
  if (board.constructor.name === 'Chessboard') {
    InitialBigBoard();
  }

  updateLayout();

  const constraints = {};
  mingly.constraints = constraints;

  // // specify no audio for user media

  constraints.audio = true;
  constraints.video = {};
  if (videoSource) {
    constraints.video = { deviceId: { ideal: videoSource } };
  }
  constraints.video.width = {};
  constraints.video.height = {};
  constraints.video.width[room.videoConfig] = room.videoWidth;
  constraints.video.height[room.videoConfig] = room.videoHeight;

  // simple constraints as this is handled by the mediaserver
  // if (board.room.mediaserver) {
  //   constraints.video = true;
  //   constraints.audio = true;
  // }

  // else {
  // constraints.video.facingMode = 'user';
  // }

  if (audioSource) {
    constraints.audio = { deviceId: { ideal: audioSource } };
  }

  if (room.smoothAudio) {
    constraints.audio = Object.assign(constraints.audio, {
      googEchoCancellation: true,
      googAutoGainControl: true,
      googNoiseSuppression: true,
      googHighpassFilter: true,
      googNoiseSuppression2: true,
      googEchoCancellaton2: true,
      googAutoGainControl2: true,
    });
  }

  if (room.frameRate) {
    constraints.video.frameRate = {};
    constraints.video.frameRate.max = room.frameRate;
  }
  
  // if(isIpad){
  //   //certain constraints do not work on the ipad
  //   constraints = {
  //     audio: true,
  //     video: true
  //   };
  // }

  // create the choice of device here

  // set up local video stream
  if (navigator.mediaDevices.getUserMedia) {
    navigator.mediaDevices.getUserMedia(constraints)
      .then((stream) => {
        window.mingly.streamLowRes = stream;
        // window.window.mingly.streamLowRes = window.mingly.streamLowRes; // used if editing
        me.videoElement.srcObject = stream;
        me.videoElement.onplaying = function () {
          me.isPlayingVideo = true;
        };

        me.videoElement.onpause = function () {
          me.isPlayingVideo = false;
        };

        me.resumeVideo();
        window.mingly.streamBlack = stream.clone();
        window.mingly.streamBlack.getVideoTracks()[0].enabled = false;
        window.mingly.streamBlackSilence = stream.clone();
        window.mingly.streamBlackSilence.getVideoTracks()[0].enabled = false;
        window.mingly.streamBlackSilence.getAudioTracks()[0].enabled = false;
      })
      .catch(errorHandler)
      // set up websocket and message all existing clients
      .then(() => {
        // Here we can have some ways of doing different servers for Mingly maybe
        // we will get a list of servers that are used in the room
        // one or more will be our servers 
        // For now we only do mediaserver or not
        // setUpTestingServer('ws://localhost:3001');
        // update N users
        setInterval(function () {
          const partCalc = Object.keys(window.mingly.board.participants).length;
          window.mingly.board.room.nParticipants =  partCalc + 1;
        }, 1000);
        // setUpTestingServer('wss://mingly.io:3443');
        // setUpTestingServer('ws://localhost:3001');
        console.log('This is the wsurl part');
        console.log(window.mingly.wsurl);
        if (!window.mingly.wsurl) {
          // if (window.mingly.board.room.name === 'F300Spring2022-8243') {
          //   window.mingly.wsurl = 'wss://soup-us-east-2-01.mingly.io:8443';
          // } else if (window.mingly.board.room.name === 'F300Spring2022-10523') {
          //   window.mingly.wsurl = 'wss://soup-us-east-2-02.mingly.io:8443';
          // } else if (window.mingly.board.room.name === 'TestRoom9') {
          //   window.mingly.wsurl = 'wss://soup-bgo-01.mingly.io:8443';
          // } else {
          //   // window.mingly.wsurl = 'wss://soup.mingly.io';
          window.mingly.wsurl = 'wss://localhost:8443';
          // window.mingly.wsurl = 'wss://192.168.4.99:8443';
          // window.mingly.wsurl = 'wss://soup-test-osl-01.mingly.io:3000';
          // }
        }
        // if (room.mediaserver) {
        //   window.mingly.wsurl = wsurlMS;
        // } else {
        //   window.mingly.wsurl = wsurlPP;
        // }
        // this websocket server should be the "main communication server"
        // it does not have to be the webRTC or mediaserver (signaling)
        connectToWebSocket(window.mingly.wsurl);
        // connectToWebSocket(wsurlPPlocal);
      })
      .catch(errorHandler);
  } else {
    alert('Your browser does not support getUserMedia API');
  }
}

function setUpTestingServer(wsurl) {
  const { mingly } = window;
  mingly.log('setting Up server for test statistics');
  mingly.wsBenchmarkingServer = new WebSocket(wsurl);
  // add eventlisteners here
  mingly.wsBenchmarkingServer.onopen = gotConnectionBenchmarkingServer;
}

function gotConnectionBenchmarkingServer() {
  window.mingly.log('Connected to benchmarking server');
}

function connectToWebSocket(wsurl) {
  window.mingly.log(`Connecting to websocket: ${wsurl}`);
  serverConnection = new WebSocket(wsurl);
  serverConnection.onmessage = gotMessageFromServer;
  serverConnection.onopen = gotConnectionToWebSocket;
  serverConnection.onerror = problemsConnectionWebSocket;
  serverConnection.onclose = closedTheWebSocket;
}

function closeLogicServer(testReconnect = false) {
  const { mingly } = window;
  // Closing the websocket
  // This should in principle not trigger a restart as we exclude volentary closures 
  serverConnection.close();
  if (testReconnect) {
    setTimeout(() => {
      console.log('reconnecting');
      connectToWebSocket(mingly.wsurl);
    }, 3000);
  }
}

function sendStatisticsMS(participant, consumer) {
  const { room } = window.mingly.board;
  const { me } = window.mingly.board;
  const { mingly } = window;
  const statInterval = setInterval(() => {
    // Check if consumer is there
    if (!consumer.closed) {
      mingly.log(consumer);
      consumer.getStats(null).then((stats) => {
        stats.forEach((stat) => {
          if (stat.type === 'inbound-rtp' && stat.kind === 'video') {
            let framesPs;
            if (stat.framesPerSecond) {
              framesPs = stat.framesPerSecond;
            } else {
              framesPs = 'NaN';
            }
            mingly.log(`frame per second: ${stat.framesPerSecond}`);
            const data = {
              'room': room.name,
              'Nparticipants': room.nParticipants,
              'mediaserver': 'true',
              'myID': me.uuid,
              'myName': me.name,
              'participantID': participant.uuid,
              'participantName': participant.name,
              'me.x': me.x,
              'me.y': me.y,
              'participant.x': participant.x,
              'participant.y': participant.y,
              'videovar': participant.videovar,
              'browser': window.adapter.browserDetails.browser,
              'timestamp': stat.timestamp,
              'remoteID': stat.remoteId,
              'packetsLost': stat.packetsLost,
              'bytesReceived': stat.bytesReceived,
              'jitter': stat.jitter,
              'framesPerSecond': framesPs,
              'jitterBufferDelay': stat.jitterBufferDelay,
              'frameWidth': stat.frameWidth,
              'frameHeight': stat.frameHeight,
            };
            // console.log(data);
            try {
              window.mingly.wsBenchmarkingServer.send(JSON.stringify(data));
            } catch {
              mingly.log('could not send over becnhmarking server');
            }
          }
        });
      });
    } else {
      mingly.log('Closing interval statistics');
      clearInterval(statInterval);
    }
  }, 5000);
}
function sendStatistics(participant) {
  // pc is the peer connection
  const { mingly }  = window;
  const { room } = mingly.board;
  const { me } = mingly.board;
  const pc = participant.peerConnection; 
  // const { me } = window.mingly.board;
  const statInterval = setInterval(() => {
    if (pc.iceConnectionState === 'failed' || pc.iceConnectionState === 'failed' 
    || pc.iceConnectionState === 'disconnected' || pc.iceConnectionState === 'closed') {
      mingly.log('Closing interval statistics');
      clearInterval(statInterval);
    } else {
      pc.getStats(null).then((stats) => {
        // mingly.wsBenchmarkingServer.send(JSON.stringify(stats));
        stats.forEach((stat) => {
          if (stat.type === 'inbound-rtp' && stat.kind === 'video') {
            mingly.log(`frame per second: ${stat.framesPerSecond}`);
            let framesPs;
            if (stat.framesPerSecond) {
              framesPs = stat.framesPerSecond;
            } else {
              framesPs = 'NaN';
            }
            mingly.wsBenchmarkingServer.send(JSON.stringify(Object.assign({
              'room': room.name,
              'Nparticipants': room.nParticipants,
              'mediaserver': 'false',
              'myID': me.uuid,
              'myName': me.name,
              'participantID': participant.uuid,
              'participantName': participant.name,
              'me.x': me.x,
              'me.y': me.y,
              'participant.x': participant.x,
              'participant.y': participant.y,
              'videovar': participant.videovar,
              'browser': window.adapter.browserDetails.browser,
              'timestamp': stat.timestamp,
              'remoteID': stat.remoteId,
              'packetsLost': stat.packetsLost,
              'bytesReceived': stat.bytesReceived,
              'jitter': stat.jitter,
              'framesPerSecond': framesPs,
              'jitterBufferDelay': stat.jitterBufferDelay,
              'frameWidth': stat.frameWidth,
              'frameHeight': stat.frameHeight,
            })));
          }
        });
      }); 
    } 
  }, 5000);
}

function resetMingly() {
  // restting many of the mingly parameters
  const { mingly } = window;
  mingly.device = null; 
  mingly.sendTransportOptions = null;
  mingly.recvTransportOptions = null;
  mingly.recvTransport = null;
  mingly.sendTransport = null;
  mingly.camVideoProducer = null;
  mingly.camAudioProducer = null;
  mingly.screenVideoProducer = null;
  mingly.screenAudioProducer = null;
  mingly.msVideoStream = null;
  mingly.msBroadCastId = null; 
  mingly.msBroadCastType = null; 
  mingly.msVideoStream = null; 
  mingly.myTransporstAreReady = 0; 
  mingly.msOnProduceCallback = null;
}
function callRestartSession() {
  const { mingly } = window;
  const { me } = mingly.board;
  if (!me.isHost) return;
  console.log('test');
  // this should be a function that restarts the session
  // calculate the time to restart for each participant
  // send over the websocket that we are restarting the session
  // restart the session
}
function restartSession(seconds) {
  const { mingly } = window;
  // save my position and view
  const storePos = {
    'view': mingly.board.ctx.getTransform(),
    'x': mingly.board.me.x,
    'y': mingly.board.me.y,
  };
  // save my name
  const name = mingly.board.me.name; 
  // get the room config ... did I store it?

  // close all participants (remove them so that vue part updates)
  Object.values(mingly.board.participants).forEach((participant) => {
    const peerUuid = participant.uuid;
    const initiate = false;
    killPeer(peerUuid, initiate);
  });
  // call close connections
  // closing logic/cs server
  closeLogicServer();
  serverConnection = null;
  // closing mediaservers
  Object.keys(mingly.rtc.wssServers).forEach((key) => {
    const server = mingly.rtc.wssServers[key];
    server.serverConnection.close();
    // Use the serverUrl as needed
  });
  mingly.rtc = {};
  // clean up Mingly 
  const external = true;
  const board = mingly.board;
  board.participants = {};
  board.canvasObjects = {};
  board.me = {}; 
  board.room = {};
  board.isExternal = external;
  cleanUpMinglyObject();
  // call start again with parameters
  setTimeout(() => {
    start(board, external, null, name, room.name, null, storePos);
  }, seconds * 1000);
}

function cleanUpMinglyObject() {
  const { mingly } = window;
  mingly.device = null;
  mingly.sendTransportOptions = null;
  mingly.recvTransportOptions = null;
  mingly.recvTransport = null;
  mingly.sendTransport = null;
  mingly.camVideoProducer = null;
  mingly.camAudioProducer = null;
  mingly.screenVideoProducer = null;
  mingly.screenAudioProducer = null;
  mingly.msVideoStream = null;
  mingly.msBroadCastId = null;
  mingly.msBroadCastType = null;
  mingly.msVideoStream = null;
  mingly.myTransporstAreReady = 0;
  mingly.msOnProduceCallback = null;
  mingly.msBroadCastOnCanvasData = {
    id: null,
    x: null,
    y: null,
    size: null,
    aspectRatio: null,
    active: false,
  };
  mingly.msBroadCastOnCanvas = {};
  //
  mingly.msVideoStream = null; // should remove this one
  mingly.myTransporstAreReady = 0; // 0, 1 or 2 transports ready
  mingly.msOnProduceCallback = null; // this has to be checked
  mingly.hostStates = {
    lockedMove: false,
    mutedAudio: false,
    turnedOffVideo: false,
    disabledMenues: [],
    hostId: null,
    numberOfBroadCasters: 0,
  };
  mingly.raisedHandData = {};
}
function closedTheWebSocket(event) {
  const { mingly } = window;
  const { room }  = mingly.board;
  // should print the meaningful part of the event
  console.log('Main logic websocket closed');
  console.log(JSON.stringify(event.code));
  console.log(JSON.stringify(event));
  // cleaning up peer connections
  Object.values(participants).forEach((participant) => {
    killPeer(participant.uuid, false);
  });
  if (mingly.onServerDownReconnect) {
    try {
      localStorage.setItem('displayName', mingly.board.me.name);
      mingly.onServerDownReconnect();
    }
    catch {
      window.mingly.log('did not trigger change video');
    }
  }
  if (event.code === 1006 || event.code === 1001 || event.code === 1011) {
    console.log('Attempting WebSocket restart...');
    // Wait for a brief period before reconnecting (e.g., 5 seconds)
    setTimeout(() => {
      if (!mingly.logicServerStatus.reconnect) {
        mingly.logicServerStatus.reconnect = true;
        connectToWebSocket(window.mingly.wsurl);
      }
    }, 5000);
  } else {
    console.log('WebSocket connection closed. No automatic restart.');
    // mingly.logicServerStatus.reconnect = true;
  }
  // clean up mingly stuff
  // resetMingly();
  // serverConnection = null;
  // if (room.mediaserver) {
  //   // this does not work yet
  //   mingly.log('Disconnected to server');
  //   // need to try to reconnect but it does not work right now
  //   // try {
  //   //   mingly.device = new window.mediaServerClient.mediasoupClient.Device();
  //   //   mingly.log('setting up Mediasoup');
  //   // } catch (e) {
  //   //   if (e.name === 'UnsupportedError') {
  //   //     console.error('browser not supported for video calls');
  //   //     return;
  //   //   } // check if correct as used to have else 
  //   // }
  //   // connectToWebSocket(window.mingly.wsurl);
  // } else {
  //   connectToWebSocket(window.mingly.wsurl);
  // }
  
  // random delay to avoid everyon connecting at the same time 
  // not sure this is necessary
  // let maxWait = 10000; // maximum wait time ms
  // let randomTime = Math.ceil(Math.random()*maxWait);
  // setTimeout(() => {
  // start(mingly.board, mingly.board.isExternal, mingly.board.me.name,
  // mingly.board.me.name, room.name, mingly.board.me.avatar);
  // connectToWebSocket(window.mingly.wsurl);
  // },randomTime);
  // try to reconnect here somehow
  // connectToWebSocket(window.mingly.wsurl);
}

function problemsConnectionWebSocket(event) {
  // should have a list of alternatives if down
  const { mingly } = window;
  const { room }  = mingly.board;
  // should print the meaningful part of the event
  console.log('THERE IS AN ERROR TO WEBSOCKET!');
  console.log(`Error to websocket: ${event}`);
  if (mingly.onServerDownReconnect) {
    try {
      mingly.onServerDownReconnect();
    }
    catch {
      window.mingly.log('did not trigger change video');
    }
  }
  // connectToWebSocket(window.mingly.wsurl);
  // if (room.mediaserver) {
  //   // dropping mediaserver 
  //   room.mediaserver = false;
  //   window.mingly.wsurl = wsurlPP;
  //   mingly.log('Dropping mediaserver');
  //   connectToWebSocket(window.mingly.wsurl);
  // } else {
  //   mingly.log(`Error to websocket: ${wsurlPP}`);
  //   window.mingly.wsurl = wsurlPP;
  //   connectToWebSocket(window.mingly.wsurl);
  // }
}

function backgroundBackChange() {
  const { board } = window.mingly;
  if (board.backgroundType === 'stretch') {
    board.ctxBackGround.drawImage(board.room.backgroundPicture, 0, 0, board.stretchSize,
      board.stretchSize);
  } else {
    // only two types here so far
    board.ctxBackGround.fillStyle = '#102a4a';
    board.ctxBackGround.fillRect(0, 0, board.stretchSize, board.stretchSize);
  }
}
 
function gotConnectionToWebSocket(event) {
  const { mingly } = window;
  const { board } = mingly;
  const { rooms } = mingly;
  const { videoSource } = mingly;
  const { audioSource } = mingly;
  const { audioOutSource } = mingly;
  const { allowSpeakerSelect } = mingly;
  // set up rtc 
  // eslint-disable-next-line dot-notation
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.OpenConnection }, me.asJSON(), { 'type': 'logic', 'dest': 'server' })));
  board.me.setUpCounter += 1;
  mingly.log('THE SETUPCOUNTER');
  mingly.log(board.me.setUpCounter);
  if (board.me.server.type === 'webRTC') {
    if (board.me.setUpCounter === 2) {
      // this.me.readyForMessages = true;
      mingly.log('saying hi to everyone webRTC style');
      sayHiToEveryone();
    }
  } 
  // if previously lost connection then directly say hi
  // the || host might have to be changed. 
  if (board.me.server.type === 'mediasoup' || board.me.server.type === 'host') { 
    if (board.me.setUpCounter === 3) {
      mingly.log('HeiHeiHei');
      board.me.readyForMessages = true;
      sayHiToEveryone();
    }
  }
  // need to make sure to check if device is on (i.e mediaserver 
  // is present to run next 2 lines)
  // Here we need to make sure  that MS is ready first 
  // (maybe do after receiving message that it is ready)
  // createTransport('send');
  // createTransport('recv');
  // setting up transports for media server

  // setting up change of camera, mic and speaker
  if (cameraSelect) {
    cameraSelect.onchange = function () {
      mingly.log(`changing video camera: ${cameraSelect.value}`);
      const videoSource = cameraSelect.value;
      // constraints.video.deviceId = {};
      changeCamera(videoSource);
    };
  }

  if (micSelect) {
    micSelect.onchange = function () {
      const me = window.me;
      mingly.log(`changing mic ${micSelect.value}`);
      const audioSource = micSelect.value;
      // constraints.video.deviceId = {};
      changeMicrophone(audioSource);
    };
  }
  try {
    if (allowSpeakerSelect && speakerSelect &&
      window.adapter.browserDetails.browser === 'chrome') {
      speakerSelect.onchange = function () {
        mingly.log(`changing speaker : ${speakerSelect.value}`);
        window.mingly.audioOutSource = speakerSelect.value;
        Object.values(participants).forEach((participant) => {
          participant.videoElement.setSinkId(audioOutSource);
          // might have to set volume again?
        });
      };
    }
  }
  catch {
    window.mingly.log('cannot set sinkId');
  }

  // TODO: This is wierd. We have to pause and restart video.+
  // todo: extend the Room class, then remove this block
  console.log('this is the board');
  console.log(board);
  if (board.type === 'Canvasboard' &&
    !mingly.logicServerStatus.reconnect) {
    
    board.doBackground = true;
    board.videoBackGroundStyle = 2;
    board.videoBorderWidth = 2;
  
    board.resizeCanvas();
    board.drawBackgroundPicture(board.ctx0, board.width, board.height);
    board.drawBackgroundPicture(board.ctxSide0, board.canvasSide0.width, board.canvasSide0.height);
    // board.ctx0.drawImage(board.room.backgroundPicture, 0, 0, board.width, board.height);
    // me.videoElement.pause();
    // board.ctxSide0.drawImage(board.room.backgroundPicture, 0, 0,
    //   board.canvasSide.width, 
    //   board.canvasSide.height);
   
    // Created function based on promise, but have not been able to test it
    if (board.room.backgroundPicture.complete) {
      backgroundBackChange();
      mingly.log('Background picture was ready');
      if (mingly.onReadyCanvas) {
        try {
          mingly.onReadyCanvas();
        }
        catch {
          mingly.log('onReadyCanvas did not triger');
        }
      }
      board.updateAll();
    } else { 
      waitForImageToLoad(board.room.backgroundPicture).then(() => {
        backgroundBackChange();
        if (mingly.onReadyCanvas) {
          try {
            mingly.onReadyCanvas();
          }
          catch {
            mingly.log('onReadyCanvas did not triger');
          }
        }
        board.updateAll();
        mingly.log('watited for picture');
      }).catch(errorHandler);
    }
    // this one we can get rid of once loading issues are all resolved
    setTimeout(function () {
      board.updateAll();
    }, 6000);

    if (board.doIntroLocation && !room.mediaserver) {
      board.drawVideo(me);
      board.doIntroAnimation();
    } 
  
    // board.update(); //might not be necessary

    // some things related to delays etc:

    // not good this one... but it does clean up every now and then...
    setInterval(function () { window.mingly.board.update(); }, 1000);

    // another one based on requestanimationframe slowing down of some strange reason
    // setInterval( function() { board.resetVideo(); },board.periodicResetVideo);
    // I think this one is solved now....

    document.addEventListener('visibilitychange', function () {
      window.mingly.board.resetVideo();
    });

    window.addEventListener('pagehide', (event) => {
      if (event.persisted) {
        window.mingly.board.resetVideo();
      }
    }, false);
  }
  // mingly.logicServerStatus.reconnect = false;
  setTimeout(function () {
    window.mingly.board.hasWaited = true;
    window.mingly.board.hasInteracted = true; 
    // this I am not sure is necessary
    updateConnections();
  }, 500); // wait a bit before starting thing
} 

function gotMessageFromServer(message) {
  const { mingly } = window;
  const { board } = mingly;
  const { participants, room } = board;
  const signal = JSON.parse(message.data);
  const peerUuid = signal.uuid;
  const ScS = signal.screenShare;
  const nVid = signal.noVideo;
  if (mingly.measureMessages.active) {
    mingly.measureMessages.messages += 1;
  }
  // if websocket for media servers are ready, then I am good to go
  mingly.log(`gotMessageFromServer: got ${signal.command} from ${signal.uuid}, sender: ${signal.sender}, videovar ${signal.streamV}`);

  // Ignore messages that are not for us or from ourselves
  if (signal.room !== board.room.name) return;

  if (peerUuid === me.uuid || (signal.dest !== me.uuid && signal.dest !== 'all')) return;

  const participant = participants[peerUuid];

  // handle commands
  switch (signal.command) {
    case CMD.OpenConnection:
      // don't need to do anything here
      break;
    case CMD.NumberOfClients:
      // setup the right servers here
      console.log('The number of clients are');
      console.log(signal.clients);
      console.log(mingly.logicServerStatus.reconnect);
      if (!mingly.logicServerStatus.reconnect) {
        // doing it only when not reconnecting
        // should maybe checl if mingly.rtc object is here
        console.log('setting up RTC');
        setUpRTC(signal.clients, serverConnection);
      } else {
        console.log('saying hi directly');
        sayHiToEveryone();
      }
      // not sure when the best time to set this up is
      // this is just for testing purposes
      // setting up here based on a fixed set of servers
      // if (signal.clients < 20) {
      //   me.server.url = configRTC[0].wssServer;
      //   configRTC[0].send = true;
      //   console.log('using first server');
      // } 
      // // else if (signal.clients >= 20 && signal.clients < 40) {
      // //   me.server.url = configRTC[1].wssServer;
      // //   configRTC[1].send = true;
      // //   console.log('using 2nd server');
      // // } else if (signal.clients >= 40 && signal.clients < 60) {
      // //   me.server.url = configRTC[2].wssServer;
      // //   configRTC[2].send = true;
      // //   console.log('using 3rd server');
      // // } else if (signal.clients >= 60 && signal.clients < 80) {
      // //   me.server.url = configRTC[3].wssServer;
      // //   configRTC[3].send = true;
      // //   console.log('using 4th server');
      // // } 
      // else {
      //   me.server.url = configRTC[1].wssServer; 
      //   configRTC[1].send = true;
      //   console.log('using last server');
      // }
      // // me.server.url = 'wss://localhost:8443';
      // // me.server.url = 'wss://soup.mingly.io';
      // me.server.type = 'mediasoup';
      // window.mingly.rtc = new MinglyRTC(configRTC, room.name,
      //   serverConnection, me, mingly.board.participants);
      break;
    case CMD.HandShake: // new connection received
      if (!signal.displayName) {
        mingly.error('gotMessageFromServer.cmdOpenConnection: lacks displayName');
      }

      switch (signal.dest) {

        case 'all': // this is a "hello" to everyone from a new peer
          // signal back to sender - note the message destination:

          if (me.usepicture) {
            serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.HandShake }, me.asJSON(), { 'picUpdated': room.backGroundPictureUpdated, 'newpic': room.currentBackGroundPicture, 'dest': peerUuid, 'avatar': localStorage.getItem('imgData') })));
          }
          else {
            serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.HandShake }, me.asJSON(), { 'picUpdated': room.backGroundPictureUpdated, 'newpic': room.currentBackGroundPicture, 'dest': peerUuid })));
          }  
          // set up peer connection object for a newcomer peer
          setUpPeer(peerUuid, signal.position_x, signal.position_y, signal.displayName,
            signal.avatar, signal.color, signal.serverUrl, signal.serverType,
            signal.serverReadyState, 
            signal.mutedParticipant,
            signal.isSleeping, signal.isGhost, signal.isHost,
            ScS, nVid, false, 3);
          // setUpPeer(peerUuid,signal.position_x,signal.position_y, signal.displayName,
          //   signal.avatar,false,true);

          // checking if we need to get to to subscribe to some screen sharing 
          // should also check if this applies to classic view on broadcast
          if (mingly.msBroadCastOnCanvas[me.uuid] !== undefined) {
            serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.BroadcastOnCanvas }, me.asJSON(), { 'type': mingly.msBroadCastOnCanvas[me.uuid].type, 'dataBroadCast': mingly.msBroadCastOnCanvas[me.uuid], 'dest': signal.uuid  })));
          }
          if (me.localBroadCast) {
            serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.localBroadCast }, me.asJSON(), { 'localBroadCast': mingly.board.me.localBroadCast, 'dest': signal.uuid })));
          }
          // host states 
          if (me.isHost) {
            serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.UpdateHostStates }, me.asJSON(), { 'dest': signal.uuid, 'hostStates': mingly.hostStates })));
            // need to place a new incomeing person at the right spot. This might require redoing 
            if (window.mingly.hostStates.lockedMove) {
              // const testCondition = true;
              // This might be too quick so the info the new participant gets
              // should be stored and then used to set initial position instead of the random
              // position
              // The setTimeout is a bit of a bug to make sure we delay things a bit...
              setTimeout(() => {
                hostPlaceAndLockParticipants(true);
              }, 4000);
            }
          }
          break;

        case me.uuid:
          // initiate call if we are the newcomer peer
          // this is where we handle the messages sent in the previous switch block ^^
          // only connect webrtc if less than 5 as distance is high
          mingly.log('THIS IS WHERE I SET UP A PARTICIPANT'); 
          if (signal.newpic && !room.backGroundPictureUpdated) {
            room.backGroundPictureUpdated = signal.picUpdated;
            changeBackgroundPicture(signal.newpic);
            mingly.log('gotMessageFromServer.cmdOpenConnection: picture is updated');
          }
          // need toc creat 'asJSON' here to and pass this to setUpPeer
          setUpPeer(peerUuid, signal.position_x, signal.position_y, signal.displayName,
            signal.avatar, signal.color, signal.serverUrl,
            signal.serverType,
            signal.serverReadyState,
            signal.mutedParticipant, signal.isSleeping, signal.isGhost,
            signal.isHost, ScS, nVid, true, 3);
          // initial muting check
            
          // setUpPeer(peerUuid,signal.position_x,signal.position_y,signal.displayName,
          //   signal.avatar, true,true);

          break;

        default:
          mingly.error('gotMessageFromServer.cmdOpenConnection: unhandled destination: ', signal.dest);
      }

      break;

    case CMD.Move: // grid move
      if (room.mediaserver) {
        if (!me.readyForMessages) { return; }
      }
      // uses can update their avatar on the go. for now, this triggers a moveit signal (we think)
      participant.setSpeed(signal.speedX, signal.speedY);
      participant.movingUsingKeyboardNow = signal.movingUsingKeyboardNow;
      participant.avatar = signal.avatar; // todo: Participant.setAvatar(newAvatar)\
      participant.firstimePic = signal.firstimePic;
      if (participant.firstimePic) {
        participant.setAvatar(participant.avatar);
        participant.drawAvatar();
      }

      // move peer in board, hide if uniformSound
      // eslint-disable-next-line no-case-declarations
      const dx = participant.x - me.x;
      // eslint-disable-next-line no-case-declarations
      const dy = participant.y - me.y;
      participant.moveTo(signal.position_x, signal.position_y);
      // move if following that participant
      if (me.followingMove.isFollowing && me.followingMove.following === signal.uuid) {
        me.moveToClosesetAvailable(signal.position_x - dx, signal.position_y - dy);
      }
      mingly.log('gotMessageFromServer.cmdMove: updated peer connection with new position and avatar');
      mingly.log('gotMessageFromServer.cmdMove: the speed of the participant in x direction is ', signal.speedX);

      // todo: is this really something we want to handle during move?
      // move it to cmdDJControl?
      participant.uniformSound = signal.uniform;
      // participants[peerUuid].isDJ = signal.isDJ;
      participant.adjustVolume(me);

      // only update as long as the participant is standing still
      if (participant.speedX === 0 && participant.speedY === 0) {
        // Don't update the first time someone enters the board. This seems to create issues
        if (signal.hasMoved) {
          participant.hasMoved = true;
          // This creates a lof overhead as you update all connections possibly (at least loop over)
          // every time someone moves
          updateConnections();
        }
      }
      // maybe this is where should do the change of stream suff
      break;

    case CMD.Leaving:
      if (room.mediaserver) {
        if (!me.readyForMessages) { return; }
      }
      killPeer(peerUuid, false);
      break;

    case CMD.Chat: // chat message
      if (room.mediaserver) {
        if (!me.readyForMessages) { return; }
      }
      mingly.log(signal.chatmessage);
      if (board.isExternal) {
        if (mingly.onChatMessage) {
          try {
            // don't need to check if for me as this is done earlier
            mingly.onChatMessage(signal);
          } 
          catch {
            mingly.log('sending chat external did not work');
          }
        }
        mingly.log('is external');
      } else { 
        addChatMessage(new Date(signal.datetime_utc), signal.displayName, signal.chatmessage);
      }
      break;

    case CMD.EditStream: // change in streams
      if (room.mediaserver) {
        if (!me.readyForMessages) { return; }
      }

      // this can cause problems if cmdEditStream triggered very qucikly
      // if(!participant){
      //   setUpPeer(peerUuid,signal.position_x,signal.position_y,
      //             signal.displayName,signal.avatar,true,true);
      // }
      if (signal.dest === me.uuid) {
        if (!participant) {
          break; // this should be taken care of elsewhere
        }

        if (signal.streamV === 0) { // this means that it is a request for audio only (no video)
          mingly.log('gotMessageFromServer.cmdEditStream: audio Only');
          participant.videovar = 0;
          if (window.mingly.onVideovar) {
            try {
              window.mingly.onVideovar(participant);
            }
            catch {
              mingly.log('gotMessageFromServer.cmdEditStream: error when calling videovar event listener');
            }
          }
          mingly.rtc.swapStream(participant.uuid, window.mingly.streamBlack);
        }
        else {
          // ask for video, here we need to check
          let nvids = 0;
          const distanceVec = [];
          Object.values(participants).forEach((p) => {
            nvids += p.videovar;
            // could do this separately if nvids binds, but is probably not that costly
            distanceVec.push(p.distanceTo(me));
          });

          if (nvids < room.maxNumberVideos) {
            // free capacity
            if (me.screenShare) {
              mingly.rtc.swapStream(participant.uuid, window.mingly.streamScreen, 'video');
            }
            else {
              mingly.rtc.swapStream(participant.uuid, window.mingly.streamLowRes);
            }
          }
          else {

            // takes care of the distances
            const sortDist = distanceVec.sort(function (a, b) { return a - b; });
            let cutoffnow;
            cutoffnow = sortDist[room.maxNumberVideos - 1]; // check this again!

            // check if next in the line has same distance, if so need to drop
            if (cutoffnow === sortDist[room.maxNumberVideos]) {
              // todo: a more sane picture
              mingly.log('gotMessageFromServer.cmdEditStream: inne her as it is a draw');
              let testdiff = 0;
              let index = room.maxNumberVideos - 1;
              while (testdiff === 0) {
                testdiff = sortDist[index] - sortDist[index - 1];
                mingly.log('gotMessageFromServer.cmdEditStream: testdiff = ', testdiff);
                index -= 1;
                cutoffnow = sortDist[index];
              }
            }

            if (participant.distanceTo(me) <= cutoffnow) {
              if (me.screenShare) {
                mingly.rtc.swapStream(participant.uuid, window.mingly.streamScreen, 'video');
              }
              else {
                mingly.rtc.swapStream(participant.uuid, window.mingly.streamLowRes);
              }

              // check who to remove
              Object.values(participants).forEach((part) => {
                if (part.videovar === 1) {
                  if (part.distanceTo(me) > cutoffnow) {
                    part.videovar = 0;
                    if (window.mingly.onVideovar) {
                      try {
                        window.mingly.onVideovar(part);
                      }
                      catch {
                        mingly.log('gotMessageFromServer.cmdEditStream: error when calling videovar event listener');
                      }
                    }
                    swapStream(part, window.mingly.streamBlack);
                    mingly.log('gotMessageFromServer.cmdEditStream: sorry you\'re kicked out');
                    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.EditStream }, me.asJSON(), { 'streamV': part.videovar, 'dest': part.uuid })));
                    // serverConnection.send(JSON.stringify({'command': CMD.EditStream}, 
                    // me.asJSON(),
                    // {'streamV':participant.videovar , 'dest': participant.uuid,
                    //   'room': localRoom }));
                  }
                }
              });
            }
            else {
              // send message that you would like to keep it as it was (no video)
              mingly.log('gotMessageFromServer.cmdEditStream: sorry no video, even though you want it');
              participant.videovar = 0;
              if (window.mingly.onVideovar) {
                try {
                  window.mingly.onVideovar(participant);
                }
                catch {
                  mingly.log('gotMessageFromServer.cmdEditStream: error when calling videovar event listener');
                }
              }
              serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.EditStream }, me.asJSON(), { 'streamV': participant.videovar, 'dest': participant.uuid })));
            }
          }

        }

        participant.setSpeed(signal.speedX, signal.speedY);
        participant.avatar = signal.avatar;
        // participant.drawAvatar();
        participant.moveTo(signal.position_x, signal.position_y);
      }
      break;

    case CMD.DJControl: // dj control command
      if (room.mediaserver) {
        if (!me.readyForMessages) { return; }
      }
      DJVol = signal.volume;
      break;

    case CMD.SDP: // SDP (Session Description Protocol) request for session, and possibly offer
      if (room.mediaserver) {
        if (!me.readyForMessages) { return; }
      }
      if (participant.failed) {
        participant.failed = false;
      }

      if (!participant.peerConnection) {
        setupPeerConnection(participant.uuid, false);
      }

      participant.peerConnection.setRemoteDescription(new RTCSessionDescription(signal.sdp))
        .then(function () {
          // Only create answers in response to offers
          if (signal.sdp.type === 'offer') {
            participant.peerConnection.createAnswer()
              .then((description) => createdDescription(description, peerUuid))
              .catch(errorHandler);
          }
        }).catch((error) => {
          mingly.error(error);
          switch (error.name) {
            case 'InvalidStateError': {
              // delete peerconnection if it is there
              killPC(participant.uuid, false); // unclear if true or false here
              // set failed to true
              participant.failed = true;
              // waitTime for calling back, cancel calling if other party call you first
              const waitTimeCall = Math.round(Math.random() * 5000);
              setTimeout(function () {
                if (participant.failed) {
                  // call since failed is still true (you are the first)
                  participant.failed = false;
                  setupPeerConnection(participant.uuid, true);
                }
              }, waitTimeCall);
              break;
            }

            default:
              errorHandler(error);
          }
        });

      break;

    case CMD.ICE: // got ice candidate
      if (room.mediaserver) {
        if (!me.readyForMessages) { return; }
      }
      participant.peerConnection.addIceCandidate(new RTCIceCandidate(signal.ice))
        .catch(errorHandler);
      break;

    case CMD.PingParticipant:
      if (room.mediaserver) {
        if (!me.readyForMessages) { return; }
      }
      if (signal.dest === me.uuid) { // only relevant if it is for me
        if (signal.areYouThere) { // someone is checking if we are still here
          serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.PingParticipant }, me.asJSON(), { 'IamHere': true }, { 'dest': peerUuid })));
        } else {
          participant.isThere = true;
        }
      }
      break;

    case CMD.ShareScreen:
      if (room.mediaserver) {
        if (!me.readyForMessages) { return; }
      }
      participant.screenShare = signal.screenShare;
      participant.drawAvatar();
      break;

    case CMD.NoVideo:
      if (room.mediaserver) {
        if (!me.readyForMessages) { return; }
      }
      participant.noVideo = signal.noVideo;
      if (participant.noVideo === true && participant.reqAnimation) {
        cancelAnimationFrame(participant.reqAnimation);
      }
      board.updateAll();
      // participant.drawAvatar();
      break;

    case CMD.ChgBackGrnd:
      if (!room.backGroundPictureUpdated) {
        room.backGroundPictureUpdated = true;
      }

      changeBackgroundPicture(signal.pic);
      break;

    case CMD.CloseRTC:
      if (room.mediaserver) {
        if (!me.readyForMessages) { return; }
      }
      // means that the other party has closed the PC
      killPC(signal.uuid, false); 
      break;

    case CMD.ClosePeer:
      if (room.mediaserver) {
        if (!me.readyForMessages) { return; }
      }
      killPeer(signal.uuid, false);
      break;

    case CMD.ChangeName:
      if (room.mediaserver) {
        if (!me.readyForMessages) { return; }
      }
      participant.name = signal.displayName;
      mingly.log(`changing name of participant to ${participant.name}`);
      if (mingly.onChangeName) {
        try {
          // don't need to check if for me as this is done earlier
          mingly.onChangeName(signal);
        } 
        catch {
          mingly.log('Could not change name');
        }
      }
      break;

    case CMD.ChangePPic:
      if (room.mediaserver) {
        if (!me.readyForMessages) { return; }
      }
      participant.setParticipantPicture(signal.pic);
      participant.drawAvatar();
      mingly.log(`changing Profile Picture of participant to ${participant.name}`);
      if (mingly.onChangeProfilePic) {
        try {
          // don't need to check if for me as this is done earlier
          mingly.onChangeProfilePic(signal);
        } 
        catch {
          mingly.log('Could not change profile picture');
        }
      }
      break;

    case CMD.RemoteVideo: 
      if (room.mediaserver) {
        if (!me.readyForMessages) { return; }
      }
      // eslint-disable-next-line no-case-declarations
      const { remoteVideoCmd } = signal;
      mingly.log('gotMessageFromServer.cmdRemoteVideo: remote video CMD:', remoteVideoCmd);
      switch (remoteVideoCmd) {
        case 'add':
          board.addVideoToCanvas(signal.vidsrc, signal.vidwidth, signal.vidx, signal.vidy);
          break;

        case 'stop':
          if (board.backgroundVideo) {
            board.backgroundVideo.video.pause();
          }
          break;

        case 'volume':
          board.backgroundVideo.video.volume = Number(signal.vidVolume) / 100;
          break;

        default:
          mingly.log('gotMessageFromServer.cmdRemoteVideo: there was no command for remote video');
          break;
      }
      break;

    case CMD.MediaServerIn: 
      // should remove this eventually 
      mingly.log('the mediaserver message');
      mingly.routerRtpCapabilities = { routerRtpCapabilities: signal.routerRtpCapabilities };
      // might need some flag to indicate that it is ready (.then)
      mingly.device.load(mingly.routerRtpCapabilities).then((res) => {
        mingly.log('MS device ready');
        createTransport('send');
        createTransport('recv');  
      }).catch(errorHandler);
      break;
  
    case CMD.CreateTransport: 
      mingly.log('Setting up transport for MS');
      // eslint-disable-next-line no-case-declarations
      const id = signal.id;
      // eslint-disable-next-line no-case-declarations
      const iceParameters = signal.iceParameters;
      // eslint-disable-next-line no-case-declarations
      const iceCandidates = signal.iceCandidates;
      // eslint-disable-next-line no-case-declarations
      const dtlsParameters = signal.dtlsParameters;
      // eslint-disable-next-line no-case-declarations
      const direction = signal.direction; 
      mingly.log(direction);
      mingly.log({
        transportOptions: { id, iceParameters, iceCandidates, dtlsParameters } });
      setUpTransport({ id, iceParameters, iceCandidates, dtlsParameters }, direction);
      break;
    
    case CMD.SubscribeToTrack:
      // this is the reply from the server
      mingly.log('In cmdSubscribeToTrack');
      // eslint-disable-next-line no-case-declarations
      const peerId = signal.mediaPeerId;
      // eslint-disable-next-line no-case-declarations
      const mediaTag = signal.mediaTag; 
      // eslint-disable-next-line no-case-declarations
      const consumerParameters = {
        producerId: signal.producerId,
        id: signal.consumerId,
        kind: signal.kind,
        rtpParameters: signal.rtpParameters,
        type: signal.type,
        producerPaused: signal.producerPaused,
      };
      mingly.log(consumerParameters);
      setUpConsumption(peerId, consumerParameters, mediaTag, signal.ms);
      break;
    case CMD.Broadcast:
      if (mingly.msBroadCastId === signal.uuid || 
        mingly.msBroadCastOnCanvas[signal.uuid] !== undefined) {
        // should not need to flip video here
        participant.flipVideo = false;
        // need to generalize closeMSboardCast to multiple broadcasts
        closeMSbroadCast(participant.uuid);
      } else {
        mingly.log('opening broadcast remote');
        mingly.log(signal.uuid);
        mingly.log(signal.type);
        openMSbroadCast(signal.uuid, signal.type); // hard wired video, 
        // need to send what intension is
      }
      break;
    case CMD.BroadcastOnCanvas:
      // careful to see if this is set if broadcastvideooncanvas
      if (mingly.msBroadCastOnCanvas[signal.uuid] !== undefined) {
        // should flip back video here
        // but need to be careful if we are always flipping
        participant.flipVideo = false;
        console.log('if a new share it should not be here');
        closeMSbroadCast(participant.uuid);
      } else {
        console.log('opening broadcast remote');
        console.log(signal.uuid);
        console.log(signal.type);
        mingly.msBroadCastOnCanvas[signal.uuid] = signal.dataBroadCast;
        mingly.msBroadCastType = signal.type; // should only use for classic broadcast
        participant.flipVideo = true;
        openMSbroadCast(signal.uuid, signal.type); 
      }
      break;
    case CMD.UpdateBroadcastOnCanvas:
      updateCanvasOnScreenPlacement(signal.dataBroadCast.x,
        signal.dataBroadCast.y, signal.dataBroadCast.size, peerUuid);
      break;
    case CMD.ProduceReady:
      // eslint-disable-next-line no-case-declarations
      const proId = signal.id; 
      mingly.log('Transport ready');
      mingly.msOnProduceCallback({ proId }); // had to do it this way...
      if (mingly.msBroadCastId === me.uuid) {
        mingly.myTransporstAreReady += 1;
        mingly.log(mingly.myTransporstAreReady);
        if (mingly.msBroadCastType === 'ms_canvas_screen') {
          mingly.log(signal.type);
          // could do the place canvas here
          board.placeingObject.placingFunction = placeBroadCastScreenOnCanvas;
          board.placeingObject.isPlacing = true;
          mingly.myTransporstAreReady = 0;
        } else {
          mingly.log(signal.type);
          if (mingly.myTransporstAreReady === 2) {
            mingly.log('check if screen or not');
            if (mingly.msBroadCastType === 'ms_canvas_screen_and_screenaudio') {
              console.log('in MS video Canvas audio');
              // could do the place canvas here
              board.placeingObject.placingFunction = placeBroadCastScreenOnCanvas;
              board.placeingObject.isPlacing = true;
            } else {
              serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.Broadcast }, me.asJSON(), { 'type': mingly.msBroadCastType, 'dest': 'all' })));
              if (mingly.board.isExternal) {
                const dat = {
                  uuid: me.uuid,
                };
                if (window.mingly.onMSBroadcast) {
                  try {
                    window.mingly.onMSBroadcast(dat);
                  }
                  catch {
                    window.mingly.log('did not do onMSBroadcast');
                  }
                }
              } else {
                setupMsVideo(me);  
              }
            }
            mingly.myTransporstAreReady = 0;
          }
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (room.mediaserver) {
          mingly.myTransporstAreReady += 1;
          mingly.log(signal.type);
          mingly.log(mingly.myTransporstAreReady);
          // if (mingly.myTransporstAreReady === 2) {
          //   sayHiToEveryone();
          //   me.readyForMessages = true;
          //   mingly.myTransporstAreReady = 0;
          // }
        }
      }
      break;
    case CMD.NewPoll:
      if (signal.pollData.onCanvas) {
        mingly.log('Canvas poll');
        // we received a poll of type canvas
        board.quiz.inQuizMode = true;
        board.quiz.data = signal.pollData;
        board.quiz.alternatives = signal.pollData.alternatives.length;
        board.updateAll();
      } 
      if (mingly.onGotPoll) {
        try {
          // don't need to check if for me as this is done earlier
          mingly.log('Sending poll');
          mingly.onGotPoll(signal);
        } 
        catch {
          mingly.log('Could not get poll through');
        }
      }
      break;

    case CMD.PollAnswer:
      mingly.log(signal.pollData.checkedAlternatives);
      Object.values(signal.pollData.checkedAlternatives).forEach((alternatives) => {
        mingly.pollResults[alternatives] += 1;
      });
      if (mingly.onGotPollAnswer) {
        try {
          // don't need to check if for me as this is done earlier
          mingly.onGotPollAnswer(signal);
        } 
        catch {
          mingly.log('Could not get Poll answer');
        }
      }
      break;
    case CMD.PollStats:
      if (mingly.onGotPollStats) {
        try {
          // don't need to check if for me as this is done earlier
          mingly.onGotPollStats(signal);
        } 
        catch {
          mingly.log('Could not get Poll answer');
        }
      }
      break;
    case CMD.ClosePoll:
      if (board.quiz.inQuizMode) {
        mingly.log('Canvas poll closing');
        // we received a poll of type canvas
        board.quiz.inQuizMode = false;
        board.quiz.data = {};
        board.quiz.alternatives = null;
        board.quiz.hasAnswered = false;
        board.updateAll();
      }
      if (mingly.onClosePoll) {
        try {
          // don't need to check if for me as this is done earlier
          mingly.onClosePoll();
        } 
        catch {
          mingly.log('Could not Close Poll');
        }
      }
      break;
    case CMD.GameInvite:
      if (mingly.onGameInvite) {
        try {
          // don't need to check if for me as this is done earlier
          mingly.onGameInvite(signal);
        } 
        catch {
          mingly.log('Could not react to gameinvite');
        }
      }
      break;
    case CMD.NewQuizQuestion:
      drawGameToCanvas(signal.quizData);
      break;
    case CMD.QuizAnswer:
      if (mingly.onGotQuizAnswer) {
        try {
          // don't need to check if for me as this is done earlier
          mingly.onGotQuizAnswer(signal);
        } 
        catch {
          mingly.log('Could not get Quiz answer');
        }
      }
      break;
    case CMD.AcceptGame:
      if (mingly.onGotGameAcceptance) {
        try {
          // don't need to check if for me as this is done earlier
          mingly.onGotGameAcceptance(signal);
        } 
        catch {
          mingly.log('got answer game invite not working');
        }
      }
      break;
    case CMD.changeGameMenuVisibility:
      console.log('In the right place here!');
      if (mingly.onHideShowGame) {
        try {
          mingly.onHideShowGame();
        }
        catch {
          mingly.log('could not change game menu visibility');
        }
      }
      break;
    case CMD.forcedMoveFromHost:
      console.log('got forced move from host');
      console.log(signal.x);
      console.log(signal.y);
      me.moveTo(signal.x, signal.y, true);
      break;
    case CMD.StopGame:
      board.quiz.inQuizMode = false;
      board.quiz.data = {};
      board.quiz.alternatives = null;
      board.quiz.hasAnswered = false;
      board.updateAll();
      if (mingly.onStopGame) {
        try {
          // don't need to check if for me as this is done earlier
          mingly.onStopGame();
        } 
        catch {
          mingly.log('could not stop game');
        }
      }
      changeBackgroundPicture(board.room.backgroundPictureURL);
      break;
    case CMD.UpdateGameData:
      if (mingly.onUpdateGameData) {
        try {
          // don't need to check if for me as this is done earlier
          mingly.onUpdateGameData(signal);
        } 
        catch {
          mingly.log('could not update Game data');
        }
      }
      break;
    case CMD.Ping:
      // a ping from the server
      mingly.log('ping');
      serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.Pong }, me.asJSON(), { 'dest': 'server' })));
      break;
    case CMD.Pong:
      break;
    case CMD.ParticipantLeft:
      mingly.log('removedParticpant');
      killPeer(signal.uuid, false);
      break;
    case CMD.Muted:
      // might want to pause audio subscription here
      participant.muted = signal.mutedParticipant;
      break;
    case CMD.Sleeping:
      participant.isSleeping = signal.isSleeping;
      break;
    case CMD.TurnGhost:
      participant.isGhost = signal.isGhost;
      break;
    case CMD.localBroadCast:
      participant.localBroadCast = signal.localBroadCast;
      updateConnections();
      break;
    case CMD.BallGame:
      // check if ball object it there
      console.log('det');
      // if not create it
      // check if dead when killed
      // draw object
      break;
    case CMD.Calling:
      playCallingSound();
      break;
    case CMD.MoveCursor:
      participant.cursor.active = signal.active;
      participant.moveCursorTo(signal.x, signal.y);
      break;
    case CMD.LeadingView:
      // taking the view from someone else
      mingly.log('that mats');
      mingly.log(signal.mat1);
      mingly.log(signal.mat2);
      // check if I am leading, if so override 
      // alternative is to disregard the data
      // but this can lead to two try control
      if (me.following.isLeading) {
        me.following.isLeading = false;
      }
      // We only want one to lead your view? 
      // Right now multiple people could lead 
      // your view in principle
      if (!me.following.isFollowing) {
        me.following.isFollowing = true;
      }
      // updating the zoom and focus
      mingly.board.setView(signal.mat1, signal.mat2, signal.dim);
      // updating the objects
      mingly.log(signal.groupLevels);
      Object.values(board.canvasObjects).forEach((object) => {
        Object.values(signal.groupLevels).forEach((levels) => {
          if (object.groupName === levels.name) {
            object.currentLevel = levels.level;
            object.updateRoom();
          }
        });
      });
      
      break;
    case CMD.AskToFollowView:
      board.me.following.isLeading = true;
      if (board.me.following.participants[signal.uuid]) {
        const part = {};
        // remove participant if already in there
        Object.values(board.me.following.participants).forEach((participant) => {
          if (participant !== signal.uuid) {
            part[participant] = participant;
          }
        });
        board.me.following.participants = part;
      } else {
        board.me.following.participants[signal.uuid] = signal.uuid;
      }
      break;
    case CMD.HostMuteAll:
      muteFromHost(signal.type);
      break;
    case CMD.HostNoVideo:
      noVideoFromHost(signal.type);
      break;
    case CMD.ActivateNoHostMenu:
      // Push to vue
      if (mingly.onNoHostMenu) {
        try {
          mingly.onNoHostMenu(signal.active);
        }
        catch {
          mingly.log('did not triggeronNoHostMenu');
        }
      }
      break;
    case CMD.UpdateHostStates:
      mingly.log('host states updating');
      mingly.log(signal.hostStates);
      mingly.hostStates = signal.hostStates;
      // NEED TO RUN UPDATE HOST STATES FUNCTION 
      // not sure this is sufficient
      updateHostStates();
      break;
    case CMD.RaiseHand:
      // store information in mingly 
      if (signal.type) {
        // this is the case that the participant 
        // raises the hand
        mingly.raisedHandData[signal.uuid] = {
          uuid: signal.uuid,
          oldX: signal.position_x,
          oldY: signal.position_y,
          isBroadCasting: false,
        };
      } else {
        
        // move participant back to origianl position
        // turn of broadcast (by sending message to)
        // check states to see if mute and video off
        // remove from raisedHandData
        serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.HostToHandRaiser }, me.asJSON(), { 'dest': signal.uuid, 'type': signal.type, 'x': mingly.raisedHandData[signal.uuid].oldX, 'y': mingly.raisedHandData[signal.uuid].oldY })));
        const part = {};
        Object.values(mingly.raisedHandData).forEach((hands) => {
          if (hands.uuid !== signal.uuid) {
            part[hands.uuid] = hands;
          }
        });
        mingly.raisedHandData = part;
      }
      // push this to vue
      if (mingly.onRaiseHand) {
        try {
          mingly.onRaiseHand({
            id: signal.uuid,
            name: signal.displayName,
            type: signal.type,
          });
        }
        catch {
          mingly.log('did not trigger onRaiseHand');
        }
      }
      break;
    case CMD.HostToHandRaiser:
      // follow if raising hand
      // signa.type is if you are broadcasting or not
      if (signal.type) {
        me.followingMove.isFollowing = true;
        me.followingMove.following = signal.uuid;
        mingly.board.canvasObjects[mingly.hostArea.canvasUuid].isWalkable = true;
        // make sure media is turned on again
        if (mingly.hostStates.mutedAudio) {
          muteFromHost(false, false);
        } else if (me.muted) {
          muteAudio();
        }
        if (me.noVideo) {
          noVideo();
        }
        if (mingly.hostStates.turnedOffVideo) {
          noVideoFromHost(false, false);
        }
        // move to position
        const dist = signal.numberBroadCasters;
        me.moveTo(participant.x + dist * (board.avatarSize + 10), participant.y);
        board.updateAll();
        if (mingly.onLowerHand) {
          try {
            mingly.onLowerHand();
          }
          catch {
            mingly.log('did not trigger onLowerHand');
          }
        }
      } else {
        me.followingMove.isFollowing = false;
        mingly.board.canvasObjects[mingly.hostArea.canvasUuid].isWalkable = false;
        me.followingMove.following = '';
        me.hasHandRaised = false;
        me.moveTo(signal.x, signal.y);
        if (mingly.hostStates.lockedMove) {
          // just do normal mute and audio
          if (!me.muted) {
            muteAudio();
          }
          if (!me.noVideo) {
            noVideo();
          }
        }
        // board.updateAll();
        updateHostStates();
      }
      // broadcast
      localBroadCast();
      break;
    case CMD.PlaceAndLock: 
      if (signal.lock) {
        mingly.hostStates.lockedMove = signal.lock;
        if (!me.noVideo) {
          noVideo();
        }
        if (!me.muted) {
          muteAudio();
        }
        // reset any events on current moving 
        me.movingUsingKeyboardNow = false;
        me.movingUsingKeyboardUp = false;
        me.movingUsingKeyboardDown = false;
        me.movingUsingKeyboardLeft = false;
        me.movingUsingKeyboardRight = false;
        board.dragMe = false;
        // if (!mingly.hostArea.isActive) {
        //   mingly.hostArea.isActive = true;
        //   mingly.board.canvasObjects[mingly.hostArea.canvasUuid].isActive = true;
        //   board.updateAll();
        // }    
        // DO I need dragge

        // This makes the gather thing obsolete! Uncomment the next to activate it
        // me.moveTo(signal.x, signal.y);
      } else {
        // should now be able to move freely
        if (mingly.hostArea.isActive) {
          mingly.hostArea.isActive = false;
          mingly.board.canvasObjects[mingly.hostArea.canvasUuid].isActive = false;
          board.updateAll();
        }    
        mingly.hostStates.lockedMove = signal.lock;
        if (mingly.gatherOnHostLock) {
          me.moveToRandomPosition();
        }
        if (me.noVideo) {
          // turn back the video
          noVideo();
        }
        if (me.muted && !mingly.board.room.mutedOnEntry) {
          // turn back the audio if not muted on entry
          muteAudio();
        }
      }
      break;
    case CMD.biVideoAnimation1:
      console.log('starting BI video thingy');
      startBIIntroVideo(signal.source);
      break;
    default:
      mingly.error(`gotMessageFromServer: unhandled command: ${signal.command} the message was ${signal}`);
  }

}

function setUpPeer(peerUuid, positionX, positionY, displayName, avatar, 
  color, serverUrl, serverType, serverReadyState, muted, isSleeping, isGhost, isHost, 
  ScreenShare, nVid, initCall = false, video = 3) {

  const { mingly } = window;

  mingly.log('setUpPeer: starting');
  // next to var set to 1 means video is true
  participants[peerUuid] = new Participant(mingly, displayName, avatar, video, 0);
  participants[peerUuid].uuid = peerUuid;
  participants[peerUuid].board = window.mingly.board;
  participants[peerUuid].moveTo(positionX, positionY);
  participants[peerUuid].screenShare = ScreenShare;
  participants[peerUuid].noVideo = nVid;
  participants[peerUuid].onGetUniformSoundVolumeLevel = getUniformSoundVolumeLevel;
  participants[peerUuid].muted = muted; // does not seem to work that well
  participants[peerUuid].isSleeping = isSleeping;
  participants[peerUuid].isGhost = isGhost;
  participants[peerUuid].isHost = isHost;
  participants[peerUuid].color = color;
  participants[peerUuid].server.url = serverUrl;
  participants[peerUuid].server.type = serverType;
  participants[peerUuid].server.readyState = serverReadyState;
  
  if (mingly.onAddParticipant) {
    try {
      mingly.onAddParticipant(participants[peerUuid]);
    }
    catch {
      mingly.log('did not add participant trigger');
    }
  }

  addToListOfUsers(participants[peerUuid]);
  // mingly.rtc.addParticipant(participants[peerUuid]);

  // pure peer to peer webrtc or mediaserver
  if (!mingly.board.room.mediaserver) {
    // HERE WE CHECK IF WE NEED TO SETUP PEER CONNECTION
    mingly.log('Should not be here if MS');
    // setupPeerConnection(peerUuid, initCall); // THIS IS THE WEBRTC PART
    mingly.rtc.setupPeerConnection(peerUuid, initCall);
    // IF WE DO NOT SETUP THE PEER CONNECTION WE SHOULD DO
    // participants[peerUuid].drawAvatar(); participants[peerUuid].adjustVolume(me); updateLayout();
  } 
  // might want to be a bit careful here to do the updating
  // maybe add a time since last update here, to avoid calling 
  // update connection to frequently
  setTimeout(() => {
    updateConnections();
  }, 2000);
}

function addToListOfUsers(participant) {
  const listusers = document.getElementById('listusers');

  if (!listusers) {
    console.error('addToListOfUsers: element listusers could not be found');
    return;
  }

  const userListEl = document.createElement('div');
  userListEl.setAttribute('id', `listusers_${participant.uuid}`);
  userListEl.setAttribute('class', 'userinroom');
  userListEl.setAttribute('class', `positiondata_${participant.name}`);
  participant.userListEntry = userListEl;
  listusers.appendChild(userListEl);

  const listpart = document.getElementById(`listusers_${participant.uuid}`);
  if (listpart) {
    listpart.innerText = participant.name;
    listpart.addEventListener('mouseover', function (event) {
      // how / when is this object reference evaluated?
      // Can we use participant.focus() here? Do we need to lookup the participant from the event?
      participants[participant.uuid].focus();
    });

    listpart.addEventListener('mouseout', function (event) {
      // how / when is this object reference evaluated? Can we use participant.focus() here?
      // Do we need to lookup the participant from the event?
      participants[participant.uuid].focus(false);
    });

  } else {
    console.error('addToListOfUsers: unable to locate listpart for uuid', participant.uuid);
  }

  // document.head.insertAdjacentHTML('beforeend', '<style> .positiondata_'+
  // position[peerUuid].name + ':hover{background: red;}</style>');
}

function sayHiToEveryone() {
  const { mingly } = window;
  const { board } = mingly;
  const { room } = board;
  const { me } = board;
  if (me.usepicture) {
    // only send image over websocket first time to easen on the flow
    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.HandShake }, me.asJSON(), { 'dest': 'all', 'avatar': localStorage.getItem('imgData') })));
  }
  else {
    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.HandShake }, me.asJSON(), { 'dest': 'all' })));
  }
  // serverConnection.send(JSON.stringify({'signal_position':pos }))
  if (mingly.logicServerStatus.reconnect) {
    console.log('a reconnect');
    // board.zoomtoParticipant(me);
  } else if (!mingly.initialPositionAndView) {
    me.moveToRandomPosition();
    // check if I have valid position
    setInterval(function () {
      const inBoard = me.hasValidPosition();
      let freeCord = board.isSpotAvailable(me.x, me.y);
      if (window.mingly.removeVisableFromBoard
        && window.mingly.hostStates.lockedMove) {
        freeCord = true;
      }
      if (!inBoard || !freeCord) {
        me.moveToRandomPosition();
      }
    }, 3000);
    // updating all connections after a short wait
    setTimeout(function () {
      updateConnections();
      // this is move close on entry
      moveCloseToParticipants();
    }, 3000);
    if (board.doIntroLocation && room.mediaserver) {
      board.drawVideo(me);
      board.doIntroAnimation();
    }
    me.hasMoved = true;
    if (room.mutedOnEntry || room.name === 'F300') {
      // note great and should have another way of doing this
      setTimeout(function () {
        muteAudio();
        console.log('muted the audio now');
      }, 5000);
    }
  } else {
    console.log('set the initial position and view');
    const mat = {
      a: mingly.initialPositionAndView.view.a,
      b: mingly.initialPositionAndView.view.b,
      c: mingly.initialPositionAndView.view.c,
      d: mingly.initialPositionAndView.view.d,
      e: mingly.initialPositionAndView.view.e,
      f: mingly.initialPositionAndView.view.f,
    };
    const dim = {
      width: mingly.initialPositionAndView.windowInnerWidth,
      height: mingly.initialPositionAndView.windowInnerHeight,
    };
    board.setView(mat,
      mat, dim);
  }
}

function moveCloseToParticipants() {
  const { mingly } = window;
  const { board } = mingly;
  // const { room } = board;
  const { me } = board;
  const decider1 = Math.random();
  const decider2 = Math.random();
  let signOfD = 1;
  if (decider2 < 0.5) {
    signOfD = -1;
  }
  let directionX = 0;
  let directionY = signOfD * 1;
  if (decider1 < 0.5) {
    directionX = signOfD * 1;
    directionY = 0;
  }
  if (mingly.moveCloseToSomeOneOnEntry.active === true) {
    Object.values(board.participants).forEach((participant) => {
      if (mingly.moveCloseToSomeOneOnEntry.activeSearch === true) {
        const x0 = participant.x + directionX * board.avatarSize;
        const y0 = participant.y + directionY * board.avatarSize;
        if (board.isSpotAvailable(x0, y0) && !me.isOutOfBounds(x0, y0)) {
          simulateTheMovementCloseToParticipant(x0, y0);
          mingly.moveCloseToSomeOneOnEntry.activeSearch = false;
        }
      }
      if (mingly.moveCloseToSomeOneOnEntry.activeSearch === true) {
        const x0 = participant.x - directionX * board.avatarSize;
        const y0 = participant.y - directionY * board.avatarSize;
        if (board.isSpotAvailable(x0, y0) && !me.isOutOfBounds(x0, y0)) {
          simulateTheMovementCloseToParticipant(x0, y0);
          mingly.moveCloseToSomeOneOnEntry.activeSearch = false;
        }
      }
    });
    setTimeout(function () {
      animateZoomToFocus();
    }, 1000);
  }
}
function simulateTheMovementCloseToParticipant(x0, y0) {
  // this function creates the simulation of movement to the participant
  // then zooms in 
  const { mingly } = window;
  const { board } = mingly;
  const { me } = board;
  const nSteps = 50;
  let currentX = me.x;
  let currentY = me.y;
  const distX = (x0 - currentX) / nSteps;
  const distY = (y0 - currentY) / nSteps;
  let counter = 0;
  const myTimer = setInterval(() => {
    if (counter < 50) {
      currentX += distX;
      currentY += distY;
      me.moveTo(currentX, currentY);
    } else {
      clearInterval(myTimer);
    }
    counter += 1;
  }, 10);
  // for (let i = 0; i < nSteps; i += 1) {
  //   currentX += distX;
  //   currentY += distY;
  //   me.moveTo(currentX, currentY);
  // }
}

function animateZoomToFocus() {
  const { mingly } = window;
  const { board } = mingly;
  const { me } = board;
  const { focusFactor } = board;
  const { x } = me;
  const { y } = me;
  const nSteps = 50;
  const dist = (board.focusFactor - 1) / (nSteps - 1);
  let focus = 1;
  let counter = 0;
  const myTimer = setInterval(() => {
    if (counter < 50) {
      focus += dist;
      const xDir = -x * focus + window.innerWidth / 2;
      const yDir = -y * focus + window.innerHeight / 2;
      board.ctx.setTransform(focus, 0, 0, focus, xDir, yDir);
      board.ctx0.setTransform(focus, 0, 0, focus, xDir, yDir);
      board.ctx1.setTransform(focus, 0, 0, focus, xDir, yDir);
      board.updateAll();
    } else {
      clearInterval(myTimer);
      board.updateAll();
      updateConnections();
    }
    counter += 1;
  }, 10);
  // for (let i = 0; i < nSteps; i += 1) {
  //   focus += dist;
  //   const xDir = -x * focus + window.innerWidth / 2;
  //   const yDir = -y * focus + window.innerHeight / 2;
  //   board.ctx.setTransform(focus, 0, 0, focus, xDir, yDir);
  //   board.ctx0.setTransform(focus, 0, 0, focus, xDir, yDir);
  //   board.ctx1.setTransform(focus, 0, 0, focus, xDir, yDir);
  //   board.updateAll();
  // }
}

function gotIceCandidate(event, peerUuid) {
  if (event.candidate != null) {
    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.ICE }, me.asJSON(), { 'ice': event.candidate, 'dest': peerUuid })));
  }
}

function createdDescription(description, peerUuid) {
  const { mingly } = window;
  const { participants } = mingly.board;

  const participant = participants[peerUuid];
  mingly.log(`got description, peer ${peerUuid}`);
  participant.peerConnection.setLocalDescription(description).then(function () {
    if (room.editBandWidth === true) {
      description.sdp = setMediaBitrates(description.sdp, room.videoBW, room.audioBW);
    }
    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.SDP }, me.asJSON(), { 'sdp': description, 'dest': peerUuid, 'room': me.board.room.name })));
  }).catch(errorHandler);
}

function renegotiate(event, peerUuid) {
  const { mingly } = window;
  mingly.log(`renegotiating with peer: ${peerUuid}`);
  participants[peerUuid].peerConnection.createOffer().then((description) => createdDescription(
    description, peerUuid)).catch(errorHandler);
}

function setupPeerConnection(peerUuid, initCall) {
  const { participants } = window.mingly.board;
  const { room } = window.mingly.board;

  if (room.mediaserver) {
    return;
  }

  // HERE WE CAN ADD MEDIA SERVERS AND OTHER WAYS OF SETTING UP THE CHANNEL FOR VIDEO AND AUDIO

  // THIS IS THE VANILLA WEBRTC:
  // let video = 1; // we should kill this one
  const video = 1; // we should kill this one

  participants[peerUuid].peerConnection = new RTCPeerConnection(room.peerConnectionConfig());
  participants[peerUuid].peerConnection.onicecandidate = (event) => gotIceCandidate(
    event, peerUuid);

  participants[peerUuid].peerConnection.ontrack = (event) => gotRemoteStream(
    event, peerUuid);

  participants[peerUuid].peerConnection.oniceconnectionstatechange = (event) => checkPeerDisconnect(
    event, peerUuid);

  participants[peerUuid].peerConnection.negotiationneeded = (event) => renegotiate(
    event, peerUuid);
  
  if (room.testing) {
    sendStatistics(participants[peerUuid]);
  }

  if (participants[peerUuid].isDJ) {
    for (const track of window.mingly.localStream4.getTracks()) {
      participants[peerUuid].peerConnection.addTrack(track); // not optimal, but at least better
    }
  }
  else {
    // for(const track of window.mingly.streamLowRes.getTracks()) {
    // change April 1 -- use window.mingly.streamBlackSilene()
    for (const track of window.mingly.streamBlackSilence.getTracks()) {
      participants[peerUuid].peerConnection.addTrack(track);
    }
  }

  participants[peerUuid].drawAvatar();
  participants[peerUuid].adjustVolume(me);
  updateLayout();

  if (initCall) {
    participants[peerUuid].peerConnection.createOffer().then((description) => createdDescription(
      description, peerUuid)).catch(errorHandler);
  }
}

function swapStream(participant, stream, part = 'all') {
  // HERE WE CAN ADD MEDIA SERVERS AND OTHER WAYS OF SWAPIING THE STREAMS
  // WE MIGHT HAVE TO INCLUDE INPUT THAT JUST INDICATES WHICH STREAM TO USE IF WE USE MEDIA SERVERS

  // THIS IS THE VANILLA WEBRTC VERSION:
  switch (part) {
    case 'audio': {
      const audioTrack = stream.getAudioTracks()[0];
      participant.peerConnection.getSenders().map((sender) => sender.replaceTrack(
        stream.getTracks().find((t) => t.kind === 'audio'), audioTrack));
      break;
    }

    case 'video': {
      const videoTrack = stream.getVideoTracks()[0];
      participant.peerConnection.getSenders().map((sender) => sender.replaceTrack(
        stream.getTracks().find((t) => t.kind === 'video'), videoTrack));
      break;
    }

    case 'all':
      participant.peerConnection.getSenders().map((sender) => sender.replaceTrack(
        stream.getTracks().find((t) => t.kind === sender.track.kind), stream));
      break;

    default: // same as all some maybe not great
      participant.peerConnection.getSenders().map((sender) => sender.replaceTrack(
        stream.getTracks().find((t) => t.kind === sender.track.kind), stream));

  }

  // if(onlyVideo){

  //   let videoTrack = stream.getVideoTracks()[0];
  //   //this is dangerous it seems
  //   participant.peerConnection.getSenders().map(sender =>
  //     sender.replaceTrack(stream.getTracks().find(t => t.kind == 'video'), videoTrack));

  //   // senders.find(sender => sender.track.kind === 'video')
  //   //   .replaceTrack(userMediaStream.getTracks().find(track => track.kind === 'video'));
  //   //   var sender = participant.peerConnection.getSenders().find(
  //   //     track => track.kind === 'video');
  //   //   console.log('found sender:', sender);
  //   //   sender.replaceTrack(videoTrack)
  // }
  // else if(onlyAudio){
  //    let audioTrack = stream.getAudioTracks()[0];
  //   participant.peerConnection.getSenders().map(sender =>
  //     sender.replaceTrack(stream.getTracks().find(t => t.kind == 'audio'), audioTrack)); //th

  // }
  // else{
  //   participant.peerConnection.getSenders().map(sender =>
  //     sender.replaceTrack(stream.getTracks().find(t => t.kind == sender.track.kind), stream));
  // }
}

// We do not use this functon anymore. Might bring a version of it back.
// Leave it commented out for now
// function gotRemoteStreamWrapper(event, peerUuid, video) {
//   const { mingly } = window;
//   const board = window.mingly.board;

//   mingly.log('gotRemoteStreamWrapper: entered function');
//   if (video) {
//     mingly.log('gotRemoteStreamWrapper: its a video track');
//     // console.log("the length is " +event.streams[0].getVideoTracks().length)
//     gotRemoteStream(event, peerUuid);
//   }
//   else {
//     mingly.log('gotRemoteStreamWrapper: video track is not defined');
//     gotRemoteAudioStream(event, peerUuid);
//   }
// }

function gotRemoteStream(event, peerUuid) {
  const { mingly } = window;
  const { board } = window.mingly;
  const { participants } = board;
  const { audioOutSource } = window.mingly;
  const { allowSpeakerSelect } = window.mingly;

  const participant = participants[peerUuid];
  mingly.log(`gotRemoteStream: got remote stream, peer ${peerUuid}`);
  if (!document.getElementById(`remoteVideo_${peerUuid}`)) {
    // if(!participant.videoContainer){
    // createVideoContainer(participant, event.streams, event.track);
    createVideoContainer(participant);

    if (event.streams && event.streams[0]) {
      participant.videoElement.srcObject = event.streams[0];
    }
    else {
      if (!participant.peerStreams) {
        participant.peerStreams = new MediaStream();
        // The ipad part for volume change -- this is quite experimental at the moments
        participant.videoElement.srcObject = participant.peerStreams;

        // todo: We need to do the folloing: Save the video track,
        // add all Gain stuff to audioelement rejoin into new stream
      }
      mingly.log('gotRemoteStream: missing stream, adding track (no remote video)');
      if (event.track.kind === 'audio' && board.isIpad) {
        mingly.log('Audio AND Ipad');
        // todo: here we split the peerStreams but it does not work. Keep it for now to work on it
        participant.setupAudioCtx(event.track);
        // participant.setupAudioCtx(event);
      }
      else {
        participant.peerStreams.addTrack(event.track);
      }

    }
    // vidElement.setAttribute('autoplay', '');
    // participant.videovar = 1;
    participant.videoElement.srcObject.volume = 0;
    participant.videoElement.onplaying = function () {
      participant.isPlayingVideo = true;
    };

    participant.videoElement.onpause = function () {
      participant.isPlayingVideo = false;
    };

    participant.resumeVideo();

    // if (board.type === 'Canvasboard') {
    //   participant.videoElement.addEventListener('play', function(){
    //     console.log("Participant.play: about to drawVideo")
    //     board.drawVideo(participant)
    //   }, false);
    // }

    // board.drawVideo(participant);
    board.drawAvatar(participant);
  } else {
    // there is a remote video for peerUuid
    const vidContainer = document.getElementById(`remoteVideo_${peerUuid}`);
    participant.videoElement = vidContainer.childNodes[0];
    if (event.streams && event.streams[0]) {
      participant.videoElement.srcObject = event.streams[0];
    }
    else {
      if (!participant.peerStreams) {
        participant.peerStreams = new MediaStream();
        participant.videoElement.srcObject = participant.peerStreams;
      }
      if (event.track.kind === 'audio' && board.isIpad) {
        mingly.log('Audio AND Ipad');
        // todo: here we split the peerStreams but it does not work. Keep it for now to work on it
        participant.setupAudioCtx(event.track);
        // participant.setupAudioCtx(event);
      } else {
        participant.peerStreams.addTrack(event.track);
      }
      mingly.log('gotRemoteStream: missing stream, adding track (we have a remote video)');
      // participant.videoElement.srcObject = participant.peerStreams;
    }

    // participant.vidElement.setAttribute('autoplay', '');
    try {
      if (allowSpeakerSelect && audioOutSource &&
        window.adapter.browserDetails.browser === 'chrome') {
        participant.videoElement.setSinkId(audioOutSource);
      }
    }
    catch {
      window.mingly.log('cannot set sinkId');
    }

    participant.videoElement.srcObject.volume = 0;
    vidContainer.childNodes[0].volume = 0;
    participant.videoElement.onplaying = function () {
      participant.isPlayingVideo = true;
    };

    participant.videoElement.onpause = function () {
      participant.isPlayingVideo = false;
    };

    participant.resumeVideo();
    // board.drawVideo(participant);
    board.drawAvatar(participant);
  }
}

// This function is not used at the moment. It is related to the wrapper above 
// function gotRemoteAudioStream(event, peerUuid) {
//   const { mingly } = window;  
//   const { audioOutSource } = window.mingly;

//   const participant = participants[peerUuid];
//   mingly.log(`gotRemoteAudioStream: got stream, peer ${peerUuid}`);
//   // assign stream to new HTML video element
//   if (me.isDJ) {
//     if (!participant.peerStreams) {
//       participant.peerStreams = new MediaStream();
//     }
//   }
//   else if (!document.getElementById(`remoteAudio_${peerUuid}`)) {
//     const audElement = document.createElement('audio');
//     audElement.setAttribute('autoplay', '');
//     if (audioOutSource) {
//       audElement.setSinkId(audioOutSource);
//     }

//     // vidElement.setAttribute('muted', '');
//     // peerConnections[peerUuid].stream=event.streams[0];

//     if (event.streams && event.streams[0]) {
//       audElement.srcObject = event.streams[0];
//     }
//     else {
//       if (!participant.peerStreams) {
//         participant.peerStreams = new MediaStream();
//       }
//       mingly.log('gotRemoteAudioStream: (no remote video)');
//       participant.peerStreams.addTrack(event.track);
//       audElement.srcObject = participant.peerStreams;
//     }

//     // audElement.srcObject = event.streams[0];
//     // console.log(event.streams[0])
//     participant.videovar = 0;
//     if (window.mingly.onVideovar) {
//       try {
//         window.mingly.onVideovar(participant);
//       }
//       catch {
//         mingly.log('gotRemoteAudioStream: error when calling videovar event listener');
//       }
//     }

//     const audContainer = document.createElement('div');
//     audContainer.setAttribute('id', `remoteAudio_${peerUuid}`);
//     audContainer.setAttribute('class', 'audioContainer');
//     audContainer.appendChild(audElement);

//     document.getElementById('audios').appendChild(audContainer);
//     audElement.srcObject.volume = 0;
//     audContainer.childNodes[0].volume = 0;

//     // updateLayout();
//   }
// }

function checkPeerDisconnect(event, peerUuid) {
  const participant = participants[peerUuid];

  if (participant && participant.peerConnection) {
    const state = participant.peerConnection.iceConnectionState;
    window.mingly.log(`checkPeerDisconnect: connection with peer ${peerUuid} ${state}`);
    if (state === 'failed' || state === 'closed' || state === 'disconnected') {
      window.mingly.log(`checkPeerDisconnect: state in [failed|closed|disconnected] (${state}). Removing connection.`);
      // SEND A PING HERE
      pingParticipant(participant);
    }
  }
  else {
    window.mingly.log(`checkPeerDisconnect: unable to get participants listing of peer ${peerUuid}`);
  }
}

function pingParticipant(participant) {
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.PingParticipant }, me.asJSON(), { 'areYouThere': true }, { 'dest': participant.uuid })));
  participant.isThere = false;
  setTimeout(function () {
    if (participant.isThere) {
      // SHOULD ONE RECONNECT IF THERE?
      // NEED TO RUN A TEST TO SEE IF CLOSE ENOUGH, IF SO RECONNECT
      if (!window.mingly.board.room.mediaserver) {
        window.mingly.log('Participant still here! Trying to connect again');
        participant.peerConnection.close();
        if (participant.peerStreams) {
          delete participant.peerStreams;
        }
        if (participant.vidContainer) {
          delete participant.vidContainer;
        }
        setupPeerConnection(participant.uuid, true);  
      }
    }
    else {
      window.mingly.log('participant not here anymore, killing...');
      killPeer(participant.uuid, true);
    }
  }, 5000); // WAIT 5 SEC FOR EXECUTION
}

function killPC(peerUuid, initiate) {
  if (participants[peerUuid].peerConnection) {
    participants[peerUuid].peerConnection.close(); // need to see if this works
    if (initiate) {
      serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.CloseRTC }, me.asJSON(), { 'dest': peerUuid })));
    }
    delete participants[peerUuid].peerConnection;
  }

  if (participants[peerUuid].peerStreams) {
    delete participants[peerUuid].peerStreams;
  }

  if (document.getElementById(`remoteVideo_${peerUuid}`)) {
    document.getElementById('videos').removeChild(document.getElementById(`remoteVideo_${peerUuid}`));
  }

  if (document.getElementById(`remoteAudio_${peerUuid}`)) {
    document.getElementById('audios').removeChild(document.getElementById(`remoteAudio_${peerUuid}`));
  }
}

function killPeer(peerUuid, initiate) {
  const { mingly } = window;
  console.log('Inititating killPeer');
  try {
    if (participants[peerUuid].isHost) {
      // resetting this since otherwise 
      // other participants will be locked 
      // in the room without the possibility 
      // to move, turn on video or audio
      mingly.hostStates.lockedMove = false;
      mingly.hostStates.mutedAudio = false;
      mingly.hostStates.turnedOffVideo = false;
    }
  } catch {
    mingly.log('No host defined');
  }
  mingly.log('Call KillPeer');
  if (mingly.onRemoveParticipant) {
    try {
      mingly.onRemoveParticipant(participants[peerUuid]);
    }
    catch {
      mingly.log('Did not trigger remove');
    }
  }
  if (initiate) {
    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.ClosePeer }, me.asJSON(), { 'dest': peerUuid })));
  }
  try {
    participants[peerUuid].clearAvatar();
  } catch {
    mingly.log('No avatar to clear');
  }
  try {
    if (participants[peerUuid].peerConnection) {
      participants[peerUuid].peerConnection.close(); // need to check if this works
      delete participants[peerUuid].peerConnection;
    }
  } catch {
    mingly.log('No peerconnection');
  }
  // if media server part 
  if (mingly.board.room.mediaserver) {
    try {
      participants[peerUuid].consumerMS.forEach((consumer) => {
        consumer.close();
      });
    } catch {
      mingly.log('no consumers in MS');
    }
  }
  // if (mingly.msBroadCastOnCanvasData.id === peerUuid) {
  if (mingly.msBroadCastOnCanvas[peerUuid] !== undefined) {
    closeMSbroadCast(peerUuid);
  }
  // should also close transport etc...
 
  delete participants[peerUuid];
  // mingly.removeParticipant(peerUuid);
  // updateLayout();
  // Connected Canvas Elements
  // It goes here
  // DOM elements
  if (document.getElementById(`remoteVideo_${peerUuid}`)) {
    document.getElementById('videos').removeChild(document.getElementById(`remoteVideo_${peerUuid}`));
  }

  if (document.getElementById(`remoteAudio_${peerUuid}`)) {
    document.getElementById('audios').removeChild(document.getElementById(`remoteAudio_${peerUuid}`));
  }

  if (document.getElementById(`msRemoteVideo_${peerUuid}`)) {
    document.getElementById('videos').removeChild(document.getElementById(`msRemoteVideo_${peerUuid}`));
  }

  const elem = document.getElementById('listusers');
  if (elem) {
    try {
      elem.removeChild(document.getElementById(`listusers_${peerUuid}`));
    } catch {
      mingly.log('No list to remove here');
    }
  }
}

function updateLayout() {
  const Ntot = Object.keys(participants).length + 1; // include yourself
  let videoWidth;
  let videoHeight;
  let nameTop;
  let nameLeft;

  switch (Ntot) {
    case 1:
      videoWidth = '100%';
      videoHeight = '720px';
      nameTop = 5;
      nameLeft = 96;
      break;

    case 2:
      videoWidth = '50%';
      videoHeight = '720px';
      nameTop = 150;
      nameLeft = 5;
      break;

    case 3:
    case 4:
      videoWidth = '50%';
      videoHeight = '360px';
      nameTop = 5;
      nameLeft = 52;
      break;

    case 5:
    case 6:
      videoWidth = '33%';
      videoHeight = '360px';
      nameTop = 42;
      nameLeft = 5;
      break;

    case 7:
    case 8:
      videoWidth = '25%';
      videoHeight = '360px';
      nameTop = 76;
      nameLeft = 5;
      break;

    default:
      videoWidth = '22.5%';
      videoHeight = '360px';
      nameTop = 76;
      nameLeft = 5;
  }

  document.documentElement.style.setProperty('--videoHeight', videoHeight);
  document.documentElement.style.setProperty('--videoWidth', videoWidth);
  document.documentElement.style.setProperty('--nameTop', `${nameTop.toString()}px`);
  document.documentElement.style.setProperty('--nameLeft', `${nameLeft.toString()}px`);
}

function makeLabel(label) {
  const vidLabel = document.createElement('div');
  vidLabel.appendChild(document.createTextNode(label));
  vidLabel.setAttribute('class', 'videoLabel');
  return vidLabel;
}

function errorHandler(error) {
  if (error) {
    console.error('errorHandler: ', error);
  } else {
    console.error('errorHandler: called with unassigned error object');
  }
}

// Taken from http://stackoverflow.com/a/105074/515584
// Strictly speaking, it's not a real UUID, but it gets the job done here
function createUUID() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  }

  // eslint-disable-next-line prefer-template
  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

function muteDistantParticipants() {
  const { mingly } = window;
  const { participants } = mingly.board;
  Object.values(participants).forEach((participant) => {
    const distance = participant.distanceTo(me);
    if (participant.peerConnection && (distance > room.upperVideoCutoff)) {
      mingly.rtc.swapStream(participant.uuid, window.mingly.streamBlack);
      participant.videovar = 0;
      if (mingly.onVideovar) {
        try {
          mingly.onVideovar(participant);
        }
        catch {
          mingly.error('muteDistantParticipants: error when calling videovar event listener');
        }
      }
    }
  });
}

// A lighter version of updateConnections that only adjusts volume
function adjustVolumeAll() {
  const { participants } = window.mingly.board;
  const { canvasObjects } = window.mingly.board;
  Object.values(participants).forEach((participant) => {
    participant.adjustVolume(me);
  });
  Object.values(canvasObjects).forEach((object) => {
    object.adjustVolume();
  });
}

function getSilence() {
  const { mingly } = window;
  const { me } = mingly.board;
  if (me.noSound) {
    me.noSound = false;
  } else {
    me.noSound = true;
  }
  adjustVolumeAll();
}

function shareVideoAndAudio() {
  const { mingly } = window;
  const { constraints } = window.mingly;

  // const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
  // const isEdgeChromium = isChrome && (navigator.userAgent.indexOf('Edg') !== -1);

  if (window.adapter.browserDetails.browser !== 'chrome') {
    return;
  }

  if (!me.screenShare) {
    me.screenShare = true;
    if (mingly.onScreenShare) {
      try {
        mingly.onScreenShare(me.screenShare);
      }
      catch {
        mingly.log('did not do screen share trigger');
      }
    }
    const ShareOptions = {
      video: true,
      audio: true,
    };

    navigator.mediaDevices.getDisplayMedia(ShareOptions)
      .then((stream) => {
        stream.getAudioTracks()[0].addEventListener('ended', () => {
          if (me.screenShare) {
            me.screenShare = false;
          }
        });
        stream.getVideoTracks()[0].addEventListener('ended', () => {
          if (me.screenShare) {
            me.screenShare = false;
          }
          if (mingly.onScreenShare) {
            try {
              mingly.onScreenShare(me.screenShare);
            }
            catch {
              mingly.log('did not do screen share trigger');
            }
          }
          navigator.mediaDevices.getUserMedia(constraints)
            .then((stream) => {
              window.mingly.streamLowRes = stream;
              me.videoElement.srcObject = stream;
              me.videoElement.onplaying = function () {
                me.isPlayingVideo = true;
              };
              me.videoElement.onpause = function () {
                me.isPlayingVideo = false;
              };
              me.resumeVideo();
              window.mingly.streamBlack = stream.clone();
              window.mingly.streamBlack.getVideoTracks()[0].enabled = false;
            }).catch(errorHandler);
      
          window.mingly.log('shareVideoAndAudio: switching back to video');
          serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.ShareScreen }, me.asJSON(), { 'dest': 'all' })));
          if (window.mingly.board.room.mediaserver) {
            const producers = window.mingly.board.me.producerMS;
            const producerVideo = producers.find((p) => (p.appData.mediaTag === 'video'));
            producerVideo.replaceTrack({ track: window.mingly.streamLowRes.getVideoTracks()[0] });
            const producerAudio = producers.find((p) => (p.appData.mediaTag === 'audio'));
            producerAudio.replaceTrack({ track: window.mingly.streamLowRes.getAudioTracks()[0] });
    
          } else {
            Object.values(participants).forEach((participant) => {
              if (participant.videovar === 1) {
                mingly.rtc.swapStream(participant.uuid, window.mingly.streamLowRes);
              }
              else {
                mingly.rtc.swapStream(participant.uuid, window.mingly.streamBlack);
              }
            });
          }
        });
        window.mingly.streamScreen = stream;
        me.videoElement.srcObject = stream;
        window.mingly.streamBlack = stream.clone();
        window.mingly.streamBlack.getVideoTracks()[0].enabled = false;
        me.resumeVideo();
        serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.ShareScreen }, me.asJSON(), { 'dest': 'all' })));
        if (window.mingly.board.room.mediaserver) {
          const producers = window.mingly.board.me.producerMS;
          const producerVideo = producers.find((p) => (p.appData.mediaTag === 'video'));
          producerVideo.replaceTrack({ track: window.mingly.streamScreen.getVideoTracks()[0] });
          const producerAudio = producers.find((p) => (p.appData.mediaTag === 'audio'));
          producerAudio.replaceTrack({ track: window.mingly.streamScreen.getAudioTracks()[0] });
        } else {
          Object.values(participants).forEach((participant) => {
            mingly.rtc.swapStream(participant.uuid, window.mingly.streamScreen);
          });
        }
      })
      .catch(function (e) {
        me.screenShare = false;
        if (mingly.onScreenShare) {
          try {
            mingly.onScreenShare(me.screenShare);
          }
          catch {
            mingly.log('did not do screen share trigger');
          }
        }
        me.videoElement.srcObject = window.mingly.streamLowRes;
        me.resumeVideo();   
        mingly.error(e.message);
      });

  }
  else {
    me.screenShare = false;
    if (window.mingly.onScreenShare) {
      try {
        window.mingly.onScreenShare(me.screenShare);
      }
      catch {
        window.mingly.log('did not do screen share trigger');
      }
    }
    if (window.mingly.board.room.mediaserver) {
      // seems like the replacetrack methods stops the previous stream 
      // therefore we have to call gertUserMedia again
      navigator.mediaDevices.getUserMedia(window.mingly.constraints)
        .then((stream) => {
          window.mingly.streamLowRes = stream;
          window.window.mingly.streamLowRes = window.mingly.streamLowRes; // should remove this
          me.videoElement.srcObject = stream;
          me.videoElement.onplaying = function () {
            me.isPlayingVideo = true;
          };

          me.videoElement.onpause = function () {
            me.isPlayingVideo = false;
          };
          const producers = window.mingly.board.me.producerMS;
          const producerVideo = producers.find((p) => (p.appData.mediaTag === 'video'));
          producerVideo.replaceTrack({ track: window.mingly.streamLowRes.getVideoTracks()[0] });
          const producerAudio = producers.find((p) => (p.appData.mediaTag === 'audio'));
          producerAudio.replaceTrack({ track: window.mingly.streamLowRes.getAudioTracks()[0] });
          // window.mingly.streamScreen.getVideoTracks()[0].stop(); // might not need this
          me.videoElement.srcObject = window.mingly.streamLowRes;
          window.mingly.log('shareScreen: switching back to video');
          serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.ShareScreen }, me.asJSON(), { 'dest': 'all' })));
          me.resumeVideo();
        }).catch(errorHandler);
    } else {
      window.mingly.streamScreen.getVideoTracks()[0].stop();
      window.mingly.streamScreen.getAudioTracks()[0].stop();
      if (mingly.onScreenShare) {
        try {
          mingly.onScreenShare(me.screenShare);
        }
        catch {
          mingly.log('did not do screen share trigger');
        }
      }
      navigator.mediaDevices.getUserMedia(constraints)
        .then((stream) => {
          window.mingly.streamLowRes = stream;
          me.videoElement.srcObject = stream;
          me.videoElement.onplaying = function () {
            me.isPlayingVideo = true;
          };
          me.videoElement.onpause = function () {
            me.isPlayingVideo = false;
          };
          me.resumeVideo();
          window.mingly.streamBlack = stream.clone();
          window.mingly.streamBlack.getVideoTracks()[0].enabled = false;
        }).catch(errorHandler);

      window.mingly.log('shareVideoAndAudio: switching back to video');
      serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.ShareScreen }, me.asJSON(), { 'dest': 'all' })));
      Object.values(participants).forEach((participant) => {
        if (participant.videovar === 1) {
          mingly.rtc.swapStream(participant.uuid, window.mingly.streamLowRes);
        }
        else {
          mingly.rtc.swapStream(participant.uuid, window.mingly.streamBlack);
        }
      });
    }
  }
}
function playCallingSound() {
  const callingAudio = document.createElement('audio');
  callingAudio.src = 'https://www.dropbox.com/s/a7d0gxwuvf1bxvd/zapsplat_multimedia_ringtone_modern_digital_smartphone_001_63265.mp3?raw=1';
  callingAudio.play();
}
function callParticipant(participant) {
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.Calling }, me.asJSON(), { 'dest': participant.uuid })));
  playCallingSound();
}
function sleep() {
  const { board } = window.mingly;
  const { me } = board;
  if (!me.isSleeping) {
    me.isSleeping = true;
    if (!me.muted) {
      muteAudio();
    }
    if (!me.noSound) {
      getSilence();
    }
    if (!me.noVideo) {
      noVideo();
    }
  } else {
    me.isSleeping = false;
    if (me.muted) {
      muteAudio();
    }
    if (me.noSound) {
      getSilence();
    }
    if (me.noVideo) {
      noVideo();
    }
  }
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.Sleeping }, me.asJSON(), { 'dest': 'all' })));
}
function GhostMe() {
  const { board } = window.mingly;
  const { me } = board;
  if (!me.isGhost) {
    me.isGhost = true;
    if (!me.muted) {
      muteAudio();
    }
    if (!me.noVideo) {
      noVideo();
    }
  } else {
    me.isGhost = false;
    if (me.muted) {
      muteAudio();
    }
    if (me.noVideo) {
      noVideo();
    }
  }
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.TurnGhost }, me.asJSON(), { 'dest': 'all' })));
}
function playOrStopHandRaiseBroadcat(id, stop = false) {
  const { mingly } = window;
  const { board } = mingly;
  const { participants } = board;
  const participant = participants[id];
  if (!stop) {
    mingly.raisedHandData[id].isBroadCasting = !mingly.raisedHandData[id].isBroadCasting;
  }
  if (mingly.raisedHandData[id].isBroadCasting) {
    mingly.hostStates.numberOfBroadCasters += 1;
    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.HostToHandRaiser }, me.asJSON(), { 'dest': participant.uuid, 'type': mingly.raisedHandData[id].isBroadCasting, 'numberBroadCasters': mingly.hostStates.numberOfBroadCasters })));
  } else {
    if (!stop) {
      mingly.hostStates.numberOfBroadCasters -= 1;
    }
    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.HostToHandRaiser }, me.asJSON(), { 'dest': participant.uuid, 'type': mingly.raisedHandData[id].isBroadCasting, 'x': mingly.raisedHandData[id].oldX, 'y': mingly.raisedHandData[id].oldY })));
  }
}

function hostActivateNoHostMenu() {
  const { mingly } = window;
  const { board } = mingly;
  const { me } = board;
  if (!me.isHost) return;
  let active = false;
  if (mingly.hostStates.lockedMove ||
    mingly.hostStates.mutedAudio ||
    mingly.hostStates.turnedOffVideo) {
    active = true;
  }
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.ActivateNoHostMenu }, me.asJSON(), { 'dest': 'all', 'active': active })));
}
function hostMute(type) {
  const { mingly } = window;
  const { board } = mingly;
  const { me } = board;
  if (!me.isHost) return;
  if (type === 'mute') {
    mingly.hostStates.mutedAudio = true;
  } else {
    mingly.hostStates.mutedAudio = false;
  }
  // might want to avoid double message so can economize here
  hostActivateNoHostMenu();
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.HostMuteAll }, me.asJSON(), { 'dest': 'all', 'type': type })));
}
function hostNoVideo(type) {
  const { mingly } = window;
  const { board } = mingly;
  const { me } = board;
  if (!me.isHost) return;
  if (type === 'off') {
    mingly.hostStates.turnedOffVideo = true;
  } else {
    mingly.hostStates.turnedOffVideo = false;
  }
  // might want to avoid double message so can economize here
  hostActivateNoHostMenu();
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.HostNoVideo }, me.asJSON(), { 'dest': 'all', 'type': type })));
}
function placeInCenterAreaForGuessTheCity() {
  const { mingly } = window;
  const { board } = mingly;
  const { me } = board;
  let counter = 0;
  const spaceBetween = 10; 
  const fractionParticipants = 5 / 8; // based on grid of 8
  // some calculations
  const w = board.width;
  const h = board.height;
  const boardCenter = w / 2;
  const avatarSize = board.avatarSize;
  const heightParticipantsArea = h * fractionParticipants;
  const maxParticipantsHeight = heightParticipantsArea / (avatarSize + spaceBetween);
  const delta = avatarSize + spaceBetween;  
  // count the number of participants excluding yourself
  let  N = 1; // count youself first here
  Object.values(board.participants).forEach((participant) => {
    N += 1;
  });
  // find out how many in each row in the square approximation
  let inEachRow = Math.ceil(Math.sqrt(N));
  const rows = inEachRow; // this can be improved on
  // check for capacity 
  let startX;
  const startY = h - heightParticipantsArea;
  let widthParticipantsArea;
  if (rows < maxParticipantsHeight) {
    widthParticipantsArea = (inEachRow - 1) * (avatarSize + spaceBetween);
    startX = boardCenter - widthParticipantsArea / 2;
  } else {
    // double number in each row
    inEachRow *= 2; 
    widthParticipantsArea = 2 * (inEachRow - 1) * (avatarSize + spaceBetween);
    startX = boardCenter - widthParticipantsArea / 2;
  }
  console.log(boardCenter);
  console.log(widthParticipantsArea);
  console.log(heightParticipantsArea);
  let inRowCounter = 0;
  let currentX = startX;
  let currentY = startY;
  counter = 1;
  me.moveTo(startX, startY, true);
  currentY += delta;
  inRowCounter = 1;
  Object.values(board.participants).forEach((participant) => {
    setTimeout(function () {
      serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.forcedMoveFromHost }, me.asJSON(), { 'dest': participant.uuid, 'x': currentX, 'y': currentY })));
      currentX += delta;
      inRowCounter += 1;
      if (inRowCounter === inEachRow) {
        currentY += delta;
        currentX = startX;
        inRowCounter = 0;
      }
    }, 100 * counter);
    counter += 1;
  });
}
function placeInGroups() {
  const { mingly } = window;
  const { board } = mingly;
  const avatarSize = board.avatarSize;
  // should I place myself, probably not
  const Nparticipants = Object.keys(board.participants).length;
  if (Nparticipants < 2) {
    return;
  }
  // setting up the number of groups
  // this we want o have as a parameter later
  let groups = 0;
  let groupSize = 0;
  if (Nparticipants < 4) {
    // just one group
    groups = 1;
    groupSize = Nparticipants;
  } else if (Nparticipants < 7) {
    // two groups
    groups = 2;
    groupSize = Math.floor(Nparticipants / 2);
  } else if (Nparticipants < 18) {
    // three groups
    groups = 3;
    groupSize = Math.floor(Nparticipants / 3);
  }  else {
    // more groups with max size of 6 in each group
    groupSize = 6;
    groups = Math.floor(Nparticipants / 6);
  }
  // randomize the groups
  const shuffledParticipants = shuffleArray(Object.keys(board.participants));
  // move me to a "safe place" for now
  const { me } = board;
  const w = board.width;
  const h = board.height;
  me.moveTo(w - board.avatarSize, h - board.avatarSize, true);
  // setting up the placement of the groups on the board
  const spaceBetween = 10;
  const spaceBetweenGroups = 300;
  const groupsPerRows = 3; // should make this dynamic later
  const inEachRow = Math.ceil(Math.sqrt(groupSize));
  // setting up the placement of the participants in the groups
  let startX = avatarSize + spaceBetween;
  let startY = avatarSize + spaceBetween;
  let currentX = startX;
  let currentY = startY;
  let inRowCounter = 0;
  let inGroupCounter = 0;
  let groupCounter = 0;
  let totalGroupCounter = 0;
  let counter = 1;
  Object.values(shuffledParticipants).forEach((sp) => {
    const participant = board.participants[sp];
    console.log('some stuff');
    console.log(participant);
    console.log(groupSize);
    console.log(inEachRow);
    console.log(groupsPerRows);
    setTimeout(function () {
      serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.forcedMoveFromHost }, me.asJSON(), { 'dest': participant.uuid, 'x': currentX, 'y': currentY })));
      currentX += avatarSize + spaceBetween;
      inRowCounter += 1;
      inGroupCounter += 1;
      if (inRowCounter === inEachRow) {
        currentY += avatarSize + spaceBetween;
        currentX = startX;
        inRowCounter = 0;
      }
      if (inGroupCounter === groupSize) {
        inRowCounter = 0;
        inGroupCounter = 0;
        groupCounter += 1;
        currentX += spaceBetweenGroups;
        startX = currentX;
        currentY = startY;
        totalGroupCounter += 1;
        if (totalGroupCounter === groups - 1) {
          console.log('last group');
          const NparticipantsLeft = Nparticipants - groupSize * totalGroupCounter;
          groupSize = NparticipantsLeft;
          console.log(groupSize);
        }
      }
      if (groupCounter === groupsPerRows) {
        groupCounter = 0;
        inRowCounter = 0;
        currentX = avatarSize + spaceBetween;
        currentY += spaceBetweenGroups;
        startY = currentY;
      }
    }, 100 * counter);
    counter += 1;
  });

}
// Function to shuffle an array (Fisher-Yates shuffle)
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    // Pick a remaining element...
    const j = Math.floor(Math.random() * (i + 1));
    // And swap it with the current element.
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}
function hostPlaceAndLockParticipants(override = false) {
  // at a later stage we need to think about the inputs
  // Can think of things like sections of the board as 
  // the placing area etc. 
  const { mingly } = window;
  const { board }  = mingly;
  const { me } = board;
  let counter = 0;
  if (!me.isHost) return;
  if (mingly.hostStates.lockedMove  && !override) {
    mingly.hostStates.lockedMove = false;
    // might want to avoid double message so can economize here
    hostActivateNoHostMenu();
    // draw random x and y
    // if (mingly.hostArea.isActive) {
    //   mingly.hostArea.isActive = false;
    //   mingly.board.canvasObjects[mingly.hostArea.canvasUuid].isActive = false;
    //   board.updateAll();
    // }
    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.PlaceAndLock }, me.asJSON(), { 'dest': 'all', 'lock': mingly.hostStates.lockedMove })));

    // Object.values(board.participants).forEach((participant) => {
    //   setTimeout(function () {
    //     serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.PlaceAndLock }, 
    // me.asJSON(), { 'dest': participant.uuid, 'lock': mingly.hostStates.lockedMove })));
    //   }, 200 * counter);
    //   counter += 1;
    // });
    return;
  }
  if (override) console.log('did an override');
  // update the state
  mingly.hostStates.lockedMove = true;
  // might want to avoid double message so can economize here
  hostActivateNoHostMenu();
  // some parameters. We could make them inputs later
  if (mingly.gatherOnHostLock) {
    const spaceBetween = 10; 
    const fractionParticipants = 0.6;
    // some calculations
    const w = board.width;
    const h = board.height;
    const boardCenter = w / 2;
    const avatarSize = board.avatarSize;
    const heightParticipantsArea = h * fractionParticipants;
    const maxParticipantsHeight = heightParticipantsArea / (avatarSize + spaceBetween);
    const delta = avatarSize + spaceBetween;  
    // count the number of participants excluding yourself
    let  N = 0;
    Object.values(board.participants).forEach((participant) => {
      N += 1;
    });
    // find out how many in each row in the square approximation
    let inEachRow = Math.ceil(Math.sqrt(N));
    const rows = inEachRow; // this can be improved on
    // check for capacity 
    let startX;
    const startY = h - heightParticipantsArea;
    let widthParticipantsArea;
    if (rows < maxParticipantsHeight) {
      widthParticipantsArea = (inEachRow - 1) * (avatarSize + spaceBetween);
      startX = boardCenter - widthParticipantsArea / 2;
    } else {
      // double number in each row
      inEachRow *= 2; 
      widthParticipantsArea = 2 * (inEachRow - 1) * (avatarSize + spaceBetween);
      startX = boardCenter - widthParticipantsArea / 2;
    }
    console.log(boardCenter);
    console.log(widthParticipantsArea);
    console.log(heightParticipantsArea);
    // const data = {}; // the data we will return 
    let inRowCounter = 0;
    let currentX = startX;
    let currentY = startY;
    counter = 0;
    Object.values(board.participants).forEach((participant) => {
      // data[participant.uuid] = {
      //   uuid: participant.uuid,
      //   x: currentX,
      //   y: currentY,
      // };
      // need to be a bit careful with the setTimeout used here
      setTimeout(function () {
        serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.PlaceAndLock }, me.asJSON(), { 'dest': participant.uuid, 'lock': mingly.hostStates.lockedMove, 'x': currentX, 'y': currentY })));
        currentX += delta;
        inRowCounter += 1;
        if (inRowCounter === inEachRow) {
          currentY += delta;
          currentX = startX;
          inRowCounter = 0;
        }
      }, 200 * counter);
      counter += 1;
    });
  } else {
    counter = 0;
    // if (!mingly.hostArea.isActive) {
    //   mingly.hostArea.isActive = true;
    //   mingly.board.canvasObjects[mingly.hostArea.canvasUuid].isActive = true;
    //   board.updateAll();
    // }
    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.PlaceAndLock }, me.asJSON(), { 'dest': 'all', 'lock': mingly.hostStates.lockedMove })));

    // Object.values(board.participants).forEach((participant) => {
    //   // Just keep at same x and y
    //   setTimeout(function () {
    //     serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.PlaceAndLock }, 
    // me.asJSON(), { 'dest': participant.uuid, 'lock': mingly.hostStates.lockedMove, 
    // 'x': participant.x, 'y': participant.y })));
    //     counter += 1;
    //   });
    // });
  }
}
function raiseHand() {
  const { mingly } = window;
  const { board } = mingly;
  const { participants } = board;
  const { me } = board;
  if (me.isHost) return;
  let type;
  if (me.hasHandRaised) {
    type = false;
    me.hasHandRaised = false;
  } else {
    type = true;
    me.hasHandRaised = true;
  }
  let host; // just send it to server in case nothing
  Object.values(participants).forEach((participant) => {
    if (participant.isHost) {
      host = participant.uuid;
    }
  });
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.RaiseHand }, me.asJSON(), { 'dest': host, 'type': type })));

}
function updateHostStates() {
  const  { mingly } = window;
  // this function updates the host states
  // it is only run when entering the room
  // need to check if a video is playing from the host, 
  // if so I start it. This has a different trigger
  if (mingly.hostStates.currentVideo) {
    setTimeout(function () {
      startBIIntroVideo(mingly.hostStates.currentVideo);
    },  5000);
    return;
  }

  if (mingly.hostStates.mutedAudio) {
    muteFromHost('mute');
  }
  // do video here
  if (mingly.hostStates.turnedOffVideo) {
    noVideoFromHost('off');
  }
  // do lock to position here
  // host reply menu
  let active = false;
  if (mingly.hostStates.lockedMove ||
    mingly.hostStates.mutedAudio ||
    mingly.hostStates.turnedOffVideo) {
    active = true;
  }
  if (mingly.onNoHostMenu) {
    try {
      mingly.onNoHostMenu(active);
    }
    catch {
      mingly.log('did not triggeronNoHostMenu');
    }
  }
}
function muteFromHost(type, updateHostState = true) {
  const { mingly } = window;
  const { board } = mingly;
  const { me } = board;
  if (type === 'mute') {
    me.muted = true;
    // when screen sharing the video track is the only thing replaced
    // (not sharing audio from screen)
    if (board.room.mediaserver) {
      const producers = me.producerMS;
      const producer = producers.find((p) => (p.appData.mediaTag === 'audio'));
      pauseProducer(producer, true);  
    } else {
      window.mingly.streamLowRes.getAudioTracks()[0].enabled = false;
      window.mingly.streamBlack.getAudioTracks()[0].enabled = false;
    }
    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.Muted }, me.asJSON(), { 'dest': 'all' })));
    if (updateHostState) {
      mingly.hostStates.mutedAudio = true;
    } 
    board.updateAll();
  } else {
    // eslint-disable-next-line no-lonely-if
    if (!me.isSleeping) {
      me.muted = false;
      // when screen sharing the video track is the only thing replaced
      // (not sharing audio from screen)
      if (board.room.mediaserver) {
        const producers = me.producerMS;
        const producer = producers.find((p) => (p.appData.mediaTag === 'audio'));
        pauseProducer(producer, false);  
      } else {
        window.mingly.streamLowRes.getAudioTracks()[0].enabled = true;
        window.mingly.streamBlack.getAudioTracks()[0].enabled = true;
      }
    }
    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.Muted }, me.asJSON(), { 'dest': 'all' })));
    board.updateAll();
    if (updateHostState) {
      mingly.hostStates.mutedAudio = false;
    }
  }
}
function noVideoFromHost(type, updateHostState = true) {
  const { mingly } = window;
  const { board } = mingly;
  const { me } = board;
  if (type === 'off') {
    me.noVideo = true;
    if (board.room.mediaserver) {
      const producers = me.producerMS;
      let tag = null;
      if (me.screenShare) {
        tag = 'screen';
      } else {
        tag = 'video';
      }
      const producer = producers.find((p) => (p.appData.mediaTag === tag));
      pauseProducer(producer, true);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (me.screenShare) {
        window.mingly.streamScreen.getVideoTracks()[0].enabled = false;
        me.videoElement.srcObject = window.mingly.streamScreen;
      }
      else {
        window.mingly.streamLowRes.getVideoTracks()[0].enabled = false;
        me.videoElement.srcObject = window.mingly.streamLowRes;
      }
    }
    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.NoVideo }, me.asJSON(), { 'dest': 'all' })));
    if (updateHostState) {
      mingly.hostStates.turnedOffVideo = true;
    }
    board.updateAll();
  }
  else {
    if (!me.isSleeping) {
      me.noVideo = false;
      if (board.room.mediaserver) {
        const producers = me.producerMS;
        let tag = null;
        if (me.screenShare) {
          tag = 'screen';
        } else {
          tag = 'video';
        }
        const producer = producers.find((p) => (p.appData.mediaTag === tag));
        pauseProducer(producer, false);
      } else {
        // eslint-disable-next-line no-lonely-if
        if (me.screenShare) {
          window.mingly.streamScreen.getVideoTracks()[0].enabled = true;
          me.videoElement.srcObject = window.mingly.streamScreen;
        }
        else {
          window.mingly.streamLowRes.getVideoTracks()[0].enabled = true;
          me.videoElement.srcObject = window.mingly.streamLowRes;
        }
      }
      me.resumeVideo();
      serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.NoVideo }, me.asJSON(), { 'dest': 'all' })));
      board.updateAll();
    } 
    if (updateHostState) {
      mingly.hostStates.turnedOffVideo = false;
    }
  } 
}
// Not working at the moment
function muteAudio(firstTime = false) {
  const { mingly } = window;
  const { board } = mingly;
  const { me } = board;
  // just diable the possibility to mute or unmute if host is muting you
  if (mingly.hostStates.mutedAudio) return;
  // if (board.isExternal && !me.isSleeping) {
  // need to check if screen sharing if enabled=false method
  if (!me.muted || firstTime) {
    me.muted = true;
    // sending to Vue
    // when screen sharing the video track is the only thing replaced
    // (not sharing audio from screen)
    if (board.room.mediaserver) {
      const producers = me.producerMS;
      console.log(producers);
      // console.log(producers.find((p) => (p.appData.mediaTag === 'audio')));
      const producer = producers.find((p) => (p.appData.mediaTag === 'audio'));
      // console.log(producer);
      // console.log('just after');
      const pause = true;
      pauseProducer(producer, pause);  
    } else {
      window.mingly.streamLowRes.getAudioTracks()[0].enabled = false;
      window.mingly.streamBlack.getAudioTracks()[0].enabled = false;
    }
  }
  else {
    // if (!me.isSleeping) {
    me.muted = false;
    // when screen sharing the video track is the only thing replaced
    // (not sharing audio from screen)
    if (board.room.mediaserver) {
      const producers = me.producerMS;
      const producer = producers.find((p) => (p.appData.mediaTag === 'audio'));
      const pause = false;
      pauseProducer(producer, pause);  
    } else {
      window.mingly.streamLowRes.getAudioTracks()[0].enabled = true;
      window.mingly.streamBlack.getAudioTracks()[0].enabled = true;
    }
    // }
    window.mingly.log('is sleeping test');
  }
  if (board.isExternal) {
    try {
      mingly.onMutedMe();
    } 
    catch {
      mingly.log('Did not turn off the video');
    }
  } 
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.Muted }, me.asJSON(), { 'dest': 'all' })));
}

function noVideo() {
  const { mingly } = window;
  const { board } = mingly;
  const { me } = board;
  // sending to Vue
  // if (board.isExternal && !me.isSleeping) {
  if (board.isExternal) {
    try {
      mingly.onNoVideo();
    } 
    catch {
      mingly.log('Did not turn off the video');
    }
  } 

  // need to check if sharing screen if not swapping but use enabled=false
  if (!me.noVideo) {
    me.noVideo = true;
    if (board.room.mediaserver) {
      const producers = me.producerMS;
      let tag = null;
      if (me.screenShare) {
        tag = 'screen';
      } else {
        tag = 'video';
      }
      const producer = producers.find((p) => (p.appData.mediaTag === tag));
      pauseProducer(producer, true);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (me.screenShare) {
        window.mingly.streamScreen.getVideoTracks()[0].enabled = false;
        me.videoElement.srcObject = window.mingly.streamScreen;
      }
      else {
        window.mingly.streamLowRes.getVideoTracks()[0].enabled = false;
        me.videoElement.srcObject = window.mingly.streamLowRes;
      }
    }
    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.NoVideo }, me.asJSON(), { 'dest': 'all' })));
    board.updateAll();
  }
  else {
    // if (!me.isSleeping) {
    me.noVideo = false;
    if (board.room.mediaserver) {
      const producers = me.producerMS;
      let tag = null;
      if (me.screenShare) {
        tag = 'screen';
      } else {
        tag = 'video';
      }
      const producer = producers.find((p) => (p.appData.mediaTag === tag));
      pauseProducer(producer, false);
    } else {
      // eslint-disable-next-line no-lonely-if
      if (me.screenShare) {
        window.mingly.streamScreen.getVideoTracks()[0].enabled = true;
        me.videoElement.srcObject = window.mingly.streamScreen;
      }
      else {
        window.mingly.streamLowRes.getVideoTracks()[0].enabled = true;
        me.videoElement.srcObject = window.mingly.streamLowRes;
      }
    }
    me.resumeVideo();
    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.NoVideo }, me.asJSON(), { 'dest': 'all' })));
    board.updateAll();
    // } 
    window.mingly.log('is sleeping test');
  }
}

function shareScreen() {
  const { mingly } = window;
  if (!me.screenShare) {
    me.screenShare = true;
    if (window.mingly.onScreenShare) {
      try {
        window.mingly.onScreenShare(me.screenShare);
      }
      catch {
        window.mingly.log('did not do screen share trigger');
      }
    }
    const ShareOptions = {
      video: true,
      audio: false,
    };

    navigator.mediaDevices.getDisplayMedia(ShareOptions)
      .then((stream) => {
        stream.getVideoTracks()[0].addEventListener('ended', () => {
          me.screenShare = false;
          if (window.mingly.onScreenShare) {
            try {
              window.mingly.onScreenShare(me.screenShare);
            }
            catch {
              window.mingly.log('did not do screen share trigger');
            }
          }
          me.videoElement.srcObject = window.mingly.streamLowRes;
          me.resumeVideo();
          window.mingly.log('shareScreen: switching back to video');
          serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.ShareScreen }, me.asJSON(), { 'dest': 'all' })));
          if (window.mingly.board.room.mediaserver) {
            navigator.mediaDevices.getUserMedia(window.mingly.constraints)
              .then((stream) => {
                window.mingly.streamLowRes = stream;
                me.videoElement.srcObject = stream;
                me.videoElement.onplaying = function () {
                  me.isPlayingVideo = true;
                };
                me.videoElement.onpause = function () {
                  me.isPlayingVideo = false;
                };
                const producers = window.mingly.board.me.producerMS;
                const producer = producers.find((p) => (p.appData.mediaTag === 'video'));
                // A possible way of ``replace track'' is to use clone instead
                // this should maybe not interfer with the original track
                producer.replaceTrack({ track: window.mingly.streamLowRes.getVideoTracks()[0] });
                // window.mingly.streamScreen.getVideoTracks()[0].stop(); // might not need this
                me.videoElement.srcObject = window.mingly.streamLowRes;
                window.mingly.log('shareScreen: switching back to video');
                serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.ShareScreen }, me.asJSON(), { 'dest': 'all' })));
                me.resumeVideo();
              }).catch(errorHandler);  
            // const producers = window.mingly.board.me.producerMS;
            // const producer = producers.find((p) => (p.appData.mediaTag === 'video'));
            // producer.replaceTrack({ track: window.mingly.streamLowRes.getVideoTracks()[0] });
    
          } else {
            Object.values(participants).forEach((participant) => {
              if (participant.videovar === 1) {
                mingly.rtc.swapStream(participant.uuid, window.mingly.streamLowRes, 'video');
              }
              else {
                mingly.rtc.swapStream(participant.uuid, window.mingly.streamBlack, 'video');
              }
            });   
          }       
        });
        window.mingly.streamScreen = stream;
        me.videoElement.srcObject = stream;
        me.resumeVideo();
        serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.ShareScreen }, me.asJSON(), { 'dest': 'all' })));
        if (window.mingly.board.room.mediaserver) {
          // therefore we have to call gertUserMedia again
          const producers = window.mingly.board.me.producerMS;
          const producer = producers.find((p) => (p.appData.mediaTag === 'video'));
          producer.replaceTrack({ track: window.mingly.streamScreen.getVideoTracks()[0] });
  
        } else {
          Object.values(participants).forEach((participant) => {
            mingly.rtc.swapStream(participant.uuid, window.mingly.streamScreen, 'video');
          });
        }
      })
      .catch(function (e) {
        me.screenShare = false;
        if (window.mingly.onScreenShare) {
          try {
            window.mingly.onScreenShare(me.screenShare);
          }
          catch {
            window.mingly.log('did not do screen share trigger');
          }
        }
        me.videoElement.srcObject = window.mingly.streamLowRes;
        me.resumeVideo();   
        window.mingly.error(e.message);
      });
  }
  else {
    me.screenShare = false;
    if (window.mingly.onScreenShare) {
      try {
        window.mingly.onScreenShare(me.screenShare);
      }
      catch {
        window.mingly.log('did not do screen share trigger');
      }
    }
    if (window.mingly.board.room.mediaserver) {
      // seems like the replacetrack methods stops the previous stream 
      // therefore we have to call gertUserMedia again
      navigator.mediaDevices.getUserMedia(window.mingly.constraints)
        .then((stream) => {
          window.mingly.streamLowRes = stream;
          // window.window.mingly.streamLowRes = window.mingly.streamLowRes; // should remove this
          me.videoElement.srcObject = stream;
          me.videoElement.onplaying = function () {
            me.isPlayingVideo = true;
          };

          me.videoElement.onpause = function () {
            me.isPlayingVideo = false;
          };
          const producers = window.mingly.board.me.producerMS;
          const producer = producers.find((p) => (p.appData.mediaTag === 'video'));
          // A possible way of ``replace track'' is to use clone instead
          // this should maybe not interfer with the original track
          producer.replaceTrack({ track: window.mingly.streamLowRes.getVideoTracks()[0] });
          // window.mingly.streamScreen.getVideoTracks()[0].stop(); // might not need this
          me.videoElement.srcObject = window.mingly.streamLowRes;
          window.mingly.log('shareScreen: switching back to video');
          serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.ShareScreen }, me.asJSON(), { 'dest': 'all' })));
          me.resumeVideo();
        }).catch(errorHandler);
    } else {
      window.mingly.streamScreen.getVideoTracks()[0].stop();
      me.videoElement.srcObject = window.mingly.streamLowRes;
      me.resumeVideo();
      window.mingly.log('shareScreen: switching back to video');
      serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.ShareScreen }, me.asJSON(), { 'dest': 'all' })));
      Object.values(participants).forEach((participant) => {
        if (participant.videovar === 1) {
          mingly.rtc.swapStream(participant.uuid, window.mingly.streamLowRes, 'video');
        }
        else {
          mingly.rtc.swapStream(participant.uuid, window.mingly.streamBlack, 'video');
        }
      });
    }
  }
}

function changeCamera(videoSource) {
  const { mingly } = window;
  const { constraints } = mingly;

  if (typeof constraints.video === 'boolean') {
    constraints.video = { deviceId: { ideal: videoSource } };
  }
  else {
    constraints.video.deviceId = videoSource;
  }
  // calling getUserMedia here is BAD!
  // should use track.applyConstraints(constraints);
  navigator.mediaDevices.getUserMedia(constraints)
    .then((stream) => {
      window.mingly.streamLowRes = stream;
      me.videoElement.srcObject = stream;
      me.videoElement.onplaying = function () {
        me.isPlayingVideo = true;
      };

      me.videoElement.onpause = function () {
        me.isPlayingVideo = false;
      };

      me.resumeVideo();
      if (mingly.onChangeVideo) {
        try {
          mingly.onChangeVideo();
        }
        catch {
          window.mingly.log('did not trigger change video');
        }
      }

      window.mingly.streamBlack = stream.clone();
      window.mingly.streamBlack.getVideoTracks()[0].enabled = false;
      window.mingly.streamBlackSilence = stream.clone();
      window.mingly.streamBlackSilence.getVideoTracks()[0].enabled = false;
      window.mingly.streamBlackSilence.getAudioTracks()[0].enabled = false;

      // turn of screenshare
      if (me.screenShare) {
        me.screenShare = false;
        serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.ShareScreen }, me.asJSON(), { 'dest': 'all' })));
      }

      // switch streams.
      if (window.mingly.board.room.mediaserver) {
        const producers = window.mingly.board.me.producerMS;
        const producer = producers.find((p) => (p.appData.mediaTag === 'video'));
        producer.replaceTrack({ track: window.mingly.streamLowRes.getVideoTracks()[0] });

      } else {
        Object.values(participants).forEach((participant) => {
          if (participant.videovar) {
            mingly.rtc.swapStream(participant.uuid, window.mingly.streamLowRes);
          }
          else {
            // eslint-disable-next-line no-lonely-if
            if (participant.audiovar) {
              mingly.rtc.swapStream(participant.uuid, window.mingly.streamBlack);
            }
            else {
              mingly.rtc.swapStream(participant.uuid, window.mingly.streamBlackSilence);
            }
          }
        });
      }
    })
    .catch(errorHandler);
}

function changeMicrophone(audioSource) {
  const { mingly } = window;
  const { constraints } = mingly;

  // calling getUserMedia here is BAD! should use track.applyConstraints(constraints);
  if (typeof constraints.audio === 'boolean') {
    window.mingly.log('Audio bolean');
    constraints.audio = { deviceId: { ideal: audioSource } };
  }
  else {
    constraints.audio.deviceId = audioSource;
  }
  navigator.mediaDevices.getUserMedia(constraints)
    .then((stream) => {
      window.mingly.streamLowRes = stream;
      me.videoElement.srcObject = stream;
      me.videoElement.onplaying = function () {
        me.isPlayingVideo = true;
      };

      me.videoElement.onpause = function () {
        me.isPlayingVideo = false;
      };

      me.resumeVideo();
      // strictly speaking we change video so use the same event listener here
      if (mingly.onChangeVideo) {
        try {
          mingly.onChangeVideo();
        }
        catch {
          mingly.log('did not trigger change video');
        }
      }
      window.mingly.streamBlack = stream.clone();
      window.mingly.streamBlack.getVideoTracks()[0].enabled = false;
      window.mingly.window.mingly.streamBlackSilence = stream.clone();
      window.mingly.window.mingly.streamBlackSilence.getVideoTracks()[0].enabled = false;
      window.mingly.window.mingly.streamBlackSilence.getAudioTracks()[0].enabled = false;

      // turn of screenshare
      if (me.screenShare) {
        me.screenShare = false;
        serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.ShareScreen }, me.asJSON(), { 'dest': 'all' })));
      }

      // switch streams.
      if (window.mingly.board.room.mediaserver) {
        const producers = window.mingly.board.me.producerMS;
        const producer = producers.find((p) => (p.appData.mediaTag === 'audio'));
        producer.replaceTrack({ track: window.mingly.streamLowRes.getAudioTracks()[0] });

      } else {
        Object.values(participants).forEach((participant) => {
          if (participant.videovar) {
            mingly.rtc.swapStream(participant.uuid, window.mingly.streamLowRes);
          }
          else {
            // eslint-disable-next-line no-lonely-if
            if (participant.audiovar) {
              mingly.rtc.swapStream(participant.uuid, window.mingly.streamBlack);
            }
            else {
              mingly.rtc.swapStream(participant.uuid, window.mingly.streamBlackSilence);
            }
          }
        });
      }
    })
    .catch(errorHandler);
}

function distanceCutOffCalculator(nMax) {
  const  { mingly } = window;
  const { board } = mingly;
  const { participants } = board;
  const N = Object.keys(participants).length;
  const distanceVec = [];
  Object.values(participants).forEach((participant) => {
    distanceVec.push(participant.distanceTo(me));
  });
  const sortDist = distanceVec.sort(function (a, b) { return a - b; });
  // here we might move this outside the for loop!
  let cutoffnow;
  
  window.mingly.log('updateConnections: This is where the distance ranking starts to matter');
  cutoffnow = sortDist[nMax - 1]; // check this again!
  // check if next in the line has same distance, if so need to drop
  if (nMax < (N + 1) && cutoffnow === sortDist[nMax]) {
    // todo: a more sane picture
    window.mingly.log('updateConnetions: inne her as it is a draw');
    let testdiff = 0;
    let index = room.maxNumberVideos - 1;
    while (testdiff === 0) {
      testdiff = sortDist[index] - sortDist[index - 1];
      window.mingly.log('updateConnections: testdiff = ', testdiff);
      index -= 1;
      cutoffnow = sortDist[index];
    }
  }
  return cutoffnow;
}

function visionCutOffCalculator(participant) {
  const  { mingly } = window;
  const { board } = mingly;
  const { participants } = board;
  let outOfView = true;
  const posPerson = {
    x1: participant.x - board.avatarRadius,
    x2: participant.x + board.avatarRadius,
    y1: participant.y - board.avatarRadius,
    y2: participant.y + board.avatarRadius,
  };
  const posObject = {
    x1: board.ctx.transformedPoint(0, 0).x,
    x2: board.ctx.transformedPoint(window.innerWidth, 0).x,
    y1: board.ctx.transformedPoint(0, 0).y,
    y2: board.ctx.transformedPoint(0, window.innerHeight).y,
  };
  const test = ((posObject.x1 < posPerson.x1 &&
    posPerson.x1 < posObject.x2) ||
  (posObject.x1 < posPerson.x2 &&
  posPerson.x2 < posObject.x2)) &&
  ((posObject.y1 < posPerson.y1 &&
  posPerson.y1 < posObject.y2) ||
    (posObject.y1 < posPerson.y2 &&
    posPerson.y2 < posObject.y2));
  if (test) {
    outOfView = false;
  }
  return outOfView;
}

function decideMedia() {
  // function that sets the videovar to the correct value
  // It is based on distance and various other cutoffs
  // videovar = 0 means audio only
  // videovar = 1 means video
  // videovar = 2 means no video or audio
  const  { mingly } = window;
  const  { rtc } = mingly;
  const { board } = mingly;
  const { room } = board;
  const { participants } = board;
  const N = Object.keys(participants).length;
  const maxDistVideoBasedOnN = distanceCutOffCalculator(room.maxNumberVideos);
  const maxDistAudioBasedOnN = distanceCutOffCalculator(room.maxNumberAudios);
  const maxConnectionsBasedOnN = distanceCutOffCalculator(room.maxConnections);
  // check zoom condition
  const noZoomVideo = (board.ctx.getTransform(board.width, board.height).a 
    < board.ZoomOutVideoCutoff);
  Object.values(participants).forEach((participant) => {
    //  check if participant is not ready as the 
    // server is not ready
    if (participant.server.readyState === 0) {
      console.log('Participant is not ready');
      participant.videovar = 3;
      return;
    }
    // check if I am not ready 
    const name = `${participant.server.url}_${room.name}_${participant.server.type}`;
    if (rtc.wssServers[name] === undefined) {
      // connect to server
      console.log('Reconnecting to server');
      participant.videovar = 3;
      rtc.connectToServer(name);
    }
    if ((rtc.wssServers[name].send === true && 
      rtc.wssServers[name].nTransportsReady < 2) ||
      (rtc.wssServers[name].send === false && 
        rtc.wssServers[name].nTransportsReady < 1)) {
      // the transports are not ready
      console.log('Tansports Not Ready');
      participant.videovar = 3;
      return;
    }
    const d = participant.distanceTo(me);
    const outOfView = visionCutOffCalculator(participant);
    const noConnections  = (d > maxConnectionsBasedOnN);
    const noAudio = ((d > room.upperAudioCutoff || d > maxDistAudioBasedOnN) && !noConnections); 
    const noVideo = ((d > room.upperVideoCutoff || d > maxDistVideoBasedOnN || noZoomVideo
                      || outOfView) &&
                    !(noAudio || noConnections));
    mingly.log('Updating connection:');
    mingly.log(noConnections);
    mingly.log(noAudio);
    mingly.log(noVideo);
    // const noConnections  = (d > maxConnectionsBasedOnN);
    // do a nested one here
    if (N < room.moveLimitVideo) {
      participant.videovar = 1;
    } else if (participant.localBroadCast) {
      participant.videovar = 1;
    } else {
      // eslint-disable-next-line no-lonely-if
      if (noConnections) {
        participant.videovar = 3;
      } else {
        // eslint-disable-next-line no-lonely-if
        if (noAudio) {
          participant.videovar = 2;
        } else if (noVideo) {
          participant.videovar = 0;
        } else {
          participant.videovar = 1;
        }
      }
    }
    // if (N < room.moveLimitVideo) {
    //   participant.videovar = 1;
    // } else if (noConnections) {
    //   participant.videovar = 3;
    // } else if (noAudio) {
    //   participant.videovar = 2;
    // } else if (noVideo) {
    //   participant.videovar = 0;
    // } else {
    //   participant.videovar = 1;
    // }
  });
}

// a function that setsup everything for a given participants based on video var values
function setupMediaRemoteConnections() {
  const  { mingly } = window;
  const { board } = mingly;
  const { room } = board;
  const { participants } = board;
  console.log('Updateing Medias');
  Object.values(participants).forEach((participant) => {
    console.log('Updateing participants');
    // // new broadcast version (local)
    // moved to decide media
    // if (participant.localBroadCast) {
    //   participant.videovar = 1;
    // }
    // communicate to vue (might replace this with something that is not always updated)
    if (window.mingly.onVideovar) {
      try {
        window.mingly.onVideovar(participant);
      }
      catch {
        window.mingly.log('updateConnections: error when calling videovar event listener');
      }
    }
    console.log('the videovar');
    console.log(participant.videovar);
    switch (participant.videovar) {
      case 0:
        // remove video element if there
        if (document.getElementById(`remoteVideo_${participant.uuid}`)) {
          document.getElementById('videos').removeChild(document.getElementById(`remoteVideo_${participant.uuid}`));
          if (!board.room.mediaserver) {
            mingly.rtc.swapStream(participant.uuid, window.mingly.streamBlack);
            window.mingly.log('updateConnections: should be audio now');
          }
        }
        // do updatess
        if (board.room.mediaserver) {
          pickTracksPeer(participant);
        } else {
          serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.EditStream }, me.asJSON(), { 'streamV': 1, 'dest': participant.uuid })));
        }
        // create audio container
        // createAudioContainer(participant);
        // check if this is correct
        participant.drawAvatar();
        participant.adjustVolume(me);
        break;
      case 1:
        // should we remove videoElement if there?
        // if (!document.getElementById(`remoteVideo_${participant.uuid}`)) {
        //   createVideoContainer(participant);
        // }
        if (board.room.mediaserver) {
          pickTracksPeer(participant);
        } else {
          serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.EditStream }, me.asJSON(), { 'streamV': 1, 'dest': participant.uuid })));
        }
        // check if this is correct
        if (document.getElementById(`remoteAudio_${participant.uuid}`)) {
          document.getElementById('audios').removeChild(document.getElementById(`remoteAudio_${participant.uuid}`));
        }
        // participant.resumeVideo();
        participant.drawAvatar();
        // if (!document.getElementById(`remoteVideo_${participant.uuid}`)) {
        //   createVideoContainer(participant);
        participant.resumeVideo();
        //   participant.drawAvatar();
        // }
        participant.adjustVolume(me);
        break;
      case 2:
        // check if this is fine
        if (document.getElementById(`remoteVideo_${participant.uuid}`)) {
          document.getElementById('videos').removeChild(document.getElementById(`remoteVideo_${participant.uuid}`));
        }
        if (document.getElementById(`remoteaAudio_${participant.uuid}`)) {
          document.getElementById('audios').removeChild(document.getElementById(`remoteAudio_${participant.uuid}`));
        }

        if (board.room.mediaserver) {
          pickTracksPeer(participant);
        }
        // not sure killPC is right here. Should do paused instead
        if (participant && participant.peerConnection) {
          killPC(participant.uuid, true);
        }
        // createAudioContainer(participant);
        participant.drawAvatar();
        // should not need to adjust volume
        break;
      case 3:
        // check if this is fine
        if (document.getElementById(`remoteVideo_${participant.uuid}`)) {
          document.getElementById('videos').removeChild(document.getElementById(`remoteVideo_${participant.uuid}`));
        }
        if (document.getElementById(`remoteaAudio_${participant.uuid}`)) {
          document.getElementById('audios').removeChild(document.getElementById(`remoteAudio_${participant.uuid}`));
        }
        if (board.room.mediaserver) {
          pickTracksPeer(participant);
        }
        // not sure killPC is right here. Should do paused instead
        if (participant && participant.peerConnection) {
          killPC(participant.uuid, true);
        }
        participant.drawAvatar();
        break;
      default:
        mingly.log('invalid videovar value');
        break;
    }
  });
}
function updateConnections() {
  const  { mingly } = window;
  const { board } = mingly;
  const { audioOutSource } = window.mingly; 
  const { allowSpeakerSelect } = window.mingly;
  // we have the old code here for a while
  const old = false;
  if (!old) {
    mingly.log('updateConnections called NEW!!!');
    decideMedia();
    setupMediaRemoteConnections(); 
    return; 
  }
  // this is the old stuff
  mingly.log('updateConnections called OLD!!!');
  const N = Object.keys(participants).length;
  const distanceVec = []; // probably inefficient
  if (N < room.moveLimitVideo) { // always stay connected if N+1<5 (Should be feasible)
    Object.values(participants).forEach((participant) => {
      if (!document.getElementById(`remoteVideo_${participant.uuid}`)) {
        participant.videovar = 1;
        if (window.mingly.onVideovar) {
          try {
            window.mingly.onVideovar(participant);
          }
          catch {
            window.mingly.log('updateConnections: error when calling videovar event listener');
          }
        }
        createVideoContainer(participant);

        // ask for video if not already there
        // Note sure we need this since moveit is triggering updateconnections on the other side
        if (board.room.mediaserver) {
          pickTracksPeer(participant);
        } else {
          serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.EditStream }, me.asJSON(), { 'streamV': 1, 'dest': participant.uuid })));
        }
      }
      // Adjust volume
      participant.adjustVolume(me);
    });

    window.mingly.log('updateConnections: processed N < moveLimitVideo');
    // here we can maybe consider doing a audio only if over a certain distance

  } // end of if part (total number less than cutoff)
  else {
    Object.values(participants).forEach((participant) => {
      distanceVec.push(participant.distanceTo(me));
    });

    const sortDist = distanceVec.sort(function (a, b) { return a - b; });

    Object.values(participants).forEach((participant) => {
      const distance = participant.distanceTo(me);

      if (distance > room.upperAudioCutoff) {
        // this is where we agree that there should be no audio
        // latch on no audio no video and send message of the distancec
        // kill audio and video tags if any
        participant.videovar = 2;
        if (board.room.mediaserver) {
          // new broadcast version (local)
          if (participant.localBroadCast) {
            participant.videovar = 1;
          }
          pickTracksPeer(participant);
        }
        if (participant && participant.peerConnection) {
          if (window.mingly.onVideovar) {
            try {
              window.mingly.onVideovar(participant);
            }
            catch {
              window.mingly.log('updateConnections: error when calling videovar event listener');
            }
          }
          killPC(participant.uuid, true);
        }
      }
      else {
        // Check if too many
        // eslint-disable-next-line no-lonely-if
        if (!participant.peerConnection && !board.room.mediaserver) {
          // only setup at this stage if no connection. Might change this later
          mingly.rtc.setupPeerConnection(participant.uuid, true); // make the call as I discover it
          setTimeout(function () {
            window.mingly.log('updateConnections.spwan: will update again in between x and y sec');
            updateConnections();
          }, 2000 + Math.round(Math.random() * 2000)); // update soon again
        }
        else {
          // if(!peerConnections[userIds[i]]){
          //   setUpPeer(userIds[i],participant.x, participant.y,participant.name, true,true);
          // }
          if (distance > room.upperVideoCutoff) {

            // Participant is too far away - do the audio only

            // Get rid of video stream
            if (document.getElementById(`remoteVideo_${participant.uuid}`)) {
              document.getElementById('videos').removeChild(document.getElementById(`remoteVideo_${participant.uuid}`));
              if (!board.room.mediaserver) {
                mingly.rtc.swapStream(participant.uuid, window.mingly.streamBlack);
                window.mingly.log('updateConnections: should be audio now');
              }
              // audio only
              participant.videovar = 0;
              if (window.mingly.onVideovar) {
                try {
                  window.mingly.onVideovar(participant);
                }
                catch {
                  window.mingly.log('updateConnections: error when calling videovar event listener');
                }
              }

              // why only for Canvasboard? We change videovar for all boards
              if (board.type === 'Canvasboard') {
                // clear and draw since the videovar has changed from 1 to zero
                // participant.clearAvatar(); //- clear is implicit in draw
                participant.drawAvatar();
              }
              if (board.room.mediaserver) {
                window.mingly.log('Calling pickTracksPeer');
                if (participant.localBroadCast) {
                  participant.videovar = 1;
                }
                pickTracksPeer(participant);
              } else {
                // update all board types with new stream config
                window.mingly.log('Sending EditStream Message');
                serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.EditStream }, me.asJSON(), { 'streamV': participant.videovar, 'dest': participant.uuid })));
              }
            }

            // Make sure we get audio
            if (!document.getElementById(`remoteAudio_${participant.uuid}`)) {
              const audElement = document.createElement('audio');
              audElement.setAttribute('autoplay', '');
              try {
                if (allowSpeakerSelect && audioOutSource &&
                  window.adapter.browserDetails.browser === 'chrome') {
                  audElement.setSinkId(audioOutSource);
                }
              }
              catch {
                window.mingly.log('cannot set sinkId');
              }
            
              audElement.srcObject = participant.peerStreams; // need to get audiotracks

              const audContainer = document.createElement('div');
              audContainer.setAttribute('id', `remoteAudio_${participant.uuid}`);
              audContainer.setAttribute('class', 'audioContainer');
              audContainer.appendChild(audElement);

              document.getElementById('audios').appendChild(audContainer);
              // audElement.srcObject.volume = 0;
              // audContainer.childNodes[0].volume = 0;

            }

          }
          else {
            // this is where it depends on N closest

            // here we might move this outside the for loop!
            let cutoffnow;
            if (N < room.maxNumberVideos) {
              cutoffnow = room.upperVideoCutoff + 1;
            }
            else {
              window.mingly.log('updateConnections: This is where the distance ranking starts to matter');
              cutoffnow = sortDist[room.maxNumberVideos - 1]; // check this again!

              // check if next in the line has same distance, if so need to drop
              if (cutoffnow === sortDist[room.maxNumberVideos]) {
                // todo: a more sane picture
                window.mingly.log('updateConnetions: inne her as it is a draw');
                let testdiff = 0;
                let index = room.maxNumberVideos - 1;
                while (testdiff === 0) {
                  testdiff = sortDist[index] - sortDist[index - 1];
                  window.mingly.log('updateConnections: testdiff = ', testdiff);
                  index -= 1;
                  cutoffnow = sortDist[index];
                }
              }
            }

            if (distance <= cutoffnow) {
              window.mingly.log('updateConnections: should be video now');

              // this is not obvious if it should change stream here.
              // There could be non-overlapping videos
              if (participant.videovar === 0) {
                participant.videovar = 1;
                if (window.mingly.onVideovar) {
                  try {
                    window.mingly.onVideovar(participant);
                  }
                  catch {
                    window.mingly.log('updateConnections: error when calling videovar event listener');
                  }
                }
                if (board.room.mediaserver) {
                  if (participant.localBroadCast) {
                    participant.videovar = 1;
                  }
                  pickTracksPeer(participant);
                } else {
                  if (me.screenShare) {
                    mingly.rtc.swapStream(participant.uuid, window.mingly.streamScreen, 'video');
                  }
                  else {
                    mingly.rtc.swapStream(participant.uuid, window.mingly.streamLowRes);
                  }
                  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.EditStream }, me.asJSON(), { 'streamV': participant.videovar, 'dest': participant.uuid })));
                }
              }

              if (document.getElementById(`remoteAudio_${participant.uuid}`)) {
                document.getElementById('audios').removeChild(document.getElementById(`remoteAudio_${participant.uuid}`));
                // if (participant.audioCtx) {
                //   // todo: participant should take care of its own propeties!
                //   delete participant.audioCtx;
                //   delete participant.source;
                //   delete participant.gainNode;
                // }
              }

              if (!document.getElementById(`remoteVideo_${participant.uuid}`)) {
                createVideoContainer(participant);
                participant.resumeVideo();
                participant.drawAvatar();
              }
            }
            else {

              if (participant.videovar === 1) {
                participant.videovar = 0;
                if (window.mingly.onVideovar) {
                  try {
                    window.mingly.onVideovar(participant);
                  }
                  catch {
                    window.mingly.log('updateConnections: error when calling videovar event listener');
                  }
                }
                if (board.room.mediaserver) {
                  if (participant.localBroadCast) {
                    participant.videovar = 1;
                  }
                  pickTracksPeer(participant);
                } else {
                  mingly.rtc.swapStream(participant.uuid, window.mingly.streamBlack);
                  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.EditStream }, me.asJSON(), { 'streamV': 0, 'dest': participant.uuid })));
                }
              }

              if (document.getElementById(`remoteVideo_${participant.uuid}`)) {
                document.getElementById('videos').removeChild(document.getElementById(`remoteVideo_${participant.uuid}`));
                // if (participant.audioCtx) {
                //   // todo: participant should care for its own properties!
                //   delete participant.audioCtx;
                //   delete participant.source;
                //   delete participant.gainNode;
                // }

                if (board.type === 'Canvasboard') {
                  // todo: why only for Canvasboard??
                  // clear and draw since the videovar has changed from 1 to zero
                  participant.drawAvatar();
                }
              }

              if (!document.getElementById(`remoteAudio_${participant.uuid}`)) {
                const audElement = document.createElement('audio');
                audElement.setAttribute('autoplay', '');
                try {
                  if (allowSpeakerSelect && audioOutSource &&
                    window.adapter.browserDetails.browser === 'chrome') {
                    audElement.setSinkId(audioOutSource);
                  }
                }
                catch {
                  window.mingly.log('cannot set sinkId');
                }
              
                audElement.srcObject = participant.peerStreams; // need to get audiotracks

                const audContainer = document.createElement('div');
                audContainer.setAttribute('id', `remoteAudio_${participant.uuid}`);
                audContainer.setAttribute('class', 'audioContainer');
                audContainer.appendChild(audElement);

                document.getElementById('audios').appendChild(audContainer);
                // Ask for audio only

              }

            }

          }

          participant.adjustVolume(me);
        }
      }
    }); // maybe the for loop
  } // end of else relative number of people
  // check if there is a local broadcaster 
  Object.values(participants).forEach((participant) => {
    if (participant.localBroadCast && board.room.mediaserver) {
      participant.videovar = 1;
      pickTracksPeer(participant);
      if (!document.getElementById(`remoteVideo_${participant.uuid}`)) {
        createVideoContainer(participant);
        participant.resumeVideo();
        participant.drawAvatar();
      }
    }
  });

} // end of function
function onKeyDown(event) {
  const board = window.mingly.board;
  if (board.hasWaited) {
    board.hasInteracted = true;
  }

  const x = event.keyCode;

  if (board.move_keyboard) {
    // we are not in the chat

    if (me.notSet()) {
      window.mingly.log('keyCode: you need to place you piece first');
      return;
    }
    // for mouse cursor
    // 67 = c
    if (x === 67) {
      board.me.activateCursor();
    }
    // // q = 81
    // if (x === 81) {
    //   const seconds = 1;
    //   restartSession(seconds);
    // }
    // the next two will change, but we keep it for now to test broadcast stuff
    // \
    // if (x === 220) {
    //   // const pic = new Image();
    //   // pic.src = 'https://www.dropbox.com/s/ffatfao23zt4t8g/Slide1.jpeg?raw=1';
    //   // pic.onload = function () {
    //   //  addPicture(pic, -100, -100, 100, 100);
    //   // };

    //   const { canvasObjects } = board;
    //   function myFunc(x, y, width, height, text, ctx) {
    //     ctx.fillStyle = 'black';
    //     ctx.fillRect(x, y, width, height);
    //     ctx.textAlign = 'center';
    //     ctx.fillStyle = 'white';
    //     ctx.fillText(text, x + 0.5 * width, y + 0.5 * height);
    //   }
    //   const objectType = 'canvasDrawing';
    //   const object = new CanvasObject( -100, 0, 100, 100, myFunc, board.canvas0, objectType);
    //   object.text = 'Lecture 1';
    //   object.uuid = createUUID();
    //   canvasObjects[object.uuid] = object;
    //   // might want to do some on load here or should maybe handle this outside
    //   board.updateAll();
    // }
    // // ] 
    // if (x === 221) {
    //   window.mingly.board.canvasObjects = {};
    //   window.mingly.board.updateAll();
    // }

    // // 5 = 54
    // if (x === 53) {
    //   ballBounce();
    // }
    // // 6 = 54
    // if (x === 54) {
    //   ballBounce(false);
    // }
    // P
    if (x === 80) {
      if (!board.me.isHost) return;
      serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.biVideoAnimation1 }, me.asJSON(), { 'source': 'introVideo', 'dest': 'all' })));
      const video = 'introVideo';
      startBIIntroVideo(video);
      window.mingly.hostStates.currentVideo = video;
    }
    // u 
    if (x === 85) {
      if (!board.me.isHost) return;
      serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.biVideoAnimation1 }, me.asJSON(), { 'source': 'Video2', 'dest': 'all' })));
      const video = 'Video2';
      startBIIntroVideo(video);
      window.mingly.hostStates.currentVideo = video;
    }
    // 0
    if (x === 48) {
      if (!board.me.isHost) return;
      serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.biVideoAnimation1 }, me.asJSON(), { 'source': 'Video3', 'dest': 'all' })));
      const video = 'Video3';
      startBIIntroVideo(video);
      window.mingly.hostStates.currentVideo = video;
    }
    if (x === 56) {
      //   ballBounce(false);
      // 
      window.mingly.board.me.greenScreen = !window.mingly.board.me.greenScreen;
    }
    // 71 = g
    if (x === 71) {
      GhostMe();
      window.mingly.board.resetZoom();
    }
    if (x === 53) {
      addPosition(true);
    }
    if (x === 54) {
      addPosition(false);
    }
    // 50 = 2
    if (x === 50) {
      if (board.room.toggleWorking) return;
      // this should go in a function
      if (board.room.toggleViews && board.room.toggleViews.length > 0) {
        if (board.room.toggleViewIndex >= board.room.toggleViews.length - 1) {
          // reset the index to 0 if it is greater than the length of the array
          board.room.toggleViewIndex = 0;
        }
        else {
          board.room.toggleViewIndex += 1;
        }
        const currentView = board.room.toggleViews[board.room.toggleViewIndex];
        if (currentView && currentView.me && typeof currentView.me.x === 'number' && typeof currentView.me.y === 'number') {
          window.mingly.board.me.moveTo(currentView.me.x, currentView.me.y);
        } else {
          console.log('no me');
          // Handle the c
          // case where 'me' does not exist or does not have the expected structure
        }
        // This code will only execute if board.room.toggleViews is not empty
        const mat1 = board.room.toggleViews[board.room.toggleViewIndex].mat; 
        const mat2 = board.room.toggleViews[board.room.toggleViewIndex].mat; 
        const dim = board.room.toggleViews[board.room.toggleViewIndex].dim; 
        if ('transition' in board.room.toggleViews[board.room.toggleViewIndex]) {
          const { transition } = board.room.toggleViews[board.room.toggleViewIndex];
          console.log(transition);
          transitionDynamics(transition);
        } else {
          window.mingly.board.setView(mat1, mat2, dim);
        }
      } else {
        // Handle the empty array case or do nothing
        console.log('board.room.toggleViews is empty');
      }
    }
    // 49 = 1
    if (x === 49) {
      if (board.room.toggleWorking) return;
      // this should go in a function
      if (board.room.toggleViews && board.room.toggleViews.length > 0) {
        // This code will only execute if board.room.toggleViews is not empty
        if (board.room.toggleViewIndex <= 0) {
          // reset the index to 0 if it is greater than the length of the array
          board.room.toggleViewIndex = board.room.toggleViews.length - 1;
        }
        else {
          board.room.toggleViewIndex -= 1;
        }
        if ('me' in board.room.toggleViews[board.room.toggleViewIndex]) {
          window.mingly.board.me.moveTo(board.room.toggleViews[board.room.toggleViewIndex].me.x,
            board.room.toggleViews[board.room.toggleViewIndex].me.y);
        }
        const mat1 = board.room.toggleViews[board.room.toggleViewIndex].mat; 
        const mat2 = board.room.toggleViews[board.room.toggleViewIndex].mat; 
        const dim = board.room.toggleViews[board.room.toggleViewIndex].dim; 
        if ('transition' in board.room.toggleViews[board.room.toggleViewIndex]) {
          const { transition } = board.room.toggleViews[board.room.toggleViewIndex];
          transitionDynamics(transition);
        } else {
          window.mingly.board.setView(mat1, mat2, dim);
        }
      } else {
        // Handle the empty array case or do nothing
        console.log('board.room.toggleViews is empty');
      }
    }

    // these are disable in external mode:
    if (!window.mingly.board.isExternal) {
      // 192 = `
      if (x === 192) {
        // closeLogicServer(true);
        window.mingly.board.zoom(-0.1, true);
      }
      // 191 = /
      if (x === 191) {
        localBroadCast();
      }
      // 49 = 1
      // if (x === 49) {
      // GhostMe();
      //  window.mingly.board.resetZoom();
      // }
      if (x  === 85) {
        if (window.mingly.msBroadCastOnCanvas[board.me.uuid] !== undefined) {
          closeMSbroadCast(board.me.uuid);
        } else {
        // const size = 200;
        // const x = 600;
        // const y = 150;           
        // broadCastScreenOnCanvas(size, x, y);
          // window.mingly.msBroadCastOnCanvasData.size = 200;
          broadCastScreenOnCanvas(false, 400);
        // board.placeingObject.placingFunction = placeBroadCastScreenOnCanvas;
        // board.placeingObject.isPlacing = true;
        }
      }
      // 222 = '
      if (x  === 222) {
        if (window.mingly.msBroadCastOnCanvas[board.me.uuid] !== undefined) {
          closeMSbroadCast(board.me.uuid);
        } else {
          // const size = 200;
          // const x = 600;
          // const y = 150;  
          window.mingly.msBroadCastOnCanvasData.size = 200;         
          broadCastScreenOnCanvas(true);
        }
      }  
      // O
      if (x === 79) {
        shareVideoAndAudio();
      }
      // [
      if (x === 219) {
        playCallingSound();
      }

      // F
      if (x === 70) {
        // todo: this should be a simple board.fullscreenMode = !board.fullscreenMode
        // change fullscreenMode to get/set and move the opacity logic there
        if (board.fullscreenMode) {
          board.fullscreenMode = false;
        }
        else {
          board.fullscreenMode = true;
        }
        return;
      }
      // 0
      if (x === 48) {
        getSilence();
      }
      // I
      if (x === 73) {
        shareScreen();
      }

      // P
      // if (x === 80) {
      //   noVideo();
      // }
      // M
      if (x === 77) {
        muteAudio();
        return;
      }
      // space bar
      if (x === 32) {
        if (board.type === 'Canvasboard') {
          board.showMenu();
        }
        return;
      }
      // +
      if (x === 187) {
        if (board.type === 'Canvasboard') {
          board.zoom(board.keyZoomFactor, true);
        }
        return;
      }

      // -
      if (x === 189) {
        if (board.type === 'Canvasboard') {
          board.zoom(-board.keyZoomFactor, true);
        }
        return;
      }
      // 71 = g
      if (x === 71) {
        navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        })
          .then((stream) => {
            window.mingly.msVideoStream = stream;
            sendTrackMediaServer(window.mingly.msVideoStream.getAudioTracks()[0], 'audio');
          });
      }
      // 72 = h
      if (x === 72) {
        navigator.mediaDevices.getUserMedia({
          video: true,
          audio: true,
        })
          .then((stream) => {
            window.mingly.msVideoStream = stream;
            sendTrackMediaServer(window.mingly.msVideoStream.getVideoTracks()[0], 'video');
          });
      }
      // 89 = y
      if (x === 89) {
        window.mingly.log('calling subscribe to track');
        subscribeToTrack(window.mingly.board.me.uuid, 'cam-video', true);
      }
      // End of testign mediaserver

      // has to be remove, only for testing as it kills or peers
      // if (x === 89) {
      //   Object.values(participants).forEach((participant) => {
      //     killPeer(participant.uuid, true);
      //   });     
      // }

      // F
      if (x === 70) {
        // todo: this should be a simple board.fullscreenMode = !board.fullscreenMode
        // change fullscreenMode to get/set and move the opacity logic there
        if (board.fullscreenMode) {
          board.fullscreenMode = false;
          window.mingly.board.canvas0.style.opacity = 1;
          window.mingly.board.canvas2.style.opacity = 1;
        }
        else {
          board.fullscreenMode = true;
          window.mingly.board.canvas0.style.opacity = 0.5;
          window.mingly.board.canvas2.style.opacity = 0.5;
        }
        return;
      }
    }

    // A or left arrow
    if (x === 65 || x === 37) {
      if (window.mingly.hostStates.lockedMove && !me.isHost) return;
      event.preventDefault();
      me.movingUsingKeyboardNow = true;
      if (!me.movingUsingKeyboardLeft) {
        me.movingUsingKeyboardLeft = true;
        me.moveLeft();
      }
      return;
    }

    // D or right arrow
    if (x === 68 || x === 39) {
      if (window.mingly.hostStates.lockedMove && !me.isHost) return;
      event.preventDefault();
      me.movingUsingKeyboardNow = true;
      if (!me.movingUsingKeyboardRight) {
        me.movingUsingKeyboardRight = true;
        me.moveRight();
      }
      return;
    }

    // S or down arrow
    if (x === 87 || x === 38) {
      if (window.mingly.hostStates.lockedMove && !me.isHost) return;
      event.preventDefault();
      me.movingUsingKeyboardNow = true;
      if (!me.movingUsingKeyboardDown) {
        me.movingUsingKeyboardDown = true;
        me.moveDown();
      }
      return;
    }

    // W or up arrow
    if (x === 83 || x === 40) {
      if (window.mingly.hostStates.lockedMove && !me.isHost) return;
      event.preventDefault();
      me.movingUsingKeyboardNow = true;
      if (!me.movingUsingKeyboardUp) {
        me.movingUsingKeyboardUp = true;
        me.moveUp();
      }
      // return;
    }

  }
  else { // not move_keyboard
    // eslint-disable-next-line no-lonely-if
    if (!board.isExternal) {
      if (x === 13) { // pressed Enter
        processOutgoingChatMessage();
      }  
    }
  }
}

function onKeyUp(event) {
  const board = window.mingly.board;
  const x = event.keyCode;
  if (board.type === 'Canvasboard') {
    
    // 
    
    // A or left arrow
    if (x === 65 || x === 37) {
      me.movingUsingKeyboardNow = false; 
      me.movingUsingKeyboardLeft = false;
    }

    // D or right arrow
    if (x === 68 || x === 39) {
      me.movingUsingKeyboardNow = false; 
      me.movingUsingKeyboardRight = false;
    }

    // S or down arrow
    if (x === 87 || x === 38) {
      me.movingUsingKeyboardNow = false; 
      me.movingUsingKeyboardDown = false;
    }

    // W or up arrow
    if (x === 83 || x === 40) {
      me.movingUsingKeyboardNow = false; 
      me.movingUsingKeyboardUp = false;
    }
    me.speedX = 0;
    me.speedY = 0;
    // me.movingUsingKeyboardNow = false;
    // if (!me.isGhost) {
    //   board.drawAvatarBackground(me);
    // }
    board.drawVideo(me);
    me.videovar = 1;
    if (board.mouseTimer) {
      clearInterval(board.mouseTimer);
    }
    muteDistantParticipants(); // inefficient
    updateConnections(); // be careful with this one
    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.Move }, me.asJSON(), { 'moveit': 1, 'dest': 'all' })));
  }

  // F|f
  // eslint-disable-next-line no-restricted-globals
  if (event.keyCode === 70) {
    Object.values(participants).forEach((participant) => {
      if (participant.hoover) {
        participant.hoover = false;
        const elem = document.getElementById(`listusers_${participant.uuid}`);
        if (elem) {
          elem.style.backgroundColor = '';
        }
      }
    });

    board.update();
  }
} 

function createKeyboardEventListeners() {
  document.addEventListener('keydown', onKeyDown);
  document.addEventListener('keyup', onKeyUp);
}

function removeKeyboardEventListeners() {
  document.removeEventListener('keydown', onKeyDown);
  document.removeEventListener('keyup', onKeyUp);
}

function InitialBigBoard() {
  if (BigBoard === 1) {
    const BoardArea = document.getElementById('locationArea');
    BoardArea.setAttribute('id', 'locationAreaBig');

    const VideoArea = document.getElementById('videoContainerArea');
    VideoArea.setAttribute('id', 'videoContainerAreaSmall');

    const InstructionEl = document.getElementById('PlaceStart');
    InstructionEl.innerHTML = 'Place yourself on the Board';

    BigBoard = 0;
  }
  else {
    const BoardArea = document.getElementById('locationAreaBig');
    BoardArea.setAttribute('id', 'locationArea');

    const VideoArea = document.getElementById('videoContainerAreaSmall');
    VideoArea.setAttribute('id', 'videoContainerArea');

    if (document.getElementById('PlaceStart')) {
      BoardArea.removeChild(document.getElementById('PlaceStart'));
    }

    BigBoard = 1;
  }

}

const boardMoveFunction = function () {
  const board = window.mingly.board;

  window.mingly.log('boardMoveFunction: entering function');
  if ((!serverConnection) || (serverConnection.readyState !== WebSocket_OPEN)) {
    window.mingly.log('boardMoveFunction: connection is not open. Exiting.');
    return;
  }

  window.mingly.log(`boardMoveFunction: id is: ${this.id}`);

  // id is formatted as xNNyNN
  const [x, y] = this.id.replace('x', '').split('y');

  board.move_keyboard = true;
  if (board.isSpotAvailable(x, y)) {
    window.mingly.log('boardMoveFunction: wanted spot is available');
    let firsttime = 0;
    if (!me.hasValidPosition()) {
      me.moveTo(x, y);
      if (!me.uniformSound) {
        if (!me.isDJ) {
          window.mingly.log('boardMoveFunction: calling InitialBigBoard');
          InitialBigBoard();
        }
        firsttime = 1;
      }
    }
    else {
      me.moveTo(x, y);
    }

    if (firsttime === 0) {
      updateConnections();
    }

    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.Move }, me.asJSON(), { 'dest': 'all' })));

    if (firsttime === 1) {
      // just to check after 1 sec again with first connection
      setTimeout(updateConnections(), 1000);
    }
  }
};

function getUuidForXY(xy) {
  if (xy && participants) {
    // turn xNyN into x=N, y=N
    const [x, y] = xy.replace('x', '').split('y');

    for (const peerUuid in participants) {
      if (participants[peerUuid].isAt(x, y)) {
        return peerUuid;
      }
    }
  }

  return null;
}

// Assign event listeners to board tiles
const elements1 = document.getElementsByClassName('white');
for (let i = 0; i < elements1.length; i++) {
  elements1[i].addEventListener('click', boardMoveFunction, false);

  elements1[i].addEventListener('mouseover', function (event) {
    const peerUuid = getUuidForXY(this.id);
    if (peerUuid) {
      try {
        document.getElementById(`listusers_${peerUuid}`).style.backgroundColor = 'red';
      }
      catch {
        window.mingly.log(`mouseover.event: unable to get or manipulate element listusers_${peerUuid}`);
      }
    }
  });

  elements1[i].addEventListener('mouseout', function (event) {
    const peerUuid = getUuidForXY(this.id);
    if (peerUuid) {
      try {
        document.getElementById(`listusers_${peerUuid}`).style.backgroundColor = '';
      }
      catch {
        window.mingly.log(`mouseout.event: unable to get or manipulate element listusers_${peerUuid}`);
      }
    }
  });

}

// Assign event listener to the chat box
const myChatMessageBox = document.getElementById('myChatMessage');
if (myChatMessageBox) {
  myChatMessageBox.addEventListener('click', function (event) {
    window.mingly.board.move_keyboard = false;
  });
}
else
{
  console.error('unable to locate chat message box');
}

// Assign event listener to the chat send button
const sendButton = document.getElementById('buttonChatsSendText');
if (sendButton) {
  sendButton.addEventListener('click', function (event) {
    processOutgoingChatMessage();
  });
}
else {
  console.error('unable to locate chat send button');
}

const switchBackButton = document.getElementById('buttonSwitchBackground');
if (switchBackButton) {
  switchBackButton.addEventListener('click', function (event) {
    processSwitchBackGround();
  });
}
else {
  console.error('unable to locate SwitchBackGroun send button');
}

// Assign event listener to the host toolbox button
const buttonHost = document.getElementById('hostThings');
if (buttonHost) {
  buttonHost.addEventListener('click', function ()
  {
    const hostMenu = document.getElementById('hostBox');
    if (hostMenu) {
      hostMenu.hidden = (!hostMenu.hidden);
    }
  });
}
else {
  console.error('unable to locate host toolbox button');
}

function changeDJuniform() {
  // var movetoUniform=document.getElementById('buttonUniform');
  me.uniformSound = !me.uniformSound;

  if (me.uniformSound) {
    // change stream
    window.mingly.log('changeDJuniform: uniformSound is true. Remove DJ from board and push update');
    me.clearAvatar();
    // movetoUniform.innerHTML='Non-Uniform Sound';
  }
  else {
    // change stream
    // movetoUniform.innerHTML='Uniform Sound';

    // can we get back our original position?
    // eslint-disable-next-line no-lonely-if
    if (me.hasValidPosition()) {
      me.drawAvatar();
    } else {
      // todo: find available spot
    }
  }

  // let the other participants know we toggled uniformSound
  // todo: this
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.Move }, me.asJSON(), { 'dest': 'all' })));
}

function changeDJvideo() {
  videoDJ = (!videoDJ);
  window.mingly.streamHighRes.getVideoTracks()[0].enabled = videoDJ;
}

function updateDJvolume(e) {
  // var DJvolumeinputVar = document.getElementById('DJvolumeinput');
  DJVol = e.target.value / 100;
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.DJControl }, me.asJSON(), { 'volume': DJVol, 'dest': 'all' })));
}

// sdp editing
function setMediaBitrates(sdp, brV, brA) {
  let sdpEdit = {};
  if (window.mingly.board.room.editVideoBandWidthOnly) {
    sdpEdit =  setMediaBitrate(sdp, 'video', brV);
  }
  else {
    sdpEdit = setMediaBitrate(setMediaBitrate(sdp, 'video', brV), 'audio', brA);
  }
  return sdpEdit;
}

function setMediaBitrate(sdp, media, bitrate) {
  const lines = sdp.split('\n');
  let line = -1;

  for (let i = 0; i < lines.length; i++) {
    if (lines[i].indexOf(`m=${media}`) === 0) {
      line = i;
      break;
    }
  }

  if (line === -1) {
    console.debug(`Could not find the m line for ${media}`);
    return sdp;
  }
  console.debug(`Found the m line for ${media} at line ${line}`);

  // Pass the m line
  line++;

  // Skip i and c lines
  while (lines[line].indexOf('i=') === 0 || lines[line].indexOf('c=') === 0) {
    line++;
  }

  // If we're on a b line, replace it
  if (lines[line].indexOf('b') === 0) {
    console.debug(`Replaced b line at line ${line}`);
    lines[line] = `b=AS:${bitrate}`;
    return lines.join('\n');
  }

  // Add a new b line
  console.debug(`Adding new b line before line ${line}`);
  let newLines = lines.slice(0, line);
  newLines.push(`b=AS:${bitrate}`);
  newLines = newLines.concat(lines.slice(line, lines.length));
  return newLines.join('\n');
}

function changeName(name) {
  const board = window.mingly.board;
  board.me.name = name;
  localStorage.setItem('displayName', board.me.name);
  // should be removed once we have the alternative way of doing hosts
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.ChangeName }, board.me.asJSON(), { 'dest': 'all' })));
}

function changeProfilePic(pic) {
  const board = window.mingly.board;
  board.me.setParticipantPicture(pic);
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.ChangePPic }, board.me.asJSON(), { 'pic': pic, 'dest': 'all' })));
}

function mirrorVideo(flip) {
  const { mingly } = window;
  const { participants } = window.mingly.board;
  const { me } = mingly.board;
  me.flipVideo = flip;
  Object.values(participants).forEach((participant) => {
    participant.flipVideo = flip;
  });
}

function HostChangeBackGroundPicture(pic) {
  changeBackgroundPicture(pic);
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.ChgBackGrnd }, me.asJSON(), { 'pic': pic, 'dest': 'all' })));
}

function HostAddVideo(video, width, x, y) {
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.RemoteVideo }, me.asJSON(), { 'remoteVideoCmd': 'add', 'vidsrc': video, 'vidwidth': width, 'vidx': x, 'vidy': y, 'dest': 'all' })));
  window.mingly.board.addVideoToCanvas(video, width, x, y);
}

function HostStopVideo() {
  const board = window.mingly.board;

  if (board.backgroundVideo) {
    board.backgroundVideo.video.pause();
    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.RemoteVideo }, me.asJSON(), { 'remoteVideoCmd': 'stop', 'dest': 'all' })));
  }
}

function HostChangeVolume(vol) {
  const board = window.mingly.board;

  if (board.backgroundVideo) {
    board.backgroundVideo.video.volume = vol / 100;
    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.RemoteVideo }, me.asJSON(), { 'remoteVideoCmd': 'volume', 'vidVolume': vol, 'dest': 'all' })));
  }
}

function addPicture(pic, x, y, width, height) {
  const { board } = window.mingly;
  const { canvasObjects } = board;
  const object = new CanvasObject(x, y, width, height, pic, board.canvas0);
  object.uuid = createUUID();
  canvasObjects[object.uuid] = object;
  // might want to do some on load here or should maybe handle this outside
  board.updateAll();
}

function changeBackgroundPicture(pic) {
  const board = window.mingly.board;

  // need to change scope but then we need to fix a few more things
  if (pic instanceof HTMLImageElement) {
    board.room.currentBackGroundPicture = pic.src;
    board.room.backgroundPicture = pic;
    doChangeBackground();
  } else {
    board.room.currentBackGroundPicture = pic;
    board.room.backgroundPicture.src = pic;
    board.room.backgroundPicture.onload = function () {
      doChangeBackground();
    };
  }
  function doChangeBackground() {
    backgroundBackChange();
    board.drawBackgroundPicture(board.ctxSide0, board.canvasSide0.width, board.canvasSide0.height);
    // board.ctxSide0.drawImage(board.room.backgroundPicture, 0, 0,
    //   board.canvasSide0.width, board.canvasSide0.height);
    board.updateAll();
  }
  // should do some async here I guess
  // need to do error handling
  // setTimeout(function () {
  //   const { board } = window.mingly;
  //   board.ctxBackGround.drawImage(board.room.backgroundPicture, 0, 0, 
  // board.stretchSize, board.stretchSize);
  //   board.updateAll();
  // }, 2000);

}

function takeProfilePic(canvasPic, videoPic, widthOfVideo) {
  const { mingly } = window;
  // canvasPic is a html Canvas
  // videoPic is the video/picture element
  var context = canvasPic.getContext('2d');
  if (videoPic.tagName === 'IMG') {
    mingly.log('it is a picture');
    context.drawImage(videoPic, 0, 0, widthOfVideo, widthOfVideo);
  } else {
    canvasPic.width = widthOfVideo;
    canvasPic.height = widthOfVideo;
    // you can use any shape
    context.arc(widthOfVideo / 2,
      widthOfVideo / 2,
      widthOfVideo / 2,
      0,
      Math.PI * 2,
      true);
    context.clip();

    const ratio = videoPic.videoWidth / videoPic.videoHeight;
    if (ratio > 1) {
      const viddiff = videoPic.videoWidth - videoPic.videoHeight;
      context.drawImage(videoPic,
        Math.round(viddiff / 2),
        0,
        videoPic.videoHeight,
        videoPic.videoHeight,
        0,
        0,
        widthOfVideo,
        widthOfVideo);
    }
    else if (ratio < 1) {
      const viddiff = videoPic.videoHeight - videoPic.videoWidth;
      context.drawImage(videoPic,
        0,
        Math.round(viddiff / 2),
        videoPic.videoWidth,
        videoPic.videoWidth,
        0,
        0,
        widthOfVideo,
        widthOfVideo);
    }
    else {
      context.drawImage(videoPic, 0, 0, widthOfVideo, widthOfVideo);
    }
  }
  var data = canvasPic.toDataURL('image/png');
  const profile = new Image();
  profile.src  = data;
  profile.setAttribute('id', 'ProfilePictureTaken');
  return profile;
}

function getBase64Image(canvasPic) {
  var dataURL = canvasPic.toDataURL('image/png');
  return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
}

function sendTrackMediaServer(track, type) {
  // type 'cam-video' - need to use type
  // do we need the type - can always check
  const { mingly } = window;
  const { me } = mingly.board;
  const dat = { mediaTag: {} };
  dat.mediaTag = type;
  if (type === 'video' || type === 'ms_video') {
    mingly.sendTransport.produce({
      track, // changed from track: track, track,
      encodings: camEncodings(),
      appData: dat,
    }).then((producer) => {
      me.producerMS.push(producer);
    }).catch(errorHandler);
  } else {
    mingly.sendTransport.produce({
      track,
      appData: dat,
    }).then((producer) => {
      me.producerMS.push(producer);
    }).catch(errorHandler);
  }
}

function pickTracksPeer(participant) {
  const { mingly } = window;
  // const videovar = participant.videovar; // 0=audio only, 1=video and audio, 2= nothing
  // const consumers = participant.consumerMS;
  // const peerId = participant.uiid;
  // const audio = consumers.find((c) => (c.appData.mediaTag === 'audio'));
  // const video = consumers.find((c) => (c.appData.mediaTag === 'video'));
  // // videovar values:
  // // 0 = audio only
  // // 1 = audio and video
  // // 2 = nothing 
  // if (videovar === 0) {
  //   if (video) {
  //     pauseConsumer(peerId, video, true);
  //   }
  //   if (!audio) {
  //     subscribeToTrack(peerId, 'audio', false);
  //   }
  //   if (audio.paused) {
  //     pauseConsumer(peerId, video, false);   
  //   }
  //   // ask audio only
  // } else if (videovar === 1) {
  //   if (!video) {
  //     subscribeToTrack(peerId, 'video', true);
  //   }
  //   if (!audio) {
  //     subscribeToTrack(peerId, 'audio', true);
  //   }
  //   if (video.paused) {
  //     pauseConsumer(peerId, video, false);   
  //   }
  //   // ask for audio and video
  // } else if (videovar === 2) {
  //   if (video) {
  //     pauseConsumer(peerId, video, true);
  //   }
  //   if (audio) {
  //     pauseConsumer(peerId, video, true);
  //   }
  // } else {
  //   mingly.log('Invalid videovar value');
  // }
  mingly.rtc.pickTracksPeer(participant);
 
}

function subscribeToTrack(uuid, mediaTag, ms = true) {
  // uuid is the uuid of the particpant we want a track from
  // mediaTag is the kidn of media
  const { mingly } = window;
  mingly.log('subscribing to track', uuid, mediaTag);
  // Should check if we have a recvTransport first (should have),
  // but there is a risk you don't so it should be check before
  // using this function. Could create it if it does not exist, 
  // but it have to the by async so makes it more messy.
  // We need to as sever for the subscription
  // serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.SubscribeToTrack },
  // me.asJSON(), { 'mediaPeerId': uuid, 'mediaTag': mediaTag,
  // 'rtpCapabilities': mingly.device.rtpCapabilities, 'dest': 'server', 'ms': ms })));
  // using MinglyRTC
  mingly.rtc.subscribeToTrack(uuid, mediaTag, ms);
}

function setUpConsumption(peerId, consumerParameters, mediaTag, ms) {
  // this function is called when server get back to us with details after 
  // calling subcribeToTrack 
  const { mingly } = window;
  const { board } = mingly;
  mingly.log('in setupConsumption');
  let participant;
  if (peerId === mingly.board.me.uuid) {
    participant = mingly.board.me;
  } else {
    participant = mingly.board.participants[peerId];
  }
  // should do some checks if the client can consume the media
  mingly.recvTransport.consume({
    ...consumerParameters,
    appData: { peerId, mediaTag },
  }).then((consumer) => {
    mingly.log('add consumption here');
    // storing the consumer object (do we need to?)
    if (!participant.consumerMS) {
      participant.consumerMS = consumer;
    } else {
      // not sure about multiple 'consumers' here either
      participant.consumerMS.push(consumer); 
    }
    // if (board.room.testing) {
    // sendStatisticsMS(participant, consumer);
    // }
    // On the transport close
    consumer.on('transportclose', () => {
      // Assuming closed transport means participant is no longer here
      // Note that this is risky when moving between MS and no MS
      window.mingly.log('Transport Closed');
      // killPeer(participant.uuid);
    });

    consumer.on('trackended', () => {
      // Assuming closed transport means participant is no longer here
      // Note that this is risky when moving between MS and no MS
      window.mingly.log('Track ended');
      // killPeer(participant.uuid);
    });

    // NEED TO FIX REST
    // resuming consumer client side (not sure about this)
    consumer.resume();
    // ask server to resume consumer
    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.ResumeConsumer }, me.asJSON(), { 'mediaPeerId': peerId, 'mediaTag': mediaTag, 'consumerId': consumer.id, 'dest': 'server' })));
    // attach the media to the the participant object (new for now?)
    // storing it as mediaserver stream, want to change this later
    if (ms) {
      if (participant.msStream) {
        participant.msStream.addTrack(consumer.track);
      } else {
        const stream = new MediaStream();
        stream.addTrack(consumer.track);
        participant.msStream = stream;
        if (mingly.board.isExternal && mingly.msBroadCastType !== 'ms_canvas_screen'
         && mingly.msBroadCastType !== 'ms_canvas_screen_and_screenaudio') {
          const dat = {
            uuid: participant.uuid,
          };
          if (window.mingly.onMSBroadcast) {
            try {
              window.mingly.onMSBroadcast(dat);
            }
            catch {
              window.mingly.log('did not do onMSBroadcast');
            }
          }
        } else {
          // create video element and 
          mingly.log('inside the right one');
          if (mingly.msBroadCastType === 'ms_canvas_screen') {
            setupMsCanvasVideo(participant);
          } else if (mingly.msBroadCastType === 'ms_canvas_screen_and_screenaudio') {
            setupMsCanvasVideo(participant);
          } else {
            setupMsVideo(participant);
          }
        }
      }
    } else {
      // add to normal "stream"
      if (participant.peerStreams) {
        // need to fix here!
        if (consumer.kind === 'audio' && board.isIpad) {
          mingly.log('Audio AND Ipad');
          participant.setupAudioCtx(consumer.track);
        } else {
          mingly.log('Audio AND NOT Ipad');
          participant.peerStreams.addTrack(consumer.track);
        } 
      } else {
        // eslint-disable-next-line no-lonely-if
        if (consumer.kind === 'audio' && board.isIpad) {
          mingly.log('Audio AND Ipad');
          participant.setupAudioCtx(consumer.track);
        } else {
          const stream = new MediaStream();
          stream.addTrack(consumer.track);
          participant.peerStreams = stream;
        } 
      }
      if (participant.videovar < 2) {
        if (!document.getElementById(`remoteVideo_${peerId}`)) {
          createVideoContainer(participant);
          participant.videoElement.srcObject = participant.peerStreams;
          participant.videoElement.srcObject.volume = 0;
          participant.videoElement.onplaying = function () {
            participant.isPlayingVideo = true;
          };
          participant.videoElement.onpause = function () {
            participant.isPlayingVideo = false;
          };
        } else {
          try {
            if (mingly.allowSpeakerSelect && mingly.audioOutSource &&
              window.adapter.browserDetails.browser === 'chrome') {
              participant.videoElement.setSinkId(mingly.audioOutSource);
            }
          }
          catch {
            window.mingly.log('cannot set sinkId');
          }        
          participant.videoElement.srcObject.volume = 0;
          // vidContainer.childNodes[0].volume = 0; // check if this is ok to comment out
          participant.videoElement.onplaying = function () {
            participant.isPlayingVideo = true;
          };
      
          participant.videoElement.onpause = function () {
            participant.isPlayingVideo = false;
          };
        }
        participant.resumeVideo();     
        participant.adjustVolume(me);
        board.drawAvatar(participant);
      } else {
        mingly.log('Should not be media now');
      }
      // mingly.log('Going to call gotRemoteStream');
      // gotRemoteStream(consumer, participant.uuid);
    }
  }).catch(errorHandler);
}
// window.setupMsVideo = function (participant) {
function setupMsVideo(participant) {
  if (!participant.msVideoElement) {
    participant.msVideoElement = document.createElement('video');
  }
  // might need playsinline if iPhone
  participant.msVideoElement.setAttribute('id', `msVid_${participant.uuid}`);
  const vidContainer = document.createElement('div');
  vidContainer.setAttribute('id', `msRemoteVideo_${participant.uuid}`);
  vidContainer.setAttribute('class', 'videoContainerBig');
  vidContainer.appendChild(participant.msVideoElement);
  document.getElementById('videos').appendChild(vidContainer);
  document.getElementById('mainCanvas').hidden = true;
  document.getElementById('videoContainerArea').hidden = false;
  participant.msVideoElement.srcObject = participant.msStream;
  participant.msVideoElement.play();
  if (participant.uuid === window.mingly.board.me.uuid) {
    // mute my own video
    participant.msVideoElement.muted = true;
  }
}

function setupMsCanvasVideo(participant) {
  const { mingly } = window;
  const { board } = mingly;
  if (!participant.msVideoElement) {
    participant.msVideoElement = document.createElement('video');
  }
  // might need playsinline if iPhone
  participant.msVideoElement.setAttribute('id', `msVid_${participant.uuid}`);
  participant.msVideoElement.setAttribute('playsinline', '');
  const vidContainer = document.createElement('div');
  vidContainer.setAttribute('id', `ms_${participant.uuid}`);
  vidContainer.appendChild(participant.msVideoElement);
  document.getElementById('videos').appendChild(vidContainer);
  participant.msVideoElement.srcObject = participant.msStream;
  // this is a bit of a hack with the volume here
  const canvasVideoPlayPromise = participant.msVideoElement.play();
  if (canvasVideoPlayPromise !== undefined) {
    canvasVideoPlayPromise.catch((error) => {
      // Auto-play was prevented
      console.log('Auto-play was prevented, click');
      // Show a UI element to let the user manually start playback
    }).then(() => {
      participant.msVideoElement.play();
      // Auto-play started
    });
  } 
  if (participant.uuid === board.me.uuid) {
    // mute my own video
    participant.msVideoElement.muted = true;
  }
  // start the Canvas part
  // const vidsize = mingly.msBroadCastOnCanvasData.size;
  // const x = mingly.msBroadCastOnCanvasData.x;
  // const y = mingly.msBroadCastOnCanvasData.y;
  // const vidsize = mingly.msBroadCastOnCanvas[participant.uuid].size;
  // console.log('height');
  // console.log(participant.msVideoElement.videoHeight);
  // console.log('width'); 
  // console.log(participant.msVideoElement.videoWidth);
  
  // mingly.msBroadCastOnCanvas[participant.uuid].aspRatio = 
  //   participant.msVideoElement.videoHeight / participant.msVideoElement.videoWidth;
  const x = mingly.msBroadCastOnCanvas[participant.uuid].x;
  const y = mingly.msBroadCastOnCanvas[participant.uuid].y;
  const w = mingly.msBroadCastOnCanvas[participant.uuid].size;
  const h = w * mingly.msBroadCastOnCanvas[participant.uuid].aspRatio;
  console.log('HER ER VIKTIG');
  console.log(mingly.msBroadCastOnCanvas[participant.uuid].aspRatio);
  console.log(h);
  const canvasScreenShare = new CanvasObject(x, y, w, h, participant.msVideoElement, board.canvas0, 'rtcVideo');
  canvasScreenShare.uuid = participant.uuid; // set same as uuid for sharing
  canvasObjects[canvasScreenShare.uuid] = canvasScreenShare;
  
  // the old function to deal with drawing
  // board.addVideoToCanvas(participant.msVideoElement, vidsize, x, y);
}

// this is if someone else broadcasts
function openMSbroadCast(peerId, type) {
  const { mingly } = window;
  const onCanvas = (type === 'ms_canvas_screen' || type === 'ms_canvas_screen_and_screenaudio');
  if (mingly.msBroadCastId && !onCanvas) {
    mingly.log('already someone broadcasting');
    return;
  }
  if (type === 'ms_canvas_screen') {
    mingly.rtc.subscribeToTrack(peerId, 'ms_screen', true);
  } else if (type === 'ms_canvas_screen_and_screenaudio') {
    mingly.rtc.subscribeToTrack(peerId, 'ms_screen', true);
    mingly.rtc.subscribeToTrack(peerId, 'ms_screen_audio', true);
  } else {
    mingly.msBroadCastId = peerId;
    mingly.rtc.subscribeToTrack(peerId, type, true);
    mingly.rtc.subscribeToTrack(peerId, 'audio', true);
  }
}

function closeMSbroadCast(peerId) {
  const { mingly } = window;
  // unsubscribes from all (could be some are not subscribed to)
  const { type } = mingly.msBroadCastOnCanvas[peerId];
  console.log('THIS IS THE CLOSING');
  console.log(type);
  if (peerId === mingly.board.me.uuid) {
    if (type === 'ms_video') {
      // always have both audio and video
      closeProducer('ms_video');
      closeProducer('ms_audio');
    } else if (type === 'ms_screen') {
      mingly.board.me.msStream.getVideoTracks()[0].stop();
      closeProducer('ms_screen');
      closeProducer('ms_audio');
    } else if (type === 'ms_canvas_screen') {
      mingly.board.me.msStream.getVideoTracks()[0].stop();
      closeProducer('ms_screen');
    } else if (type === 'ms_canvas_screen_and_screenaudio') {
      mingly.board.me.msStream.getVideoTracks()[0].stop();
      mingly.board.me.msStream.getAudioTracks()[0].stop();
      closeProducer('ms_screen');
      closeProducer('ms_screen_audio');
    } else {
      closeProducer('ms_audio');
    }
    removeBroadCastView(peerId);
    mingly.msBroadCastType = null;
  } else {
    if (type === 'ms_video') {
      // always have both audio and video
      mingly.rtc.unsubscribeFromTrack(peerId, 'ms_video');
      mingly.rtc.unsubscribeFromTrack(peerId, 'ms_audio');
    } else if (type === 'ms_screen') {
      mingly.rtc.unsubscribeFromTrack(peerId, 'ms_screen_audio');
      mingly.rtc.unsubscribeFromTrack(peerId, 'ms_screen');
    } else if (type === 'ms_canvas_screen') {
      mingly.rtc.unsubscribeFromTrack(peerId, 'ms_screen');
    } else if (type === 'ms_canvas_screen_and_screenaudio') {
      mingly.rtc.unsubscribeFromTrack(peerId, 'ms_screen_audio');
      mingly.rtc.unsubscribeFromTrack(peerId, 'ms_screen');
    } else {
      mingly.rtc.unsubscribeFromTrack(peerId, 'ms_audio');
    }
    removeBroadCastView(peerId);
  }
}

function setUpStreamMS() {
  // const { mingly } = window;
  // const { me } = window.mingly.board;
  // might want to use the mediastream in the me object (although the same)
  sendTrackMediaServer(window.mingly.streamLowRes.getVideoTracks()[0], 'video');
  sendTrackMediaServer(window.mingly.streamLowRes.getAudioTracks()[0], 'audio');
}

function updateCanvasOnScreenPlacement(x, y, size, peerId) {
  const { mingly } = window;
  const { board } = mingly;
  mingly.msBroadCastOnCanvas[peerId].x = x;
  mingly.msBroadCastOnCanvas[peerId].y = y;
  mingly.msBroadCastOnCanvas[peerId].size = size;
  const w = size;
  const h = w * mingly.msBroadCastOnCanvas[peerId].aspRatio;
  canvasObjects[peerId].x = x;
  canvasObjects[peerId].y = y;
  canvasObjects[peerId].width = w;
  canvasObjects[peerId].height = h;
  board.updateAll();
  if (board.me.uuid === peerId) {
    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.UpdateBroadcastOnCanvas }, me.asJSON(), { 'dataBroadCast': mingly.msBroadCastOnCanvas[peerId], 'dest': 'all' })));
  }
}

function placeBroadCastScreenOnCanvas(x, y, audio = false) {
  // might remove audio?
  const { mingly } = window;
  const { board } = mingly;
  const { me } = board;
  console.log('test to');
  console.log(mingly.msBroadCastOnCanvas[me.uuid]);
  const data = mingly.msBroadCastOnCanvas[me.uuid];
  let size;
  if (data.size) {
    size = data.size;
  } else {
    size = 200;
  }
  // check if aspect ratio is defined
  console.log('IN PLACEING FUNCTION');
  if (data.aspectRatio) {
    const aspectRatio = data.aspectRatio;
    const adj = 0.5 * size;
    const xnew = x - adj;
    const ynew = y - adj / aspectRatio;
    x = xnew;
    y = ynew;
  }
  if (data.active) {
    // board.changeVideoOnCanvasPars(size, x, y);
    updateCanvasOnScreenPlacement(x, y, size, me.uuid);
  } else {
    // broadCastScreenOnCanvas(size, x, y, audio);
    startBroadCastOnCanvas(size, x, y);
  }
}

function startBroadCastOnCanvas(size, x, y) {
  const { mingly } = window;
  const { room } = mingly.board;
  const { me } = mingly.board;
  // mingly.msBroadCastOnCanvasData.id = me.uuid;
  // mingly.msBroadCastOnCanvasData.x = x;
  // mingly.msBroadCastOnCanvasData.y = y;
  // mingly.msBroadCastOnCanvasData.size = size;
  // mingly.msBroadCastOnCanvasData.active = true;
  const name = `${me.server.url}_${room.name}_${me.server.type}`;
  mingly.msBroadCastOnCanvas[me.uuid].id =  me.uuid;
  mingly.msBroadCastOnCanvas[me.uuid].x =  x;
  mingly.msBroadCastOnCanvas[me.uuid].y =  y;
  mingly.msBroadCastOnCanvas[me.uuid].size =  size;
  mingly.msBroadCastOnCanvas[me.uuid].active =  true;
  mingly.msBroadCastOnCanvas[me.uuid].name =  name;

  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.BroadcastOnCanvas }, me.asJSON(), { 'type': mingly.msBroadCastOnCanvas[me.uuid].type, 'dataBroadCast': mingly.msBroadCastOnCanvas[me.uuid], 'dest': 'all' })));
  if (me.flipVideo) {
    me.flipVideo = true;
  }
  setupMsCanvasVideo(me);
}

// function broadCastScreenOnCanvas(size, x, y, audio = false) {
function broadCastScreenOnCanvas(audio = false, size, constraints = {
  video: true,
  audio: true,
}) {
  const { mingly } = window;
  const { me } = mingly.board;
  const { room } = mingly.board;
  const numberOfBroadCasters = Object.keys(mingly.msBroadCastOnCanvas).length;
  if (numberOfBroadCasters > room.maxNumberBroadCastOnCanvas) {
    mingly.log('There is already too many broadcasters');
    return;
  }
  let type;
  if (audio) {
    type = 'ms_canvas_screen_and_screenaudio';
  } else {
    type = 'ms_canvas_screen';
  }
  mingly.msBroadCastOnCanvas[me.uuid] = {
    id: me.uuid,
    x: null,
    y: null,
    size,
    active: false,
    type,
  };
  // set these 
  // mingly.msBroadCastOnCanvasData.id = me.uuid;
  // mingly.msBroadCastOnCanvasData.x = x;
  // mingly.msBroadCastOnCanvasData.y = y;
  // mingly.msBroadCastOnCanvasData.size = size;
  // mingly.msBroadCastOnCanvasData.active = true;
  // setting the broadcasting
  broadCast(type, constraints);
}

function broadCast(type, constraints = {
  video: true,
  audio: true,
}) {
  // this function assumes that you broadcast both video and audio
  const { mingly } = window;
  const { me } = window.mingly.board;
  const { room } = window.mingly.board;
  const name = `${me.server.url}_${room.name}_${me.server.type}`;
  const isOnCanvas = mingly.msBroadCastOnCanvas[me.uuid].type === 'ms_canvas_screen' 
  || mingly.msBroadCastOnCanvas[me.uuid].type === 'ms_canvas_screen_and_screenaudio';
  if (mingly.msBroadCastId && !isOnCanvas) {
    mingly.log('There is already a broadcaster');
    // could check if there is a video and remove 
    // msBroadCastId if not
    return;
  }

  // mute and turn off camera for standard stream
  if (!isOnCanvas) {
    noVideo();
  }
  // if we are using peer-to-peer
  if (type === 'ms_video') {
    // we only use the msBroadCastId for ``classic broadCast''
    mingly.msBroadCastId = me.uuid;
    mingly.msBroadCastType = type;
    // need to get the stream
    navigator.mediaDevices.getUserMedia(constraints)
      .then((stream) => {
        me.msStream = stream;
        mingly.rtc.sendTrackMediaServer(me.msStream.getVideoTracks()[0], name, 'ms_video');
        mingly.rtc.sendTrackMediaServer(me.msStream.getAudioTracks()[0], name, 'ms_audio');
        // setupMsVideo(me);
      }).catch(function () {
        mingly.msBroadCastId = null;
        mingly.msBroadCastType = null;
      });
  } else if (type === 'ms_canvas_screen') {
    // override if by mistake audio is set to true
    if (constraints.audio === true) {
      constraints.audio = false;
    }
    console.log(constraints);
    navigator.mediaDevices.getDisplayMedia(constraints).then((stream) => {
      me.msStream = stream;
      stream.getVideoTracks()[0].addEventListener('ended', () => {
        closeMSbroadCast(me.uuid);
      });
      // could change to ms_canvas_screen here as well 
      const h = mingly.board.me.msStream.getVideoTracks()[0].getSettings().height;
      const w = mingly.board.me.msStream.getVideoTracks()[0].getSettings().width;
      // mingly.msBroadCastOnCanvasData.aspectRatio = w / h;
      mingly.msBroadCastOnCanvas[me.uuid].aspectRatio = w / h;
      mingly.log(me.msStream.getVideoTracks()[0]);
      mingly.rtc.sendTrackMediaServer(me.msStream.getVideoTracks()[0], name, 'ms_screen');
    }).catch(function () {
      mingly.msBroadCastId = null;
      mingly.msBroadCastType = null;
    });
  } else if (type === 'ms_canvas_screen_and_screenaudio') {
    navigator.mediaDevices.getDisplayMedia(constraints).then((stream) => {
      me.msStream = stream;
      stream.getVideoTracks()[0].addEventListener('ended', () => {
        closeMSbroadCast(me.uuid);
      });
      const h = mingly.board.me.msStream.getVideoTracks()[0].getSettings().height;
      const w = mingly.board.me.msStream.getVideoTracks()[0].getSettings().width;
      mingly.msBroadCastOnCanvas[me.uuid].aspectRatio = w / h;
      // could change to ms_canvas_screen here as well 
      mingly.log(me.msStream.getVideoTracks()[0]);
      mingly.rtc.sendTrackMediaServer(me.msStream.getVideoTracks()[0], name, 'ms_screen');
      // check this one out
      if (me.msStream.getAudioTracks()[0]) {
        mingly.rtc.sendTrackMediaServer(me.msStream.getAudioTracks()[0], name, 'ms_screen_audio');
      }  else {
        mingly.msBroadCastType = 'ms_canvas_screen';
      }
    }).catch(function () {
      mingly.msBroadCastId = null;
      mingly.msBroadCastType = null;
    });
  } else {  
    // video only implied here
    if (constraints.audio === true) {
      constraints.audio = false;
    }  
    navigator.mediaDevices.getDisplayMedia(constraints).then((stream) => {
      me.msStream = stream;
      stream.getVideoTracks()[0].addEventListener('ended', () => {
        closeMSbroadCast(me.uuid);
      });
      mingly.rtc.sendTrackMediaServer(me.msStream.getVideoTracks()[0], name, 'ms_screen');
      mingly.rtc.sendTrackMediaServer(window.mingly.streamLowRes.getAudioTracks()[0], name, 'ms_audio');
      // navigator.mediaDevices.getUserMedia({
      //   video: false,
      //   audio: true,
      // }).then((stream) => {
      //   me.msStream.addtrack(stream.getAudioTracks()[0]);
      //   sendTrackMediaServer(me.msStream.getAudioTracks()[0],'ms_audio');
      //   // setupMsVideo(me);
      // });
    }).catch(function () {
      mingly.msBroadCastId = null;
      mingly.msBroadCastType = null;
    });
  }
  // serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.Broadcast },
  // me.asJSON(), { 'type': type,'dest': 'all' })));
}

function localBroadCast() {
  const { mingly } = window;
  if (mingly.board.me.localBroadCast ===  true) {
    mingly.board.me.localBroadCast = false;
  } else {
    mingly.board.me.localBroadCast =  true;
  }
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.localBroadCast }, me.asJSON(), { 'localBroadCast': mingly.board.me.localBroadCast, 'dest': 'all' })));
}
 
function removeBroadCastView(peerId) {
  const { mingly } = window;
  const { board } = mingly;
  const { participants, me } = window.mingly.board;
  // this is the old code that we probably will remove
  mingly.msBroadCastId = null;
  // if (mingly.msBroadCastType !== 'ms_canvas_screen'
  //   && mingly.msBroadCastType !== 'ms_canvas_screen_and_screenaudio') {
  //   if (mingly.board.isExternal) {
  //     const dat = {
  //       uuid: peerId,
  //     };
  //     if (window.mingly.onMSBroadcast) {
  //       try {
  //         window.mingly.onMSBroadcast(dat);
  //       }
  //       catch {
  //         window.mingly.log('did not do onMSBroadcast');
  //       }
  //     }
  //   } else {
  //     document.getElementById('mainCanvas').hidden = false;
  //     document.getElementById('videoContainerArea').hidden = true;
  //     if (document.getElementById(`msRemoteVideo_${peerId}`)) {
  //       document.getElementById('videos').removeChild(document.
  //       getElementById(`msRemoteVideo_${peerId}`));
  //     }
  //   }
  // }
  // end of old code 1 (for classic broadcast)
  if (peerId === me.uuid) {
    // send information to others to close their broadcast view
    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.Broadcast }, me.asJSON(), { 'dest': 'all' })));
    // need to restart the normal stream as it dies after new call to get usermedia
    // THIS IS THE OLD CODE FOR CLASSIC BROADCAST WE WILL PROBABLY REMOVE IT
    // if (mingly.msBroadCastType !== 'ms_canvas_screen'
    //   &&  mingly.msBroadCastType !== 'ms_canvas_screen_and_screenaudio') {
    //   navigator.mediaDevices.getUserMedia(mingly.constraints)
    //     .then((stream) => {
    //       window.mingly.streamLowRes = stream;
    //       me.videoElement.srcObject = stream;
    //       me.videoElement.onplaying = function () {
    //         me.isPlayingVideo = true;
    //       };
    //       me.videoElement.onpause = function () {
    //         me.isPlayingVideo = false;
    //       };
    //       window.mingly.streamBlack = stream.clone();
    //       window.mingly.streamBlack.getVideoTracks()[0].enabled = false;
    //       noVideo();
    //       // switch streams.
    //       if (mingly.board.room.mediaserver) {
    //         const producers = window.mingly.board.me.producerMS;
    //         const producer = producers.find((p) => (p.appData.mediaTag === 'video'));
    //         producer.replaceTrack({ track: window.mingly.streamLowRes.getVideoTracks()[0] });
    //       } else {
    //         Object.values(participants).forEach((participant) => {
    //           if (participant.videovar) {
    //             mingly.rtc.swapStream(participant.uuid, window.mingly.streamLowRes);
    //           }
    //           else {
    //             // eslint-disable-next-line no-lonely-if
    //             if (participant.audiovar) {
    //               mingly.rtc.swapStream(participant.uuid, window.mingly.streamBlack);
    //             }
    //             else {
    //               mingly.rtc.swapStream(participant.uuid, window.mingly.streamBlackSilence);
    //             }
    //           }
    //         });
    //       }
    //       // serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.NoVideo }, 
    //       // me.asJSON(), { 'dest': 'all' })));
    //     }).catch(errorHandler);
    // }
    // mingly.board.backgroundVideo = {};.
    
    // remove canvasObject. Note we are right now assuming all broadcast is on canvas
    mingly.board.canvasObjects[peerId].stopVideo();
    delete mingly.board.canvasObjects[peerId];
    console.log('removing the msBroadCastOnCanvasObjet');
    delete mingly.msBroadCastOnCanvas[me.uuid];
    if (document.getElementById(`ms_${me.uuid}`)) {
      document.getElementById('videos').removeChild(document.getElementById(`ms_${me.uuid}`));
    }
    me.msStream = null;
    me.msVideoElement = null;
    board.updateAll();
    return; 
  }
  mingly.board.canvasObjects[peerId].stopVideo();
  delete mingly.board.canvasObjects[peerId];
  delete mingly.msBroadCastOnCanvas[peerId];
  if (document.getElementById(`ms_${peerId}`)) {
    document.getElementById('videos').removeChild(document.getElementById(`ms_${peerId}`));
  }
  participants[peerId].msStream = null;
  participants[peerId].msVideoElement = null;
  board.updateAll();
}

function unsubscribeFromTrack(peerId, mediaTag) {
  const consumer = findConsumerForTrack(peerId, mediaTag);
  if (!consumer) {
    window.mingly.log('did not find consumer for track');
    return;
  }
  // close the consumer
  closeConsumer(consumer);
  let theConsumers = window.mingly.board.participants[peerId].consumerMS;
  // let msC = [];
  // Object.values(window.mingly.board.participants[peerId].consumerMS).forEach((c) => {
  //   if (c.appData.mediaTag === mediaTag) {
  //     closeConsumer(c);
  //   } else {
  //     msC.push(c);
  //   }
  // });
  // window.mingly.board.participants[peerId].consumerMS = msC;
  // window.mingly.participants[peerId].consumerMS = null; //not sure this is necessary
  theConsumers = theConsumers.filter((c) => c !== consumer);
}
function findConsumerForTrack(peerId, mediaTag) {
  const consumers = window.mingly.board.participants[peerId].consumerMS;
  return consumers.find((c) => (c.appData.peerId === peerId &&
                                c.appData.mediaTag === mediaTag));
}
function closeConsumer(consumer) {
  console.log(consumer.id);
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.CloseConsumer }, me.asJSON(), { 'peerId': consumer.appData.peerId, 'mediaTag': consumer.appData.mediaTag, 'consumerId': consumer.id, 'dest': 'server' })));
  consumer.close();
}

function closeProducer(mediaTag) {
  // should think about the case with multiple server producers
  console.log('calling close Producer');
  console.log(mediaTag);
  const { mingly } = window;
  const producers = window.mingly.board.me.producerMS;
  const producer = producers.find((p) => (p.appData.mediaTag === mediaTag));
  const name = `${me.server.url}_${room.name}_${me.server.type}`;
  if (!producer) {
    window.mingly.log('No producer found for removal');
  } else {
    mingly.rtc.wssServers[name].serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.CloseProducer, 'uuid': me.uuid, 'room': name, 'mediaTag': producer.appData.mediaTag, 'dest': 'server' })));
    window.mingly.board.me.producerMS = 
      window.mingly.board.me.producerMS.filter((p) =>  p.appData.mediaTag !== mediaTag);
    producer.close(); // before or after sending over ws?
  }
}

function pauseConsumer(peerId, consumer, pause) {
  const mediaTag = consumer.appData.mediaTag;
  if (pause) {
    consumer.pause();
    // ask server to resume consumer
    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.PauseConsumer }, me.asJSON(), { 'mediaPeerId': peerId, 'mediaTag': mediaTag, 'consumerId': consumer.id, 'dest': 'server' })));
  } else {
    consumer.resume();
    // ask server to resume consumer
    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.ResumeConsumer }, me.asJSON(), { 'mediaPeerId': peerId, 'mediaTag': mediaTag, 'consumerId': consumer.id, 'dest': 'server' })));
    // ask server to resume consumer
    // CAREFUL UUID TWICE (in meAS and the other, want peerId)
    // serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.ResumeConsumer },
    // me.asJSON(), { 'uuid': uuid, 'mediaTag': mediaTag, 'consumerId':
    // consumer.id, 'dest': 'server' })));
  }
}

function pauseProducer(producer, pause) {
  const  { mingly } = window;
  const { rtc } = mingly;
  const { wssServers } = rtc;
  const name = `${me.server.url}_${room.name}_${me.server.type}`;

  console.log(pause);
  if (pause) {
    producer.pause();
    // ask server to resume consumer
    wssServers[name].serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.PauseProducer, 'uuid': me.uuid, 'room': name, 'mediaTag': producer.appData.mediaTag, 'dest': 'server' })));

    // Object.values(wssServers).forEach((server) => {
    //   if (server.send) {
    //     server.serverConnection.send(JSON.stringify(Object.assign(
    // { 'command': CMD.PauseProducer }, me.asJSON(), { 'mediaTag': producer.appData.mediaTag, 
    // 'dest': 'server' })));
    //   }
    // });
  } else {
    producer.resume();
    wssServers[name].serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.ResumeProducer, 'uuid': me.uuid, 'room': name, 'mediaTag': producer.appData.mediaTag, 'dest': 'server' })));

    // ask server to resume consumer
    // Object.values(wssServers).forEach((server) => {
    //   if (server.send) {
    //     server.serverConnection.send(JSON.stringify(Object.assign({ 'command': 
    // CMD.ResumeProducer }, me.asJSON(), 
    // { 'mediaTag': producer.appData.mediaTag, 'dest': 'server' })));
    //   }
    // });  
  }
}

function createTransport(direction) {
  const { mingly } = window;
  // ask the server to create a server-side transport object and send
  // us back the info we need to create a client-side transport
  // most of what will happen only happens when there is a reply
  // 'create-transport'
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.CreateTransport }, me.asJSON(), { 'direction': direction, 'dest': 'server' })));
}

function setUpTransport(transportOptions, direction) {
  const { mingly } = window;
  const { me, room } = mingly.board;
  console.log('Tansport in webrtc main');
  // this takes the transportOptions from the server to create the trasnport
  // note: make sure server send back direction 
  // transportOptions.iceServers = [
  //   {
  // eslint-disable-next-line max-len
  //     username: 'TtqoRCtM6cikkHvDfXtYdfPZJJFzUNzsIoqe6DYlopYrhB-VPHNXdS9JGGDHMTrNAAAAAF9Y6i1jaGhleWVyZGFobGxhcnNlbg==',
  //     credential: 'e433e0b4-f2aa-11ea-b7fe-0242ac140004',
  //     urls: [
  //       'turn:eu-turn7.xirsys.com:80?transport=udp',
  //       'turn:eu-turn7.xirsys.com:3478?transport=udp',
  //       'turn:eu-turn7.xirsys.com:80?transport=tcp',
  //       'turn:eu-turn7.xirsys.com:3478?transport=tcp',
  //       'turns:eu-turn7.xirsys.com:443?transport=tcp',
  //       'turns:eu-turn7.xirsys.com:5349?transport=tcp',
  //     ],
  //   }];
  // if (direction === 'recv') {
  //   mingly.recvTransportOptions = transportOptions;
  //   mingly.recvTransport = mingly.device.createRecvTransport(transportOptions);
  //   me.nTransportsReady += 1; // want both to be up and running
  //   // before doing anything
  //   if (me.nTransportsReady === 2) {
  //     setUpStreamMS();
  //   }
  //   mingly.log('got past first Receive');
  //   mingly.recvTransport.on('connect', async ({ dtlsParameters }, callback, errback) => {
  //     mingly.log('connected receive transport');
  //     console.log(dtlsParameters);
  // eslint-disable-next-line max-len
  //     serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.dtlsParameters }, me.asJSON(), { 'dtlsPars': dtlsParameters, 'transportId': mingly.recvTransportOptions.id, 'dest': 'server' })));
  //     callback();
  //   });
  // } else if (direction === 'send') {
  //   mingly.sendTransportOptions = transportOptions;
  //   mingly.sendTransport  = mingly.device.createSendTransport(transportOptions);
  //   me.nTransportsReady += 1; // want both to be up and running before doing anything
  //   if (me.nTransportsReady === 2) {
  //     setUpStreamMS();
  //   }
  //   mingly.log('got past first Send');
  //   mingly.sendTransport.on('connect',  async ({ dtlsParameters }, callback, errback) => {
  //     mingly.log('connected send transport');
  //     console.log(dtlsParameters);
  // eslint-disable-next-line max-len
  //     serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.dtlsParameters }, me.asJSON(), { 'dtlsPars': dtlsParameters, 'transportId': mingly.sendTransportOptions.id, 'dest': 'server' })));
  //     callback();
  //   });
  //   // on produce event. Should be be setup after hearing back from server on connect?
  // eslint-disable-next-line max-len
  //   mingly.sendTransport.on('produce', async ({ kind, rtpParameters, appData }, callback, errback) => { 
  //     // send to server 
  //     //  Unsure if this is the way to go
  //     mingly.msOnProduceCallback = callback;
  //     mingly.log('in produce');
  //     const paused = 'paused';
  //     serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.OnTransportProduce }, 
  //       me.asJSON(), { 'transportId': mingly.sendTransportOptions.id, 
  //         'kind': kind, 
  //         'rtpParameters': rtpParameters, 
  //         'paused': paused, 
  //         'appData': appData, 
  //         'dest': 'server' })));
  //     // .then(callback)
  //     // .catch(errback);  
  //   });
  // } else {
  //   mingly.log('Bad direction for mediaserver');
  // }
}

const CAM_VIDEO_SIMULCAST_ENCODINGS =
[
  // { maxBitrate: 96000, scaleResolutionDownBy: 4 },
  // { maxBitrate: 680000, scaleResolutionDownBy: 1 },
  { maxBitrate: 500000, scaleResolutionDownBy: 1 },
  { maxBitrate: 1500000, scaleResolutionDownBy: 1 },
];

function camEncodings() {
  return CAM_VIDEO_SIMULCAST_ENCODINGS;
}
function sendPoll(pollData) {
  // keep track of answers
  window.mingly.pollResults = [];
  Object.values(pollData.alternatives).forEach((alternative) => {
    window.mingly.log(alternative);
    window.mingly.pollResults.push(0);
  });
  // send the poll
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.NewPoll }, me.asJSON(), { 'pollData': pollData, 'dest': 'all' })));
}

function closePoll() {
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.ClosePoll }, me.asJSON(), { 'dest': 'all' })));
}

function sendPollStats(pollData) {
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.PollStats }, me.asJSON(), { 'pollData': pollData, 'dest': 'all' })));
}

function answerPoll(pollData) {
  const isAnonymous = pollData.isAnonymous;
  const creatorId = pollData.creatorId;
  console.log('Sending to', creatorId);
  console.log('polldata', pollData);
  // should make it anonymous if that is activated
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.PollAnswer }, me.asJSON(), { 'pollData': pollData, 'dest': creatorId })));
}

function answerQuiz(quizData) {
  if (quizData.checkedAlternatives[0] === window.mingly.board.quiz.data.correctAnswerPos) {
    quizData.correctAnswer = true;
    console.log('CORRECT!');
  }
  const creatorId = quizData.creatorId;
  console.log('Sending to', creatorId);
  if (creatorId === window.mingly.board.me.uuid) {
    const signal = {
      uuid: window.mingly.board.me.uuid,
      displayName: window.mingly.board.me.name,
      quizData,
    };    
    if (window.mingly.onGotQuizAnswer) {
      try {
        // don't need to check if for me as this is done earlier
        window.mingly.onGotQuizAnswer(signal);
      } 
      catch {
        window.mingly.log('Could not get Poll answer');
      }
    }
  } else {
    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.QuizAnswer }, me.asJSON(), { 'quizData': quizData, 'dest': creatorId })));
  }
}

function sendGameInvite(gameData) {
  const players = gameData.invitedPlayers;
  Object.values(players).forEach((player) => {
    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.GameInvite }, me.asJSON(), { 'gameData': gameData, 'dest': player })));    
  });
}

function acceptGameInvite(data) {
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.AcceptGame }, me.asJSON(), { 'accepted': data.accepted, 'dest': data.creatorId })));
}
function updateGameData(gameData) {
  for (let i = 0; i < gameData.players.length; i++) {
    // should skip myself
    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.UpdateGameData }, me.asJSON(), { 'gameData': gameData, 'dest': gameData.players[i].userId })));
  }
}
function changeGameMenuVisibilityFromHost(gameData) {
  for (let i = 0; i < gameData.players.length; i++) {
    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.changeGameMenuVisibility }, me.asJSON(), { 'dest': gameData.players[i].userId })));
  }
}
function stopGame(gameData) {
  changeBackgroundPicture(window.mingly.board.room.backgroundPictureURL);
  for (let i = 0; i < gameData.players.length; i++) {
    // should skip myself
    serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.StopGame }, me.asJSON(), { 'dest': gameData.players[i].userId })));
  }
  window.mingly.board.quiz.inQuizMode = false;
  window.mingly.board.quiz.data = {};
  window.mingly.board.quiz.alternatives = null;
  window.mingly.board.quiz.hasAnswered = false;
  window.mingly.board.updateAll();
}

function drawGameToCanvas(quizData) {
  const { mingly } = window;
  const { board } = mingly;
  // might not need this
  board.quiz.hasAnswered = false; 
  // make it as a poll
  const pollData = {
    question: quizData.question,
    alternatives: quizData.alternatives,
    creatorId: quizData.creatorId,
    isAnonymous: false,
    isActive: true,
    onCanvas: true,
    quiz: true,
    timed: true,
    answerSelf: true,
    correctAnswerPos: quizData.correctAnswerPos,
  };
  board.quiz.inQuizMode = true;
  board.quiz.data = pollData;
  board.quiz.alternatives = pollData.alternatives.length;
  const pic = quizData.link;
  board.room.currentBackGroundPicture = pic;
  board.room.backgroundPicture.src = pic;
  board.room.backgroundPicture.onload = function () {
    const { board } = window.mingly;
    board.me.quizTime = performance.now();
    backgroundBackChange();
    board.drawBackgroundPicture(board.ctxSide0, board.canvasSide0.width, board.canvasSide0.height);
    board.updateAll();
    board.resetZoom();
  };
  board.drawQuizAlternatives();
}

function drawGame() {
  // initiate db
  const guessCityDB = initGuessTheCity();
  const nAlt = guessCityDB.length;
  const drawCity = Math.floor(Math.random() * nAlt);
  const drawAlts = [];
  // the below has to be optimized!
  // draw first alternative 
  let draw = Math.floor(Math.random() * nAlt);
  while (guessCityDB[draw].city === guessCityDB[drawCity].city) {
    draw = Math.floor(Math.random() * nAlt);
  }
  drawAlts.push(draw);
  // draw 2nd alternative 
  draw = Math.floor(Math.random() * nAlt);
  while (guessCityDB[draw].city === guessCityDB[drawCity].city ||
        guessCityDB[draw].city === guessCityDB[drawAlts[0]].city) {
    draw = Math.floor(Math.random() * nAlt);
  }
  drawAlts.push(draw);

  // draw 3nd alternative 
  draw = Math.floor(Math.random() * nAlt);
  while (guessCityDB[draw].city === guessCityDB[drawCity].city ||
    guessCityDB[draw].city === guessCityDB[drawAlts[0]].city ||
    guessCityDB[draw].city === guessCityDB[drawAlts[1]].city) {
    draw = Math.floor(Math.random() * nAlt);
  }
  drawAlts.push(draw);
  const drawCorrectPos = Math.floor(Math.random() * 4); // 4 alternatives
  const alt = [];
  let k = 0;
  for (let i = 0; i < 4; i++) {
    if (i === drawCorrectPos) {
      alt.push({ data: guessCityDB[drawCity].city });
    } else {
      alt.push({ data: guessCityDB[drawAlts[k]].city });
      k += 1;
    }
  }
  const quizData = {
    link: guessCityDB[drawCity].link,
    question: 'Guess the city in the picture',
    creatorId: window.mingly.board.me.uuid,
    alternatives: alt,
    correctAnswerPos: drawCorrectPos,
  };
  console.log(quizData);
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.NewQuizQuestion }, me.asJSON(), { 'quizData': quizData, 'dest': 'all' })));    
  // const players = gameData.invitedPlayers;
  // Object.values(players).forEach((player) => {
  //   serverConnection.send(JSON.stringify(Object.assign({ 'command':
  // cmdGameInvite }, me.asJSON(), { 'gameData': gameData, 'dest': player })));
  // });
  drawGameToCanvas(quizData);
}
function iAmLeaving() {
  removeKeyboardEventListeners();
  const { me } = window.mingly.board;
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.Leaving }, me.asJSON(), { 'dest': 'all' })));
}

function allFollowMyView() {
  const { board } = window.mingly;
  if (!board.me.following.isLeading) {
    board.me.following = {
      isLeading: true,
      participants: 'all', // here we say all if to all or a specific id
      isFollowing: false,
    };
    board.updateAll();
  } else {
    // eslint-disable-next-line no-lonely-if
    if (board.me.following.participants !== 'all') {
      board.me.following = {
        isLeading: true,
        participants: 'all', // here we say all if to all or a specific id
        isFollowing: false,
      };
      board.updateAll();
    } else {
      board.me.following = {
        isLeading: false,
        participants: {}, // here we say all if to all or a specific id
        isFollowing: false,
      }; 
    }
  }
}

function setUpEasterEgg() {
  const { board } = window.mingly;
  // set up video
  const vidEaster =  'https://www.dropbox.com/s/1jy7s9l2v2qi1x0/EasterMingly.mp4?raw=1';
  // board.addVideoToCanvas(vidEaster, 1, 140, 140, true); 
  const objectType = 'canvasVideo';
  const source = {
    src: vidEaster,
    muted: true,
    loop: true,
  };
  const object = new CanvasObject(140, 140, 1, 1, source, board.canvas0, objectType);
  // object.source.muted = true;
  object.uuid = createUUID();
  object.onClick = whenClick.bind(object);
  function whenClick() {
    this.source.muted = !this.source.muted;
  }
  canvasObjects[object.uuid] = object;
}
function setupFinanisellStyring() {
  const { board } = window.mingly;
  const objectType = 'canvasDrawing';
  const object1 = new CanvasObject(600, -150, 200,
    200, myTextFunction, board.canvas0, objectType);
  object1.text = 'BØK 3632 Finansiell Styring';
  object1.uuid = createUUID();
  // set up children for lecture 1
  object1.isActive = true;
  canvasObjects[object1.uuid] = object1;
  function myTextFunction(x, y, width, height, text, ctx) {
    ctx.font = '30px Courier New';
    ctx.textAlign = 'center';
    ctx.fillStyle = 'white';
    ctx.fillText(text, x + 0.5 * width, y + 0.5 * height);
  }
}

function setUpChristiansRoom() {
  const { board } = window.mingly;
  const objectType = 'canvasDrawing';
  const boarderOfRoom = new CanvasObject(-1, -1, 1402,
    802, boarderFun, board.canvas0, objectType);
  boarderOfRoom.uuid = createUUID();
  boarderOfRoom.isActive = true;
  boarderOfRoom.level = 0;
  canvasObjects[boarderOfRoom.uuid] = boarderOfRoom;

  const object1 = new CanvasObject(600, -150, 200,
    200, myTextFunction, board.canvas0, objectType);
  object1.text = 'Christian Heyerdahl-Larsen\'s room';
  object1.uuid = createUUID();
  // set up children for lecture 1
  object1.isActive = true;
  canvasObjects[object1.uuid] = object1;

  function myTextFunction(x, y, width, height, text, ctx) {
    ctx.font = '30px Courier New';
    ctx.textAlign = 'center';
    ctx.fillStyle = '#efc33c';
    ctx.fillText(text, x + 0.5 * width, y + 0.5 * height);
  }
  function boarderFun(x, y, width, height, text, ctx) {
    ctx.lineWidth = '3';
    ctx.strokeStyle = '#efc33c';
    ctx.beginPath();
    ctx.rect(x, y, width, height);
    ctx.stroke();
  }

}

function setLegelistensRoom() {
  const { board } = window.mingly;
  const objectType = 'canvasDrawing';
  const boarderOfRoom = new CanvasObject(-1, -1, 1402,
    802, boarderFun, board.canvas0, objectType);
  boarderOfRoom.uuid = createUUID();
  boarderOfRoom.isActive = true;
  boarderOfRoom.level = 0;
  canvasObjects[boarderOfRoom.uuid] = boarderOfRoom;

  function boarderFun(x, y, width, height, text, ctx) {
    ctx.lineWidth = '3';
    ctx.strokeStyle = '#ffffff';
    ctx.beginPath();
    ctx.rect(x, y, width, height);
    ctx.stroke();
  }
}

function setUpMinglyASMeeting() {
  const { board } = window.mingly;
  const objectType = 'canvasDrawing';
  const boarderOfRoom = new CanvasObject(-1, -1, 1402,
    802, boarderFun, board.canvas0, objectType);
  boarderOfRoom.uuid = createUUID();
  boarderOfRoom.isActive = true;
  boarderOfRoom.level = 0;
  canvasObjects[boarderOfRoom.uuid] = boarderOfRoom;

  const object1 = new CanvasObject(600, -150, 200,
    200, myTextFunction, board.canvas0, objectType);
  object1.text = 'Mingly AS\'s Meeting Room';
  object1.uuid = createUUID();
  // set up children for lecture 1
  object1.isActive = true;
  canvasObjects[object1.uuid] = object1;

  function myTextFunction(x, y, width, height, text, ctx) {
    ctx.font = '30px Courier New';
    ctx.textAlign = 'center';
    ctx.fillStyle = '#efc33c';
    ctx.fillText(text, x + 0.5 * width, y + 0.5 * height);
  }
  function boarderFun(x, y, width, height, text, ctx) {
    ctx.lineWidth = '3';
    ctx.strokeStyle = '#efc33c';
    ctx.beginPath();
    ctx.rect(x, y, width, height);
    ctx.stroke();
  }

}

function setUpF300V2() {
  const { board } = window.mingly;
  const objectType = 'canvasDrawing';
  const boarderOfRoom = new CanvasObject(-1, -1, 1402,
    802, boarderFun, board.canvas0, objectType);
  boarderOfRoom.uuid = createUUID();
  boarderOfRoom.isActive = true;
  boarderOfRoom.level = 0;
  canvasObjects[boarderOfRoom.uuid] = boarderOfRoom;

  const object1 = new CanvasObject(600, -150, 200,
    200, myTextFunction, board.canvas0, objectType);
  object1.text = 'Introduction to Financial Management';
  object1.uuid = createUUID();
  // set up children for lecture 1
  object1.isActive = true;
  canvasObjects[object1.uuid] = object1;

  // Questions
  const imgC0 = new Image();
  imgC0.onload = function () {
    const aspectRatio  =  imgC0.width / imgC0.height;
    const object3 = new CanvasObject(-255, 100, 250,
      250 / aspectRatio, imgC0, board.canvas0, 'picture');
    object3.uuid = createUUID();
    // set up children for lecture 1
    object3.isActive = true;
    canvasObjects[object3.uuid] = object3;
    board.updateAll();
  };
  imgC0.src = 'https://www.dropbox.com/s/vw7p6veilywq7pj/Slide2.jpeg?raw=1';

  const imgC1 = new Image();
  imgC1.onload = function () {
    const aspectRatio  =  imgC1.width / imgC1.height;
    const object3 = new CanvasObject(-255, 500, 250,
      250 / aspectRatio, imgC1, board.canvas0, 'picture');
    object3.uuid = createUUID();
    // set up children for lecture 1
    object3.isActive = true;
    canvasObjects[object3.uuid] = object3;
    board.updateAll();
  };
  imgC1.src = 'https://www.dropbox.com/s/csl0mzk1cfx9si5/Slide3.jpeg?raw=1';

  const imgC2 = new Image();
  imgC2.src = 'https://www.dropbox.com/s/ugrmurcpt2a3zrx/Slide4.jpeg?raw=1';
  imgC2.onload = function () {
    const aspectRatio  =  imgC2.width / imgC2.height;
    const object3 = new CanvasObject(1405, 100, 250,
      250 / aspectRatio, imgC2, board.canvas0, 'picture');
    object3.uuid = createUUID();
    // set up children for lecture 1
    object3.isActive = true;
    canvasObjects[object3.uuid] = object3;
    board.updateAll();
  };

  const imgC3 = new Image();
  imgC3.onload = function () {
    const aspectRatio  =  imgC3.width / imgC3.height;
    const object3 = new CanvasObject(1405, 500, 250,
      250 / aspectRatio, imgC3, board.canvas0, 'picture');
    object3.uuid = createUUID();
    // set up children for lecture 1
    object3.isActive = true;
    canvasObjects[object3.uuid] = object3;
    board.updateAll();
  };
  imgC3.src = 'https://www.dropbox.com/s/tn4xcpodo84fw7a/Slide5.jpeg?raw=1';

  function myTextFunction(x, y, width, height, text, ctx) {
    ctx.font = '30px Courier New';
    ctx.textAlign = 'center';
    ctx.fillStyle = '#efc33c';
    ctx.fillText(text, x + 0.5 * width, y + 0.5 * height);
  }
  function boarderFun(x, y, width, height, text, ctx) {
    ctx.lineWidth = '3';
    ctx.strokeStyle = '#efc33c';
    ctx.beginPath();
    ctx.rect(x, y, width, height);
    ctx.stroke();
  }

}

function setUpHostArea() {
  const { mingly } = window;
  const { board } = mingly;
  const { x } = mingly.hostArea;
  const  { y } = mingly.hostArea;
  const { width } = mingly.hostArea;
  const { height } = mingly.hostArea;
  const { isActive } = mingly.hostArea;
  const  { walkable } = mingly.hostArea;
  const { drawBoarder } = mingly.hostArea;
  let objectType;
  let source;
  if (drawBoarder) {
    console.log('DRAWBOARDER');
    objectType = 'canvasDrawing';
    source = hostAreaDrawings;
  } else {
    objectType = 'definedArea';
    source = {};
  }
  const hostArea = new CanvasObject(x, 
    y, 
    width,
    height, source, board.canvas0, objectType);
  hostArea.uuid = createUUID();
  hostArea.name = 'hostArea';
  mingly.hostArea.canvasUuid =  hostArea.uuid;
  hostArea.isActive = isActive; 
  if (mingly.isHost) {
    // always walkable if host
    hostArea.isWalkable = true;
  } else {
    hostArea.isWalkable = walkable;
  }
  canvasObjects[hostArea.uuid] = hostArea;
  function hostAreaDrawings(x, y, width, height, text, ctx) {
    // ctx.globalAlpha = 0.2;
    // ctx.fillStyle = 'black';
    ctx.strokeStyle = 'black';
    ctx.strokeRect(x, y, width, height);
   
  }
}

function setUpHaakonsRoom() {
  // TO DO FIX WHY SOMETIMES SOMETHING DOES NOT LOAD
  // FIX REDUNDANT BACKGROUN CHANGES
  // FIX WHY BACKGROUND IS CHANGED
  const { board } = window.mingly;
  const objectType = 'canvasDrawing';
  // set up level structure
  // Pictures
  const backGround0 = new Image();
  const backGround1 = new Image();
  const backGround2 = new Image();
  const backGround3 = new Image();
  const pic0a = new Image();
  const pic0b = new Image();
  const pic0c = new Image();
  const pic0d = new Image();
  const pic0e = new Image();
  const picBack = new Image();
  const picDay47 = new Image();
  const dagringer1 = new Image();
  const vvvv   = new Image();
  const tredimOi2 = new Image();
  const puzzle6 = new Image();
  const AI = new Image();
  const diverCity = new Image();
  const dragen = new Image();
  const fire = new Image();
  const fLOVEr = new Image();
  const sugerTown = new Image();
  const utopia = new Image();
  const picMore = new Image();
  // 1014 days
  const day0 = new Image();
  const day53 = new Image();
  // dagringer
  const dagringer2 = new Image();
  // origami
  const fiskebrett = new Image();
  const wrapCity = new Image();
  // 3d
  const ringo2  = new Image();
  const monsterBy = new Image();
  // menu pictures
  const HQ = false;
  // back button
  // picBack.src  = 'https://www.dropbox.com/s/bta5mtpqy2e7edm/backButton.jpg?raw=1';
  // picMore.src  = 'https://www.dropbox.com/s/am9gc3smqedomdd/More.jpg?raw=1';
  picBack.src  = 'data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTIwLDExVjEzSDhMMTMuNSwxOC41TDEyLjA4LDE5LjkyTDQuMTYsMTJMMTIuMDgsNC4wOEwxMy41LDUuNUw4LDExSDIwWiIvPjwvc3ZnPg==';
  picMore.src  = 'data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTQsMTFWMTNIMTZMMTAuNSwxOC41TDExLjkyLDE5LjkyTDE5Ljg0LDEyTDExLjkyLDQuMDhMMTAuNSw1LjVMMTYsMTFINFoiLz48L3N2Zz4=';
  // gallery pictures
  // backgrounds
  backGround0.src = 'https://www.dropbox.com/s/xk7yl3x0vjy8yam/Rosa%20NCS%20S%206010-Y70R.jpg?raw=1';
  backGround1.src = 'https://www.dropbox.com/s/1z6crd5jntclx8f/Oransj%20NCS%20S%204030-Y40R.jpg?raw=1';
  backGround2.src = 'https://www.dropbox.com/s/ev55hegybes533i/Gr%E2%80%BAnn%20NCS%20S%207010-G70Y.jpg?raw=1';
  backGround3.src = 'https://www.dropbox.com/s/41kuvrfs34mjphu/Lilla%20NCS%20S%207010-R50B.jpg?raw=1';

  if (HQ) {
    picDay47.src = 'https://www.dropbox.com/s/qh9fvqwsfpgarzd/Day%2047.jpg?raw=1';
    dagringer1.src  = 'https://www.dropbox.com/s/zx33nw9xd67yvpe/Dagringer%20ARK%201.jpg?raw=1';
    vvvv.src   = 'https://www.dropbox.com/s/jv12mxbnlxjwuii/VVVV.jpg?raw=1';
    puzzle6.src  = 'https://www.dropbox.com/s/kf4oqxe9yvr1q8p/puzzle%20%C3%A5r%206.jpg?raw=1';
    diverCity.src = 'https://www.dropbox.com/s/lg3gmsu9k9p79fb/DiverCity.jpg?raw=1';
    dragen.src = 'https://www.dropbox.com/s/kq86ddu6ypo1r4t/Dragen.jpg?raw=1';
    fire.src = 'https://www.dropbox.com/s/xtowj34z1cmm3zy/fire.jpg?raw=1';
    fLOVEr.src = 'https://www.dropbox.com/s/ql7cfp7xv300fdm/fLOVEr%20%28m%C3%A5%20scanne%29.jpg?raw=1';
    sugerTown.src = 'https://www.dropbox.com/s/mjfw9wi0cxqnlep/Sugar%20town.jpg?raw=1';
    utopia.src = 'https://www.dropbox.com/s/vy6rum9ju68vyto/Utopia.jpg?raw=1';
  } else {
    picDay47.src  = 'https://www.dropbox.com/s/dpk52cjy9g0f1mu/Day%2047%20LQ.jpg?raw=1';
    dagringer1.src  = 'https://www.dropbox.com/s/wcf6i2zknskwozh/Dagringer%20ARK%201%20LQ.jpg?raw=1';
    vvvv.src   = 'https://www.dropbox.com/s/wlarpxviowqqxb8/VVVV%20LQ.jpg?raw=1';

    puzzle6.src  = 'https://www.dropbox.com/s/nu7zkkv3pxyxwvp/puzzle%20%C3%A5r%206LQ.jpg?raw=1';
    diverCity.src = 'https://www.dropbox.com/s/080gi7l8c99byny/DiverCityLQ.jpg?raw=1';
    dragen.src = 'https://www.dropbox.com/s/rlksvzaxk3lovk5/DragenLQ.jpg?raw=1';
    fire.src = 'https://www.dropbox.com/s/a1m66d40p5pkg3d/fireLQ.jpg?raw=1';
    fLOVEr.src = 'https://www.dropbox.com/s/ql7cfp7xv300fdm/fLOVEr%20%28m%C3%A5%20scanne%29.jpg?raw=1';
    sugerTown.src = 'https://www.dropbox.com/s/mjfw9wi0cxqnlep/Sugar%20town.jpg?raw=1';
    utopia.src = 'https://www.dropbox.com/s/dog6vp09ubmiap2/UtopiaLQ.jpg?raw=1';
  }
  AI.src = 'https://www.dropbox.com/s/45x7wg0w2fmgkiw/AI.jpg?raw=1';
  tredimOi2.src  = 'https://www.dropbox.com/s/vz5n0th0aesyhs7/OI%202.jpg?raw=1';

  // 1014 days 
  day0.src = 'https://www.dropbox.com/s/70vvl2529fq902z/Day%200.jpg?raw=1';
  day53.src = 'https://www.dropbox.com/s/nrhbz7hlsncjshe/Day%2053.jpg?raw=1';
  // dagringer
  dagringer2.src = 'https://www.dropbox.com/s/v1lgvoif0p74o5t/Dagringer%20ARK%202%20LQ.jpg?raw=1';
  // oirgami 
  fiskebrett.src = 'https://www.dropbox.com/s/pkh79dr5h2d5mxb/Fiskebrett%20LQ.jpg?raw=1';
  wrapCity.src = 'https://www.dropbox.com/s/96iemfr3q051u1n/Wrap%20city%202.jpg?raw=1';
  // 3d
  ringo2.src  = 'https://www.dropbox.com/s/b63d88nkw2jutd0/Ringo%202.jpg?raw=1';  
  monsterBy.src = 'https://www.dropbox.com/s/eadr4y2smwzt16l/M%C3%B8nsterby.jpg?raw=1';
  // intro
  pic0a.src = 'https://www.dropbox.com/s/njvv5tqyacnzyp4/Forside%20Haakon.jpg?raw=1';
  pic0b.src  = 'https://www.dropbox.com/s/jp7is5imd2j8oj6/Forside%20Blomstereng%20Haakon.jpg?raw=1';
  pic0c.src  = 'https://www.dropbox.com/s/fxirx9gjx58chcr/Forside%20About%20Haakon.jpg?raw=1';
  pic0d.src  = 'https://www.dropbox.com/s/e6pvy093i8es07d/Forside%20Gallery%20Haakon.jpg?raw=1';
  pic0e.src  = 'https://www.dropbox.com/s/36233dremkp5dnu/Forside%20shop%20Haakon.jpg?raw=1';
  const nPics = 22;
  let nLoaded = 0;
  backGround0.onload = checkStatus;
  backGround1.onload = checkStatus;
  backGround2.onload = checkStatus;
  pic0a.onload = checkStatus;
  pic0b.onload = checkStatus;
  pic0c.onload = checkStatus;
  pic0d.onload = checkStatus;
  pic0e.onload = checkStatus;
  picBack.onload = checkStatus;
  picMore.onload = checkStatus;
  picDay47.onload = checkStatus;
  dagringer1.onload = checkStatus;
  vvvv.onload = checkStatus;
  tredimOi2.onload = checkStatus;
  puzzle6.onload = checkStatus;
  AI.onload = checkStatus;
  diverCity.onload = checkStatus;
  dragen.onload = checkStatus;
  fire.onload = checkStatus;
  fLOVEr.onload = checkStatus;
  sugerTown.onload = checkStatus;
  utopia.onload = checkStatus;
  // set up the initial level
  // let curentLevel = -1;
  // set up the controlling canvas object 
  // This object is used to call update functions
  // by iterating over the objects and to see if there is 
  // an object of type 'control' you call the function
  // that updates the level for the group
  const objectControlling = new CanvasObject(0, 0, 0, 0, 'control', board.canvas0, 'control');
  objectControlling.uuid = createUUID();
  objectControlling.level = 0;
  objectControlling.groupName = 'HaakonsGallery';
  objectControlling.currentLevel = -1;
  objectControlling.updateRoom = updateRoom;
  // might not be that nice way of doing it
  // objectControlling.backgGrounds = {};
  // objectControlling.backgGrounds[backGround1.src] = {
  //   levels: [-1, 1, 2, 3, 5],
  // };
  // objectControlling.backgGrounds[backGround1.src] = {
  //   src: backGround1.src,
  //   levels: [-1, 1, 2, 3, 5],
  // };
  canvasObjects[objectControlling.uuid] = objectControlling;
  // end of 'Controll' canvas 
  function checkStatus() {
    nLoaded += 1;
    console.log('loaded');
    console.log(nLoaded);
    if (nLoaded === nPics) {
      objectControlling.currentLevel = 1;
      setUpPicture(pic0a, 0, 0, board.width, null, 1);
      setUpPicture(pic0b, 0, 0, board.width, null, 3);
      setUpPicture(pic0c, 0, 0, board.width, null, 4);
      setUpPicture(pic0d, 0, 0, board.width, null, 5);
      setUpPicture(pic0e, 0, 0, board.width, null, 2);
      setUpPicture(picBack, -103, -3, 100, null, 6, 1, function () {
        if (board.room.currentBackGroundPicture !== backGround0.src) {
          changeBackgroundPicture(backGround0);
        }
      });
      setUpPicture(picMore, 1403, -3, 100, null, 6, 7, function () {
        if (board.room.currentBackGroundPicture !== backGround1.src) {
          changeBackgroundPicture(backGround1);
        }
      });
      // gallery section 
      let sizeBase = 250;
      const spaceBetween = 35;
      let currentX = xNonWalk + 20;
      let currentY = yNonWalk + 100;
      // const event1047days = {
      //   onclick: () => {
          
      //   },
      // };
      // collections
      // PAGE 1
      // PIC 1
      let ratio = (365 / 657);
      const objectDay47 = new CanvasObject(currentX + (sizeBase * ratio) / 2,
        sizeBase + currentY, 
        sizeBase * ratio,
        spaceBetween, textBoxFun, board.canvas0, objectType);
      objectDay47.text = '1014 Days';
      objectDay47.uuid = createUUID();
      objectDay47.level = 6;
      objectDay47.dat = {
        fontType: 'px Arial',
        fontSize: 20,
      };
      canvasObjects[objectDay47.uuid] = objectDay47;

      setUpPicture(picDay47, currentX, currentY, sizeBase * ratio, null, 6, 10, function () {
        if (board.room.currentBackGroundPicture !== backGround2.src) {
          changeBackgroundPicture(backGround2);
        }
      });
      // PIC 2
      currentX += sizeBase * ratio + spaceBetween;
      ratio = 1;
      const objectDagringer = new CanvasObject(currentX + (sizeBase * ratio) / 2,
        sizeBase + currentY, 
        sizeBase * ratio,
        spaceBetween, textBoxFun, board.canvas0, objectType);
      objectDagringer.text = 'Dagringer';
      objectDagringer.uuid = createUUID();
      objectDagringer.level = 6;
      objectDagringer.dat = {
        fontType: 'px Arial',
        fontSize: 20,
      };
      canvasObjects[objectDagringer.uuid] = objectDagringer;
      setUpPicture(dagringer1, currentX, currentY, sizeBase, null, 6, 11, function () {
        if (board.room.currentBackGroundPicture !== backGround2.src) {
          changeBackgroundPicture(backGround2);
        }
      });
      // PIC 3
      currentX += sizeBase * ratio + spaceBetween;
      ratio = (698 / 493);
      const objectOrigami = new CanvasObject(currentX +  (sizeBase * ratio) / 2, 
        sizeBase + currentY, 
        sizeBase * ratio,
        spaceBetween, textBoxFun, board.canvas0, objectType);
      objectOrigami.text = 'Origami';
      objectOrigami.uuid = createUUID();
      objectOrigami.level = 6;
      objectOrigami.dat = {
        fontType: 'px Arial',
        fontSize: 25,
      };
      canvasObjects[objectOrigami.uuid] = objectOrigami;
      setUpPicture(vvvv, currentX, currentY, sizeBase * (698 / 493), null, 6, 12, function () {
        if (board.room.currentBackGroundPicture !== backGround2.src) {
          changeBackgroundPicture(backGround2);
        }
      });
      // PAGE 2
      // PIC 4
      currentY = yNonWalk + 100;
      currentX = xNonWalk + 100;
      ratio  = (534 / 713);
      const object3d = new CanvasObject(currentX +  (sizeBase * ratio) / 2, 
        sizeBase + currentY, 
        sizeBase * ratio,
        spaceBetween, textBoxFun, board.canvas0, objectType);
      object3d.text = '3D';
      object3d.uuid = createUUID();
      object3d.level = 7;
      object3d.dat = {
        fontType: 'px Arial',
        fontSize: 25,
      };
      canvasObjects[object3d.uuid] = object3d;
      setUpPicture(tredimOi2, currentX, currentY, sizeBase * ratio, null, 7, 13, function () {
        if (board.room.currentBackGroundPicture !== backGround2.src) {
          changeBackgroundPicture(backGround2);
        }
      });
      // PIC 5
      currentX += sizeBase * ratio + spaceBetween;
      ratio = (515 / 713);
      const objectOJ = new CanvasObject(currentX +  (sizeBase * ratio) / 2, 
        sizeBase + currentY, 
        sizeBase * ratio,
        spaceBetween, textBoxFun, board.canvas0, objectType);
      objectOJ.text = 'Jigsaw Puzzle';
      objectOJ.uuid = createUUID();
      objectOJ.level = 7;
      objectOJ.dat = {
        fontType: 'px Arial',
        fontSize: 25,
      };
      canvasObjects[objectOJ.uuid] = objectOJ;
      setUpPicture(puzzle6, currentX, currentY, sizeBase * ratio, null, 7);
      // PIC 6
      currentX += sizeBase * ratio + spaceBetween;
      ratio = (484 / 713);
      const objectAI = new CanvasObject(currentX +  (sizeBase * ratio) / 2, 
        sizeBase + currentY, 
        sizeBase * ratio,
        spaceBetween, textBoxFun, board.canvas0, objectType);
      objectAI.text = 'AI';
      objectAI.uuid = createUUID();
      objectAI.level = 7;
      objectAI.dat = {
        fontType: 'px Arial',
        fontSize: 25,
      };
      canvasObjects[objectAI.uuid] = objectAI;
      setUpPicture(AI, currentX, currentY, sizeBase * ratio, null, 7);
      setUpPicture(picBack, -103, -3, 100, null, 7, 6, function () {
        if (board.room.currentBackGroundPicture !== backGround1.src) {
          changeBackgroundPicture(backGround1);
        }
      });
      setUpPicture(picMore, 1403, -3, 100, null, 7, 8, function () {
        if (board.room.currentBackGroundPicture !== backGround1.src) {
          changeBackgroundPicture(backGround1);
        }
      });
      // PAGE 3
      currentY = yNonWalk + 100;
      currentX = xNonWalk + 50;
      // PIC 7
      ratio = (851 / 583);
      const objectDiverCity = new CanvasObject(currentX +  (sizeBase * ratio) / 2, 
        sizeBase + currentY, 
        sizeBase * ratio,
        spaceBetween, textBoxFun, board.canvas0, objectType);
      objectDiverCity.text = 'DiverCity';
      objectDiverCity.uuid = createUUID();
      objectDiverCity.level = 8;
      objectDiverCity.dat = {
        fontType: 'px Arial',
        fontSize: 25,
      };
      canvasObjects[objectDiverCity.uuid] = objectDiverCity;
      setUpPicture(diverCity, currentX, currentY, sizeBase * ratio, null, 8);
      currentX += sizeBase * ratio + spaceBetween;
      // PIC 8
      ratio = (423 / 713);
      const objectDragen = new CanvasObject(currentX +  (sizeBase * ratio) / 2, 
        sizeBase + currentY, 
        sizeBase * ratio,
        spaceBetween, textBoxFun, board.canvas0, objectType);
      objectDragen.text = 'Dragen';
      objectDragen.uuid = createUUID();
      objectDragen.level = 8;
      objectDragen.dat = {
        fontType: 'px Arial',
        fontSize: 25,
      };
      canvasObjects[objectDragen.uuid] = objectDragen;

      setUpPicture(dragen, currentX, currentY, sizeBase * ratio, null, 8);
      currentX += sizeBase * ratio + spaceBetween;
      // PIC 9
      ratio = (423 / 713);
      const objectFire = new CanvasObject(currentX +  (sizeBase * ratio) / 2, 
        sizeBase + currentY, 
        sizeBase * ratio,
        spaceBetween, textBoxFun, board.canvas0, objectType);
      objectFire.text = 'Fire';
      objectFire.uuid = createUUID();
      objectFire.level = 8;
      objectFire.dat = {
        fontType: 'px Arial',
        fontSize: 25,
      };
      canvasObjects[objectFire.uuid] = objectFire;
      setUpPicture(fire, currentX, currentY, sizeBase * (473 / 713), null, 8);
      setUpPicture(picBack, -103, -3, 100, null, 8, 7, function () {
        if (board.room.currentBackGroundPicture !== backGround1.src) {
          changeBackgroundPicture(backGround1);
        }
      });
      setUpPicture(picMore, 1403, -3, 100, null, 8, 9, function () {
        if (board.room.currentBackGroundPicture !== backGround1.src) {
          changeBackgroundPicture(backGround1);
        }
      });
      // PAGE 4
      currentY = yNonWalk + 100;
      currentX = xNonWalk + 50;
      ratio = (632 / 640);
      const objectfLOVEr = new CanvasObject(currentX +  (sizeBase * ratio) / 2, 
        sizeBase + currentY, 
        sizeBase * ratio,
        spaceBetween, textBoxFun, board.canvas0, objectType);
      objectfLOVEr.text = 'fLOVEr';
      objectfLOVEr.uuid = createUUID();
      objectfLOVEr.level = 9;
      objectfLOVEr.dat = {
        fontType: 'px Arial',
        fontSize: 25,
      };
      canvasObjects[objectfLOVEr.uuid] = objectfLOVEr;
      setUpPicture(fLOVEr, currentX, currentY, sizeBase * ratio, null, 9);
      currentX += sizeBase * ratio + spaceBetween;
      ratio = (507 / 713);
      const objectsugerTown = new CanvasObject(currentX +  (sizeBase * ratio) / 2, 
        sizeBase + currentY, 
        sizeBase * ratio,
        spaceBetween, textBoxFun, board.canvas0, objectType);
      objectsugerTown.text = 'SugerTown';
      objectsugerTown.uuid = createUUID();
      objectsugerTown.level = 9;
      objectsugerTown.dat = {
        fontType: 'px Arial',
        fontSize: 25,
      };
      canvasObjects[objectsugerTown.uuid] = objectsugerTown;
      setUpPicture(sugerTown, currentX, currentY, sizeBase * ratio, null, 9);
      currentX += sizeBase * ratio + spaceBetween;
      ratio = (716 / 712);
      const objectUtopia = new CanvasObject(currentX +  (sizeBase * ratio) / 2, 
        sizeBase + currentY, 
        sizeBase * ratio,
        spaceBetween, textBoxFun, board.canvas0, objectType);
      objectUtopia.text = 'Utopia';
      objectUtopia.uuid = createUUID();
      objectUtopia.level = 9;
      objectUtopia.dat = {
        fontType: 'px Arial',
        fontSize: 25,
      };
      canvasObjects[objectUtopia.uuid] = objectUtopia;
      setUpPicture(utopia, currentX, currentY, sizeBase * ratio, null, 9);
      currentX += sizeBase * ratio + spaceBetween;
      setUpPicture(picBack, -103, -3, 100, null, 9, 8, function () {
        if (board.room.currentBackGroundPicture !== backGround1.src) {
          changeBackgroundPicture(backGround1);
        }
      });
      // collection 1014 days
      const objectText1014 = new CanvasObject(1550, 400, 250,
        250, textBoxFun, board.canvas0, objectType);
      objectText1014.text = '1014 days is a series of drawing based on sketches from my 1014 days long stay in Japan. The drawings portrays the everyday life of a foreigner living in a unfamiliar and unique culture. Some common themes is alienation, the brutal Japanese working culture and the beauty of Japan.';
      objectText1014.uuid = createUUID();
      // set up children for lecture 1
      objectText1014.level = 10; // entry level might want to formalize this in the class
      objectText1014.dat = {
        fontType: 'px Arial',
        fontSize: 25,
      };
      canvasObjects[objectText1014.uuid] = objectText1014;

      currentX = xNonWalk + 60;
      currentY = yNonWalk + 100;
      ratio =  (703 / 713);
      const objectDay0 = new CanvasObject(currentX +  (sizeBase * ratio) / 2, 
        sizeBase + currentY, 
        sizeBase * ratio,
        spaceBetween, textBoxFun, board.canvas0, objectType);
      objectDay0.text = 'Day 0';
      objectDay0.uuid = createUUID();
      objectDay0.level = 10;
      objectDay0.dat = {
        fontType: 'px Arial',
        fontSize: 25,
      };
      canvasObjects[objectDay0.uuid] = objectDay0;
      setUpPicture(day0, currentX, currentY, sizeBase * ratio, null, 10, 15, function () {
        if (board.room.currentBackGroundPicture !== backGround3.src) {
          changeBackgroundPicture(backGround3);
        }
      });
      currentX += sizeBase * ratio + spaceBetween;
      ratio = (365 / 657);
      const objectDay47N2 = new CanvasObject(currentX +  (sizeBase * ratio) / 2, 
        sizeBase + currentY, 
        sizeBase * ratio,
        spaceBetween, textBoxFun, board.canvas0, objectType);
      objectDay47N2.text = 'Day 47';
      objectDay47N2.uuid = createUUID();
      objectDay47N2.level = 10;
      objectDay47N2.dat = {
        fontType: 'px Arial',
        fontSize: 25,
      };
      canvasObjects[objectDay47N2.uuid] = objectDay47N2;
      setUpPicture(picDay47, currentX, currentY, sizeBase * ratio, null, 10);
      currentX += sizeBase * ratio + spaceBetween;
      ratio = (709 / 713);
      const objectDay53 = new CanvasObject(currentX +  (sizeBase * ratio) / 2, 
        sizeBase + currentY, 
        sizeBase * ratio,
        spaceBetween, textBoxFun, board.canvas0, objectType);
      objectDay53.text = 'Day 53';
      objectDay53.uuid = createUUID();
      objectDay53.level = 10;
      objectDay53.dat = {
        fontType: 'px Arial',
        fontSize: 25,
      };
      canvasObjects[objectDay53.uuid] = objectDay53;
      setUpPicture(day53, currentX, currentY, sizeBase * ratio, null, 10);

      setUpPicture(picBack, -103, -3, 100, null, 10, 6, function () {
        if (board.room.currentBackGroundPicture !== backGround1.src) {
          changeBackgroundPicture(backGround1);
        }
      });
      // Dagringer
      const objectTextDagringer = new CanvasObject(1550, 400, 250,
        250, textBoxFun, board.canvas0, objectType);
      objectTextDagringer.text = 'In sorrow after suddenly being parted from my girlfriend due to the pandemic, I started a project where I drew a full round of circles every day we were apart. Just as the pain from being away from each other grows over time, the time and effort put into the drawing grows day by day. Little did I know that it would be 725 days and about 1,8 millions circles drawn before we met again.';
      objectTextDagringer.uuid = createUUID();
      // set up children for lecture 1
      objectTextDagringer.level = 11; // entry level might want to formalize this in the class
      objectTextDagringer.dat = {
        fontType: 'px Arial',
        fontSize: 25,
      };
      canvasObjects[objectTextDagringer.uuid] = objectTextDagringer;
      sizeBase = 300; // 250 is baseline
      setUpPicture(picBack, -103, -3, 100, null, 11, 6, function () {
        if (board.room.currentBackGroundPicture !== backGround1.src) {
          changeBackgroundPicture(backGround1);
        }
      });
      currentX = xNonWalk + 120;
      currentY = yNonWalk + 100;
      ratio = 1;
      const objectDagringerArk1 = new CanvasObject(currentX +  (sizeBase * ratio) / 2, 
        sizeBase + currentY, 
        sizeBase * ratio,
        spaceBetween, textBoxFun, board.canvas0, objectType);
      objectDagringerArk1.text = 'Dagringer Ark 1';
      objectDagringerArk1.uuid = createUUID();
      objectDagringerArk1.level = 11;
      objectDagringerArk1.dat = {
        fontType: 'px Arial',
        fontSize: 25,
      };
      canvasObjects[objectDagringerArk1.uuid] = objectDagringerArk1;
      setUpPicture(dagringer1, currentX, currentY, sizeBase, null, 11);
      currentX += sizeBase * ratio + spaceBetween;
      ratio = 1;
      const objectDagringerArk2 = new CanvasObject(currentX +  (sizeBase * ratio) / 2, 
        sizeBase + currentY, 
        sizeBase * ratio,
        spaceBetween, textBoxFun, board.canvas0, objectType);
      objectDagringerArk2.text = 'Dagringer Ark 2';
      objectDagringerArk2.uuid = createUUID();
      objectDagringerArk2.level = 11;
      objectDagringerArk2.dat = {
        fontType: 'px Arial',
        fontSize: 25,
      };
      canvasObjects[objectDagringerArk2.uuid] = objectDagringerArk2;
      setUpPicture(dagringer2, currentX, currentY, sizeBase, null, 11);
      currentX += sizeBase * ratio + spaceBetween;
      
      // Origami
      const objectTextOrigami = new CanvasObject(1550, 500, 250,
        250, textBoxFun, board.canvas0, objectType);
      objectTextOrigami.text = 'Through my relationship with Chihiro I have been introduced to the unique and deeply inspiring Japanese culture. With my background as an architect, working with space and in three dimensions, I immediately got fascinated by origami; The art of making three dimensional shapes from a flat piece of paper. Since this discovery I have from time to time worked with how two dimensional drawings can get a new meaning and dimension through folding.';
      objectTextOrigami.uuid = createUUID();
      // set up children for lecture 1
      objectTextOrigami.level = 12; // entry level might want to formalize this in the class
      objectTextOrigami.dat = {
        fontType: 'px Arial',
        fontSize: 25,
      };
      canvasObjects[objectTextOrigami.uuid] = objectTextOrigami;
      setUpPicture(picBack, -103, -3, 100, null, 12, 6, function () {
        if (board.room.currentBackGroundPicture !== backGround1.src) {
          changeBackgroundPicture(backGround1);
        }
      });
      sizeBase = 240; 
      currentX = xNonWalk + 10;
      currentY = yNonWalk + 100;
      ratio = (698 / 493);
      setUpPicture(vvvv, currentX, currentY, sizeBase * ratio, null, 12);
      currentX += sizeBase * ratio + spaceBetween;
      ratio = (548 / 713);
      setUpPicture(fiskebrett, currentX, currentY, sizeBase * ratio, null, 12);
      currentX += sizeBase * ratio + spaceBetween;
      ratio = 1;
      setUpPicture(wrapCity, currentX, currentY, sizeBase, null, 12);
      // 3D
      setUpPicture(picBack, -103, -3, 100, null, 13, 6, function () {
        if (board.room.currentBackGroundPicture !== backGround1.src) {
          changeBackgroundPicture(backGround1);
        }
      });
      currentX = xNonWalk + 80;
      currentY = yNonWalk + 100;
      ratio = (534 / 713);
      const objectOI2 = new CanvasObject(currentX +  (sizeBase * ratio) / 2, 
        sizeBase + currentY, 
        sizeBase * ratio,
        spaceBetween, textBoxFun, board.canvas0, objectType);
      objectOI2.text = 'OI';
      objectOI2.uuid = createUUID();
      objectOI2.level = 13;
      objectOI2.dat = {
        fontType: 'px Arial',
        fontSize: 25,
      };
      canvasObjects[objectOI2.uuid] = objectOI2;
      setUpPicture(tredimOi2, currentX, currentY, sizeBase * ratio, null, 13);
      currentX += sizeBase * ratio + spaceBetween;
      ratio = (534 / 713);
      const objectRingo2 = new CanvasObject(currentX +  (sizeBase * ratio) / 2, 
        sizeBase + currentY, 
        sizeBase * ratio,
        spaceBetween, textBoxFun, board.canvas0, objectType);
      objectRingo2.text = 'Ringo';
      objectRingo2.uuid = createUUID();
      objectRingo2.level = 13;
      objectRingo2.dat = {
        fontType: 'px Arial',
        fontSize: 25,
      };
      canvasObjects[objectRingo2.uuid] = objectRingo2;
      setUpPicture(ringo2, currentX, currentY, sizeBase * ratio, null, 13);
      currentX += sizeBase * ratio + spaceBetween;
      ratio = (723 / 673);
      const objectMonsterBy = new CanvasObject(currentX +  (sizeBase * ratio) / 2, 
        sizeBase + currentY, 
        sizeBase * ratio,
        spaceBetween, textBoxFun, board.canvas0, objectType);
      objectMonsterBy.text = 'Mønsterby';
      objectMonsterBy.uuid = createUUID();
      objectMonsterBy.level = 13;
      objectMonsterBy.dat = {
        fontType: 'px Arial',
        fontSize: 25,
      };
      canvasObjects[objectMonsterBy.uuid] = objectMonsterBy;
      setUpPicture(monsterBy, currentX, currentY, sizeBase * ratio, null, 13);
      currentX += sizeBase * ratio + spaceBetween;
      // ODA og JACOB
      // Day 0 DEEPEST LEVEL
      setUpPicture(picBack, -103, -3, 100, null, 15, 10, function () {
        if (board.room.currentBackGroundPicture !== backGround2.src) {
          changeBackgroundPicture(backGround2);
        }
      });
      sizeBase = 400; 
      currentX = xNonWalk + 200;
      currentY = yNonWalk;
      setUpPicture(day0, currentX, currentY, sizeBase * ratio, null, 15);
      updateRoom();
    }
  }

  // function that setup the levels
  function isImage(i) {
    return i instanceof HTMLImageElement;
  }
  function updateRoom() {
    boardLeadingView();
    Object.values(canvasObjects).forEach((object) => {
      if (object.level === 0) {
        object.isActive = true;
      } else {
        object.isActive = false;
      }
      console.log('Here is object');
      console.log(object);
      if (object.name === 'hostArea') {
        window.mingly.log('hostlevel');
      } else if (!object.level.length) {
        console.log('not length');
        if (object.level === objectControlling.currentLevel) {
          console.log('Should now be true');
          object.isActive = true; 
          setTimeout(() => { 
            board.updateAll();
          }, 50);  
        } 
      } else {
        for (let k = 0; k < object.level.length; k++) {
          if (object.level[k] === objectControlling.currentLevel) {
            object.isActive = true; 
            setTimeout(() => { 
              board.updateAll();
            }, 50);  
          } 
        }
      }
    });
    // setting the correct background pictures 
    // THIS IS A NASTY NESTED IF. SHOULD CHANGE IT
    if (objectControlling.currentLevel < 6) {
      if (board.room.currentBackGroundPicture !== backGround0.src) {
        changeBackgroundPicture(backGround0);
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (objectControlling.currentLevel < 10) {
        // eslint-disable-next-line no-lonely-if
        if (board.room.currentBackGroundPicture !== backGround1.src) {
          changeBackgroundPicture(backGround1);
        }
      } else {
        // eslint-disable-next-line no-lonely-if
        if (objectControlling.currentLevel < 15) {
          if (board.room.currentBackGroundPicture !== backGround2.src) {
            changeBackgroundPicture(backGround2);
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (board.room.currentBackGroundPicture !== backGround3.src) {
            changeBackgroundPicture(backGround3);
          }
        }
      }
    }
  }
  // static object for all types
  // boarder
  const boarderOfRoom = new CanvasObject(-1, -1, 1402,
    802, boarderFunBlack, board.canvas0, objectType);
  boarderOfRoom.uuid = createUUID();
  boarderOfRoom.isActive = true;
  boarderOfRoom.level = 0;
  canvasObjects[boarderOfRoom.uuid] = boarderOfRoom;
  // level one 
  const boarderOfRoom2 = new CanvasObject(-1, -1, 1402,
    802, boarderFunWhite, board.canvas0, objectType);
  boarderOfRoom2.uuid = createUUID();
  boarderOfRoom2.isActive = true;
  boarderOfRoom2.level = [1, 2, 3, 4, 5];
  canvasObjects[boarderOfRoom2.uuid] = boarderOfRoom2;
  // non-walkable area
  const xNonWalk = (board.width * 7.7) / 40;
  const yNonWalk = (board.height  * 3) / 22.9;
  const widthNonWalk  = (board.width * 24.5) / 40;
  const heightNonWalk = (board.height * 15.1) / 22.9;
  const noWalkArea = new CanvasObject(xNonWalk, yNonWalk, widthNonWalk,
    heightNonWalk, emptyFunc, board.canvas0, objectType);
  noWalkArea.uuid = createUUID();
  noWalkArea.isActive = true;
  noWalkArea.isWalkable = false; 
  noWalkArea.level = 0;
  canvasObjects[noWalkArea.uuid] = noWalkArea;
  // set up a "waiting room" to load resources so it is smooth afterwards
  const objectWaitingRoom = new CanvasObject(580, 150, 250,
    250, waitingRoomFun, board.canvas0, objectType);
  objectWaitingRoom.text = 'Welcome to Haakon Heyerdahl-Larsen\'s Gallery';
  objectWaitingRoom.uuid = createUUID();
  objectWaitingRoom.level = -1;
  objectWaitingRoom.isActive = true;
  canvasObjects[objectWaitingRoom.uuid] = objectWaitingRoom;
  const objectWaitingRoom2 = new CanvasObject(580, 250, 250,
    250, waitingRoomFun, board.canvas0, objectType);
  objectWaitingRoom2.text = 'Please wait while we load the pictures...';
  objectWaitingRoom2.uuid = createUUID();
  objectWaitingRoom2.level = -1;
  objectWaitingRoom2.isActive = true;
  canvasObjects[objectWaitingRoom2.uuid] = objectWaitingRoom2;
  function waitingRoomFun(x, y, width, height, text, ctx) {
    ctx.font = '30px Courier New';
    ctx.textAlign = 'center';
    ctx.fillStyle = 'Black';
    ctx.fillText(text, x + 0.5 * width, y + 0.5 * height);
  }

  // hover boxes

  const hoverArea1 = new CanvasObject(xNonWalk + 0.55 * widthNonWalk,
    yNonWalk + 0.55 * heightNonWalk, 0.45 * widthNonWalk,
    0.45 * heightNonWalk, emptyFunc, board.canvas0, objectType);
  hoverArea1.uuid = createUUID();
  hoverArea1.isActive = false;
  hoverArea1.isWalkable = false; 
  hoverArea1.level = [1, 2];
  hoverArea1.onHover = {
    hover: true,
    fun: hoverPic1,
    nofun: nohoverPic1,
  };
  const hoverArea2 = new CanvasObject(xNonWalk,
    yNonWalk, 0.45 * widthNonWalk,
    0.45 * heightNonWalk, emptyFunc, board.canvas0, objectType);
  hoverArea2.uuid = createUUID();
  hoverArea2.isActive = false;
  hoverArea2.isWalkable = false; 
  hoverArea2.level = [1, 3];
  hoverArea2.onHover = {
    hover: true,
    fun: hoverPic2,
    nofun: nohoverPic2,
  };
  const hoverArea3 = new CanvasObject(xNonWalk + 0.55 * widthNonWalk,
    yNonWalk, 0.45 * widthNonWalk,
    0.45 * heightNonWalk, emptyFunc, board.canvas0, objectType);
  hoverArea3.uuid = createUUID();
  hoverArea3.isActive = false;
  hoverArea3.isWalkable = false; 
  hoverArea3.level = [1, 4];
  hoverArea3.onHover = {
    hover: true,
    fun: hoverPic3,
    nofun: nohoverPic3,
  };
  const hoverArea4 = new CanvasObject(xNonWalk,
    yNonWalk + 0.55 * heightNonWalk, 0.45 * widthNonWalk,
    0.45 * heightNonWalk, emptyFunc, board.canvas0, objectType);
  hoverArea4.uuid = createUUID();
  hoverArea4.isActive = false;
  hoverArea4.isWalkable = false; 
  hoverArea4.level = [1, 5];
  hoverArea4.onClick = function () {
    console.log('jihaaaa!');
    if (board.room.currentBackGroundPicture !== backGround1.src) {
      changeBackgroundPicture(backGround1);
    }
    // setting a slight delay so you don't double click to jump two levels
    setTimeout(function () {
      objectControlling.currentLevel = 6;
      updateRoom();
    }, 5);
  };
  hoverArea4.onHover = {
    hover: true,
    fun: hoverPic4,
    nofun: nohoverPic4,
  };
  function hoverPic1() {
    // console.log('I am hovering!');
    if (objectControlling.currentLevel !== 2) {
      objectControlling.currentLevel = 2;
      updateRoom();
      console.log('hover!!!!');
    }
  }
  function nohoverPic1() {
    // console.log('I am hovering!');
    if (objectControlling.currentLevel  !== 1) {
      objectControlling.currentLevel  = 1;
      updateRoom();
      console.log('nohover!!!!');
    }
  }
  function hoverPic2() {
    // console.log('I am hovering!');
    if (objectControlling.currentLevel  !== 3) {
      objectControlling.currentLevel  = 3;
      updateRoom();
      console.log('hover!!!!');
    }
  }
  function nohoverPic2() {
    // console.log('I am hovering!');
    if (objectControlling.currentLevel  !== 1) {
      objectControlling.currentLevel  = 1;
      updateRoom();
      console.log('nohover!!!!');
    }
  }
  function hoverPic3() {
    // console.log('I am hovering!');
    if (objectControlling.currentLevel  !== 4) {
      objectControlling.currentLevel  = 4;
      updateRoom();
      console.log('hover!!!!');
    }
  }
  function nohoverPic3() {
    // console.log('I am hovering!');
    if (objectControlling.currentLevel  !== 1) {
      objectControlling.currentLevel  = 1;
      updateRoom();
      console.log('nohover!!!!');
    }
  }
  function hoverPic4() {
    // console.log('I am hovering!');
    if (objectControlling.currentLevel  !== 5) {
      objectControlling.currentLevel  = 5;
      updateRoom();
      console.log('hover!!!!');
    }
  }
  function nohoverPic4() {
    // console.log('I am hovering!');
    if (objectControlling.currentLevel  !== 1) {
      objectControlling.currentLevel  = 1;
      updateRoom();
      console.log('nohover!!!!');
    }
  }
  canvasObjects[hoverArea1.uuid] = hoverArea1;
  canvasObjects[hoverArea2.uuid] = hoverArea2;
  canvasObjects[hoverArea3.uuid] = hoverArea3;
  canvasObjects[hoverArea4.uuid] = hoverArea4;
  // function hoverPic1() {
  //   if (curentLevel !== 2) {
  //     curentLevel = 2;
  //     console.log('hover!!!!');
  //     setTimeout(function () {
  //       curentLevel = 1;
  //     }, 400);
  //   }
  // }
  // const eventListenersPic0a = {
  //   hover: hoverPic1,
  // };
  
  // const pic1 = 'https://www.dropbox.com/s/qh9fvqwsfpgarzd/Day%2047.jpg?raw=1';
  // const w1 = 200;
  // setUpPicture(pic1, (1400 - w1) / 2, w1, w1);
  function setUpPicture(source, x, y, width, eventListeners, level, goToLevel, gotToLevelFun) {
    // isImage
    function runSetupImage(imgC) {
      const aspectRatio  =  imgC.width / imgC.height;
      const object = new CanvasObject(x, y, width,
        width / aspectRatio, imgC, board.canvas0, 'picture');
      object.uuid = createUUID();
      object.isActive = false;
      object.level = level;
      object.onClick = function () {
        // to avoid a double clicking that happens for some wierd reason
        setTimeout(function () {
          if (goToLevel) {
            objectControlling.currentLevel  = goToLevel;
          }
          try {
            gotToLevelFun();
          } catch {
            window.mingly.log('noevent');
          }
          updateRoom();
        }, 5);
      };
      try {
        object.onHover = eventListeners.hover;
      } catch {
        window.mingly.log('noevent');
      }
      canvasObjects[object.uuid] = object;
      // board.updateAll();
      updateRoom();
    }
    if (isImage(source)) {
      if (imgLoaded(source)) {
        runSetupImage(source); 
      } else {
        source.onload = () => {
          runSetupImage(source);
        };
      }
    } else {
      const imgC = new Image();
      imgC.src = source;
      imgC.onload = () => {
        runSetupImage(imgC);
      };
    }
    // updateRoom();
  }

  // const imgC = new Image();
  // imgC.src = 'https://www.dropbox.com/s/qh9fvqwsfpgarzd/Day%2047.jpg?raw=1';
  // imgC.onload = function () {
  //   const aspectRatio  =  imgC.width / imgC.height;
  //   const w = 200;
  //   const object3 = new CanvasObject((1400 - w) / 2, 200, w,
  //     w / aspectRatio, imgC, board.canvas0, 'picture');
  //   // object3.isWalkable = false;
  //   object3.uuid = createUUID();
  //   // set up children for lecture 1
  //   object3.isActive = true;
  //   canvasObjects[object3.uuid] = object3;
  //   board.updateAll();
  // };
  
  function emptyFunc(x, y, width, height, text, ctx) {
  }
  function boarderFunWhite(x, y, width, height, text, ctx) {
    ctx.lineWidth = '3';
    ctx.strokeStyle = 'white';
    ctx.beginPath();
    ctx.rect(x, y, width, height);
    ctx.stroke();
  }
  function boarderFunBlack(x, y, width, height, text, ctx) {
    ctx.lineWidth = '3';
    ctx.strokeStyle = 'black';
    ctx.beginPath();
    ctx.rect(x, y, width, height);
    ctx.stroke();
  }
  function imgLoaded(imgElement) {
    return imgElement.complete && imgElement.naturalHeight !== 0;
  }
  function textBoxFun(x, y, width, height, text, ctx, dat) {
    let fontSize;
    let fontType;
    if (dat === undefined || dat === null) {
      fontType = 'px Courier New';
      fontSize = 30;
    } else {
      fontType = dat.fontType;
      fontSize = dat.fontSize;
    }
    ctx.font = fontSize + fontType;
    ctx.textAlign = 'center';
    ctx.fillStyle = 'Black';
    let metrics = ctx.measureText(text);
    if (metrics.width <= width) {
      ctx.fillText(text, x, y + height - fontSize / 4);
      return;
    }
    const words = text.split(' ');
    let line = '';
    const lines = [];
    for (var n = 0; n < words.length; n++) {
      // eslint-disable-next-line prefer-template
      const testLine = line + words[n] + ' ';
      metrics = ctx.measureText(testLine);
      if (metrics.width > width && n > 0) {
        lines.push(line);
        // eslint-disable-next-line prefer-template
        line = words[n] + ' ';  // next line
      }
      else {
        line = testLine;
      }
    }
    lines.push(line);
    var line_y = y + height - fontSize / 4;
    for (let i = lines.length - 1; i >= 0; i--) {
      ctx.fillText(lines[i], x, line_y);
      line_y -= fontSize * 1.1;
    }
  }
 
}

function setUpGalleryChristian() {
  window.mingly.canvasObjects = canvasObjects;
  const { board } = window.mingly;
  console.log('test');
  function setUpObjects() {
    // entry room
    // here is the baseline
    const objectType = 'canvasDrawing';
    let object = new CanvasObject(150, 150, 200,
      200, myFunc, board.canvas0, objectType);
    object.text = 'Gallery';
    object.uuid = createUUID();
    // set up children for lecture 1
    object.level = 'first'; // entry level might want to formalize this in the class
    object.onClick = setupGalleryOverview;
    object.isActive = true;
    canvasObjects[object.uuid] = object;

    object = new CanvasObject(550, 550, 200,
      200, myFunc, board.canvas0, objectType);
    object.text = 'About';
    object.uuid = createUUID();
    // set up children for lecture 1
    object.level = 'first'; // entry level might want to formalize this in the class
    object.onClick = setupAbout;
    object.isActive = true;
    canvasObjects[object.uuid] = object;

    object = new CanvasObject(850, 250, 200,
      200, myFunc2, board.canvas0, objectType);
    object.text = 'About';
    object.uuid = createUUID();
    object.text = 'Real Random?';
    // set up children for lecture 1
    object.level = 'first'; // entry level might want to formalize this in the class
    object.isActive = true;
    canvasObjects[object.uuid] = object;
    
    const object2 = new CanvasObject(-200, 0, 200,
      200, myFunc, board.canvas0, objectType);
    object2.text = 'Back';
    object2.uuid = createUUID();
    // set up children for lecture 1
    object2.level = 'back'; // entry level might want to formalize this in the class
    object2.onClick = setUpEntryRoom;
    object2.isActive = false;
    canvasObjects[object2.uuid] = object2;

    const object2b = new CanvasObject(-200, 0, 200,
      200, myFunc, board.canvas0, objectType);
    object2b.text = 'Back';
    object2b.uuid = createUUID();
    // set up children for lecture 1
    object2b.level = 'back2'; // entry level might want to formalize this in the class
    object2b.onClick = setupGalleryOverview;
    object2b.isActive = false;
    canvasObjects[object2b.uuid] = object2b;

    const object4 = new CanvasObject(1550, 300, 250,
      250, myFunc3, board.canvas0, objectType);
    object4.text = 'Real Random is artwork based on randomness in financial data. Data is mapped into shapes and colors based on their values.';
    object4.uuid = createUUID();
    // set up children for lecture 1
    object4.level = 'third'; // entry level might want to formalize this in the class
    object4.onClick = setUpEntryRoom;
    object4.isActive = false;
    canvasObjects[object4.uuid] = object4;

    const object5 = new CanvasObject(1550, 400, 250,
      250, myFunc3, board.canvas0, objectType);
    object5.text = 'The picture is generated using bitcoin price data from 2020. Each pixel represents a minute in 2020. The bitcoin prices are mapped into color codes. Brighter colors are higher prices.';
    object5.uuid = createUUID();
    // set up children for lecture 1
    object5.level = 'pic1'; // entry level might want to formalize this in the class
    object5.isActive = false;
    canvasObjects[object5.uuid] = object5;

    const object6 = new CanvasObject(580, -150, 250,
      250, myFunc, board.canvas0, objectType);
    object6.text = 'Bitcoin - sunrise or sunset?';
    object6.uuid = createUUID();
    // set up children for lecture 1
    object6.level = 'pic1'; // entry level might want to formalize this in the class
    object6.isActive = false;
    canvasObjects[object6.uuid] = object6;

    const object7 = new CanvasObject(1550, 400, 250,
      250, myFunc3, board.canvas0, objectType);
    object7.text = 'The picture is generated using bitcoin price data from 2019. Each pixel represents a minute in 2019. The bitcoin prices are mapped into color codes. Brighter colors are higher prices.';
    object7.uuid = createUUID();
    // set up children for lecture 1
    object7.level = 'pic2'; // entry level might want to formalize this in the class
    object7.isActive = false;
    canvasObjects[object7.uuid] = object7;

    const object8 = new CanvasObject(580, -150, 250,
      250, myFunc, board.canvas0, objectType);
    object8.text = 'Bitcoin - a cloudy morning?';
    object8.uuid = createUUID();
    // set up children for lecture 1
    object8.level = 'pic2'; // entry level might want to formalize this in the class
    object8.isActive = false;
    canvasObjects[object8.uuid] = object8;

    const object9 = new CanvasObject(1550, 350, 250,
      250, myFunc3, board.canvas0, objectType);
    object9.text = 'Created from daily data on 49 industry portfolios in the US from 1969-2021. Brighter colors are higher returns.';
    object9.uuid = createUUID();
    // set up children for lecture 1
    object9.level = 'pic3'; // entry level might want to formalize this in the class
    object9.isActive = false;
    canvasObjects[object9.uuid] = object9;

    const object10 = new CanvasObject(580, -150, 250,
      250, myFunc, board.canvas0, objectType);
    object10.text = 'Can you spot the (børs)Crack?';
    object10.uuid = createUUID();
    // set up children for lecture 1
    object10.level = 'pic3'; // entry level might want to formalize this in the class
    object10.isActive = false;
    canvasObjects[object10.uuid] = object10;

    const object11 = new CanvasObject(1550, 250, 250,
      250, myFunc3, board.canvas0, objectType);
    object11.text = 'Created using the 25 book-to-market and size portfolios from 1926-2021.';
    object11.uuid = createUUID();
    // set up children for lecture 1
    object11.level = 'pic4'; // entry level might want to formalize this in the class
    object11.isActive = false;
    canvasObjects[object11.uuid] = object11;

    const object12 = new CanvasObject(580, -150, 250,
      250, myFunc, board.canvas0, objectType);
    object12.text = 'Value or Size? (green)';
    object12.uuid = createUUID();
    // set up children for lecture 1
    object12.level = 'pic4'; // entry level might want to formalize this in the class
    object12.isActive = false;
    canvasObjects[object12.uuid] = object12;

    const object13 = new CanvasObject(1550, 250, 250,
      250, myFunc3, board.canvas0, objectType);
    object13.text = 'Created using the daily log price level of the 49 industry portfolios from 1969-2021.';
    object13.uuid = createUUID();
    // set up children for lecture 1
    object13.level = 'pic5'; // entry level might want to formalize this in the class
    object13.isActive = false;
    canvasObjects[object13.uuid] = object13;

    const object14 = new CanvasObject(580, -150, 250,
      250, myFunc, board.canvas0, objectType);
    object14.text = 'Wealth';
    object14.uuid = createUUID();
    // set up children for lecture 1
    object14.level = 'pic5'; // entry level might want to formalize this in the class
    object14.isActive = false;
    canvasObjects[object14.uuid] = object14;

    const object15 = new CanvasObject(1550, 250, 250,
      250, myFunc3, board.canvas0, objectType);
    object15.text = 'Created using interest rate data from 1961-2016. Yields are mapped into colors. Darker colors are lower yields.';
    object15.uuid = createUUID();
    // set up children for lecture 1
    object15.level = 'pic6'; // entry level might want to formalize this in the class
    object15.isActive = false;
    canvasObjects[object15.uuid] = object15;

    const object16 = new CanvasObject(580, -150, 250,
      250, myFunc, board.canvas0, objectType);
    object16.text = 'Close to the sun';
    object16.uuid = createUUID();
    // set up children for lecture 1
    object16.level = 'pic6'; // entry level might want to formalize this in the class
    object16.isActive = false;
    canvasObjects[object16.uuid] = object16;

    const object17 = new CanvasObject(1550, 250, 250,
      250, myFunc3, board.canvas0, objectType);
    object17.text = 'Created using the 25 book-to-market and size portfolios from 1926-2021.';
    object17.uuid = createUUID();
    // set up children for lecture 1
    object17.level = 'pic7'; // entry level might want to formalize this in the class
    object17.isActive = false;
    canvasObjects[object17.uuid] = object17;

    const object18 = new CanvasObject(580, -150, 250,
      250, myFunc, board.canvas0, objectType);
    object18.text = 'Value or Size?';
    object18.uuid = createUUID();
    // set up children for lecture 1
    object18.level = 'pic7'; // entry level might want to formalize this in the class
    object18.isActive = false;
    canvasObjects[object18.uuid] = object18;

    const object19 = new CanvasObject(1550, 400, 250,
      250, myFunc3, board.canvas0, objectType);
    object19.text = 'The picture is generated using bitcoin price data from 2016. Each pixel represents a minute in 2016. The bitcoin prices are mapped into color codes. Brighter colors are higher prices.';
    object19.uuid = createUUID();
    // set up children for lecture 1
    object19.level = 'pic8'; // entry level might want to formalize this in the class
    object19.isActive = false;
    canvasObjects[object19.uuid] = object19;

    const object20 = new CanvasObject(580, -150, 250,
      250, myFunc, board.canvas0, objectType);
    object20.text = 'Bitcoin 2016';
    object20.uuid = createUUID();
    // set up children for lecture 1
    object20.level = 'pic8'; // entry level might want to formalize this in the class
    object20.isActive = false;
    canvasObjects[object20.uuid] = object20;

    const object21 = new CanvasObject(1550, 400, 250,
      250, myFunc3, board.canvas0, objectType);
    object21.text = 'The picture is generated using bitcoin price data from 2018. Each pixel represents a minute in 2018. The bitcoin prices are mapped into color codes. Brighter colors are higher prices.';
    object21.uuid = createUUID();
    // set up children for lecture 1
    object21.level = 'pic9'; // entry level might want to formalize this in the class
    object21.isActive = false;
    canvasObjects[object21.uuid] = object21;

    const object22 = new CanvasObject(580, -150, 250,
      250, myFunc, board.canvas0, objectType);
    object22.text = 'Bitcoin 2018';
    object22.uuid = createUUID();
    // set up children for lecture 1
    object22.level = 'pic9'; // entry level might want to formalize this in the class
    object22.isActive = false;
    canvasObjects[object22.uuid] = object22;

    const imgC0 = new Image();
    imgC0.src = 'https://www.dropbox.com/s/2lea1nkcrxkz6n7/IconNoBackground.png?raw=1';
    imgC0.onload = function () {
      const aspectRatio  =  imgC0.width / imgC0.height;
      const object3 = new CanvasObject(525, 150, 250,
        250 / aspectRatio, imgC0, board.canvas0, 'picture');
      object3.uuid = createUUID();
      // set up children for lecture 1
      object3.level = 'icon'; // entry level might want to formalize this in the class
      object3.isActive = true;
      canvasObjects[object3.uuid] = object3;
      board.updateAll();
    };

    const imgC = new Image();
    imgC.src = 'https://www.dropbox.com/s/lz2e0i5wu5srlc8/2020BitCoinV2.png?raw=1';
    imgC.onload = function () {
      const aspectRatio  =  imgC.width / imgC.height;
      const object3 = new CanvasObject(200, 150, 100,
        100 / aspectRatio, imgC, board.canvas0, 'picture');
      object3.uuid = createUUID();
      // set up children for lecture 1
      object3.level = 'second'; // entry level might want to formalize this in the class
      object3.onClick = setUpPic1;
      object3.isActive = false;
      canvasObjects[object3.uuid] = object3;
      board.updateAll();
    };

    const imgC2 = new Image();
    imgC2.src = 'https://www.dropbox.com/s/g0acdhtn4y5hdu1/2019BitCoinV1.png?raw=1';
    imgC2.onload = function () {
      const aspectRatio  =  imgC2.width / imgC2.height;
      const object3 = new CanvasObject(350, 150, 100,
        100 / aspectRatio, imgC2, board.canvas0, 'picture');
      object3.uuid = createUUID();
      // set up children for lecture 1
      object3.level = 'second'; // entry level might want to formalize this in the class
      object3.isActive = false;
      object3.onClick = setUpPic2;
      canvasObjects[object3.uuid] = object3;
      board.updateAll();
    };

    const imgC3 = new Image();
    imgC3.src = 'https://www.dropbox.com/s/ys2rzz1lfuww3ya/49IndustriesRetV4.png?raw=1';
    imgC3.onload = function () {
      const aspectRatio  =  imgC3.width / imgC3.height;
      const object3 = new CanvasObject(500, 150, 200,
        200 / aspectRatio, imgC3, board.canvas0, 'picture');
      object3.uuid = createUUID();
      // set up children for lecture 1
      object3.level = 'second'; // entry level might want to formalize this in the class
      object3.isActive = false;
      object3.onClick = setUpPic3;
      canvasObjects[object3.uuid] = object3;
      board.updateAll();
    };
    const imgC4 = new Image();
    imgC4.src = 'https://www.dropbox.com/s/xvfwe08hzt36m6b/FFmonthlyV1NoGauss.png?raw=1';
    imgC4.onload = function () {
      const aspectRatio  =  imgC4.width / imgC4.height;
      const object3 = new CanvasObject(750, 150, 200,
        200 / aspectRatio, imgC4, board.canvas0, 'picture');
      object3.uuid = createUUID();
      // set up children for lecture 1
      object3.level = 'second'; // entry level might want to formalize this in the class
      object3.isActive = false;
      object3.onClick = setUpPic4;
      canvasObjects[object3.uuid] = object3;
      board.updateAll();
    };
    const imgC5 = new Image();
    imgC5.src = 'https://www.dropbox.com/s/dsdftky0n6ep419/Icon2NoBackground.png?raw=1';
    imgC5.onload = function () {
      const aspectRatio  =  imgC5.width / imgC5.height;
      const object3 = new CanvasObject(350, 150, 600,
        600 / aspectRatio, imgC5, board.canvas0, 'picture');
      object3.uuid = createUUID();
      // set up children for lecture 1
      object3.level = 'icon2'; // entry level might want to formalize this in the class
      object3.isActive = false;
      canvasObjects[object3.uuid] = object3;
      board.updateAll();
    };
    const imgC6 = new Image();
    imgC6.src = 'https://www.dropbox.com/s/fo6p9epv4yzpjc3/49IndustriesLog.png?raw=1';
    imgC6.onload = function () {
      const aspectRatio  =  imgC6.width / imgC6.height;
      const object3 = new CanvasObject(1000, 150, 200,
        200 / aspectRatio, imgC6, board.canvas0, 'picture');
      object3.uuid = createUUID();
      // set up children for lecture 1
      object3.level = 'second'; // entry level might want to formalize this in the class
      object3.isActive = false;
      object3.onClick = setUpPic5;
      canvasObjects[object3.uuid] = object3;
      board.updateAll();
    };

    const imgC7 = new Image();
    imgC7.src = 'https://www.dropbox.com/s/54xi376kd3nrlc0/YieldV2.png?raw=1';
    imgC7.onload = function () {
      const aspectRatio  =  imgC7.width / imgC7.height;
      const object3 = new CanvasObject(200, 450, 200,
        200 / aspectRatio, imgC7, board.canvas0, 'picture');
      object3.uuid = createUUID();
      // set up children for lecture 1
      object3.level = 'second'; // entry level might want to formalize this in the class
      object3.isActive = false;
      object3.onClick = setUpPic6;
      canvasObjects[object3.uuid] = object3;
      board.updateAll();
    };

    const imgC8 = new Image();
    imgC8.src = 'https://www.dropbox.com/s/jpvo3kb25vezke0/FFmonthlyV1Gauss.png?raw=1';
    imgC8.onload = function () {
      const aspectRatio  =  imgC8.width / imgC8.height;
      const object3 = new CanvasObject(500, 450, 200,
        200 / aspectRatio, imgC8, board.canvas0, 'picture');
      object3.uuid = createUUID();
      // set up children for lecture 1
      object3.level = 'second'; // entry level might want to formalize this in the class
      object3.isActive = false;
      object3.onClick = setUpPic7;
      canvasObjects[object3.uuid] = object3;
      board.updateAll();
    };

    const imgC9 = new Image();
    imgC9.src = 'https://www.dropbox.com/s/9nvorgxwvv0ixrm/2016BitCoinV1.png?raw=1';
    imgC9.onload = function () {
      const aspectRatio  =  imgC9.width / imgC9.height;
      const object3 = new CanvasObject(800, 450, 100,
        100 / aspectRatio, imgC9, board.canvas0, 'picture');
      object3.uuid = createUUID();
      // set up children for lecture 1
      object3.level = 'second'; // entry level might want to formalize this in the class
      object3.isActive = false;
      object3.onClick = setUpPic8;
      canvasObjects[object3.uuid] = object3;
      board.updateAll();
    };

    const imgC10 = new Image();
    imgC10.src = 'https://www.dropbox.com/s/q0iin7ylodrsmlw/2018BitCoinV1.png?raw=1';
    imgC10.onload = function () {
      const aspectRatio  =  imgC10.width / imgC10.height;
      const object3 = new CanvasObject(1000, 450, 100,
        100 / aspectRatio, imgC10, board.canvas0, 'picture');
      object3.uuid = createUUID();
      // set up children for lecture 1
      object3.level = 'second'; // entry level might want to formalize this in the class
      object3.isActive = false;
      object3.onClick = setUpPic9;
      canvasObjects[object3.uuid] = object3;
      board.updateAll();
    };

    const imgCB = new Image();
    imgCB.src = 'https://www.dropbox.com/s/lz2e0i5wu5srlc8/2020BitCoinV2.png?raw=1';
    imgCB.onload = function () {
      const aspectRatio  =  imgCB.width / imgCB.height;
      const object3 = new CanvasObject((1400 - 175) / 2, 125, 175,
        175 / aspectRatio, imgCB, board.canvas0, 'picture');
      object3.uuid = createUUID();
      // set up children for lecture 1
      object3.level = 'pic1'; // entry level might want to formalize this in the class
      object3.isActive = false;
      canvasObjects[object3.uuid] = object3;
      board.updateAll();
    };

    const imgC2B = new Image();
    imgC2B.src = 'https://www.dropbox.com/s/g0acdhtn4y5hdu1/2019BitCoinV1.png?raw=1';
    imgC2B.onload = function () {
      const aspectRatio  =  imgC2B.width / imgC2B.height;
      const object3 = new CanvasObject((1400 - 225) / 2, 125, 225,
        225 / aspectRatio, imgC2B, board.canvas0, 'picture');
      object3.uuid = createUUID();
      // set up children for lecture 1
      object3.level = 'pic2'; // entry level might want to formalize this in the class
      object3.isActive = false;
      canvasObjects[object3.uuid] = object3;
      board.updateAll();
    };

    const imgC3B = new Image();
    imgC3B.src = 'https://www.dropbox.com/s/ys2rzz1lfuww3ya/49IndustriesRetV4.png?raw=1';
    imgC3B.onload = function () {
      const aspectRatio  =  imgC3B.width / imgC3B.height;
      const w = 330;
      const object3 = new CanvasObject((1400 - w) / 2, 125, w,
        w / aspectRatio, imgC3B, board.canvas0, 'picture');
      object3.uuid = createUUID();
      // set up children for lecture 1
      object3.level = 'pic3'; // entry level might want to formalize this in the class
      object3.isActive = false;
      canvasObjects[object3.uuid] = object3;
      board.updateAll();
    };

    const imgC4B = new Image();
    imgC4B.src = 'https://www.dropbox.com/s/xvfwe08hzt36m6b/FFmonthlyV1NoGauss.png?raw=1';
    imgC4B.onload = function () {
      const aspectRatio  =  imgC4.width / imgC4.height;
      const w = 330;
      const object3 = new CanvasObject((1400 - w) / 2, 125, w,
        w / aspectRatio, imgC4B, board.canvas0, 'picture');
      object3.uuid = createUUID();
      // set up children for lecture 1
      object3.level = 'pic4'; // entry level might want to formalize this in the class
      object3.isActive = false;
      canvasObjects[object3.uuid] = object3;
      board.updateAll();
    };
    const imgC6B = new Image();
    imgC6B.src = 'https://www.dropbox.com/s/fo6p9epv4yzpjc3/49IndustriesLog.png?raw=1';
    imgC6B.onload = function () {
      const aspectRatio  =  imgC6.width / imgC6.height;
      const w = 330;
      const object3 = new CanvasObject((1400 - w) / 2, 125, w,
        w / aspectRatio, imgC6B, board.canvas0, 'picture');
      object3.uuid = createUUID();
      // set up children for lecture 1
      object3.level = 'pic5'; // entry level might want to formalize this in the class
      object3.isActive = false;
      object3.onClick = setUpPic5;
      canvasObjects[object3.uuid] = object3;
      board.updateAll();
    };
    const imgC7B = new Image();
    imgC7B.src = 'https://www.dropbox.com/s/54xi376kd3nrlc0/YieldV2.png?raw=1';
    imgC7B.onload = function () {
      const aspectRatio  =  imgC7B.width / imgC7B.height;
      const w = 550;
      const object3 = new CanvasObject((1400 - w) / 2, 125, w,
        w / aspectRatio, imgC7B, board.canvas0, 'picture');
      object3.uuid = createUUID();
      // set up children for lecture 1
      object3.level = 'pic6'; // entry level might want to formalize this in the class
      object3.isActive = false;
      canvasObjects[object3.uuid] = object3;
      board.updateAll();
    };
    const imgC8B = new Image();
    imgC8B.src = 'https://www.dropbox.com/s/jpvo3kb25vezke0/FFmonthlyV1Gauss.png?raw=1';
    imgC8B.onload = function () {
      const aspectRatio  =  imgC8B.width / imgC8B.height;
      const w = 330;
      const object3 = new CanvasObject((1400 - w) / 2, 125, w,
        w / aspectRatio, imgC8B, board.canvas0, 'picture');
      object3.uuid = createUUID();
      // set up children for lecture 1
      object3.level = 'pic7'; // entry level might want to formalize this in the class
      object3.isActive = false;
      canvasObjects[object3.uuid] = object3;
      board.updateAll();
    };
    const imgC9B = new Image();
    imgC9B.src = 'https://www.dropbox.com/s/9nvorgxwvv0ixrm/2016BitCoinV1.png?raw=1';
    imgC9B.onload = function () {
      const aspectRatio  =  imgC9B.width / imgC9B.height;
      const w = 200;
      const object3 = new CanvasObject((1400 - w) / 2, 125, w,
        w / aspectRatio, imgC9B, board.canvas0, 'picture');
      object3.uuid = createUUID();
      // set up children for lecture 1
      object3.level = 'pic8'; // entry level might want to formalize this in the class
      object3.isActive = false;
      canvasObjects[object3.uuid] = object3;
      board.updateAll();
    };
    const imgC10B = new Image();
    imgC10B.src = 'https://www.dropbox.com/s/q0iin7ylodrsmlw/2018BitCoinV1.png?raw=1';
    imgC10B.onload = function () {
      const aspectRatio  =  imgC10B.width / imgC10B.height;
      const w = 220;
      const object3 = new CanvasObject((1400 - w) / 2, 125, w,
        w / aspectRatio, imgC10B, board.canvas0, 'picture');
      object3.uuid = createUUID();
      // set up children for lecture 1
      object3.level = 'pic9'; // entry level might want to formalize this in the class
      object3.isActive = false;
      canvasObjects[object3.uuid] = object3;
      board.updateAll();
    };
  }

  // support functions
  function myFunc(x, y, width, height, text, ctx) {
    ctx.font = '80px Courier New';
    ctx.textAlign = 'center';
    ctx.fillStyle = 'Black';
    ctx.fillText(text, x + 0.5 * width, y + 0.5 * height);
  }
  function myFunc2(x, y, width, height, text, ctx) {
    ctx.font = '60px Comic Sans MS';
    ctx.textAlign = 'center';
    ctx.fillStyle = 'Black';
    ctx.fillText(text, x + 0.5 * width, y + 0.5 * height);
  }
  function myFunc3(x, y, width, height, text, ctx) {
    const fontsize = 30;
    // eslint-disable-next-line prefer-template
    ctx.font = fontsize + 'px Courier New';
    ctx.textAlign = 'center';
    ctx.fillStyle = 'Black';
    let metrics = ctx.measureText(text);
    if (metrics.width <= width) {
      ctx.fillText(text, x, y + height - fontsize / 4);
      return;
    }
    const words = text.split(' ');
    let line = '';
    const lines = [];
    for (var n = 0; n < words.length; n++) {
      // eslint-disable-next-line prefer-template
      const testLine = line + words[n] + ' ';
      metrics = ctx.measureText(testLine);
      if (metrics.width > width && n > 0) {
        lines.push(line);
        // eslint-disable-next-line prefer-template
        line = words[n] + ' ';  // next line
      }
      else {
        line = testLine;
      }
    }
    lines.push(line);
    var line_y = y + height - fontsize / 4;
    for (let i = lines.length - 1; i >= 0; i--) {
      ctx.fillText(lines[i], x, line_y);
      line_y -= fontsize * 1.1;
    }
  }
 
  // changeBackgroundPicture(backGroundCHL);
  function setUpEntryRoom() {
    // console.log('test2');
    Object.values(canvasObjects).forEach((object) => {
      if (object.level === 'first' || object.level === 'icon') {
        setTimeout(() => { 
          object.isActive = true; 
          board.updateAll();
        }, 50);  
      } else {
        object.isActive = false;
      }
    });
    const backGroundCHL = 'https://www.dropbox.com/s/jypvflw38ch64b8/GrayRec.png?raw=1';
    if (board.room.currentBackGroundPicture !== backGroundCHL) {
      changeBackgroundPicture(backGroundCHL);
    }
  }
  function setupGalleryOverview() {
    // console.log('test1');
    Object.values(canvasObjects).forEach((object) => {
      // console.log('yeyt');
      if (object.level === 'second' || object.level === 'back') {
        // console.log('neasd');
        setTimeout(() => { 
          object.isActive = true;
          board.updateAll();
        }, 50);  
      } else {
        // console.log('fsdfsdsdf');
        object.isActive = false;
        // console.log(object);
      }
    });
    // here is the display of all the pictures
    const backGroundCHL = 'https://www.dropbox.com/s/jypvflw38ch64b8/GrayRec.png?raw=1';
    changeBackgroundPicture(backGroundCHL);
  }
  function setUpPic1() {
    // console.log('test1');
    Object.values(canvasObjects).forEach((object) => {
      // console.log('yeyt');
      if (object.level === 'pic1' || object.level === 'back2') {
        // console.log('neasd');
        setTimeout(() => { 
          object.isActive = true;
          board.updateAll();
        }, 50);  
      } else {
        // console.log('fsdfsdsdf');
        object.isActive = false;
        // console.log(object);
      }
    });
    const backGroundCHL = 'https://www.dropbox.com/s/uvuyonq43mdj4lc/BlueRec.png?raw=1';
    changeBackgroundPicture(backGroundCHL);
  }
  function setUpPic2() {
    // console.log('test1');
    Object.values(canvasObjects).forEach((object) => {
      // console.log('yeyt');
      if (object.level === 'pic2' || object.level === 'back2') {
        // console.log('neasd');
        setTimeout(() => { 
          object.isActive = true;
          board.updateAll();
        }, 50);  
      } else {
        // console.log('fsdfsdsdf');
        object.isActive = false;
        // console.log(object);
      }
    });
    const backGroundCHL = 'https://www.dropbox.com/s/uvuyonq43mdj4lc/BlueRec.png?raw=1';
    changeBackgroundPicture(backGroundCHL);
  }
  function setUpPic3() {
    // console.log('test1');
    Object.values(canvasObjects).forEach((object) => {
      // console.log('yeyt');
      if (object.level === 'pic3' || object.level === 'back2') {
        // console.log('neasd');
        setTimeout(() => { 
          object.isActive = true;
          board.updateAll();
        }, 50);  
      } else {
        // console.log('fsdfsdsdf');
        object.isActive = false;
        // console.log(object);
      }
    });
    const backGroundCHL = 'https://www.dropbox.com/s/uvuyonq43mdj4lc/BlueRec.png?raw=1';
    changeBackgroundPicture(backGroundCHL);
  }
  function setUpPic4() {
    // console.log('test1');
    Object.values(canvasObjects).forEach((object) => {
      // console.log('yeyt');
      if (object.level === 'pic4' || object.level === 'back2') {
        // console.log('neasd');
        setTimeout(() => { 
          object.isActive = true;
          board.updateAll();
        }, 50);  
      } else {
        // console.log('fsdfsdsdf');
        object.isActive = false;
        // console.log(object);
      }
    });
    const backGroundCHL = 'https://www.dropbox.com/s/dq4c2zvtol65883/YellowRec.png?raw=1';
    changeBackgroundPicture(backGroundCHL);
  }
  function setUpPic5() {
    // console.log('test1');
    Object.values(canvasObjects).forEach((object) => {
      // console.log('yeyt');
      if (object.level === 'pic5' || object.level === 'back2') {
        // console.log('neasd');
        setTimeout(() => { 
          object.isActive = true;
          board.updateAll();
        }, 50);  
      } else {
        // console.log('fsdfsdsdf');
        object.isActive = false;
        // console.log(object);
      }
    });
    const backGroundCHL = 'https://www.dropbox.com/s/8epy3a61x3ib5uy/BlueRec.png?raw=1';
    changeBackgroundPicture(backGroundCHL);
  }
  function setUpPic6() {
    // console.log('test1');
    Object.values(canvasObjects).forEach((object) => {
      // console.log('yeyt');
      if (object.level === 'pic6' || object.level === 'back2') {
        // console.log('neasd');
        setTimeout(() => { 
          object.isActive = true;
          board.updateAll();
        }, 50);  
      } else {
        // console.log('fsdfsdsdf');
        object.isActive = false;
        // console.log(object);
      }
    });
    const backGroundCHL = 'https://www.dropbox.com/s/dq4c2zvtol65883/YellowRec.png?raw=1';
    changeBackgroundPicture(backGroundCHL);
  }
  function setUpPic7() {
    // console.log('test1');
    Object.values(canvasObjects).forEach((object) => {
      // console.log('yeyt');
      if (object.level === 'pic7' || object.level === 'back2') {
        // console.log('neasd');
        setTimeout(() => { 
          object.isActive = true;
          board.updateAll();
        }, 50);  
      } else {
        // console.log('fsdfsdsdf');
        object.isActive = false;
        // console.log(object);
      }
    });
    const backGroundCHL = 'https://www.dropbox.com/s/uvuyonq43mdj4lc/BlueRec.png?raw=1';
    changeBackgroundPicture(backGroundCHL);
  }
  function setUpPic8() {
    // console.log('test1');
    Object.values(canvasObjects).forEach((object) => {
      // console.log('yeyt');
      if (object.level === 'pic8' || object.level === 'back2') {
        // console.log('neasd');
        setTimeout(() => { 
          object.isActive = true;
          board.updateAll();
        }, 50);  
      } else {
        // console.log('fsdfsdsdf');
        object.isActive = false;
        // console.log(object);
      }
    });
    const backGroundCHL = 'https://www.dropbox.com/s/uvuyonq43mdj4lc/BlueRec.png?raw=1';
    changeBackgroundPicture(backGroundCHL);
  }
  function setUpPic9() {
    // console.log('test1');
    Object.values(canvasObjects).forEach((object) => {
      // console.log('yeyt');
      if (object.level === 'pic9' || object.level === 'back2') {
        // console.log('neasd');
        setTimeout(() => { 
          object.isActive = true;
          board.updateAll();
        }, 50);  
      } else {
        // console.log('fsdfsdsdf');
        object.isActive = false;
        // console.log(object);
      }
    });
    const backGroundCHL = 'https://www.dropbox.com/s/uvuyonq43mdj4lc/BlueRec.png?raw=1';
    changeBackgroundPicture(backGroundCHL);
  }
  function setupAbout() {
    // console.log('test1');
    Object.values(canvasObjects).forEach((object) => {
      // console.log('yeyt');
      if (object.level === 'third' || object.level === 'back' || object.level === 'icon2') {
        // console.log('neasd');
        setTimeout(() => { 
          object.isActive = true;
          board.updateAll();
        }, 50);  
      } else {
        // console.log('fsdfsdsdf');
        object.isActive = false;
        // console.log(object);
      }
    });
    // here is the display of all the pictures
    const backGroundCHL = 'https://www.dropbox.com/s/jypvflw38ch64b8/GrayRec.png?raw=1';
    changeBackgroundPicture(backGroundCHL);
  }
  setUpObjects();
}
function lockAndDisapear(lock) {
  const { mingly } = window;
  const { board } = window.mingly;
  const { me } = board;
  mingly.hostStates.lockedMove = lock;
  if (lock) {
    if (!me.noVideo) {
      noVideo();
    }
    if (!me.muted) {
      muteAudio();
    }
  } else {
    if (me.noVideo) {
      noVideo();
    } 
    if (me.muted) {
      muteAudio();
    }
  }
  // reset any events on current moving 
  me.movingUsingKeyboardNow = false;
  me.movingUsingKeyboardUp = false;
  me.movingUsingKeyboardDown = false;
  me.movingUsingKeyboardLeft = false;
  me.movingUsingKeyboardRight = false;
  board.dragMe = false;
}
function startBIIntroVideo(name) {
  const { board } = window.mingly;
  const { me } = board;
  let foundObject = null; // To store the found object
  for (const obj of Object.values(canvasObjects)) {
    // Check if the current object has a name property and if it equals 'MyName'
    if (obj.name && obj.name === name) {
      foundObject = obj;
      break; // Exit the loop once the object is found
    }
  }
  if (foundObject) {
    const ctx = board.ctx;
    lockAndDisapear(true);
    const matNow = {
      a: ctx.getTransform().a,
      b: ctx.getTransform().b,
      c: ctx.getTransform().c,
      d: ctx.getTransform().d,
      e: ctx.getTransform().e,
      f: ctx.getTransform().f,
    };
    const matTo = {
      a: 1,  
      b: 0,
      c: 0,
      d: 1,
      e: (window.innerWidth - 1400) / 2,
      f: (window.innerHeight - 800) / 2,
    };
    const dim = {
      height: window.innerHeight,
      width: window.innerWidth,
    };
    console.log('before the transition');
    transitionGeneral(matNow, matTo, dim).then(() => {
      console.log('in the transition');
      startCountDown().then(() => {
        foundObject.active = true;
        const matTo = {
          a: 5,  
          b: 0,
          c: 0,
          d: 5,
          e: -2650,
          f: -1650,
        };
        const dim = {
          height: 1189,
          width: 1745,
        };
        const ctx = board.ctx;
        const matNow = {
          a: ctx.getTransform().a,
          b: ctx.getTransform().b,
          c: ctx.getTransform().c,
          d: ctx.getTransform().d,
          e: ctx.getTransform().e,
          f: ctx.getTransform().f,
        };
        transitionGeneral(matNow, matTo, dim);
        const playVideoWhenReady = function () {
          // Once the video can play, remove the listener to prevent it from firing again
          foundObject.source.removeEventListener('canplay', playVideoWhenReady);
      
          // Attempt to play the video
          foundObject.source.play().then(() => {
            console.log('Video is now playing');
            foundObject.drawVideo();
          }).catch((error) => {
            // Handle error, for example, if the video could not be played
            // unlock in case you cannot play the video
            lockAndDisapear(false);
            console.error('Error trying to play the video:', error);
          });
        };
      
        // Attach the event listener to the video source
        if (foundObject.source.readyState >= 3) {
          // Directly attempt to play the video as it's ready
          playVideoWhenReady();
        } else {
          // Attach the event listener to wait for the video to be ready
          foundObject.source.addEventListener('canplay', playVideoWhenReady);
        }
        foundObject.source.addEventListener('ended', function () {
          foundObject.active = false;
          lockAndDisapear(false);
          // reset the host state
          if (window.mingly.hostStates.currentVideo) {
            window.mingly.hostStates.currentVideo = null;
          }
          foundObject.source.currentTime = 0;
          let matNow = {
            a: ctx.getTransform().a,
            b: ctx.getTransform().b,
            c: ctx.getTransform().c,
            d: ctx.getTransform().d,
            e: ctx.getTransform().e,
            f: ctx.getTransform().f,
          };
          let matTo = {
            a: 1,  
            b: 0,
            c: 0,
            d: 1,
            e: (window.innerWidth - 1400) / 2,
            f: (window.innerHeight - 800) / 2,
          };
          const dim = {
            height: window.innerHeight,
            width: window.innerWidth,
          };
          transitionGeneral(matNow, matTo, dim).then(() => {
            matNow = {
              a: ctx.getTransform().a,
              b: ctx.getTransform().b,
              c: ctx.getTransform().c,
              d: ctx.getTransform().d,
              e: ctx.getTransform().e,
              f: ctx.getTransform().f,
            };
            const xDir = -me.x * board.focusFactor + window.innerWidth / 2;
            const yDir = -me.y * board.focusFactor + window.innerHeight / 2;
            matTo = {
              a: board.focusFactor,  
              b: 0,
              c: 0,
              d: board.focusFactor,
              e: xDir,
              f: yDir,
            };
            transitionGeneral(matNow, matTo, dim);
          });
        });
      });
    }).catch((error) => {
      console.log('Error in the transition', error);
      lockAndDisapear(false);
    }); // Cleanup after trying to play the video
  }
}

function drawCountdown(countdown) {
  const { board } = window.mingly;
  const ctx = board.ctx1;
  const width = 1400;
  const height = 800; 
  ctx.clearRect(0, 0, width, height); // Clear the canvas

  // Set styles for the text
  ctx.font = '100px Arial';
  ctx.textAlign = 'center';
  ctx.fillStyle = 'black';

  // Draw "Starting in:"
  ctx.fillText('Starting in:', width / 2, height / 2 - 50);

  // Draw the countdown number
  ctx.fillText(countdown, width / 2, height / 2 + 60);
}

function startCountDown() {
  const { board } = window.mingly;
  return new Promise((resolve, reject) => {
    let countdown = 10;
    const countdownInterval = setInterval(() => {
      drawCountdown(countdown);

      countdown--;

      if (countdown < 0) {
        clearInterval(countdownInterval); // Stop the countdown
        board.ctx1.clearRect(0, 0, 1400, 800); 
        resolve();
      }
    }, 1000);
  });
}
function setUpMinglyBIPresentation() {
  const { board } = window.mingly;
  // the background stuff
  const slideHeight = 200;
  const h = 1280 / 720;
  const gap = 50;
  const slideWidth = slideHeight * h;
  const slide1Y = (800 - slideHeight) / 2;
  const slide1X = -slideWidth - gap;

  // first slide
  const image1 = new Image();
  image1.src = 'https://www.dropbox.com/scl/fi/9876c4r4ax0uk66phztyk/Slide2.PNG?rlkey=x8z9njnyo5yvjjzif2srktar6&raw=1';
  const objectSlide1 = new CanvasObject(slide1X,
    slide1Y, slideWidth, slideHeight, image1, board.canvas0, 'picture');
  objectSlide1.uuid = createUUID();
  objectSlide1.isActive = true;
  objectSlide1.onClick = function () {
    console.log('clicked on the first slide');
    objectSlide2.isActive = !objectSlide2.isActive;
    // objectSlide1.active = false;
    board.updateAll();
  };
  canvasObjects[objectSlide1.uuid] = objectSlide1;

  // first slide child
  const image2 = new Image();
  image2.src = 'https://www.dropbox.com/scl/fi/6pwgljs66hggmuahmng8c/Presentation1.png?rlkey=o7d8n40q0avzq2jvgny3y6thi&raw=1';
  const objectSlide2 = new CanvasObject(slide1X,
    slide1Y + slideHeight, slideWidth, slideHeight, image2, board.canvas0, 'picture');
  objectSlide2.isActive = false;
  objectSlide2.uuid = createUUID();
  canvasObjects[objectSlide2.uuid] = objectSlide2;

  // second slide
  const image3 = new Image();
  const slide2Y = (800 - slideHeight) / 2;
  const slide2X = 1400 + gap; 
  image3.src = 'https://www.dropbox.com/scl/fi/8uv8qrb4l0cywlhi2785u/Slide3.PNG?rlkey=2hplb1q71g96kvcx3q090q30j&raw=1';
  const objectSlide3 = new CanvasObject(slide2X,
    slide2Y, slideWidth, slideHeight, image3, board.canvas0, 'picture');
  objectSlide3.uuid = createUUID();
  objectSlide3.isActive = true;
  objectSlide3.videoActive = false;
  objectSlide3.onClick = function () {
    console.log('clicked on the second slide', objectSlide3.videoActive);
    if (objectSlide3.videouuid) {
      const tempCanvasObjects = {};
      Object.values(board.canvasObjects).forEach((canObject) => {
        if (canObject.uuid === objectSlide3.videouuid) {
          canObject.source.pause();
          board.updateAll();
        } else {
          tempCanvasObjects[canObject.uuid] = canObject;
        }
      });
      board.canvasObjects = tempCanvasObjects;
      objectSlide3.videouuid = null;
    } else {
      const SlidevideoSrc = 'https://www.dropbox.com/scl/fi/vafclv6nhz3jcz2u5evwh/VideoThatShowsWhenClick.mp4?rlkey=deb737ksdwyjnkdxaxg2yjaq6&raw=1';
      const SlideVideoactive = true;
      const SlideVideosource = { 
        src: SlidevideoSrc,
        muted: false,
      };
      const SlidevideoWidth = 1912;
      const SlidevideoHeight = 968;
      const SlideVideoratio =  SlidevideoHeight / SlidevideoWidth;
      const SlideHeight = SlideVideoratio * slideWidth;
      const SlideVideoobject = new CanvasObject(slide2X, slide2Y + slideHeight, slideWidth,
        SlideHeight, SlideVideosource, board.canvas0, 'canvasVideo', SlideVideoactive);
      SlideVideoobject.uuid = createUUID();
      objectSlide3.videouuid = SlideVideoobject.uuid;
      canvasObjects[SlideVideoobject.uuid] = SlideVideoobject;
      SlideVideoobject.source.addEventListener('ended', function () {
        const tempCanvasObjects = {};
        Object.values(board.canvasObjects).forEach((canObject) => {
          if (canObject.uuid === objectSlide3.videouuid) {
            board.updateAll();
          } else {
            tempCanvasObjects[canObject.uuid] = canObject;
          }
        });
        board.canvasObjects = tempCanvasObjects;
        objectSlide3.videouuid = null;
        console.log('In video ended', objectSlide3.videouuid);
      });
    }
    board.updateAll();
  };
  canvasObjects[objectSlide3.uuid] = objectSlide3;
  // video for the second slide

  // the presenation stuff
  const imageWidth = 300;
  const active = false;
  const videoSrc = 'https://www.dropbox.com/scl/fi/6o8u16hpv9rap3b0rgac7/IntroVideoForBIV2.mp4?rlkey=kuvhseliotpbjkslhlntldbif&raw=1';
  const source = { 
    src: videoSrc,
    muted: false,
  };
  const videoSrc2 = 'https://www.dropbox.com/scl/fi/ddkrw7nc0qbuv4x2iy5sr/GameBI.mp4?rlkey=7pxlncmmg4o8fqgyfah4hdviu&raw=1';
  const source2 = {
    src: videoSrc2,
    muted: false,
  };
  const videoSrc3 = 'https://www.dropbox.com/scl/fi/0504ujs3lwqzz3qhqbx4g/OutroVideoBI.mp4?rlkey=pbbzmye00kzg13vwd447b023j&raw=1';
  const source3 = {
    src: videoSrc3,
    muted: false,
  };
  const videoWidth = 1648;
  const videoHeight = 1078;
  const y = 350;
  const x = (1400 - imageWidth) / 2;
  const ratio =  videoHeight / videoWidth;
  const object = new CanvasObject(x, y, imageWidth,
    imageWidth * ratio, source, board.canvas0, 'canvasVideo', board, active);
  console.log('object', object);
  object.uuid = createUUID();
  object.name = 'introVideo';
  console.log('object.uuid', object.uuid);
  canvasObjects[object.uuid] = object;

  const videoWidth2 = 1920;
  const videoHeight2 = 1080;
  const y2 = 350;
  const x2 = (1400 - imageWidth) / 2;
  const ratio2 =  videoHeight2 / videoWidth2;
  const object2 = new CanvasObject(x2, y2, imageWidth,
    imageWidth * ratio2, source2, board.canvas0, 'canvasVideo', board, active);
  console.log('object', object2);
  object2.uuid = createUUID();
  object2.name = 'Video2';
  console.log('object.uuid', object2.uuid);
  canvasObjects[object2.uuid] = object2;
  
  const videoWidth3 = 1920;
  const videoHeight3 = 1080;
  const y3 = 350;
  const x3 = (1400 - imageWidth) / 2;
  const ratio3 =  videoHeight3 / videoWidth3;
  const object3 = new CanvasObject(x3, y3, imageWidth,
    imageWidth * ratio3, source3, board.canvas0, 'canvasVideo', board, active);
  console.log('object', object3);
  object3.uuid = createUUID();
  object3.name = 'Video3';
  console.log('object.uuid', object3.uuid);
  canvasObjects[object3.uuid] = object3;
}

function setUpMinglyV1Presentation() {
  const { board } = window.mingly;
  const spatialVid1 = createUUID();
  console.log('setting up MinglyV1Presenation room');
  const imgC1 = new Image();
  const imgC2 = new Image();
  const imgC3 = new Image();
  const imgC4 = new Image();
  const imgC5 = new Image();
  const imgC6 = new Image();
  imgC1.src = 'https://www.dropbox.com/scl/fi/5dbslyft8ww3anowhnzh1/Slide1.png?rlkey=llxvjbdo2yij11voebmfdodjr&raw=1';
  imgC2.src = 'https://www.dropbox.com/scl/fi/suhb9j0h5nindo2d4zpvc/Slide2.png?rlkey=dhsozkfywvrynrr478m5srge4&raw=1';
  imgC3.src = 'https://www.dropbox.com/scl/fi/7pwdzrtmoljmaucx9ye3n/Slide3.png?rlkey=1ipcmvdblpou01v0m8yk6kvot&raw=1';
  imgC4.src = 'https://www.dropbox.com/scl/fi/0cm6tk7n36l6d1zcd56xq/Slide4.png?rlkey=kk1m03n28s48kwsginnmoeniv&raw=1';
  imgC5.src = 'https://www.dropbox.com/scl/fi/kdvyp1qipzg0p6509vjow/Slide5.png?rlkey=armrdbkledn353bkdzidy7wap&raw=1';
  imgC6.src = 'https://www.dropbox.com/scl/fi/hye7s22s2gk50g1awek84/Slide6.png?rlkey=mew91qs4kcndckzxywr8odm9e&raw=1';
  const imageWidth = 300;
  const ratio = 405 / 720;
  imgC1.onload = function () {
    const x = 25;
    const y = 25;
    const object = new CanvasObject(x, y, imageWidth,
      imageWidth * ratio, imgC1, board.canvas0, 'picture');
    object.uuid = createUUID();
    canvasObjects[object.uuid] = object;
    board.updateAll();
  };
  imgC2.onload = function () {
    const x = 25 + imageWidth + 50;
    const y = 25;
    const object = new CanvasObject(x, y, imageWidth,
      imageWidth * ratio, imgC2, board.canvas0, 'picture');
    object.uuid = createUUID();
    canvasObjects[object.uuid] = object;
    board.updateAll();
  };
  imgC3.onload = function () {
    const x = 25 + (imageWidth + 50) * 2;
    const y = 25;
    const object = new CanvasObject(x, y, imageWidth,
      imageWidth * ratio, imgC3, board.canvas0, 'picture');
    object.uuid = createUUID();
    canvasObjects[object.uuid] = object;
    board.updateAll();
  };
  imgC4.onload = function () {
    const x = 25 + (imageWidth + 50) * 3;
    const y = 25;
    const object = new CanvasObject(x, y, imageWidth,
      imageWidth * ratio, imgC4, board.canvas0, 'picture');
    object.uuid = createUUID();
    canvasObjects[object.uuid] = object;
    board.updateAll();
  };
  imgC5.onload = function () {
    const x = 25;
    const y = 600;
    const object = new CanvasObject(x, y, imageWidth,
      imageWidth * ratio, imgC5, board.canvas0, 'picture');
    object.uuid = createUUID();
    canvasObjects[object.uuid] = object;
    board.updateAll();
  };
  imgC6.onload = function () {
    const x = 25 + (imageWidth + 50) * 3;
    const y = 600;
    const object = new CanvasObject(x, y, imageWidth,
      imageWidth * ratio, imgC6, board.canvas0, 'picture');
    object.uuid = createUUID();
    canvasObjects[object.uuid] = object;
    board.updateAll();
  };
  const videoSrc = 'https://www.dropbox.com/scl/fi/gpffshm009xcj5oc9sqv6/Mingly-BI-Dep-Meeting.mp4?rlkey=cejxjchi6ldiurxzd1beh4yc4&raw=1';
  const videoWidth = 1280;
  const videoHeight = 720;
  const y = 350;
  const x = (1400 - imageWidth) / 2;
  const ratio2 =  videoHeight / videoWidth;
  const active = true;
  const source = { 
    src: videoSrc,
    muted: false,
  };
  const object = new CanvasObject(x, y, imageWidth,
    imageWidth * ratio2, source, board.canvas0, 'canvasVideo', board, active);
  console.log('object', object);
  object.uuid = createUUID();
  console.log('object.uuid', object.uuid);
  object.onClick = togglePlay.bind(object);
  object.source.addEventListener('ended', function () {
    object.currentTime = 0;
  });
  canvasObjects[object.uuid] = object;
  function togglePlay() {
    if (this.source.paused) {
      this.source.play();
      this.drawVideo();
    }
    else {
      this.source.pause();
    }
  }
}

function setUpMinglyPitch() {
  const { board } = window.mingly;
  // this function sets up MinglyPitch room
  // it should be instead moved to a DB
  console.log('setting up MinglyPitch room');
  const list = getMinglyPitchSlides();
  // setup the background pictures
  // it should be 
  const NpicLength = 8;
  const NpichHeight = 7;
  const picWidth = 162;
  const aspectRatio = 960 / 540;
  const picHeight = picWidth / aspectRatio;
  for (let i = 0; i < NpicLength; i++) {
    for (let j = 0; j < NpichHeight; j++) {
      if (i + j * NpicLength >= list.length) {
        window.mingly.log('no picture for this slot');
      }
      else {
        const imgC = new Image();
        imgC.src = list[i + j * NpicLength];
        imgC.onload = function () {
          const x = 25 + i * (picWidth + 10);
          const y = 25 + j * (picHeight + 10);
          const object = new CanvasObject(x, y, picWidth,
            picHeight, imgC, board.canvas0, 'picture');
          object.uuid = createUUID();
          board.canvasObjects[object.uuid] = object;
          board.updateAll();
        };
      }
    }
  }
}
function setUpMinglyWorld() {
  const { board } = window.mingly;
  // this function sets up F300 teaching room
  // it should be instead moved to a DB 
  console.log('setting up teaching room F300');
  const list = [
    'https://www.dropbox.com/s/zlamxnkn01boudl/damini-rathore-ACXDMDtoQGg-unsplash.jpg?raw=1',
    'https://www.dropbox.com/s/zlamxnkn01boudl/damini-rathore-ACXDMDtoQGg-unsplash.jpg?raw=1',
    'https://www.dropbox.com/s/ca7czanlitx3qd1/dewang-gupta-Mu3T3DmvQQw-unsplash.jpg?raw=1',
    'https://www.dropbox.com/s/273hit8nakc96eb/diego-ph-5LOhydOtTKU-unsplash.jpg?raw=1',
    'https://www.dropbox.com/s/z1n20xjtm8ze6b0/diego-ph-vTitvl4O2kE-unsplash.jpg?raw=1',
    'https://www.dropbox.com/s/xdm00h41ewhrbpa/eberhard-grossgasteiger-snLkQ-30EBQ-unsplash.jpg?raw=1',
    'https://www.dropbox.com/s/gcahhwj8ycdpkl7/gemma-evans-JUrolU3FLTA-unsplash.jpg?raw=1',
    'https://www.dropbox.com/s/np83oqtso8g5yjj/ernesto-leon-S5Khu-gxAlY-unsplash.jpg?raw=1',
    'https://www.dropbox.com/s/79heepfpujo7rzw/illia-cholin-V21nJxh1lR4-unsplash.jpg?raw=1',
    'https://www.dropbox.com/s/n5mky4aqlhirpfq/jeremy-perkins-UgNjyPkphtU-unsplash.jpg?raw=1',
    'https://www.dropbox.com/s/g9msxujd270shmr/ibrahim-rifath-NJ7CaVfWYaw-unsplash.jpg?raw=1',
    'https://www.dropbox.com/s/efp1u9kpfinhsd1/ibrahim-mushan-uNnUdZILKB0-unsplash.jpg?raw=1',
    'https://www.dropbox.com/s/yafy2or7m4l8ve2/jessica-arends-8saVYOMHFzU-unsplash.jpg?raw=1',
    'https://www.dropbox.com/s/shzzyja4vzrhxbt/ricardo-gomez-angel-xeo82lLsXtY-unsplash.jpg?raw=1',
    'https://www.dropbox.com/s/ncdnna3vym082i1/usgs-AQ9-jKmebjM-unsplash.jpg?raw=1',
    'https://www.dropbox.com/s/y86jkzm98db9y1m/joshua-newton-7qjqQjt7zXQ-unsplash.jpg?raw=1',
    'https://www.dropbox.com/s/w8qox6ynykeihtx/joe-ciciarelli-qxvOokGBU2U-unsplash.jpg?raw=1',
    'https://www.dropbox.com/s/wicat0bej863dvz/greg-rakozy-oMpAz-DN-9I-unsplash.jpg?raw=1',
  ];
  function randomImage() {
    console.log('running random image');
    this.childActive = true;
    const img = new Image();
    const imgUrl = list.sort(() => 0.5 - Math.random())[0];
    img.src = imgUrl;
    const { childActive } = this;
    img.onload = function (childActive) {
      HostChangeBackGroundPicture(imgUrl);
      childActive = false;
    };
  }
  const heightLecTile = 42;
  function myFunc(x, y, width, height, text, ctx) {
    let fillColor;
    if (this.childActive) {
      fillColor = '#203e5fff';
    } else {
      fillColor = '#eac252';
    }
    drawFilledRoundedRect(ctx, x, y, width, height, width * 0.05, fillColor);
    ctx.font = '8px Arial';
    ctx.textAlign = 'center';
    if (this.childActive) {
      ctx.fillStyle = '#eac252';
    } else {
      ctx.fillStyle = '#203e5fff';
    }
    ctx.fillText(text, x + 0.5 * width, y + 0.5 * height);
  }
  function myFuncB(x, y, width, height, text, ctx) {
    let fillColor;
    if (this.childActive) {
      fillColor = '#203e5fff';
    } else {
      fillColor = '#eac252';
    }
    drawFilledRoundedRect(ctx, x, y, width, height, width * 0.05, fillColor);
    ctx.font = '5px Arial';
    ctx.textAlign = 'center';
    if (this.childActive) {
      ctx.fillStyle = '#eac252';
    } else {
      ctx.fillStyle = '#203e5fff';
    }
    ctx.fillText(text, x + 0.5 * width, y + 0.5 * height);
  }
  function myFunc2(x, y, width, height, text, ctx) {
    const fillColor = '#203e5fff';
    drawFilledRoundedRect(ctx, x, y, width, height, width * 0.05, fillColor);
    ctx.font = '16px Arial';
    ctx.textAlign = 'center';
    ctx.fillStyle = '#eac252';
    ctx.fillText(text, x + 0.5 * width, y + 0.5 * heightLecTile);
  }
  function myFunc3(x, y, width, height, text, ctx) {
    let fillColor;
    if (this.childActive) {
      fillColor = '#eac252';
    } else {
      fillColor = '#203e5fff';
    }
    drawFilledRoundedRect(ctx, x, y, width, height, width * 0.05, fillColor);
    ctx.font = '16px Arial';
    ctx.textAlign = 'center';
    if (this.childActive) {
      ctx.fillStyle = '#203e5fff';
    } else {
      ctx.fillStyle = '#eac252';
    }
    ctx.fillText(text, x + 0.5 * width, y + 0.5 * height);
  }
  function drawFilledRoundedRect(ctx, x, y, width, height, radius, fillColor) {
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.arcTo(x + width, y, x + width, y + radius, radius);
    ctx.lineTo(x + width, y + height - radius);
    ctx.arcTo(x + width, y + height, x + width - radius, y + height, radius);
    ctx.lineTo(x + radius, y + height);
    ctx.arcTo(x, y + height, x, y + height - radius, radius);
    ctx.lineTo(x, y + radius);
    ctx.arcTo(x, y, x + radius, y, radius);
    ctx.closePath();
    ctx.fillStyle = fillColor;
    ctx.fill();
  }
  function deleteChildSlides(canObject) {
    const N = canObject.childUuid.length;
    // this is almost surely not the most efficient way!
    for (let i = 0; i < N; i++) {
      const id = canObject.childUuid[i];
      const tempCanvasObject = {};
      Object.values(board.canvasObjects).forEach((canObject) => {
        if (canObject.uuid !== id) {
          tempCanvasObject[canObject.uuid] = canObject;
        } else {
          try {
            canObject.source.pause();
          } catch {
            window.mingly.log('not a source playable');
          }
        }
      });
      board.canvasObjects = tempCanvasObject;
    }
    canObject.childUuid = [];
  }
  function childVideo() {
    const parent = this.parentUuid;
    if (!this.childActive) {
      Object.values(board.canvasObjects).forEach((canObject) => {
        if (canObject.childActive && canObject.parentUuid === parent) {
          deleteChildSlides(canObject);
          canObject.childActive = false;
        }
      });
      this.childActive = true;
      const id = this.uuid;
      const x = 450 + 125;
      const y = 100;
      const objectType2 = 'canvasVideo';
      const source = { 
        src: this.video,
        muted: false,
      };
      const objectChild = new CanvasObject(x, y, 250, 250 / 1.77, 
        source, board.canvas, objectType2, board);
      // object.source.muted = true;
      objectChild.uuid = createUUID();
      objectChild.isWalkable = false;
      board.canvasObjects[objectChild.uuid] = objectChild;
      board.canvasObjects[id].childUuid.push(objectChild.uuid);
      objectChild.source.addEventListener('ended', () => {
        this.childActive = false;
      });
    } else {
      this.childActive = false;
      deleteChildSlides(this);
      board.updateAll();
    }
  }
  function childArrayPictures() {
    const parent = this.parentUuid;
    if (!this.childActive) {
      // loop over all objects
      Object.values(board.canvasObjects).forEach((canObject) => {
        if (canObject.childActive && canObject.parentUuid === parent) {
          deleteChildSlides(canObject);
          canObject.childActive = false;
        }
      });
      this.childActive = true;
      const id = this.uuid;
      const Npics = this.arrayPictures.length;
      const x = [];
      const y = [];
      const aspRatio = 405 / 720;
      const picChild = [];
      const widthSlide = 200;
      const heightSlide = widthSlide * aspRatio;
      for (let i = 0; i < Npics; i++) {
        let part = board.height * 0.025 + i * widthSlide;
        x.push(part);
        part = -heightSlide;
        y.push(part);
        const imgC = new Image();
        picChild.push(imgC);
        picChild[i].src = this.arrayPictures[i];
        picChild[i].onload = function () {
          const objectChild = new CanvasObject(x[i], y[i], widthSlide,
            heightSlide, picChild[i], board.canvas0, 'picture');
          objectChild.uuid = createUUID();
          board.canvasObjects[id].childUuid.push(objectChild.uuid);
          board.canvasObjects[objectChild.uuid] = objectChild;
          board.updateAll();
        };
      }
    } else {
      this.childActive = false;
      deleteChildSlides(this); 
      board.updateAll();
    }
  }
  // const numberOfLecture = 18;
  const baselineGroup1 = board.height * 0.025 + 1;
  const objectType = 'canvasDrawing';
  let object = new CanvasObject(heightLecTile, baselineGroup1,
    6.5 * heightLecTile, 4 * heightLecTile, myFunc2, board.canvas0, objectType);
  const parent1UUID = createUUID();
  object.uuid = parent1UUID;
  object.text = 'About';
  object.isWalkable = false;
  // set up children for lecture 1
  canvasObjects[object.uuid] = object;

  object = new CanvasObject(1.5 * heightLecTile, baselineGroup1 +  heightLecTile,
    heightLecTile, heightLecTile, myFunc, board.canvas0, objectType);
  object.text = 'Vision';
  object.uuid = createUUID();
  // set up children for lecture 1
  let  SlideArray = getMinglyWorldSlides(1);
  object.arrayPictures = SlideArray;
  object.onClick = childArrayPictures.bind(object);
  object.childActive = false;
  object.parentUuid = parent1UUID;
  canvasObjects[object.uuid] = object;

  object = new CanvasObject(3 * heightLecTile, baselineGroup1 + heightLecTile,
    heightLecTile, heightLecTile, myFunc, board.canvas0, objectType);
  object.text = 'Problem';
  object.uuid = createUUID();
  // set up children for lecture 1
  SlideArray = getMinglyWorldSlides(2);
  object.arrayPictures = SlideArray;
  object.onClick = childArrayPictures.bind(object);
  object.childActive = false;
  object.parentUuid = parent1UUID;
  canvasObjects[object.uuid] = object;

  object = new CanvasObject(4.5 * heightLecTile, baselineGroup1 + heightLecTile,
    heightLecTile, heightLecTile, myFunc, board.canvas0, objectType);
  object.text = 'Solution';
  object.uuid = createUUID();
  // set up children for lecture 1
  SlideArray = getMinglyWorldSlides(3);
  object.arrayPictures = SlideArray;
  object.onClick = childArrayPictures.bind(object);
  object.childActive = false;
  object.parentUuid = parent1UUID;
  canvasObjects[object.uuid] = object;
  // 
  object = new CanvasObject(6 * heightLecTile, baselineGroup1 + heightLecTile,
    heightLecTile, heightLecTile, myFunc, board.canvas0, objectType);
  object.text = 'Market';
  object.uuid = createUUID();
  // set up children for lecture 2
  SlideArray = getMinglyWorldSlides(4);
  object.arrayPictures = SlideArray;
  object.onClick = childArrayPictures.bind(object);
  object.childActive = false;
  object.parentUuid = parent1UUID;
  canvasObjects[object.uuid] = object;
  // 
  object = new CanvasObject(1.5 * heightLecTile, baselineGroup1 + 2.5 * heightLecTile,
    heightLecTile, heightLecTile, myFunc, board.canvas0, objectType);
  object.text = 'Pricing';
  object.uuid = createUUID();
  // set up children for lecture 3
  SlideArray = getMinglyWorldSlides(5);
  object.arrayPictures = SlideArray;
  object.onClick = childArrayPictures.bind(object);
  object.childActive = false;
  object.parentUuid = parent1UUID;
  canvasObjects[object.uuid] = object;
  // 
  object = new CanvasObject(3 * heightLecTile, baselineGroup1 + 2.5 * heightLecTile,
    heightLecTile, heightLecTile, myFunc, board.canvas0, objectType);
  object.text = 'The Future';
  object.uuid = createUUID();
  // set up children for lecture 4
  SlideArray = getMinglyWorldSlides(6);
  object.arrayPictures = SlideArray;
  object.onClick = childArrayPictures.bind(object);
  object.childActive = false;
  object.parentUuid = parent1UUID;
  canvasObjects[object.uuid] = object;
  //
  object = new CanvasObject(4.5 * heightLecTile, baselineGroup1 + 2.5 * heightLecTile,
    heightLecTile, heightLecTile, myFunc, board.canvas0, objectType);
  object.text = 'The Story';
  object.uuid = createUUID();
  // set up children for lecture 5
  SlideArray = getMinglyWorldSlides(7);
  object.arrayPictures = SlideArray;
  object.onClick = childArrayPictures.bind(object);
  object.childActive = false;
  object.parentUuid = parent1UUID;
  canvasObjects[object.uuid] = object;
  //
  object = new CanvasObject(6 * heightLecTile, baselineGroup1 +  2.5 * heightLecTile,
    heightLecTile, heightLecTile, myFunc, board.canvas0, objectType);
  object.text = 'The Team';
  object.uuid = createUUID();
  // set up children for lecture 5
  SlideArray = getMinglyWorldSlides(8);
  object.arrayPictures = SlideArray;
  object.onClick = childArrayPictures.bind(object);
  object.childActive = false;
  object.parentUuid = parent1UUID;
  canvasObjects[object.uuid] = object;

  object = new CanvasObject(heightLecTile, board.height - baselineGroup1 - 4 * heightLecTile,
    6.5 * heightLecTile, 4 * heightLecTile, myFunc3, board.canvas0, objectType);
  const uuidVidPar = createUUID();
  object.uuid = uuidVidPar;
  object.text = 'Try the Spatial Audio!';
  object.onClick = theSpatialAudioVideo.bind(object);
  object.childActive = false;
  object.isWalkable = false;
  const vid1 =  'https://www.dropbox.com/s/42n2msoa9g74iy4/SpatialVideoForDemoRoom.mp4?raw=1';
  // set up children for lecture 1
  canvasObjects[object.uuid] = object;

  // the right hand side
  const rightStart = board.width - 8.5 * heightLecTile;
  object = new CanvasObject(rightStart + heightLecTile, baselineGroup1,
    6.5 * heightLecTile, 4 * heightLecTile, myFunc2, board.canvas0, objectType);
  const parent2UUID = createUUID();
  object.uuid = parent2UUID;
  object.text = 'Why Mingly?';
  object.isWalkable = false;
  object.onClick = childVideo.bind(object);
  // set up children for lecture 1
  canvasObjects[object.uuid] = object;

  object = new CanvasObject(rightStart + 1.5 * heightLecTile,
    baselineGroup1 +  heightLecTile,
    heightLecTile, heightLecTile, myFuncB,
    board.canvas0, objectType);
  object.text = 'Spatial';
  object.uuid = createUUID();
  object.video = 'https://www.dropbox.com/s/ru89d9yjp8uia98/Why%20-%20Spatial%20Audio.mp4?raw=1';
  // set up children for lecture 1
  // object.onClick = childArrayPictures.bind(object);
  object.childActive = false;
  object.parentUuid = parent2UUID;
  object.onClick = childVideo.bind(object);
  canvasObjects[object.uuid] = object;

  object = new CanvasObject(rightStart + 3 * heightLecTile,
    baselineGroup1 + heightLecTile,
    heightLecTile, heightLecTile, myFuncB, board.canvas0, objectType);
  object.text = 'Intuitive';
  object.uuid = createUUID();
  object.video = 'https://www.dropbox.com/s/x2k1bk2q5iwfyyi/Why%20-%20Easy%20Navigation.mp4?raw=1';
  // set up children for lecture 1
  // object.onClick = childArrayPictures.bind(object);
  object.childActive = false;
  object.parentUuid = parent2UUID;
  object.onClick = childVideo.bind(object);
  canvasObjects[object.uuid] = object;

  object = new CanvasObject(rightStart + 4.5 * heightLecTile,
    baselineGroup1 + heightLecTile,
    heightLecTile, heightLecTile, myFuncB, board.canvas0, objectType);
  object.text = 'Personalized';
  object.uuid = createUUID();
  object.video = 'https://www.dropbox.com/s/r2bva9b92u94uqb/Why%20-%20Fun%20Background.mp4?raw=1';
  // set up children for lecture 1
  // object.onClick = childArrayPictures.bind(object);
  object.childActive = false;
  object.parentUuid = parent2UUID;
  object.onClick = childVideo.bind(object);
  canvasObjects[object.uuid] = object;
  // 
  object = new CanvasObject(rightStart + 6 * heightLecTile,
    baselineGroup1 + heightLecTile,
    heightLecTile, heightLecTile, myFuncB, board.canvas0, objectType);
  object.text = 'Interactive';
  object.uuid = createUUID();
  object.video = 'https://www.dropbox.com/s/k0ffpaufw0x5q7x/Why%20-%20Dynamic%20Backgrounds.mp4?raw=1';
  // set up children for lecture 2
  // object.onClick = childArrayPictures.bind(object);
  object.childActive = false;
  object.parentUuid = parent2UUID;
  object.onClick = childVideo.bind(object);
  canvasObjects[object.uuid] = object;
  // 
  object = new CanvasObject(rightStart + 1.5 * heightLecTile,
    baselineGroup1 + 2.5 * heightLecTile,
    heightLecTile, heightLecTile, myFuncB, board.canvas0, objectType);
  object.text = 'Dynamic';
  object.uuid = createUUID();
  object.video = 'https://www.dropbox.com/s/7ti1b0cjujfctj2/Why%20-%20Presenations.mp4?raw=1';
  // set up children for lecture 3
  // object.arrayPictures = SlideArray;
  // object.onClick = childArrayPictures.bind(object);
  object.childActive = false;
  object.parentUuid = parent2UUID;
  object.onClick = childVideo.bind(object);
  canvasObjects[object.uuid] = object;
  // 
  object = new CanvasObject(rightStart + 3 * heightLecTile,
    baselineGroup1 + 2.5 * heightLecTile,
    heightLecTile, heightLecTile, myFuncB, board.canvas0, objectType);
  object.text = 'Spatial polling';
  object.uuid = createUUID();
  object.video = 'https://www.dropbox.com/s/zqwy2ddpzrcgsld/Why%20-%20Polling.mp4?raw=1';
  // set up children for lecture 4
  // object.onClick = childArrayPictures.bind(object);
  object.childActive = false;
  object.parentUuid = parent2UUID;
  object.onClick = childVideo.bind(object);
  canvasObjects[object.uuid] = object;
  //
  object = new CanvasObject(rightStart + 4.5 * heightLecTile,
    baselineGroup1 + 2.5 * heightLecTile,
    heightLecTile, heightLecTile, myFuncB, board.canvas0, objectType);
  object.text = 'Spatial quiz';
  object.uuid = createUUID();
  object.video = 'https://www.dropbox.com/s/ka0xwywoyo3e7j5/Why%20-%20Guess%20the%20City.mp4?raw=1';
  // set up children for lecture 5
  // object.onClick = childArrayPictures.bind(object);
  object.childActive = false;
  object.parentUuid = parent2UUID;
  object.onClick = childVideo.bind(object);
  canvasObjects[object.uuid] = object;
  //
  object = new CanvasObject(rightStart + 6 * heightLecTile,
    baselineGroup1 +  2.5 * heightLecTile,
    heightLecTile, heightLecTile, myFuncB, board.canvas0, objectType);
  object.text = 'Meeting controls';
  object.uuid = createUUID();
  object.video = 'https://www.dropbox.com/s/p0gzl78f5x7wn6x/Why%20-%20Host%20Controls.mp4?raw=1';
  // set up children for lecture 5
  // object.onClick = childArrayPictures.bind(object);
  object.childActive = false;
  object.parentUuid = parent2UUID;
  object.onClick = childVideo.bind(object);
  canvasObjects[object.uuid] = object;

  object = new CanvasObject(rightStart + heightLecTile,
    board.height - baselineGroup1 - 4 * heightLecTile,
    6.5 * heightLecTile, 4 * heightLecTile, myFunc3, board.canvas0, objectType);
  object.uuid = createUUID();
  object.text = 'Change background';
  object.onClick = randomImage.bind(object);
  object.isWalkable = false;
  // set up children for lecture 1
  canvasObjects[object.uuid] = object;

  const objectType2 = 'canvasVideo';
  const source = { 
    src: vid1,
    muted: false,
  };
  const spatialVid1 = createUUID();
  const startInactive = false;
  object = new CanvasObject(550 + 75, 100, 150, 150 / 1.77, 
    source, board.canvas, objectType2, board, startInactive);
  // object.source.muted = true;
  object.uuid = spatialVid1;
  object.soundDecay = true;
  object.isWalkable = false;
  canvasObjects[object.uuid] = object;
  function theSpatialAudioVideo() {
    const obj = canvasObjects[spatialVid1];
    if (this.childActive) {
      window.mingly.log('already in there');
    } else {
      this.childActive = true;
      obj.isActive = true;
      if (obj.vidReady) {
        obj.source.muted = obj.muted;
        if (obj.source.paused) {
          obj.source.play();
        }
        obj.drawVideo(this.source);
        obj.source.addEventListener('ended', () => {
          obj.isActive = false;
          board.updateAll();
          canvasObjects[uuidVidPar].childActive = false;
        });
      }
    }
    
    // if (this.childActive) {
    //   obj.isActive = true;
    //   if (obj.vidReady) {
    //     obj.source.muted = obj.muted;
    //     if (obj.source.paused) {
    //       obj.source.play();
    //     }
    //     obj.drawVideo(this.source);
    //   }
    // } else {
    //   obj.isActive = false;
    //   obj.source.pause();
    //   cancelAnimationFrame(obj.reqAnimation);
    //   obj.clear();
    // }
  }
  
  // function theSpatialAudioVideo() {
  //   if (this.childActive) {
  //     console.log('the playing');
  //     console.log(this.childActive);
  //     objectPart.deleteVideo();
  //     const tempCanvasObject = {};
  //     Object.values(board.canvasObjects).forEach((canObject) => {
  //       if (canObject.uuid !== spatialVidUUID) {
  //         tempCanvasObject[canObject.uuid] = canObject;
  //       }
  //     });
  //     board.canvasObjects = tempCanvasObject;
  //     this.childActive = false;
  //     board.updateAll();
  //   } else {
  //     this.childActive = true;
  //     const objectType2 = 'canvasVideo';
  //     const source = { 
  //       src: vid1,
  //       muted: false,
  //       loop: true,
  //     };
  //     object = new CanvasObject(140, 140, board.avatarSize, board.avatarSize, 
  //       source, board.canvas, objectType2);
  //     // object.source.muted = true;
  //     object.uuid = spatialVidUUID;
  //     console.log(spatialVidUUID);
  //     object.soundDecay = true;
  //     object.isWalkable = false;
  //     canvasObjects[spatialVidUUID] = object;
  //     board.updateAll();
  //   }
  // }
  // const vidEaster =  'https://www.dropbox.com/s/u67kq7x66e25kej/AkwardTalk1.mp4?raw=1';
  // // board.addVideoToCanvas(vidEaster, 1, 140, 140, true); 
  // const objectType2 = 'canvasVideo';
  // const source = { 
  //   src: vidEaster,
  //   muted: false,
  //   loop: true,
  // };
  // object = new CanvasObject(140, 140, board.avatarSize, board.avatarSize, 
  //   source, board.canvas, objectType2);
  // // object.source.muted = true;
  // object.uuid = createUUID();
  // object.soundDecay = true;
  // object.isWalkable = false;
  // object.onClick = whenClick.bind(object);
  // function whenClick() {
  //   this.source.muted = !this.source.muted;
  // }
  // canvasObjects[object.uuid] = object;
  // let dir = 1;
  // setInterval(function () {
  //   let newX = canvasObjects[object.uuid].x;
  //   const newY = canvasObjects[object.uuid].y;
  //   if (canvasObjects[object.uuid].x > window.mingly.board.width - 140) {
  //     dir = -1;
  //   }
  //   if (canvasObjects[object.uuid].x < 1) {
  //     console.log('test up');
  //     dir = 1;
  //   }
  //   newX += dir;
  //   canvasObjects[object.uuid].moveTo(newX, newY);
  // }, 20);
}

function setUpF300() {
  const { board } = window.mingly;
  // this function sets up F300 teaching room
  // it should be instead moved to a DN 
  console.log('setting up teaching room F300');
  const heightLecTile = 42;
  function myFunc(x, y, width, height, text, ctx) {
    if (this.childActive) {
      ctx.fillStyle = 'blue';
    } else {
      ctx.fillStyle = 'black';
    }
    ctx.fillRect(x, y, width, height);
    ctx.font = '8px Arial';
    ctx.textAlign = 'center';
    ctx.fillStyle = 'white';
    ctx.fillText(text, x + 0.5 * width, y + 0.5 * height);
  }
  function deleteChildSlides(canObject) {
    const N = canObject.childUuid.length;
    // this is almost surely not the most efficient way!
    for (let i = 0; i < N; i++) {
      const id = canObject.childUuid[i];
      const tempCanvasObject = {};
      Object.values(board.canvasObjects).forEach((canObject) => {
        if (canObject.uuid !== id) {
          tempCanvasObject[canObject.uuid] = canObject;
        }
      });
      board.canvasObjects = tempCanvasObject;
      // console.log(tempCanvasObject);
      // console.log(canvasObjects);
      // console.log(window.mingly.board.canvasObjects);
    }
    canObject.childUuid = [];
  }
  function childArrayPictures() {
    if (!this.childActive) {
      // loop over all objects
      Object.values(board.canvasObjects).forEach((canObject) => {
        if (canObject.childActive) {
          deleteChildSlides(canObject);
          canObject.childActive = false;
        }
      });
      this.childActive = true;
      const id = this.uuid;
      const Npics = this.arrayPictures.length;
      const x = [];
      const y = [];
      const aspRatio = 3 / 4;
      const picChild = [];
      const widthSlide = 25;
      const heightSlide = widthSlide * aspRatio;
      for (let i = 0; i < Npics; i++) {
        let part = i * widthSlide;
        x.push(part);
        part = -heightSlide;
        y.push(part);
        const imgC = new Image();
        picChild.push(imgC);
        picChild[i].src = this.arrayPictures[i];
        picChild[i].onload = function () {
          const objectChild = new CanvasObject(x[i], y[i], widthSlide,
            heightSlide, picChild[i], board.canvas0, 'picture');
          objectChild.uuid = createUUID();
          board.canvasObjects[id].childUuid.push(objectChild.uuid);
          board.canvasObjects[objectChild.uuid] = objectChild;
          board.updateAll();
        };
      }
    } else {
      this.childActive = false;
      deleteChildSlides(this); 
      board.updateAll();
    }
  }
  // const numberOfLecture = 18;
  const objectType = 'canvasDrawing';
  let object = new CanvasObject(-heightLecTile, 2, heightLecTile,
    heightLecTile, myFunc, board.canvas0, objectType);
  object.text = 'Lecture 1';
  object.uuid = createUUID();
  // set up children for lecture 1
  let  F300lec1Array = getListLeturesF300(1);
  object.arrayPictures = F300lec1Array;
  object.onClick = childArrayPictures.bind(object);
  object.childActive = false;
  canvasObjects[object.uuid] = object;
  // 
  object = new CanvasObject(-heightLecTile, 2 + heightLecTile, heightLecTile,
    heightLecTile, myFunc, board.canvas0, objectType);
  object.text = 'Lecture 2';
  object.uuid = createUUID();
  // set up children for lecture 2
  F300lec1Array = getListLeturesF300(2);
  object.arrayPictures = F300lec1Array;
  object.onClick = childArrayPictures.bind(object);
  object.childActive = false;
  canvasObjects[object.uuid] = object;
  // 
  object = new CanvasObject(-heightLecTile, 2 + 2 * heightLecTile, heightLecTile,
    heightLecTile, myFunc, board.canvas0, objectType);
  object.text = 'Lecture 3';
  object.uuid = createUUID();
  // set up children for lecture 3
  F300lec1Array = getListLeturesF300(3);
  object.arrayPictures = F300lec1Array;
  object.onClick = childArrayPictures.bind(object);
  object.childActive = false;
  canvasObjects[object.uuid] = object;
  // 
  object = new CanvasObject(-heightLecTile, 2 + 3 * heightLecTile, heightLecTile,
    heightLecTile, myFunc, board.canvas0, objectType);
  object.text = 'Lecture 4';
  object.uuid = createUUID();
  // set up children for lecture 4
  F300lec1Array = getListLeturesF300(4);
  object.arrayPictures = F300lec1Array;
  object.onClick = childArrayPictures.bind(object);
  object.childActive = false;
  canvasObjects[object.uuid] = object;
  //
  object = new CanvasObject(-heightLecTile, 2 + 4 * heightLecTile, heightLecTile,
    heightLecTile, myFunc, board.canvas0, objectType);
  object.text = 'Lecture 5';
  object.uuid = createUUID();
  // set up children for lecture 5
  F300lec1Array = getListLeturesF300(5);
  object.arrayPictures = F300lec1Array;
  object.onClick = childArrayPictures.bind(object);
  object.childActive = false;
  canvasObjects[object.uuid] = object;
  //
  object = new CanvasObject(-heightLecTile, 2 + 5 * heightLecTile, heightLecTile,
    heightLecTile, myFunc, board.canvas0, objectType);
  object.text = 'Lecture 6';
  object.uuid = createUUID();
  // set up children for lecture 5
  F300lec1Array = getListLeturesF300(6);
  object.arrayPictures = F300lec1Array;
  object.onClick = childArrayPictures.bind(object);
  object.childActive = false;
  canvasObjects[object.uuid] = object;
}

// ballgame 
function ballBounce(start = true) {
  if (!start) {
    console.log('killing game');
    Object.values(canvasObjects).forEach((canvasObject) => {
      if (canvasObject.gameType === 'ballGame') {
        clearInterval(canvasObject.setInt);
        delete canvasObjects[canvasObject.uuid];
      }
    });
    return;
  }
  // need a way of stopping it as well
  const { mingly } = window;
  const { board } = mingly;
  const updateFrequency = 40;
  const speedScaler = 3 * (updateFrequency / 10);
  const middleX = board.width / 2;
  const middleY = board.height / 2;
  const objectType = 'canvasDrawing';
  const size = 20;
  const object = new CanvasObject(middleX, middleY, size,
    size, drawBall, board.canvas, objectType);
  object.gameType = 'ballGame';
  object.uuid = createUUID();
  object.canvasWitdth = board.width;
  object.canvasHeight = board.height;
  object.speedScaler = speedScaler;
  object.dx = Math.floor(Math.random() * speedScaler) 
  - Math.floor(Math.random() * 3); // can randomize these
  object.dy = Math.floor(Math.random() * speedScaler) 
  - Math.floor(Math.random() * speedScaler);
  if (object.dx === 0) {
    object.dx = 1;
  }
  object.playerPos = {};
  object.scores = {
    up: 0,
    down: 0,
    left: 0,
    right: 0,
  };
  Object.values(window.mingly.board.participants).forEach((participant) => {
    object.playerPos[participant.uuid] = {
      x: participant.x,
      y: participant.y,
    };
  });
  object.mePos = {
    x: board.me.x,
    y: board.me.y,
  };
  canvasObjects[object.uuid] = object;
  object.setInt = setInterval(drawForBallGame.bind(this, object.uuid), updateFrequency);
}
function drawForBallGame(uuid) {
  const { board } = window.mingly;
  const { me } = board;
  const ball = canvasObjects[uuid];
  const canvas = ball.canvas;
  const ctx = canvas.getContext('2d');
  ctx.clearRect(0, 0, ball.canvasWitdth, ball.canvasHeight);
  ball.drawObject();
  let newX = ball.x + ball.dx;
  let newY = ball.y + ball.dy;
  if (newX < ball.width  || newX > ball.canvasWitdth - ball.width) {
    if (newX < ball.width) {
      ball.scores.left += 1;
    } else {
      ball.scores.right += 1;
    }
    newX = board.width / 2;
    newY = board.height / 2;
    ball.dx = Math.floor(Math.random() * ball.speedScaler) 
    - Math.floor(Math.random() * ball.speedScaler);
    ball.dy = Math.floor(Math.random() * ball.speedScaler) 
    - Math.floor(Math.random() * ball.speedScaler);
    if (ball.dx === 0) {
      ball.dx = 1;
    }
    // ball.dx = -ball.dx;
    // newX = ball.x + ball.dx;
  } 
  if (newY < ball.width || newY  > ball.canvasHeight - ball.width) {
    if (newY < ball.width) {
      ball.scores.up += 1;
    } else {
      ball.scores.down += 1;
    }
    newX = board.width / 2;
    newY = board.height / 2;
    ball.dx = Math.floor(Math.random() * ball.speedScaler) 
    - Math.floor(Math.random() * ball.speedScaler);
    ball.dy = Math.floor(Math.random() * ball.speedScaler) 
    - Math.floor(Math.random() * ball.speedScaler);
    if (ball.dx === 0) {
      ball.dx = 1;
    }
  }
  // go over all participants
  // check if bouncing, check speeds/update speed
  // do calculations on new direction/speed  
  Object.values(window.mingly.board.participants).forEach((participant) => {
    // check speed of the participant
    const speedX = participant.x - ball.playerPos[participant.uuid].x;
    const speedY = participant.y - ball.playerPos[participant.uuid].y;
    ball.playerPos[participant.uuid].x = participant.x;
    ball.playerPos[participant.uuid].y = participant.y;
    const rad = window.mingly.board.avatarRadius;
    const rightC = participant.x + rad;
    const leftC = participant.x - rad;
    const uppC = participant.y - rad;
    const lowC = participant.y + rad;
    if ((newX < rightC) && (leftC < newX) && (newY < lowC) && (uppC < this.lastY)) {
      ball.dx = -ball.dx + 0.2 * speedX; 
      ball.dy = -ball.dy + 0.2 * speedY;
      newX = ball.x +  ball.dx;
      newY = ball.y + ball.dy;
    }
  });
  const speedX = me.x - ball.mePos.x;
  const speedY = me.y - ball.mePos.y;
  ball.mePos.x = me.x;
  ball.mePos.y = me.y;
  const rad = window.mingly.board.avatarRadius;
  const rightC = me.x + rad;
  const leftC = me.x - rad;
  const uppC = me.y - rad;
  const lowC = me.y + rad;
  // should fix so it is not inside
  if ((newX < rightC) && (leftC < newX) && (newY < lowC) && (uppC < newY)) {
    ball.dx = -ball.dx + 0.2 * speedX; 
    ball.dy = -ball.dy + 0.2 * speedY;
    newX = ball.x +  ball.dx;
    newY = ball.y + ball.dy;
  }
  ball.x = newX;
  ball.y = newY;
  
  // send information to rest
  // serverConnection.send(JSON.stringify({ 'command': 
  // cmdBallGame, 'ball': 'test', 'dest': 'all' }));
  serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.BallGame }, me.asJSON(), { 'ball': ball, 'dest': 'all' })));
}

function drawBall(x, y, width, height, text, c) {
  c.beginPath();
  c.arc(x, y, width, 0, Math.PI * 2);
  c.fillStyle = '#0095DD';
  c.fill();
  c.closePath();
}

function addPosition(mePos = true) {
  const { board } = window.mingly;
  const part = {
    mat: {
      a: board.ctx.getTransform().a,
      b: board.ctx.getTransform().b,
      c: board.ctx.getTransform().c,
      d: board.ctx.getTransform().d,
      e: board.ctx.getTransform().e,
      f: board.ctx.getTransform().f, 
    },
    dim: {
      height: 1431,
      width: 3167,
    },
    transition: 'smooth',
  };
  if (mePos) {
    part.me = {
      x: board.me.x,
      y: board.me.y,
    };
  }
  board.room.toggleViews.push(part); 
}

function transitionGeneral(matNow, matTo, dim, toggle = false) {
  const { mingly } = window;
  const { board } = mingly;
  return new Promise((resolve, reject) => {
    const setStep = {
      a: Math.abs(matTo.a - matNow.a) / 100,
      b: Math.abs(matTo.b - matNow.b) / 100,
      c: Math.abs(matTo.c - matNow.c) / 100,
      d: Math.abs(matTo.d - matNow.d) / 100,
      e: Math.abs(matTo.e - matNow.e) / 100,
      f: Math.abs(matTo.f - matNow.f) / 100,
    }; 
    const maxSetStep = Math.max(setStep.a, setStep.b, setStep.c, setStep.d, setStep.e, setStep.f);
    if (toggle) {
      board.room.toggleWorking = true;
    }
    const setInt = setInterval(() => {
      matNow = {
        a: matNow.a + 0.1 * (matTo.a - matNow.a),
        b: matNow.b + 0.1 *  (matTo.b - matNow.b),
        c: matNow.c + 0.1 *  (matTo.c - matNow.c),
        d: matNow.d + 0.1 * (matTo.d - matNow.d),
        e: matNow.e + 0.1 *  (matTo.e - matNow.e),
        f: matNow.f + 0.1 *  (matTo.f - matNow.f),
      };
      const check = Math.max(
        Math.abs(matTo.a - matNow.a),
        Math.abs(matTo.b - matNow.b),
        Math.abs(matTo.c - matNow.c),
        Math.abs(matTo.d - matNow.d),
        Math.abs(matTo.e - matNow.e),
        Math.abs(matTo.f - matNow.f),
      );
      console.log(matNow);
      console.log(check);
      console.log(maxSetStep);
      if (check < maxSetStep / 10) {
        board.setView(matTo, matTo, dim);
        console.log('out of loop');
        if (toggle) {
          board.room.toggleWorking = false;
        }
        clearInterval(setInt);
        resolve();
      } else {
        console.log('in interating');
        board.setView(matNow, matNow, dim);
      }
    }, 20);
  });
}

function transitionDynamics(type) {
  const { mingly } = window;
  const { board } = mingly;
  const { ctx } = board;
  // const { room } = board;
  const { toggleViewIndex } = board.room;
  const { toggleViews } = board.room;
  console.log('in transition');
  console.log(toggleViews);
  console.log(toggleViewIndex);
  let matNow = {
    a: ctx.getTransform().a,
    b: ctx.getTransform().b,
    c: ctx.getTransform().c,
    d: ctx.getTransform().d,
    e: ctx.getTransform().e,
    f: ctx.getTransform().f,
  };
  const matTo = toggleViews[toggleViewIndex].mat;
  const dim = toggleViews[toggleViewIndex].dim;
  console.log('current matrix');
  console.log(matNow);
  console.log('target matrix');
  console.log(matTo);
  let setStep;
  let setInt;
  let maxSetStep;
  switch (type) {
    case 'smooth':
      setStep = {
        a: Math.abs(matTo.a - matNow.a) / 100,
        b: Math.abs(matTo.b - matNow.b) / 100,
        c: Math.abs(matTo.c - matNow.c) / 100,
        d: Math.abs(matTo.d - matNow.d) / 100,
        e: Math.abs(matTo.e - matNow.e) / 100,
        f: Math.abs(matTo.f - matNow.f) / 100,
      }; 
      maxSetStep = Math.max(setStep.a, setStep.b, setStep.c, setStep.d, setStep.e, setStep.f);
      board.room.toggleWorking = true;
      setInt = setInterval(() => {
        matNow = {
          a: matNow.a + 0.1 * (matTo.a - matNow.a),
          b: matNow.b + 0.1 *  (matTo.b - matNow.b),
          c: matNow.c + 0.1 *  (matTo.c - matNow.c),
          d: matNow.d + 0.1 * (matTo.d - matNow.d),
          e: matNow.e + 0.1 *  (matTo.e - matNow.e),
          f: matNow.f + 0.1 *  (matTo.f - matNow.f),
        };
        const check = Math.max(
          Math.abs(matTo.a - matNow.a),
          Math.abs(matTo.b - matNow.b),
          Math.abs(matTo.c - matNow.c),
          Math.abs(matTo.d - matNow.d),
          Math.abs(matTo.e - matNow.e),
          Math.abs(matTo.f - matNow.f),
        );
        console.log(matNow);
        console.log(check);
        console.log(maxSetStep);
        if (check < maxSetStep / 10) {
          board.setView(matTo, matTo, dim);
          console.log('out of loop');
          board.room.toggleWorking = false;
          clearInterval(setInt);
        } else {
          console.log('in interating');
          board.setView(matNow, matNow, dim);
        }
      }, 20);
      break;
    case 'test':
      console.log('test transition');
      break;
    default:
      break;
  }
}

function getListLeturesF300(lec) {
  let list = [];
  switch (lec) {
    case 1: 
      list = [
        'https://www.dropbox.com/s/ffatfao23zt4t8g/Slide1.jpeg?raw=1',
        'https://www.dropbox.com/s/4fculglp3n40zze/Slide2.jpeg?raw=1',
        'https://www.dropbox.com/s/n2xvj17p7fsdiej/Slide3.jpeg?raw=1',
        'https://www.dropbox.com/s/06qmq1bv0xsyd3x/Slide4.jpeg?raw=1',
        'https://www.dropbox.com/s/3v9exwyds1b6af3/Slide5.jpeg?raw=1',
        'https://www.dropbox.com/s/fmksy70irzv5019/Slide6.jpeg?raw=1',
        'https://www.dropbox.com/s/ehwismvhi4zg5sc/Slide7.jpeg?raw=1',
        'https://www.dropbox.com/s/gyqh0nqhl1mig5w/Slide8.jpeg?raw=1',
        'https://www.dropbox.com/s/euk95u4jeqth06g/Slide9.jpeg?raw=1',
        'https://www.dropbox.com/s/f8eq0wme6f1a463/Slide10.jpeg?raw=1',
        'https://www.dropbox.com/s/pphcwrlm0an09xo/Slide11.jpeg?raw=1',
        'https://www.dropbox.com/s/4iohm6o1g279pxm/Slide12.jpeg?raw=1',
        'https://www.dropbox.com/s/6rqn98kbgczx1j9/Slide13.jpeg?raw=1',
        'https://www.dropbox.com/s/rxjcyy1u2pgz1p2/Slide14.jpeg?raw=1',
        'https://www.dropbox.com/s/in5lhaqm31kn5ju/Slide15.jpeg?raw=1',
        'https://www.dropbox.com/s/oc0iyjlmm47nu5f/Slide16.jpeg?raw=1',
        'https://www.dropbox.com/s/zt7r3b8ekmquiqq/Slide17.jpeg?raw=1',
        'https://www.dropbox.com/s/zfdjevlbwtk4h3p/Slide18.jpeg?raw=1',
        'https://www.dropbox.com/s/47202qsfjoz0k3h/Slide19.jpeg?raw=1',
        'https://www.dropbox.com/s/n262dc0zdm229jm/Slide20.jpeg?raw=1',
        'https://www.dropbox.com/s/b5qdg0xi15uryix/Slide21.jpeg?raw=1',
        'https://www.dropbox.com/s/dg9tdtzd5mag1hb/Slide22.jpeg?raw=1',
        'https://www.dropbox.com/s/m52aipddn944fe7/Slide23.jpeg?raw=1',
        'https://www.dropbox.com/s/albb1blel7ge4no/Slide24.jpeg?raw=1',
        'https://www.dropbox.com/s/hhu7ps2s5636h7u/Slide25.jpeg?raw=1',
        'https://www.dropbox.com/s/q7i8zinohvhxyoy/Slide26.jpeg?raw=1',
        'https://www.dropbox.com/s/mw1akpml00pr34f/Slide27.jpeg?raw=1',
        'https://www.dropbox.com/s/hkj9zsooolf5fyi/Slide28.jpeg?raw=1',
        'https://www.dropbox.com/s/8q3ud81vagtzu0k/Slide29.jpeg?raw=1',
        'https://www.dropbox.com/s/ptuj7cjz33st2gu/Slide30.jpeg?raw=1',
        'https://www.dropbox.com/s/spysg5k5v05nj69/Slide31.jpeg?raw=1',
        'https://www.dropbox.com/s/fwn07wvjcmtxd9l/Slide32.jpeg?raw=1',
        'https://www.dropbox.com/s/9o0buq9jfd3d2wp/Slide33.jpeg?raw=1',
        'https://www.dropbox.com/s/avr2kxwv7722lsz/Slide34.jpeg?raw=1',
        'https://www.dropbox.com/s/ez4ech4lcwc822l/Slide35.jpeg?raw=1',
        'https://www.dropbox.com/s/wx6xxz6hucd5us9/Slide36.jpeg?raw=1',
        'https://www.dropbox.com/s/v4qrruxxxzyui68/Slide37.jpeg?raw=1',
        'https://www.dropbox.com/s/6g1ulpbwtac3pnj/Slide38.jpeg?raw=1',
        'https://www.dropbox.com/s/77f5zjtpuxvz7fm/Slide39.jpeg?raw=1',
        'https://www.dropbox.com/s/3z1523z052ptdjo/Slide40.jpeg?raw=1',
        'https://www.dropbox.com/s/6zps00kkmby4gxj/Slide41.jpeg?raw=1',
        'https://www.dropbox.com/s/cjzy96xsz7zabmp/Slide42.jpeg?raw=1',
        'https://www.dropbox.com/s/8pwjelv3ud52nh2/Slide43.jpeg?raw=1',
        'https://www.dropbox.com/s/hc08i9dv1yoiprs/Slide44.jpeg?raw=1',
        'https://www.dropbox.com/s/iqvaf0g5q3k9lj0/Slide45.jpeg?raw=1',
      ];
      break;
    case 2:
      list = [
        'https://www.dropbox.com/s/qpkx5wyi3xxnr2u/Slide1.png?raw=1',
        'https://www.dropbox.com/s/73fzl0l7twf6ehk/Slide2.png?raw=1',
        'https://www.dropbox.com/s/qjg9qoa573gvksx/Slide3.png?raw=1',
        'https://www.dropbox.com/s/rjgchb9isypwpqu/Slide4.png?raw=1',
        'https://www.dropbox.com/s/jf36bog0ctj04p6/Slide5.png?raw=1',
        'https://www.dropbox.com/s/6xx8sourctgak2t/Slide6.png?raw=1',
        'https://www.dropbox.com/s/gvvjn7o68982d5e/Slide7.png?raw=1',
        'https://www.dropbox.com/s/br1c6qgy56ypwtg/Slide8.png?raw=1',
        'https://www.dropbox.com/s/osb3s6alica4jv7/Slide9.png?raw=1',
        'https://www.dropbox.com/s/8iaioz11txzequd/Slide10.png?raw=1',
        'https://www.dropbox.com/s/hcbd9m5uu78oah6/Slide11.png?raw=1',
        'https://www.dropbox.com/s/l0xom8vquqznrss/Slide12.png?raw=1',
        'https://www.dropbox.com/s/wouhzkeot0lz593/Slide13.png?raw=1',
        'https://www.dropbox.com/s/lzeesmnqaljwvzm/Slide14.png?raw=1',
        'https://www.dropbox.com/s/qar4jg3j79awsvm/Slide15.png?raw=1',
        'https://www.dropbox.com/s/yo5cpi8sz23whws/Slide16.png?raw=1',
        'https://www.dropbox.com/s/znc7xsg8aeszla2/Slide17.png?raw=1',
        'https://www.dropbox.com/s/n4sft5pa8oeuwvp/Slide18.png?raw=1',
        'https://www.dropbox.com/s/emxbcs2pzltvj2s/Slide19.png?raw=1',
        'https://www.dropbox.com/s/4o9ksg5weuvp55r/Slide20.png?raw=1',
        'https://www.dropbox.com/s/9vedky470oceu1h/Slide21.png?raw=1',
        'https://www.dropbox.com/s/m57gc206jo4kotf/Slide22.png?raw=1',
        'https://www.dropbox.com/s/m5ytaq2tawci8d7/Slide23.png?raw=1',
        'https://www.dropbox.com/s/j9jf09hooqkc2p6/Slide24.png?raw=1',
        'https://www.dropbox.com/s/dlxi4mhm2m1jqvm/Slide25.png?raw=1',
        'https://www.dropbox.com/s/0v4kgcbn4vrhhgs/Slide26.png?raw=1',
        'https://www.dropbox.com/s/5sho9hp1sl3miao/Slide27.png?raw=1',
        'https://www.dropbox.com/s/ucyc2dvau2nw4s6/Slide28.png?raw=1',
        'https://www.dropbox.com/s/gzg0uh596bsalby/Slide29.png?raw=1',
        'https://www.dropbox.com/s/w9ypz29aewk09ih/Slide30.png?raw=1',
        'https://www.dropbox.com/s/hs2djxzf29yjq1n/Slide31.png?raw=1',
        'https://www.dropbox.com/s/op6ynz5xabyajrd/Slide32.png?raw=1',
        'https://www.dropbox.com/s/k61dh16795o6wjp/Slide33.png?raw=1',
        'https://www.dropbox.com/s/ufs620lhedn2w1i/Slide34.png?raw=1',
        'https://www.dropbox.com/s/nmvbzt0n2db2rxy/Slide35.png?raw=1',
        'https://www.dropbox.com/s/7cm241bh55gu16o/Slide36.png?raw=1',
        'https://www.dropbox.com/s/jlxqx8ttlo5ufi9/Slide37.png?raw=1',
        'https://www.dropbox.com/s/xbur0yr42vtv11d/Slide38.png?raw=1',
        'https://www.dropbox.com/s/hawbzfnlggav0dm/Slide39.png?raw=1',
        'https://www.dropbox.com/s/z6t5z1c7yabfa0o/Slide40.png?raw=1',
        'https://www.dropbox.com/s/kvn0i8jakosrtjk/Slide41.png?raw=1',
        'https://www.dropbox.com/s/vsf6s8a0fhug1hf/Slide42.png?raw=1',
        'https://www.dropbox.com/s/k3s36ydn5kj0o8j/Slide43.png?raw=1',
        'https://www.dropbox.com/s/pi6677miekcr1eg/Slide44.png?raw=1',
        'https://www.dropbox.com/s/jjabyaz5b3vopsb/Slide45.png?raw=1',
        'https://www.dropbox.com/s/seqjiszzp1qhnw5/Slide46.png?raw=1',
        'https://www.dropbox.com/s/quwyckqzg0g349p/Slide47.png?raw=1',
        'https://www.dropbox.com/s/1omghdbzzsregq5/Slide48.png?raw=1',
        'https://www.dropbox.com/s/wik4wvax1ud21aa/Slide49.png?raw=1',
        'https://www.dropbox.com/s/o2aukwf9q8l0fkx/Slide50.png?raw=1',
        'https://www.dropbox.com/s/2ta4ogxgxi4uvgc/Slide51.png?raw=1',
        'https://www.dropbox.com/s/8o2nx5st3ylby5z/Slide52.png?raw=1',
      ];
      break;
    case 3: 
      list = [
        'https://www.dropbox.com/s/f6ytosd3gico253/Slide1.PNG?raw=1',
        'https://www.dropbox.com/s/80ndbcmhdbre9ny/Slide2.PNG?raw=1',
        'https://www.dropbox.com/s/8ztl7luf3iizmyn/Slide3.PNG?raw=1',
        'https://www.dropbox.com/s/ggwmz4tmt4c1suh/Slide4.PNG?raw=1',
        'https://www.dropbox.com/s/2kvk0zaxk7zrfqv/Slide5.PNG?raw=1',
        'https://www.dropbox.com/s/95a99lke4hyuztf/Slide6.PNG?raw=1',
        'https://www.dropbox.com/s/398sjahs8oi8aym/Slide7.PNG?raw=1',
        'https://www.dropbox.com/s/av0bex167s3zv6c/Slide8.PNG?raw=1',
        'https://www.dropbox.com/s/anu94k5rktaet7s/Slide9.PNG?raw=1',
        'https://www.dropbox.com/s/1ad6eyqoz592rf1/Slide10.PNG?raw=1',
        'https://www.dropbox.com/s/6ag53q326llauhc/Slide11.PNG?raw=1',
        'https://www.dropbox.com/s/u2yutr520whsjzn/Slide12.PNG?raw=1',
        'https://www.dropbox.com/s/eh4ex1fgjpaknt1/Slide13.PNG?raw=1',
        'https://www.dropbox.com/s/8ble53x62hmc8zp/Slide14.PNG?raw=1',
        'https://www.dropbox.com/s/ukl7dmvoo3zrnbh/Slide15.PNG?raw=1',
        'https://www.dropbox.com/s/8g7iqfyrjaoyblc/Slide16.PNG?raw=1',
        'https://www.dropbox.com/s/drfd7jx9lbz20lv/Slide17.PNG?raw=1',
        'https://www.dropbox.com/s/joh0l5ngp0kvipv/Slide18.PNG?raw=1',
        'https://www.dropbox.com/s/628q4erharsn7zz/Slide19.PNG?raw=1',
        'https://www.dropbox.com/s/4d6y9zp0cn3maxm/Slide20.PNG?raw=1',
        'https://www.dropbox.com/s/fouw7t4m72i3oux/Slide21.PNG?raw=1',
        'https://www.dropbox.com/s/gsr2rf39wzk7x8v/Slide22.PNG?raw=1',
        'https://www.dropbox.com/s/2fg81qq8ei9bndq/Slide23.PNG?raw=1',
        'https://www.dropbox.com/s/ktep7nj7uk0ku10/Slide24.PNG?raw=1',
        'https://www.dropbox.com/s/qo1gqfmhqch2sx0/Slide25.PNG?raw=1',
        'https://www.dropbox.com/s/la0frigan2pzedf/Slide26.PNG?raw=1',
        'https://www.dropbox.com/s/jtrxv71d5i1wjxz/Slide27.PNG?raw=1',
        'https://www.dropbox.com/s/vcmttxjgrnamkvy/Slide28.PNG?raw=1',
        'https://www.dropbox.com/s/gh4rtdgkxpdgxf7/Slide29.PNG?raw=1',
        'https://www.dropbox.com/s/63qnk2r2enctcr4/Slide30.PNG?raw=1',
      ];
      break;
    case 4: 
      list = [
        'https://www.dropbox.com/s/01a7780thwftti0/Slide1.PNG?raw=1',
        'https://www.dropbox.com/s/qxf0sc254j2iexb/Slide2.PNG?raw=1',
        'https://www.dropbox.com/s/ck7d7ya5fb1kku2/Slide3.PNG?raw=1',
        'https://www.dropbox.com/s/8fd64ftohn01jzq/Slide4.PNG?raw=1',
        'https://www.dropbox.com/s/0eebmlqph7ewq3p/Slide5.PNG?raw=1',
        'https://www.dropbox.com/s/igfua6l524vil88/Slide6.PNG?raw=1',
        'https://www.dropbox.com/s/jo7qgxb314565xy/Slide7.PNG?raw=1',
        'https://www.dropbox.com/s/bcqw2z3jyvzepgw/Slide8.PNG?raw=1',
        'https://www.dropbox.com/s/ifokv9x2phnowon/Slide9.PNG?raw=1',
        'https://www.dropbox.com/s/54nzx3gt7ja7msy/Slide10.PNG?raw=1',
        'https://www.dropbox.com/s/5wecc39py08plfq/Slide11.PNG?raw=1',
        'https://www.dropbox.com/s/6hclt0hcotr0chf/Slide12.PNG?raw=1',
        'https://www.dropbox.com/s/to6fp4fqs4nqtlq/Slide13.PNG?raw=1',
        'https://www.dropbox.com/s/y7srccxfzzbn3ex/Slide14.PNG?raw=1',
        'https://www.dropbox.com/s/ets876p5qrrpb6m/Slide15.PNG?raw=1',
        'https://www.dropbox.com/s/xyy7q8poq94kvcw/Slide16.PNG?raw=1',
        'https://www.dropbox.com/s/b70xt5zsyzja5pz/Slide17.PNG?raw=1',
        'https://www.dropbox.com/s/duzupejf7d324hx/Slide18.PNG?raw=1',
        'https://www.dropbox.com/s/672kb36igchwosl/Slide19.PNG?raw=1',
        'https://www.dropbox.com/s/iyoxkb3jn7275oo/Slide20.PNG?raw=1',
        'https://www.dropbox.com/s/l53h2jalmqfwxrx/Slide21.PNG?raw=1',
        'https://www.dropbox.com/s/90skwasto9lp8um/Slide22.PNG?raw=1',
        'https://www.dropbox.com/s/gohsqqgpfig9vx1/Slide23.PNG?raw=1',
        'https://www.dropbox.com/s/ipj3izlyjukqifs/Slide24.PNG?raw=1',
        'https://www.dropbox.com/s/7y0aqlmv2migrf4/Slide25.PNG?raw=1',
        'https://www.dropbox.com/s/bxsademjvgrvcce/Slide26.PNG?raw=1',
        'https://www.dropbox.com/s/7arxx3n5969v1qe/Slide27.PNG?raw=1',
        'https://www.dropbox.com/s/4hn83xdc88kvbde/Slide28.PNG?raw=1',
        'https://www.dropbox.com/s/avgaizjg9cbz6ch/Slide29.PNG?raw=1',
        'https://www.dropbox.com/s/5suwtir3yx9iaev/Slide30.PNG?raw=1',
        'https://www.dropbox.com/s/4zk8n715ejcdy0y/Slide31.PNG?raw=1',
        'https://www.dropbox.com/s/n2mqd72tj0w7lpa/Slide32.PNG?raw=1',
        'https://www.dropbox.com/s/ikc7m3jt05u7agi/Slide33.PNG?raw=1',
        'https://www.dropbox.com/s/f80alc3pj8l5zlk/Slide34.PNG?raw=1',
        'https://www.dropbox.com/s/ecp1v242u6wauco/Slide35.PNG?raw=1',
        'https://www.dropbox.com/s/ufokujjhay8slu2/Slide36.PNG?raw=1',
        'https://www.dropbox.com/s/vnrpj3xpuzbnw6g/Slide37.PNG?raw=1',
        'https://www.dropbox.com/s/9fcsf651vmzgrlp/Slide38.PNG?raw=1',
        'https://www.dropbox.com/s/vaqk8fjfyshisaz/Slide39.PNG?raw=1',
        'https://www.dropbox.com/s/dtqrjv8hvju9ws1/Slide40.PNG?raw=1',
      ];
      break;
    case 5: 
      list = [
        'https://www.dropbox.com/s/pwqlywl4mfavg92/Slide1.PNG?raw=1',
        'https://www.dropbox.com/s/eqjsjn51nc9r9tv/Slide2.PNG?raw=1',
        'https://www.dropbox.com/s/osm5u3kigbxvudv/Slide3.PNG?raw=1',
        'https://www.dropbox.com/s/512vdmzmlr25xii/Slide4.PNG?raw=1',
        'https://www.dropbox.com/s/fwdj55t76m680fe/Slide5.PNG?raw=1',
        'https://www.dropbox.com/s/k5eih5fh4nil9no/Slide6.PNG?raw=1',
        'https://www.dropbox.com/s/5k2jprovbrvyq0h/Slide7.PNG?raw=1',
        'https://www.dropbox.com/s/a9hnzu6jn9l32y4/Slide8.PNG?raw=1',
        'https://www.dropbox.com/s/zqzjr9ivbcr7alc/Slide9.PNG?raw=1',
        'https://www.dropbox.com/s/ce5rhknppsu47gv/Slide10.PNG?raw=1',
        'https://www.dropbox.com/s/84muue3r70tnkks/Slide11.PNG?raw=1',
        'https://www.dropbox.com/s/82jolanpcekbsm6/Slide12.PNG?raw=1',
        'https://www.dropbox.com/s/z7x4wnp8pm7nw6m/Slide13.PNG?raw=1',
        'https://www.dropbox.com/s/jwrcljpqj2fvymr/Slide14.PNG?raw=1',
        'https://www.dropbox.com/s/s8az6va79ge570l/Slide15.PNG?raw=1',
        'https://www.dropbox.com/s/c6ryj41er2lprnr/Slide16.PNG?raw=1',
        'https://www.dropbox.com/s/8ebf47d0ozaivbi/Slide17.PNG?raw=1',
        'https://www.dropbox.com/s/2nwwntbcf0b9kgi/Slide18.PNG?raw=1',
        'https://www.dropbox.com/s/g7yg16jexnh2vfa/Slide19.PNG?raw=1',
        'https://www.dropbox.com/s/ajsszh7ye6laifc/Slide20.PNG?raw=1',
        'https://www.dropbox.com/s/2ab3ghy5jynog0l/Slide21.PNG?raw=1',
      ];
      break;
    case 6: 
      list = [
        'https://www.dropbox.com/s/i7r7hrd81f89pbl/Slide1.PNG?raw=1',
        'https://www.dropbox.com/s/u0acj40jtgd0eef/Slide2.PNG?raw=1',
        'https://www.dropbox.com/s/f7pghhjozxd33fh/Slide3.PNG?raw=1',
        'https://www.dropbox.com/s/b2px40fj345ge1v/Slide4.PNG?raw=1',
        'https://www.dropbox.com/s/sjmf1m3ahewqz3p/Slide5.PNG?raw=1',
        'https://www.dropbox.com/s/0b4lyogagtcr0z7/Slide6.PNG?raw=1',
        'https://www.dropbox.com/s/v4ta1ka32ndvuiz/Slide7.PNG?raw=1',
        'https://www.dropbox.com/s/tk6qxq7ph95rqb4/Slide8.PNG?raw=1',
        'https://www.dropbox.com/s/sjd4uixrzth19nq/Slide9.PNG?raw=1',
        'https://www.dropbox.com/s/gdzehj6pgl1sx1q/Slide10.PNG?raw=1',
        'https://www.dropbox.com/s/3icnndw8of9bjvd/Slide11.PNG?raw=1',
        'https://www.dropbox.com/s/00b042udvlqapzb/Slide12.PNG?raw=1',
        'https://www.dropbox.com/s/uya36dus3vs3zt6/Slide13.PNG?raw=1',
        'https://www.dropbox.com/s/y5x9uo1svm5g5y7/Slide14.PNG?raw=1',
        'https://www.dropbox.com/s/jthxu0mxsjci8a2/Slide15.PNG?raw=1',
        'https://www.dropbox.com/s/3rx4tdg4sjoplc9/Slide16.PNG?raw=1',
        'https://www.dropbox.com/s/uau3o6ov74rn0lz/Slide17.PNG?raw=1',
        'https://www.dropbox.com/s/ornlybuybs0jvmv/Slide18.PNG?raw=1',
        'https://www.dropbox.com/s/kbzfzborgj3x1w2/Slide19.PNG?raw=1',
        'https://www.dropbox.com/s/ky8kgf8xhfyc3va/Slide20.PNG?raw=1',
        'https://www.dropbox.com/s/tfspjiwlmtyr1bj/Slide21.PNG?raw=1',
        'https://www.dropbox.com/s/0pvuc3n1bpwnswy/Slide22.PNG?raw=1',
        'https://www.dropbox.com/s/t8hgyt6t02gho03/Slide23.PNG?raw=1',
        'https://www.dropbox.com/s/kbmyphrw2qzli6z/Slide24.PNG?raw=1',
        'https://www.dropbox.com/s/7elc1uu2zu9dldu/Slide25.PNG?raw=1',
        'https://www.dropbox.com/s/9iyil0h8ryribp3/Slide26.PNG?raw=1',
        'https://www.dropbox.com/s/0o2z6f16v1yoy6o/Slide27.PNG?raw=1',
        'https://www.dropbox.com/s/c6on3b18a3ips0n/Slide28.PNG?raw=1',
        'https://www.dropbox.com/s/aswo0xut7a8jsv7/Slide29.PNG?raw=1',
        'https://www.dropbox.com/s/ukneluhkb2zuna5/Slide30.PNG?raw=1',
        'https://www.dropbox.com/s/mnlgo433hxw3xvx/Slide31.PNG?raw=1',
        'https://www.dropbox.com/s/xez7qu8c7bvo0ly/Slide32.PNG?raw=1',
        'https://www.dropbox.com/s/gtcgy5z665dnfom/Slide33.PNG?raw=1',
      ];
      break;
    default:
      break;
  }
  return list;
}

function getMinglyPitchSlides() {
  let list = [];
  list = [
    'https://www.dropbox.com/scl/fi/sp7movu4p2gtrzyy0msec/Slide1.PNG?rlkey=rwyoe67xpg0amaj4dyewxp0g2&raw=1',
    'https://www.dropbox.com/scl/fi/zezn4iu8fv9bjf5mldd3z/Slide2.PNG?rlkey=mxz2062k3to806ffad6tip28m&raw=1',
    'https://www.dropbox.com/scl/fi/r9m947in6qxdkb6fkrfm1/Slide3.PNG?rlkey=ez9wja5zizvr0uq0pz8k71x7u&raw=1',
    'https://www.dropbox.com/scl/fi/z6l0fo6nnaiqx3ucsxuzq/Slide4.PNG?rlkey=holnbi70krdt2vit48kkqcmj2&raw=1',
    'https://www.dropbox.com/scl/fi/3nhaqiw73agdba4tqb27g/Slide5.PNG?rlkey=fjvdvg7qc1zxdmrg2bs5f0hts&raw=1',
    'https://www.dropbox.com/scl/fi/n4o91npx3zdogxgvyumey/Slide6.PNG?rlkey=506r366ycx6h9dvseif27ritk&raw=1',
    'https://www.dropbox.com/scl/fi/prem70t10vh4tep208kag/Slide7.PNG?rlkey=x9e0lx6aq90i4qo2el23hadye&raw=1',
    'https://www.dropbox.com/scl/fi/x8bx8jaigz8qiqx8gzhsd/Slide8.PNG?rlkey=s2bapbs999qq1f8dmriq5flrr&raw=1',
    'https://www.dropbox.com/scl/fi/ri6aifo8s6dvn8klmjc4m/Slide9.PNG?rlkey=vntzowwg8onc29klds8wutqcs&raw=1',
    'https://www.dropbox.com/scl/fi/kh9g7o17lbo5egfp64j93/Slide10.PNG?rlkey=aja1t9ms4yyp8nfbkyw1fcid9&raw=1',
    'https://www.dropbox.com/scl/fi/zjrx2hdoczhl7kf4l0qko/Slide11.PNG?rlkey=np1hnynfgpxw5vk62y0ouihyx&raw=1',
    'https://www.dropbox.com/scl/fi/39aulpk258cfls3x5ltkk/Slide12.PNG?rlkey=whcy2c5tbtp9ewocslq5vliwa&raw=1',
    'https://www.dropbox.com/scl/fi/wnufe55l9f3q4v2gdcd3x/Slide13.PNG?rlkey=ymgvgjszzk8ciku6vzaidtsg7&raw=1',
    'https://www.dropbox.com/scl/fi/42tmuniei0oy4txxtbvrb/Slide14.PNG?rlkey=gznfnjcjj4ynro4hbqygr2w6w&raw=1',
    'https://www.dropbox.com/scl/fi/z63v8n6bdzdvrhcb2s2sm/Slide15.PNG?rlkey=3ral5228noc0jae8c5zo6yyj4&raw=1',
    'https://www.dropbox.com/scl/fi/8qtxh8e9avzehwgo6t59q/Slide16.PNG?rlkey=t93uwzgnbe41cdpxu29zuwrtz&raw=1',
    'https://www.dropbox.com/scl/fi/w5xanobv0kva4cqavi32z/Slide17.PNG?rlkey=csjw9d4us2ykbyubpjx480o4s&raw=1',
    'https://www.dropbox.com/scl/fi/kh1zn06bcgusj5clgdyvf/Slide18.PNG?rlkey=fccztzty2t0ygyhb4kxy13q1n&raw=1',
    'https://www.dropbox.com/scl/fi/1uhc99o41of31n58nejbq/Slide19.PNG?rlkey=2badsllgbphwyupdwpy6t4gpm&raw=1',
    'https://www.dropbox.com/scl/fi/dkn4j5jhr5b2oc5f34rjq/Slide20.PNG?rlkey=ieh2tkbl05qqnas90k3rvi8ap&raw=1',
    'https://www.dropbox.com/scl/fi/2t92mzr6tf8z403zmvvht/Slide21.PNG?rlkey=iajts7vutk0tdo81az2hdt86j&raw=1',
    'https://www.dropbox.com/scl/fi/vl9psq0tsckph9vuuhraf/Slide22.PNG?rlkey=l0za9kzaroo7raydlmqd1j2os&raw=1',
    'https://www.dropbox.com/scl/fi/3ks99593win7bl27jhag9/Slide23.PNG?rlkey=45bwpovtvqtw53fgds5gz4avq&raw=1',
    'https://www.dropbox.com/scl/fi/yeurccew8luz9kcln5xrl/Slide24.PNG?rlkey=9xc31b8kmk8fpjcrx4gsnhj8x&raw=1',
    'https://www.dropbox.com/scl/fi/oj7qx4qr6908e8d116ubb/Slide25.PNG?rlkey=0883ici6c0umqgjihwsqm4xtf&raw=1',
    'https://www.dropbox.com/scl/fi/q3uro6neqceiegp2nb4z1/Slide26.PNG?rlkey=8dd7dmewnxgd38idb0cc6z8ow&raw=1',
    'https://www.dropbox.com/scl/fi/fa2o8vpnwuxbnm8sevwth/Slide27.PNG?rlkey=8ea83zfe9ck64ilpbei6aeoh1&raw=1',
    'https://www.dropbox.com/scl/fi/rkfzl18rikalgxtpghavv/Slide28.PNG?rlkey=cj8wtzsh6xph35qibirghm2mm&raw=1',
    'https://www.dropbox.com/scl/fi/txo0y1r7qqtoplob3avw0/Slide29.PNG?rlkey=mkq8i9j05qvi38rlpim4f8zbp&raw=1',
    'https://www.dropbox.com/scl/fi/xl4gkpn6swt1zbuqr7v5e/Slide30.PNG?rlkey=ii7y66g7kerfbk8os66i6vbyr&raw=1',
    'https://www.dropbox.com/scl/fi/bvie3sgbjsizynf2jovbd/Slide31.PNG?rlkey=iryvc1k2tmlcdzircokjdypj1&raw=1',
    'https://www.dropbox.com/scl/fi/1rnih51zmohkzb14d0oad/Slide32.PNG?rlkey=br4jzj3vo2a5nlw4xai5mtdz7&raw=1',
    'https://www.dropbox.com/scl/fi/nzow83sg1bfmtmu6gef0s/Slide33.PNG?rlkey=aamx5k5zvshdcy35x7ks13hz6&raw=1',
    'https://www.dropbox.com/scl/fi/vor2reqxfxge60ffcwftr/Slide34.PNG?rlkey=zflj0izh5587za2r4qntvc4tq&raw=1',
    'https://www.dropbox.com/scl/fi/0qo2eeqfd4rr1t1x8m2au/Slide35.PNG?rlkey=y4otumaoe6kebeqp0rksne025&raw=1',
    'https://www.dropbox.com/scl/fi/i8sji5g75wr6cuzvth2tv/Slide36.PNG?rlkey=tl41pe0c34krkih1fjywhv6yp&raw=1',
    'https://www.dropbox.com/scl/fi/ef4na89rkyc2pvucjuyeg/Slide37.PNG?rlkey=ivgc40lzobbsp5pxha4odww0h&raw=1',
    'https://www.dropbox.com/scl/fi/9uc2fcfrdjrgg3196okt8/Slide38.PNG?rlkey=0t7d54zaiqc1sxsbt11hdkh89&raw=1',
    'https://www.dropbox.com/scl/fi/zs5ll8ul73cusyuvls835/Slide39.PNG?rlkey=pwt915fglaphb619nhjjjb82p&raw=1',
    'https://www.dropbox.com/scl/fi/yp24t20vf6zbyzuqn74xy/Slide40.PNG?rlkey=9sa2awo4f026v0ag8tca8w5ib&raw=1',
    'https://www.dropbox.com/scl/fi/i731b7bw27jd49lpcj7f8/Slide41.PNG?rlkey=lqv7vp2ihevhdaz3tf7xnx57g&raw=1',
    'https://www.dropbox.com/scl/fi/6s7z39s21gz74htyhqrtc/Slide42.PNG?rlkey=4122e4oyhwhu6earvv27ne21y&raw=1',
    'https://www.dropbox.com/scl/fi/z3i8hhk0yp4tgjwz9axm4/Slide43.PNG?rlkey=t5iwt2aqloydfq1aanxncrcuz&raw=1',
    'https://www.dropbox.com/scl/fi/5o7t1affrnzn1v9jb0xto/Slide44.PNG?rlkey=qwet1vl2p2hfj9ntnjzsvpl6m&raw=1',
    'https://www.dropbox.com/scl/fi/4hnlwyzsu75o6xozw43f3/Slide45.PNG?rlkey=b1oai32knakdpt6rr9zpevp8r&raw=1',
    'https://www.dropbox.com/scl/fi/wzkjk28uafpxj7phue9f3/Slide46.PNG?rlkey=n156fi12gralulyhi5va506ti&raw=1',
    'https://www.dropbox.com/scl/fi/3sfdzmklrj91ki792goig/Slide47.PNG?rlkey=9ltqaqp835bd0ddxgob9sgeqg&raw=1',
    'https://www.dropbox.com/scl/fi/w3pdivgfhnlr6ga2rt1si/Slide48.PNG?rlkey=vvliocxsuyq4onevc9jg2hm0j&raw=1',
    'https://www.dropbox.com/scl/fi/28wymhc1touug0lfxbisp/Slide49.PNG?rlkey=lepaevd618atzdkkl2cywt033&raw=1',
    'https://www.dropbox.com/scl/fi/ispmt09rsv6oqqpqa0kh9/Slide50.PNG?rlkey=8i7jwxnfjdyu8d7guayab5079&raw=1',
    'https://www.dropbox.com/scl/fi/w8sx0u6n5y7bt34buxnjr/Slide51.PNG?rlkey=3g4d1fgj2djdk38ehzcufbnw4&raw=1',
    'https://www.dropbox.com/scl/fi/cb6r9vzizqdirczbgatei/Slide52.PNG?rlkey=x44utxo6sbnl6yiukgtpt9xoi&raw=1',
  ];
  return list;
}

function getMinglyWorldSlides(lec) {
  let list = [];
  switch (lec) {
    case 1: 
      list = [
        'https://www.dropbox.com/s/mcm63b9dsrqc86y/Slide2.jpeg?raw=1',
      ];
      break;
    case 2:
      list = [
        'https://www.dropbox.com/s/tj467snh6gfmxks/Slide3.jpeg?raw=1',
      ];
      break;
    case 3: 
      list = [
        'https://www.dropbox.com/s/4d5rfg4n2u9psy0/Slide4.jpeg?raw=1',
      ];
      break;
    case 4: 
      list = [
        'https://www.dropbox.com/s/wcfautcgr9nj3c0/Slide8.jpeg?raw=1',
        'https://www.dropbox.com/s/y7ent4i41oey1hn/Slide9.jpeg?raw=1',
        'https://www.dropbox.com/s/8b93c23dt8tglve/Slide10.jpeg?raw=1',
        'https://www.dropbox.com/s/u9cpnqsabx2u8uo/Slide11.jpeg?raw=1',
        'https://www.dropbox.com/s/jtx2oh925ngdg66/Slide12.jpeg?raw=1',
        'https://www.dropbox.com/s/isn09jiz4e81ku0/Slide13.jpeg?raw=1',
      ];
      break;
    case 5: 
      list = [
        'https://www.dropbox.com/s/067uryx3zpc7svc/Slide14.jpeg?raw=1',
      ];
      break;
    case 6: 
      list = [
        'https://www.dropbox.com/s/gn9zc470q31poh7/Slide15.jpeg?raw=1',
        'https://www.dropbox.com/s/gjzlnu9h0vjkb8j/Slide16.jpeg?raw=1',
        'https://www.dropbox.com/s/k64zn4jjsm4qx9h/Slide17.jpeg?raw=1',
        'https://www.dropbox.com/s/iqko7ikorun1e6o/Slide18.jpeg?raw=1',
        'https://www.dropbox.com/s/riwb0oqdrk1n2z1/Slide19.jpeg?raw=1',
        'https://www.dropbox.com/s/2ir8zxsdv1y0h4b/Slide20.jpeg?raw=1',
      ];
      break;
    case 7: 
      list = [
        'https://www.dropbox.com/s/vbeor64u0n4qvnb/Slide21.jpeg?raw=1',
      ];
      break;
    case 8: 
      list = [
        'https://www.dropbox.com/s/9y7tw9d5hwz66j6/Slide22.jpeg?raw=1',
      ];
      break;
    default:
      break;
  }
  return list;
}

// terrible, but cannot edit server file etc
function initGuessTheCity() {
  const guessTheCityDB = [];
  guessTheCityDB.push({
    city: 'Oslo',
    link: 'https://images.unsplash.com/photo-1614517409437-2e25e4f5dbed?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e29zbG99fHx8fHx8MTYyODU0MDQ4Mw&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'Oslo',
    link: 'https://images.unsplash.com/photo-1603123103222-f8395b4de850?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e29zbG99fHx8fHx8MTYyODU0MDYxNg&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'Oslo',
    link: 'https://images.unsplash.com/photo-1582579302568-ed7371395d2c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e29zbG99fHx8fHx8MTYyODU0MDY0NA&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'Stockholm',
    link: 'https://images.unsplash.com/photo-1442310205806-e3ff990dfd46?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e3N0b2NraG9sbX18fHx8fHwxNjI4NTQwNjgz&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'Stockholm',
    link: 'https://images.unsplash.com/photo-1610637353072-bbd43496ad5b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e3N0b2NraG9sbX18fHx8fHwxNjI4NTQwNzAz&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'Stockholm',
    link: 'https://images.unsplash.com/photo-1552643674-05000858c944?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e3N0b2NraG9sbX18fHx8fHwxNjI4NTQwNzQx&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'Stockholm',
    link: 'https://images.unsplash.com/photo-1552643674-05000858c944?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e3N0b2NraG9sbX18fHx8fHwxNjI4NTQwNzQx&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'Bergen',
    link: 'https://images.unsplash.com/photo-1547284743-7564903fe245?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2Jlcmdlbn18fHx8fHwxNjI4NTQwNzg3&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'Bergen',
    link: 'https://images.unsplash.com/photo-1543928810-0683973efda8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2Jlcmdlbn18fHx8fHwxNjI4NTQwODAw&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });
  
  guessTheCityDB.push({
    city: 'Bergen',
    link: 'https://images.unsplash.com/photo-1625101325628-fd5606681f9e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2Jlcmdlbn18fHx8fHwxNjI4NTQwODEw&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'Copenhagen',
    link: 'https://images.unsplash.com/photo-1607252111697-8823bb97d0c4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2NvcGVuaGFnZW59fHx8fHx8MTYyODU0MDg3NQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'Copenhagen',
    link: 'https://images.unsplash.com/photo-1610115140451-35292839c7e6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2NvcGVuaGFnZW59fHx8fHx8MTYyODU0MDg5OA&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'Copenhagen',
    link: 'https://images.unsplash.com/photo-1613463189322-6ba710eed583?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2NvcGVuaGFnZW59fHx8fHx8MTYyODU0MDkyMQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });  

  guessTheCityDB.push({
    city: 'Berlin',
    link: 'https://images.unsplash.com/photo-1612357918287-c62a2f8b18d5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2Jlcmxpbn18fHx8fHwxNjI4NTQxMDAz&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'Berlin',
    link: 'https://images.unsplash.com/photo-1551526793-fc99b5ea9919?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2Jlcmxpbn18fHx8fHwxNjI4NTQxMDMy&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'Berlin',
    link: 'https://images.unsplash.com/photo-1534595277048-5eefc67087f5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2Jlcmxpbn18fHx8fHwxNjI4NTQxMDQ4&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'London',
    link: 'https://images.unsplash.com/photo-1490642914619-7955a3fd483c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2xvbmRvbn18fHx8fHwxNjI4NTQxMDcz&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'London',
    link: 'https://images.unsplash.com/photo-1470215645605-f2afbb72eedb?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2xvbmRvbn18fHx8fHwxNjI4NTQxMDgx&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'London',
    link: 'https://images.unsplash.com/photo-1519056312994-33952f238fac?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2xvbmRvbn18fHx8fHwxNjI4NTQxMDk0&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'London',
    link: 'https://images.unsplash.com/photo-1534950317861-306ba8f4e382?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2xvbmRvbn18fHx8fHwxNjI4NTQxMTA2&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'London',
    link: 'https://images.unsplash.com/photo-1473089855960-e16e88aa3bfd?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2xvbmRvbn18fHx8fHwxNjI4NTQxMTE1&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'London',
    link: 'https://images.unsplash.com/photo-1490436512653-03e8f0e01d54?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2xvbmRvbn18fHx8fHwxNjI4NTQxMTMz&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'London',
    link: 'https://images.unsplash.com/photo-1505760236854-1d4519a11982?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2xvbmRvbn18fHx8fHwxNjI4NTQxMTcz&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'London',
    link: 'https://images.unsplash.com/photo-1523540499309-18d7a30ddf76?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2xvbmRvbn18fHx8fHwxNjI4NTQxMTg3&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'London',
    link: 'https://images.unsplash.com/photo-1500319504970-a53dc034bc15?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2xvbmRvbn18fHx8fHwxNjI4NTQxMjEy&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'London',
    link: 'https://images.unsplash.com/photo-1474606030380-107829a22fc6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2xvbmRvbn18fHx8fHwxNjI4NTQxMjM2&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'New York',
    link: 'https://images.unsplash.com/photo-1598998423695-15da3392ea8b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e25ldyB5b3JrfXx8fHx8fDE2Mjg1NTM4NTY&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'New York',
    link: 'https://images.unsplash.com/photo-1534430480872-3498386e7856?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e25ldyB5b3JrfXx8fHx8fDE2Mjg1NTM4ODY&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'New York',
    link: 'https://images.unsplash.com/photo-1500916434205-0c77489c6cf7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e25ldyB5b3JrfXx8fHx8fDE2Mjg1NTM5MDY&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'New York',
    link: 'https://images.unsplash.com/photo-1596458088684-f9c9cefd4fc5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e25ldyB5b3JrfXx8fHx8fDE2Mjg1NTM5MjM&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'New York',
    link: 'https://images.unsplash.com/photo-1474027867902-ad216abcc1c6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e25ldyB5b3JrfXx8fHx8fDE2Mjg1NTM5Mzk&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'New York',
    link: 'https://images.unsplash.com/photo-1593230884137-9efdbbbd7f0d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e25ldyB5b3JrfXx8fHx8fDE2Mjg1NTM5NTE&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'New York',
    link: 'https://images.unsplash.com/photo-1501455798972-e374108fa04f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e25ldyB5b3JrfXx8fHx8fDE2Mjg1NTM5Njc&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'Paris',
    link: 'https://images.unsplash.com/photo-1522582324369-2dfc36bd9275?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e3BhcmlzfXx8fHx8fDE2Mjg1NTQwMjQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'Paris',
    link: 'https://images.unsplash.com/photo-1437125827287-55d26ddee785?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e3BhcmlzfXx8fHx8fDE2Mjg1NTQwNTM&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'Paris',
    link: 'https://images.unsplash.com/photo-1470102287786-83270382dfb0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e3BhcmlzfXx8fHx8fDE2Mjg1NTQwNjQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'Paris',
    link: 'https://images.unsplash.com/photo-1526389523699-5d3baa8a052b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e3BhcmlzfXx8fHx8fDE2Mjg1NTQwNzc&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'Paris',
    link: 'https://images.unsplash.com/photo-1499856678450-02322b3f03be?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e3BhcmlzfXx8fHx8fDE2Mjg1NTQwOTI&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080',
  });

  guessTheCityDB.push({
    city: 'Chicago',
    link: 'https://images.unsplash.com/photo-1519144196087-b93829f1356d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2NoaWNhZ299fHx8fHx8MTYzMDc2NTA5Mg&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Chicago',
    link: 'https://images.unsplash.com/photo-1473873446975-123c5143248b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2NoaWNhZ299fHx8fHx8MTYzMDc2NTEyMA&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Chicago',
    link: 'https://images.unsplash.com/photo-1466969704384-405f547b0e01?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2NoaWNhZ299fHx8fHx8MTYzMDc2NTEzMg&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Chicago',
    link: 'https://images.unsplash.com/photo-1492541935996-83ce152056e1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2NoaWNhZ299fHx8fHx8MTYzMDc2NTE0Mw&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Chicago',
    link: 'https://images.unsplash.com/photo-1606127423579-81368c61d6d7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2NoaWNhZ299fHx8fHx8MTYzMDc2NTE1Nw&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Los Angeles',
    link: 'https://images.unsplash.com/photo-1587164124634-412e286e2b23?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2xvcyBhbmdlbGVzfXx8fHx8fDE2MzA3NjUyODE&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Los Angeles',
    link: 'https://images.unsplash.com/photo-1471039497385-b6d6ba609f9c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2xvcyBhbmdlbGVzfXx8fHx8fDE2MzA3NjUyNjE&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Los Angeles',
    link: 'https://images.unsplash.com/photo-1529088766789-5e9c0c25b529?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2xvcyBhbmdlbGVzfXx8fHx8fDE2MzA3NjUyOTQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Los Angeles',
    link: 'https://images.unsplash.com/photo-1492086517200-9393d4eb53bf?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2xvcyBhbmdlbGVzfXx8fHx8fDE2MzA3NjUzMDc&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Los Angeles',
    link: 'https://images.unsplash.com/photo-1557361921-4b76d1fa1410?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2xvcyBhbmdlbGVzfXx8fHx8fDE2MzA3NjUzMzQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Los Angeles',
    link: 'https://images.unsplash.com/photo-1608187557818-10e4804a07c3?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e3ZlbmljZSBiZWFjaH18fHx8fHwxNjMwNzY1Mzk4&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Los Angeles',
    link: 'https://images.unsplash.com/photo-1477735392287-289ae08ee862?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e3ZlbmljZSBiZWFjaH18fHx8fHwxNjMwNzY1NDE5&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Los Angeles',
    link: 'https://images.unsplash.com/photo-1630090341415-2cca00791c76?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e3NhbnRhIG1vbmljYX18fHx8fHwxNjMwNzY1NDQw&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Los Angeles',
    link: 'https://images.unsplash.com/photo-1611257638238-882945be7a7d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e3NhbnRhIG1vbmljYX18fHx8fHwxNjMwNzY1NDY5&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Los Angeles',
    link: 'https://images.unsplash.com/photo-1596293108908-e1b87a55ee8f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e21hbGlidX18fHx8fHwxNjMwNzY1NDk2&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  // here

  guessTheCityDB.push({
    city: 'Las Vegas',
    link: 'https://images.unsplash.com/photo-1617074217965-09a2d0c74cbf?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2xhcyB2ZWdhc318fHx8fHwxNjMwNzY1NTU4&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Las Vegas',
    link: 'https://images.unsplash.com/photo-1617725658514-52e50345a012?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e3ZlZ2FzfXx8fHx8fDE2MzA3NjU2MjE&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Las Vegas',
    link: 'https://images.unsplash.com/photo-1506184515391-333e5afa6927?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e3ZlZ2FzfXx8fHx8fDE2MzA3NjU2NDU&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Las Vegas',
    link: 'https://images.unsplash.com/photo-1543879066-3f330d040f7e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e3ZlZ2FzfXx8fHx8fDE2MzA3NjU2NjI&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Las Vegas',
    link: 'https://images.unsplash.com/photo-1543321269-9d86d3680e1c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e3ZlZ2FzfXx8fHx8fDE2MzA3NjU2NzU&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'San Francisco',
    link: 'https://images.unsplash.com/photo-1504912490605-888b88cd7b94?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e3NhbiBmcmFuY2lzY299fHx8fHx8MTYzMDc2NTczMw&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'San Francisco',
    link: 'https://images.unsplash.com/photo-1526000130-b9ea509bfbf3?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e3NhbiBmcmFuY2lzY299fHx8fHx8MTYzMDc2NTc0Ng&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'San Francisco',
    link: 'https://images.unsplash.com/photo-1453136390024-6574de0900b5?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e3NhbiBmcmFuY2lzY299fHx8fHx8MTYzMDc2NTc4MA&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'San Francisco',
    link: 'https://images.unsplash.com/photo-1493619882410-1d8e0cd1159c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e3NhbiBmcmFuY2lzY299fHx8fHx8MTYzMDc2NTc5OQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'San Francisco',
    link: 'https://images.unsplash.com/photo-1439396087961-98bc12c21176?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e3NhbiBmcmFuY2lzY299fHx8fHx8MTYzMDc2NTgxMA&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Seattle',
    link: 'https://images.unsplash.com/photo-1541347265260-266ecda7cb08?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e1NlYXR0bGV9fHx8fHx8MTYzMDc2NTg5NA&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Seattle',
    link: 'https://images.unsplash.com/photo-1542223616-9de9adb5e3e8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e1NlYXR0bGV9fHx8fHx8MTYzMDc2NTkwNg&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Seattle',
    link: 'https://images.unsplash.com/photo-1543129861-67b33535987a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e1NlYXR0bGV9fHx8fHx8MTYzMDc2NTkxMw&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Seattle',
    link: 'https://images.unsplash.com/photo-1628562035343-54c22faa0f15?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e1NlYXR0bGV9fHx8fHx8MTYzMDc2NTkzMw&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Boston',
    link: 'https://images.unsplash.com/photo-1566222709185-7a89f1593d4b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e0Jvc3Rvbn18fHx8fHwxNjMwNzY1OTY2&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Boston',
    link: 'https://images.unsplash.com/photo-1566391980990-81bbd9aa1902?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e0Jvc3Rvbn18fHx8fHwxNjMwNzY1OTgx&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Boston',
    link: 'https://images.unsplash.com/photo-1556079337-a837a2d11f04?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e0Jvc3Rvbn18fHx8fHwxNjMwNzY1OTk0&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Boston',
    link: 'https://images.unsplash.com/photo-1606373664971-bf9b25eb508d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e0Jvc3Rvbn18fHx8fHwxNjMwNzY2MDE2&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Prague',
    link: 'https://images.unsplash.com/photo-1560684955-20cd656eecdd?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e1ByYWd1ZX18fHx8fHwxNjMwNzY2MDQ5&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Prague',
    link: 'https://images.unsplash.com/photo-1512427410951-151eb56bbd7e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e1ByYWd1ZX18fHx8fHwxNjMwNzY2MDY3&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Prague',
    link: 'https://images.unsplash.com/photo-1584862442227-f4f11eba33f0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e1ByYWd1ZX18fHx8fHwxNjMwNzY2MDc5&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Prague',
    link: 'https://images.unsplash.com/photo-1562353846-5d1c3b50669b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e1ByYWd1ZX18fHx8fHwxNjMwNzY2MDkx&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Prague',
    link: 'https://images.unsplash.com/photo-1444388204584-7d0da8506291?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e1ByYWd1ZX18fHx8fHwxNjMwNzY2MTAz&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Prague',
    link: 'https://images.unsplash.com/photo-1591649389515-2e1640c7ac8d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e1ByYWd1ZX18fHx8fHwxNjMwNzY2MTEy&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Vienna',
    link: 'https://images.unsplash.com/photo-1573680797636-cf40fb037049?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e1ZpZW5uYX18fHx8fHwxNjMwNzY2MTQ2&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Vienna',
    link: 'https://images.unsplash.com/photo-1616251892593-65467ead0e81?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e1ZpZW5uYX18fHx8fHwxNjMwNzY2MTY0&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Vienna',
    link: 'https://images.unsplash.com/photo-1516550893923-42d28e5677af?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e1ZpZW5uYX18fHx8fHwxNjMwNzY2MTgx&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Vienna',
    link: 'https://images.unsplash.com/photo-1603804995518-fff3be6f4c58?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e1ZpZW5uYX18fHx8fHwxNjMwNzY2MjA0&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Vienna',
    link: 'https://images.unsplash.com/photo-1588836836419-4da6e03e7466?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e1ZpZW5uYX18fHx8fHwxNjMwNzY2MjE1&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Amsterdam',
    link: 'https://images.unsplash.com/photo-1536880756060-98a6a140f0a7?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e0Ftc3RlcmRhbX18fHx8fHwxNjMwNzY2Mjc2&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Amsterdam',
    link: 'https://images.unsplash.com/photo-1526310098835-b0a077889002?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e0Ftc3RlcmRhbX18fHx8fHwxNjMwNzY2Mjg0&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Amsterdam',
    link: 'https://images.unsplash.com/photo-1520277513529-5c5f30718663?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e0Ftc3RlcmRhbX18fHx8fHwxNjMwNzY2MzEw&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Amsterdam',
    link: 'https://images.unsplash.com/photo-1519134855683-11ea296ec3e1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e0Ftc3RlcmRhbX18fHx8fHwxNjMwNzY2MzIz&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Amsterdam',
    link: 'https://images.unsplash.com/photo-1489340515768-943cfb51431a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e0Ftc3RlcmRhbX18fHx8fHwxNjMwNzY2MzMz&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Amsterdam',
    link: 'https://images.unsplash.com/photo-1585074638762-e9ca95ce0d4c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e0Ftc3RlcmRhbX18fHx8fHwxNjMwNzY2MzU4&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Brussels',
    link: 'https://images.unsplash.com/photo-1582474352448-33995ae2968d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e0JydXNzZWxzfXx8fHx8fDE2MzA3NjYzOTk&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Brussels',
    link: 'https://images.unsplash.com/photo-1562844519-9fe454a09369?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e0JydXNzZWxzfXx8fHx8fDE2MzA3NjY0Mjc&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Brussels',
    link: 'https://images.unsplash.com/photo-1566883998227-325989030e62?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e0JydXNzZWxzfXx8fHx8fDE2MzA3NjY0NDM&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Brussels',
    link: 'https://images.unsplash.com/photo-1618679343020-9f1e46c4629a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e0JydXNzZWxzfXx8fHx8fDE2MzA3NjY0NTk&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Brussels',
    link: 'https://images.unsplash.com/photo-1624305043552-ca7e39d04768?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e0JydXNzZWxzfXx8fHx8fDE2MzA3NjY0Nzk&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Zurich',
    link: 'https://images.unsplash.com/photo-1585586813880-257f9b38d25d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e1p1cmljaH18fHx8fHwxNjMwNzY2NTM5&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Zurich',
    link: 'https://images.unsplash.com/photo-1593765519996-a51eaff0fcc6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e1p1cmljaH18fHx8fHwxNjMwNzY2NTU3&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Zurich',
    link: 'https://images.unsplash.com/photo-1566330543597-23ca2697a257?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e1p1cmljaH18fHx8fHwxNjMwNzY2NTg3&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Zurich',
    link: 'https://images.unsplash.com/photo-1585586823259-d82e0cf6f63d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e1p1cmljaH18fHx8fHwxNjMwNzY2NjE3&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });
  
  guessTheCityDB.push({
    city: 'Barcelona',
    link: '', 
  });

  guessTheCityDB.push({
    city: 'Barcelona',
    link: 'https://images.unsplash.com/photo-1564221710304-0b37c8b9d729?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e0JhcmNlbG9uYX18fHx8fHwxNjMwNzY2NjUx&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Barcelona',
    link: 'https://images.unsplash.com/photo-1583275444506-91d0dfbb5841?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e0JhcmNlbG9uYX18fHx8fHwxNjMwNzY2NjYx&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Barcelona',
    link: 'https://images.unsplash.com/photo-1503306488045-e91a3faca799?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e0JhcmNlbG9uYX18fHx8fHwxNjMwNzY2Njcz&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Barcelona',
    link: 'https://images.unsplash.com/photo-1528727597952-973a119b7374?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e0JhcmNlbG9uYX18fHx8fHwxNjMwNzY2Njky&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Barcelona',
    link: 'https://images.unsplash.com/photo-1528744598421-b7b93e12df15?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e0JhcmNlbG9uYX18fHx8fHwxNjMwNzY2NzEz&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Madrid',
    link: 'https://images.unsplash.com/photo-1562345622-9c96107f7471?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e01hZHJpZH18fHx8fHwxNjMwNzY2NzQ4&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Madrid',
    link: 'https://images.unsplash.com/photo-1594719072279-4cef70e118f0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e01hZHJpZH18fHx8fHwxNjMwNzY2NzY1&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Madrid',
    link: 'https://images.unsplash.com/photo-1594419810692-6107792a9768?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e01hZHJpZH18fHx8fHwxNjMwNzY2ODEw&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Madrid',
    link: 'https://images.unsplash.com/photo-1557343915-4a3fd3adb659?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e01hZHJpZH18fHx8fHwxNjMwNzY2ODIy&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Madrid',
    link: 'https://images.unsplash.com/photo-1509845350455-fb0c36048db1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e01hZHJpZH18fHx8fHwxNjMwNzY2ODUz&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Milan',
    link: 'https://images.unsplash.com/photo-1601476639090-ceb781672a6c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e01pbGFufXx8fHx8fDE2MzA3NjY4ODk&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Milan',
    link: 'https://images.unsplash.com/photo-1620475655006-0f0c6a10a221?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e01pbGFufXx8fHx8fDE2MzA3NjY5MTQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Milan',
    link: 'https://images.unsplash.com/photo-1582059659791-9d01b37a84a0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e01pbGFufXx8fHx8fDE2MzA3NjY5MzE&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Milan',
    link: 'https://images.unsplash.com/photo-1556708115-f53e75feb52b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e01pbGFufXx8fHx8fDE2MzA3NjY5NTE&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Milan',
    link: 'https://images.unsplash.com/photo-1566906603626-f3b30ca3e9cf?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e01pbGFufXx8fHx8fDE2MzA3NjY5NzM&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Rome',
    link: 'https://images.unsplash.com/photo-1584213211632-3e3091fe55e8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e1JvbWV9fHx8fHx8MTYzMDc2NzAwMg&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Rome',
    link: 'https://images.unsplash.com/photo-1562066919-f42721760188?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e1JvbWV9fHx8fHx8MTYzMDc2NzAxNg&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Rome',
    link: 'https://images.unsplash.com/photo-1562141903-fa3c3de71b77?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e1JvbWV9fHx8fHx8MTYzMDc2NzA0Mg&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Rome',
    link: 'https://images.unsplash.com/photo-1601645234855-761c5648639f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e1JvbWV9fHx8fHx8MTYzMDc2NzA1OA&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Rome',
    link: 'https://images.unsplash.com/photo-1596634583480-7e12352094a8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e1JvbWV9fHx8fHx8MTYzMDc2NzA3OA&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Athens',
    link: 'https://images.unsplash.com/photo-1595445695224-6d6dbb24f1e8?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2F0aGVuc318fHx8fHwxNjMwNzY3MTA5&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Athens',
    link: 'https://images.unsplash.com/photo-1622616234995-072f0e880b81?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2F0aGVuc318fHx8fHwxNjMwNzY3MTIw&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Athens',
    link: 'https://images.unsplash.com/photo-1548260220-7f439c9a91d3?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2F0aGVuc318fHx8fHwxNjMwNzY3MTI2&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Athens',
    link: 'https://images.unsplash.com/photo-1583635658408-1f93b11d7941?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2F0aGVuc318fHx8fHwxNjMwNzY3MTU3&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Athens',
    link: 'https://images.unsplash.com/photo-1628337284384-b1da496b2937?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2F0aGVuc318fHx8fHwxNjMwNzY3MTY4&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });
  
  guessTheCityDB.push({
    city: 'Helsinki',
    link: 'https://images.unsplash.com/photo-1570097658719-ddb52358722a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2hlbHNpbmtpfXx8fHx8fDE2MzA3NjcyMTQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Helsinki',
    link: 'https://images.unsplash.com/photo-1610254901872-651ac0e4544a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2hlbHNpbmtpfXx8fHx8fDE2MzA3NjcyMzE&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Helsinki',
    link: 'https://images.unsplash.com/photo-1563100457-961b222a4e1f?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2hlbHNpbmtpfXx8fHx8fDE2MzA3NjcyNTQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Helsinki',
    link: 'https://images.unsplash.com/photo-1570097658726-ed3c922a1bc9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2hlbHNpbmtpfXx8fHx8fDE2MzA3NjcyNjE&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Helsinki',
    link: 'https://images.unsplash.com/photo-1622914844435-a8be63fa3403?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2hlbHNpbmtpfXx8fHx8fDE2MzA3NjcyNzQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Tokyo',
    link: 'https://images.unsplash.com/photo-1551641506-ee5bf4cb45f1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e3Rva3lvfXx8fHx8fDE2MzA3NjczMDE&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Tokyo',
    link: 'https://images.unsplash.com/photo-1542090519-7952b6451b60?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e3Rva3lvfXx8fHx8fDE2MzA3NjczMDc&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Tokyo',
    link: 'https://images.unsplash.com/photo-1557409518-691ebcd96038?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e3Rva3lvfXx8fHx8fDE2MzA3NjczMjk&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Tokyo',
    link: 'https://images.unsplash.com/photo-1546618943-254313868323?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e3Rva3lvfXx8fHx8fDE2MzA3NjczMzg&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Tokyo',
    link: 'https://images.unsplash.com/flagged/photo-1580051720305-a944536881fb?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e3Rva3lvfXx8fHx8fDE2MzA3NjczNTg&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Hong Kong',
    link: 'https://images.unsplash.com/photo-1557220543-2f8a7d8ad14e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2hvbmcga29uZ318fHx8fHwxNjMwNzY3Mzkw&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Hong Kong',
    link: 'https://images.unsplash.com/photo-1559463802-08e5a2c41ea4?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2hvbmcga29uZ318fHx8fHwxNjMwNzY3NDAz&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Hong Kong',
    link: 'https://images.unsplash.com/photo-1558253843-fb6179998e81?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2hvbmcga29uZ318fHx8fHwxNjMwNzY3NDI1&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Hong Kong',
    link: 'https://images.unsplash.com/photo-1614127561240-6c2a056f1556?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2hvbmcga29uZ318fHx8fHwxNjMwNzY3NDU4&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Hong Kong',
    link: 'https://images.unsplash.com/photo-1580918445189-950994975bb1?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e2hvbmcga29uZ318fHx8fHwxNjMwNzY3NDY2&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Beijing',
    link: 'https://images.unsplash.com/photo-1587632177056-10e4f9511bf9?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e0JlaWppbmd9fHx8fHx8MTYzMDc2NzUwNA&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });
  
  guessTheCityDB.push({
    city: 'Beijing',
    link: 'https://images.unsplash.com/photo-1630121007795-d99b3041c5a0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e0JlaWppbmd9fHx8fHx8MTYzMDc2NzUzMQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });
  
  guessTheCityDB.push({
    city: 'Beijing',
    link: 'https://images.unsplash.com/photo-1554254196-8ed68bf5819b?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e0JlaWppbmd9fHx8fHx8MTYzMDc2NzU4OQ&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });
  
  guessTheCityDB.push({
    city: 'Beijing',
    link: 'https://images.unsplash.com/photo-1621871129151-c18a15e39180?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e0JlaWppbmd9fHx8fHx8MTYzMDc2NzU5Ng&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });

  guessTheCityDB.push({
    city: 'Beijing',
    link: 'https://images.unsplash.com/photo-1614007155750-c623407752e0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxfDB8MXxyYW5kb218MHx8e0JlaWppbmd9fHx8fHx8MTYzMDc2NzYwNA&ixlib=rb-1.2.1&q=80&utm_campaign=api-credit&utm_medium=referral&utm_source=unsplash_source&w=1080', 
  });
  
  return guessTheCityDB;
}

export {
  Mingly,
  start,
  takeProfilePic,
  getBase64Image,
  muteAudio,
  noVideo,
  shareScreen,
  changeBackgroundPicture,
  HostChangeBackGroundPicture,
  changeCamera,
  changeMicrophone,
  chatMessage,
  changeName,
  changeProfilePic,
  sendPoll,
  answerPoll,
  sendPollStats,
  closePoll,
  sendGameInvite,
  answerQuiz,
  acceptGameInvite,
  drawGame,
  stopGame,
  updateGameData,
  getSilence,
  iAmLeaving,
  adjustVolumeAll,
  sleep,
  callParticipant,
  broadCast,
  closeMSbroadCast,
  broadCastScreenOnCanvas,
  placeBroadCastScreenOnCanvas,
  updateCanvasOnScreenPlacement,
  localBroadCast,
  createUUID,
  allFollowMyView,
  hostMute,
  hostNoVideo,
  hostPlaceAndLockParticipants,
  hostActivateNoHostMenu,
  raiseHand,
  playOrStopHandRaiseBroadcat,
  sayHiToEveryone,
  sendStatisticsMS,
  setupMsCanvasVideo,
  setAllParameters,
  updateConnections,
  boardAskToFollowView,
  changeGameMenuVisibilityFromHost,
  placeInCenterAreaForGuessTheCity,
  placeInGroups,
};
