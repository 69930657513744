<template>
  <div>
    <vue-drag-resize :isResizable="false"
                     :isDraggable="this.canDragg"
                     v-on:mousedown="draggOn"
                     v-on:mouseup="draggOn"
                    :x="0" :y="0">
      <v-card dark min-width="350">
        <CloseButton :method="closeBroadcastMinglyModeComponent" class="close_button" />
        <div class="padding">
          <h1>Mingly Mode</h1>
          <v-btn v-on:click="stopSharing()">
            Stop Sharing
          </v-btn> <v-btn v-on:click="placeScreen()">Place Screen</v-btn>
          <v-checkbox label="Broadcast video" v-on:click="onLocalBroadCast()">
          </v-checkbox>
          <v-checkbox label="Share sound (Chrome only)" v-on:click="onCheckedSound()">
          </v-checkbox>
          <!-- <v-checkbox label="Allow participants to move on screen"
                      v-on:click="onCheckedParticipantMove()"></v-checkbox> -->
          Select size:
          <v-slider v-on:mousedown="draggOff"
                  v-on:mouseup="draggOn"
                  @change="updateSize"
                  max="100" min="0" v-model="size"></v-slider>
        </div>
      </v-card>
    </vue-drag-resize>
  </div>
</template>

<script>
import VueDragResize from 'vue-drag-resize';
import { mapActions } from 'vuex';
import CloseButton from '../CloseButton.vue';
import {
  broadCastScreenOnCanvas,
  closeMSbroadCast,
  updateCanvasOnScreenPlacement,
  placeBroadCastScreenOnCanvas,
  localBroadCast,
} from '../../../../assets/js/webrtc';

export default {
  name: 'BroadcastMinglyMode',
  components: {
    VueDragResize, CloseButton,
  },
  data() {
    return {
      size: 20,
      isSound: false,
      isParticipantMove: false,
      canDragg: true,
      shareLocal: false,
    };
  },
  mounted() {
    if (window.mingly.msBroadCastOnCanvasData.active) {
      const canvasWidth = window.mingly.board.width;
      this.size = (window.mingly.msBroadCastOnCanvasData.size / canvasWidth) * 100;
    }
    // this.size =
  },
  methods: {
    ...mapActions('$_canvas', [
      'commitSetBroadcastMinglyModeActive',
    ]),
    draggOff() {
      this.canDragg = false;
    },
    draggOn() {
      this.canDragg = true;
    },
    updateSize() {
      const canvasWidth = window.mingly.board.width;
      const newSize = this.size * (canvasWidth / 100);
      const { uuid } = window.mingly.board.me;
      // eslint-disable-next-line prefer-destructuring
      const x = window.mingly.msBroadCastOnCanvas[uuid].x;
      // eslint-disable-next-line prefer-destructuring
      const y = window.mingly.msBroadCastOnCanvas[uuid].y;
      window.mingly.msBroadCastOnCanvas[uuid].size = newSize;
      // need to send it to others, so need another function here
      updateCanvasOnScreenPlacement(x, y, newSize, uuid);
    },
    onLocalBroadCast() {
      this.shareLocal = !this.shareLocal;
    },
    onCheckedSound() {
      this.isSound = !this.isSound;
    },
    onCheckedParticipantMove() {
      this.isParticipantMove = !this.isParticipantMove;
    },
    closeBroadcastMinglyModeComponent() {
      this.commitSetBroadcastMinglyModeActive();
    },
    stopSharing() {
      console.log('Stop sharing');
      if (this.shareLocal) {
        localBroadCast();
      }
      closeMSbroadCast(window.mingly.board.me.uuid);
    },
    placeScreen() {
      const { mingly } = window;
      const { board } = mingly;
      if (this.shareLocal) {
        localBroadCast();
      }
      if (mingly.msBroadCastOnCanvas[board.me.uuid] !== undefined) {
        console.log('is active');
        board.placeingObject.placingFunction = placeBroadCastScreenOnCanvas;
        board.placeingObject.isPlacing = true;
      } else {
        broadCastScreenOnCanvas(this.isSound, this.size * 10);
      }
    },
  },
};
</script>

<style scoped>
  .padding {
    padding: 2vh;
  }
  .close_button {
    position: absolute;
    right: 0.2vh;
    top: 0.2vh;
  }
</style>
