<template>
 <div class="container">
    <div class="scrollable-card">
      <v-card color="#203e5fff" class="mx-auto camera_box pa-4" max-width="600">
        <div class="padding">
          <div class="image-container" @click="$emit('openEditProfilePic')">
            <v-img :src="userProfileImage" width="240" height="240" class="center-image" />
            <v-icon large class="edit-icon">mdi-pencil</v-icon>
          </div>
        </div>
        <v-simple-table class="my-table my-4 mx-auto" max-width="500">
          <template v-slot:default>
            <tbody>
              <tr class="row-container">
                <td>Name</td>
                <td>{{ name }}</td>
                <!-- <td>{{ this.name }}</td> -->
              </tr>
              <tr class="row-container">
                <td>Display name</td>
                <td>{{ displayname }}</td>
                <!-- <td>{{ this.name }}</td> -->
              </tr>
              <tr class="row-container">
                <td>Email</td>
                <td>{{ email }}</td>
                <!-- <td>{{ this.email }}</td> -->
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </div>
    <div class="sticky-card">
        <v-card color="#203e5fff" >
          <v-card-actions class="justify-center padding_bot">
            <v-btn color="#eac252" elevation="8"
            @click="navigateTo('entermingly')">Enter Mingly</v-btn>
            <v-btn color="#eac252" elevation="8"
            @click="navigateTo('editrooms')">My rooms</v-btn>
            <!-- <v-btn color="#eac252" @click="navigateTo('create-room')">Create a room</v-btn> -->
            <!-- v-btn dark elevation="8" large class="btn_width" v-on:click="capturePhoto">
              Capture photo
              <v-icon right>
                mdi-camera
              </v-icon>
            </v-btn -->
          </v-card-actions>
        </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UserProfile',
  data() {
    return {
      changeImage: false,
      preview: false,
      name: 'Christian Heyerdahl-Larsen',
      displayname: 'Christian',
      email: 'mingly@mingly.world',
    };
  },
  computed: {
    ...mapGetters('$_userdata', ['username']),
    userProfileImage() {
      // const pic = new Image();
      let picSrc;
      if (localStorage.getItem('imgData')) {
        const loc = localStorage.getItem('imgData');
        picSrc = `data:image/png;base64,${loc}`;
      } else {
        /* eslint-disable global-require */
        picSrc = require('@/assets/SquareLogo.png');
        /* eslint-enable global-require */
      }
      return picSrc;
    },
  },
  methods: {
    enterMingly() {
      // Navigate to Mingly main room
    },
    navigateTo(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style scoped>
  .camera_box {
    margin-top: 20vh;
  }
  .padding_bot {
    padding-bottom: 2vh;
  }
  .padding {
    padding-top: 2vh;
  }
  .image-container {
  position: relative;
  width: 240px;
  height: 240px;
  margin: 0 auto;
}

.center-image {
  display: block;
  width: 100%;
  height: 100%;
}

.edit-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.4); /* semi-transparent black */
  border-radius: 50%;
  visibility: hidden; /* hidden by default */
}

.image-container:hover .edit-icon {
  visibility: visible; /* shown when hovering over the image */
}
  .my-table tr, .my-table td {
    background-color: #203e5fff;
    color: #eac252;
  }
  .row-container:hover td {
    background-color: #eac252;
    color: #203e5fff;
  }
  .container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Adjust as needed */
  }

  .scrollable-card {
    flex: 1;
    overflow-y: auto;
  }

  .sticky-card {
    position: sticky;
    bottom: 0;
    z-index: 1;
    padding-top: 20px; /* Increase the spacing between the cards */
  }
</style>
