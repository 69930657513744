<template>
 <div class="container scrollable-card">
    <div  v-for="(room, index) in rooms" :key="index">
      <v-card color="#203e5fff" class="mx-auto camera_box pa-4" max-width="600">
        <!-- Choice dialog -->
        <v-dialog v-model="choiceDialog" persistent max-width="290">
          <v-card>
            <v-card-title class="headline">Choose Image Source</v-card-title>
            <v-card-text class="mb-0">
              <v-divider></v-divider>
              <v-list>
                <v-list-item-group color="primary">
                  <v-list-item @click="openUrlDialog">
                    <v-list-item-content>
                      <v-list-item-title>Upload using URL</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider></v-divider>
                  <v-list-item @click="openMinglyDialog">
                    <v-list-item-content>
                      <v-list-item-title>Predefined Mingly Pictures</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Mingly pictures dialog -->
        <v-dialog v-model="minglyDialog" persistent max-width="290" @close="closeMinglyDialog">
        <v-card>
          <v-card-title class="headline">Mingly Pictures</v-card-title>
          <v-card-text>
            <v-list>
              <v-list-item v-for="(url, index) in minglyUrls" :key="index"
              @click="setImageFromMingly(index)">
                <v-list-item-avatar>
                  <v-img :src="url"></v-img>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>Picture {{ index + 1 }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
        </v-dialog>
        <v-dialog v-model="urlDialog" persistent max-width="290" @close="closeUrlDialog">
          <v-card>
            <v-card-title class="headline">Change Image</v-card-title>
            <v-card-text>
              <v-text-field label="Image URL" v-model="newImageUrl"></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
               <v-btn color="red" text @click="closeUrlDialog">Cancel</v-btn>
               <v-btn color="green darken-1" text @click="setImageFromUrl">OK</v-btn>
          </v-card-actions>
         </v-card>
        </v-dialog>
        <div class="header">
          <h2>{{ room.name }}</h2>
        </div>
        <div class="image-container" @click="openChoiceDialog(index)"
            :style="{backgroundImage: `url(${room.image})`}">
          <v-icon large class="edit-icon">mdi-pencil</v-icon>
        </div>
        <div class="table-toggle"
          @click="toggleTable(index)" :class="{ 'hover-background': room.showTable }">
           <h3>{{ room.showTable ? 'Hide Room Details' : 'Room Details' }}</h3>
        </div>
        <v-simple-table class="my-table my-4 mx-auto" v-if="room.showTable" max-width="500">
          <template v-slot:default>
            <tbody>
              <tr class="row-container">
                <td>Name</td>
                <td>{{ room.name }}</td>
                <!-- <td>{{ this.name }}</td> -->
              </tr>
              <tr class="row-container">
                <td>Size</td>
                <td>{{ room.size }}</td>
                <!-- <td>{{ this.name }}</td> -->
              </tr>
              <tr class="row-container">
                <td>Link</td>
                <td>{{ room.link }}</td>
                <!-- <td>{{ this.email }}</td> -->
              </tr>
              <tr class="row-container">
                <td>Protected</td>
                <td>{{ room.security }}</td>
                <!-- <td>{{ this.email }}</td> -->
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-card>
    </div>
    <div class="sticky-card">
        <v-card color="#203e5fff" >
          <v-card-actions class="justify-center padding_bot">
            <v-btn color="#eac252" elevation="8"
            @click="navigateTo('myroom')">Back</v-btn>
            <v-btn color="#eac252" elevation="8"
            @click="navigateTo('createroom')">Create Room</v-btn>
            <!-- <v-btn color="#eac252" @click="navigateTo('create-room')">Create a room</v-btn> -->
            <!-- v-btn dark elevation="8" large class="btn_width" v-on:click="capturePhoto">
              Capture photo
              <v-icon right>
                mdi-camera
              </v-icon>
            </v-btn -->
          </v-card-actions>
        </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UserProfile',
  data() {
    return {
      showTable: false,
      changeImage: false,
      preview: false,
      choiceDialog: false, // initial choice dialog visibility
      urlDialog: false, // url dialog visibility
      minglyDialog: false, // mingly dialog visibility
      closingDialog: false,
      selectedRoomIndex: null, // keep track of which room's image we're changing
      newImageUrl: '', // store the new URL entered by the user
      minglyUrls: [ // urls of the mingly images
        'https://www.dropbox.com/s/7b4m2oepjigolnx/Haust.jpg?raw=1',
        'https://www.dropbox.com/scl/fi/t9p7uf27wbjo0xs8d0ru3/Heart-of-the-City-2.jpg?rlkey=8h16bqvhjz7a3llp915wj2ubs&raw=1',
        'https://www.dropbox.com/s/smwkrgszpk97qfm/IMG_0767.jpg?raw=1',
        'https://www.dropbox.com/s/7ngeaysusn7o9u5/IMG_1038.jpg?raw=1',
        // 'https://www.dropbox.com/s/hmzrbve6aqqsa9x/IMG_1039.jpg?raw=1',
        // 'https://www.dropbox.com/s/26l0ie2xt1g44y3/IMG_2776.jpg?raw=1',
        // 'https://www.dropbox.com/s/6erib4jydiq2aj6/IMG_2888.jpg?raw=1',
        // 'https://www.dropbox.com/s/xa5wqsej3651b0o/IMG_3528.jpg?raw=1',
        // 'https://www.dropbox.com/s/sic7mk2qr8ivb28/IMG_3811.jpg?raw=1',
        // 'https://www.dropbox.com/s/o6af0lh6p9e1m1h/IMG_4154.jpg?raw=1',
        // 'https://www.dropbox.com/s/6n122sd2myu9qrl/IMG_4155.jpg?raw=1',
        // 'https://www.dropbox.com/s/zzfvbhxitx7v613/IMG_5237.JPG?raw=1',
        // 'https://www.dropbox.com/s/fudsk9m1cl1e5h4/IMG_5251.jpg?raw=1',
        // 'https://www.dropbox.com/s/shqzsqa3e916qnc/IMG_5258.jpg?raw=1',
        // 'https://www.dropbox.com/s/i9xc3jlwz89434b/IMG_5259.jpg?raw=1',
        // 'https://www.dropbox.com/s/saoufnom9tazisz/IMG_5260.jpg?raw=1',
        // 'https://www.dropbox.com/s/ss2fpgjy5qb0qce/IMG_5262.jpg?raw=1',
        // 'https://www.dropbox.com/s/3viqx3yev33k1mi/IMG_5263.jpg?raw=1',
        // 'https://www.dropbox.com/s/5uaufmanivhv5lg/IMG_5264.jpg?raw=1',
        // 'https://www.dropbox.com/s/7fdluw5x6kb0ws8/IMG_5265.jpg?raw=1',
        // 'https://www.dropbox.com/s/ijvqfw2511u58qu/IMG_5266.jpg?raw=1',
        // 'https://www.dropbox.com/s/6589zlvksevx6e6/IMG_5269.jpg?raw=1',
        // 'https://www.dropbox.com/s/q67ibm24jxxrf9z/IMG_5270.jpg?raw=1',
        // 'https://www.dropbox.com/s/ueb4crekx12d3pj/IMG_5271.jpg?raw=1',
        // 'https://www.dropbox.com/s/eamixdrrltuyucm/IMG_5272.jpg?raw=1',
        // 'https://www.dropbox.com/s/uqs0pa77ms4r32l/IMG_5273.jpg?raw=1',
        // 'https://www.dropbox.com/s/63amiczxijgpi4m/IMG_8359.jpg?raw=1',
        // 'https://www.dropbox.com/s/2vmgz45fcvkegyh/IMG_8470.jpg?raw=1',
        // 'https://www.dropbox.com/s/n2fo4ze2iv62kbw/IMG_8472.jpg?raw=1',
        // 'https://www.dropbox.com/s/o8m3vpud6hzcjhn/IMG_8655.jpg?raw=1',
        // 'https://www.dropbox.com/s/x5wbcpubqibran8/IMG_8659.jpg?raw=1',
        // 'https://www.dropbox.com/s/8r7k6phhbqhav88/IMG_8846.jpg?raw=1',
        // 'https://www.dropbox.com/s/ckl6hl6471185j5/IMG_8896.jpg?raw=1',
        // 'https://www.dropbox.com/s/8izb20yokrelzaa/IMG_8920.jpg?raw=1',
        // 'https://www.dropbox.com/s/q2f2l3m1iotd832/IMG_8921.jpg?raw=1',
        // 'https://www.dropbox.com/s/a7j571tx383kuoi/IMG_9142.jpg?raw=1',
        // 'https://www.dropbox.com/s/qjes4uaucfg1ckg/IMG_9144.jpg?raw=1',
        // 'https://www.dropbox.com/s/igvbhhgygg6wy4q/IMG_9145.jpg?raw=1',
        // 'https://www.dropbox.com/s/kt1040ezg3klt5e/IMG_9232.jpg?raw=1',
        // 'https://www.dropbox.com/s/pb6p6drpqzh5pc7/IMG_9850.jpg?raw=1',
        // 'https://www.dropbox.com/s/3tf1ku7afotxwa6/IMG_9906.jpg?raw=1',
        // 'https://www.dropbox.com/s/05fkzumyjweti7j/IMG_5573.jpeg?raw=1',
        // 'https://www.dropbox.com/s/lnr8emm60khu9z8/IMG_5574.jpeg?raw=1',
        // 'https://www.dropbox.com/s/ndf7n3h7f5llb0g/IMG_5553.jpeg?raw=1',
        // 'https://www.dropbox.com/s/5q194grqpdqvb1f/IMG_5554.jpeg?raw=1',
        // 'https://www.dropbox.com/s/cv73cx7k0y4r1m9/IMG_5555.jpeg?raw=1',
        // 'https://www.dropbox.com/s/c7zv5nx47anv284/IMG_5556.jpeg?raw=1',
        // 'https://www.dropbox.com/s/ub0qp1sq2g3yjrd/IMG_5558.jpeg?raw=1',
        // 'https://www.dropbox.com/s/g2bzgb4jmmaw5dn/IMG_5561.jpeg?raw=1',
        // 'https://www.dropbox.com/s/2sz4v0b5b23sp1l/IMG_5505.jpeg?raw=1',
        // 'https://www.dropbox.com/s/bc4pk8y20dhlrlg/IMG_5506.jpeg?raw=1',
        // 'https://www.dropbox.com/s/xp7ujqle6bzoc45/IMG_5496.jpeg?raw=1',
        // 'https://www.dropbox.com/s/0k7wn98jp23b1w0/IMG_5497.jpeg?raw=1',
        // 'https://www.dropbox.com/s/xmi8rlp86qifaeo/IMG_5422.jpeg?raw=1',
        // 'https://www.dropbox.com/s/c5bbcyx49uvdlti/IMG_5415.jpeg?raw=1',
        // 'https://www.dropbox.com/s/hqoahbziq6ilb6r/IMG_5416.jpeg?raw=1',
        // 'https://www.dropbox.com/s/fue7zo86dgzcjxx/IMG_5356.jpeg?raw=1',
        // 'https://www.dropbox.com/s/6syaav9r9c4iz8x/IMG_5334.jpeg?raw=1',
        // 'https://www.dropbox.com/s/lj7wbcmucnb8ff0/IMG_5333.jpeg?raw=1',
        // 'https://www.dropbox.com/s/lqe7v3sa7xar40z/IMG_5311.jpeg?raw=1',
        // 'https://www.dropbox.com/s/5exfehz27agdknn/IMG_5312.jpeg?raw=1',
        // 'https://www.dropbox.com/s/gakakp130gfiehm/IMG_5320.jpeg?raw=1',
        // 'https://www.dropbox.com/s/oxojw1ow0jk6sok/IMG_5304.jpeg?raw=1',
        // 'https://www.dropbox.com/s/stesrq9spmmqtpo/IMG_5305.jpeg?raw=1',
        // 'https://www.dropbox.com/s/dgctvumw07j8rco/IMG_5306.jpeg?raw=1',
        // 'https://www.dropbox.com/s/ub1pqtik2tk6e8w/IMG_5307.jpeg?raw=1',
        // 'https://www.dropbox.com/s/rvmiqw46dkjbaot/IMG_5308.jpeg?raw=1',
        // 'https://www.dropbox.com/s/m875gtefzpy2tp7/IMG_5309.jpeg?raw=1',
        // 'https://www.dropbox.com/s/53rmimt0rttyo06/IMG_8243.jpeg?raw=1',
        // 'https://www.dropbox.com/s/pjwli3u8z85w337/IMG_4612.jpeg?raw=1',
        // 'https://www.dropbox.com/s/v9yjmjtjg8v6103/IMG_3453.jpeg?raw=1',
        // 'https://www.dropbox.com/s/39zr3vuncg5yn0m/IMG_4899.jpeg?raw=1',
        // 'https://www.dropbox.com/s/pkbg79nouukwnzf/IMG_5669.jpeg?raw=1',
        // 'https://www.dropbox.com/s/i3qmsr66qcr4uty/IMG_5697.jpeg?raw=1',
        // 'https://www.dropbox.com/s/b0xq8rj7x37gbmo/IMG_1810.jpeg?raw=1',
        // 'https://www.dropbox.com/s/5lbp5uf0qr1w6ti/IMG_5750.jpeg?raw=1',
        // 'https://www.dropbox.com/s/svz27awumqqutri/IMG_5785.jpeg?raw=1',
        // 'https://www.dropbox.com/s/uwxf95hlvxlgk26/IMG_5788.jpeg?raw=1',
        // 'https://www.dropbox.com/s/um3ikkt2qkmob3s/IMG_5790.jpeg?raw=1',
        // 'https://www.dropbox.com/s/9jb48jbwfbxza9f/IMG_5800.jpeg?raw=1',
        // 'https://www.dropbox.com/s/hd7stc249tuabuo/IMG_5807.jpeg?raw=1',
        // 'https://www.dropbox.com/s/hkz0g3lnm7gmhh9/IMG_5809.jpeg?raw=1',
        // 'https://www.dropbox.com/s/p68qpb02shk85pc/IMG_5817.jpeg?raw=1',
        // 'https://www.dropbox.com/s/4prlca09x7svnmq/IMG_5851.jpeg?raw=1',
        // ... more urls
      ],
      rooms: [
        {
          name: 'My first room',
          size: 'medium',
          link: 'www.room1.com',
          security: 'open',
          image: 'https://www.dropbox.com/scl/fi/u607wycmkywi23cvv5x2z/IMG_7359.jpeg?rlkey=og1nsoafmcdyn0xmhp8dic2mi&raw=1',
          showTable: false,
        },
        {
          name: 'My second room',
          size: 'medium',
          link: 'www.room2.com',
          security: 'closed',
          image: 'https://www.dropbox.com/s/eeaq8lkpwlvhaio/IMG_2775.jpg?raw=1',
          showTable: false,
        },
        // other rooms...
      ],
    };
  },
  computed: {
    ...mapGetters('$_userdata', ['username']),
  },
  methods: {
    enterMingly() {
      // Navigate to Mingly main room
    },
    toggleTable(index) {
      this.rooms[index].showTable = !this.rooms[index].showTable;
    },
    openChoiceDialog(index) {
      if (this.closingDialog) return;
      this.selectedRoomIndex = index;
      this.choiceDialog = true;
    },
    closeChoiceDialog() {
      this.closingDialog = true;
      this.choiceDialog = false;
      this.$nextTick(() => {
        this.closingDialog = false;
      });
    },
    openUrlDialog() {
      this.closeChoiceDialog();
      this.urlDialog = true;
    },
    closeUrlDialog() {
      this.closingDialog = true;
      this.urlDialog = false;
      this.newImageUrl = '';
      this.$nextTick(() => {
        this.closingDialog = false;
      });
    },
    openMinglyDialog() {
      this.closeChoiceDialog();
      this.minglyDialog = true;
    },
    closeMinglyDialog() {
      this.closingDialog = true;
      this.minglyDialog = false;
      this.selectedRoomIndex = null;
      this.$nextTick(() => {
        this.closingDialog = false;
      });
    },
    setImageFromUrl() {
      if (this.newImageUrl) {
        this.rooms[this.selectedRoomIndex].image = this.newImageUrl;
        this.closeUrlDialog();
      }
    },
    setImageFromMingly(index) {
      this.rooms[this.selectedRoomIndex].image = this.minglyUrls[index];
      this.closeMinglyDialog();
    },
    navigateTo(route) {
      this.$router.push(route);
    },
  },
};
</script>

<style scoped>
  .camera_box {
    margin-top: 20vh;
  }
  .padding_bot {
    padding-bottom: 2vh;
  }
  .padding {
    padding-top: 2vh;
  }
  /* .image-container {
  position: relative;
  width: 240px;
  height: 240px;
  margin: 0 auto;
} */

.center-image {
  display: block;
  max-width: 100%;
  max-height: 100%;
  margin: 0 auto;
}

.edit-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.4); /* semi-transparent black */
  border-radius: 50%;
  visibility: hidden; /* hidden by default */
}

.image-container {
  position: relative;
  width: 420px;
  height: 240px;
  margin: 0 auto;
  background-size: cover;
  background-position: center;
}
/* .image-container:hover .edit-icon {
  visibility: visible;
} */
  .my-table tr, .my-table td {
    background-color: #203e5fff;
    color: #eac252;
  }
  .row-container:hover td {
    background-color: #eac252;
    color: #203e5fff;
  }
  .container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Adjust as needed */
  }

  .scrollable-card {
    flex: 1;
    overflow-y: auto;
  }

  .sticky-card {
    position: sticky;
    bottom: 0;
    z-index: 1;
    padding-top: 20px; /* Increase the spacing between the cards */
  }
  .header {
    text-align: center;
    color: #eac252;
  }
  .table-toggle {
      text-align: center;
      color: #eac252;
      cursor: pointer;
      transition: background-color 0.3s ease;
  }

.table-toggle:hover {
  background-color: #eac252;
  color: #203e5fff;
}

.hover-background {
  background-color: #eac252;
  color: #203e5fff;
}

</style>
