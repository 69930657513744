<template>
  <div>
    <v-card color="#203e5fff" class="mx-auto list_box pa-4" max-width="600">
      <v-card-title class="room-title">
        <v-row class="fill-height" align="center" justify="center">
          <v-col cols="12" class="text-center">
            Rooms
          </v-col>
        </v-row>
      </v-card-title>
      <v-text-field label="Search" v-model="searchQuery" solo></v-text-field>
      <v-list color="#203e5fff" class="scrollable-list">
        <v-list-item
          v-for="room in filteredRooms"
          :key="room"
          @click="goToRoom(room)"
          @mouseenter="hoveredRoom = room"
          @mouseleave="hoveredRoom = null">
          <v-list-item-content>
            <v-list-item-title
            :class="{'hovered-room': hoveredRoom === room}"
            class="room-name">{{ room }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn color="#203e5fff" v-if="hoveredRoom === room" class="hover-button">Join</v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'RoomsList',
  data() {
    return {
      rooms: [],
      hoveredRoom: null,
      searchQuery: '',
    };
  },
  computed: {
    filteredRooms() {
      return this.rooms.filter((room) => room.toLowerCase()
        .includes(this.searchQuery.toLowerCase()));
    },
  },
  mounted() {
    this.getRooms();
  },
  methods: {
    ...mapActions('$_userdata', ['commitSetRoom', 'commitSetUsername']),
    async getRooms() {
      this.rooms = [
        'ChristiansRoom',
        'MinglyFriends',
        'MinglyStudio',
        'YCdemoroom',
        'MinglyWorld',
        'ChristiansTeaching',
        'MinglyRecording',
        'ROIWAN',
      ];
    },
    goToRoom(room) {
      this.commitSetRoom(room);
      if (localStorage.getItem('minglyUsername')) {
        const name = localStorage.getItem('minglyUsername');
        this.commitSetUsername(name);
        this.$router.push({ name: 'Canvas', params: { room } });
      } else {
        this.$router.push({ name: 'EnterName' });
      }
    },
  },
};
</script>

<style scoped>
  .room-title {
    color: #eac252 !important;
  }
  .room-name {
    color: #eac252;
  }
  .hovered-room {
    color: #203e5fff !important;
  }
  .list_box {
    margin-top: 23vh;
  }
  .v-list-item:hover {
    background-color: #eac252;
    color: #203e5fff;
  }
  .hover-button {
    color: #eac252 !important;
  }
  .list_box {
    margin-top: 23vh;
  }
  .scrollable-list {
    max-height: 250px;  /* adjust as needed */
    overflow-y: auto;
  }

</style>
