<template>
    <vue-drag-resize :isActive="this.isScreenActive"
                     :preventActiveBehavior="this.isChatBehaviorActive"
                     :isResizable="false"
                     :w="this.width" :h="this.height"
                    :x="this.left" :y="this.top"
                     :isDraggable="this.isChatDraggableActive"
                    v-on:deactivated="onDeactivated"
                   v-on:activated="onActivated">
      <v-card dark class="box">
        <CloseButton :method="closeChatComponent" class="close_button" />
        <v-row style="border: solid #1e1e1e 3px;">
          <v-col cols="4" style="padding: 0;">
            <v-list class="list_style">
              <div v-if="checkIfActive('everyone-id')" style="margin-bottom: 10px">
                <v-list-item style="background: #4f4f4f;"
                  v-on:click="makeChatActive('everyone-id')">
                  <ChatItem :isAllChat="true" />
                </v-list-item>
              </div>
              <div v-else style="margin-bottom: 10px">
                <v-list-item v-on:click="makeChatActive('everyone-id')">
                  <ChatItem :isAllChat="true" />
                </v-list-item>
              </div>
              <div v-for="(person, index) in participants.getUsersAndPictures()"
                :key="index" v-on:click="makeChatActive(person.userID)"
                style="cursor: pointer; margin-bottom: 10px">
                <div v-if="checkIfActive(person.userID)" style="background: #4f4f4f;">
                  <v-list-item>
                    <ChatItem :person="person" :isAllChat="false"/>
                  </v-list-item>
                </div>
                <div v-else>
                  <v-list-item>
                    <ChatItem :person="person" :isAllChat="false"/>
                  </v-list-item>
                </div>
              </div>
            </v-list>
          </v-col>
          <v-col cols="8" style="background: white; color: red; padding: 0;">
            <div class="chat_text">
              <div v-for="(entry, index) in getChat(message.userID)" :key="index">
                <MessageBubble :username="entry.name" :id="entry.id" :data="entry.data" />
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row style="background: #1e1e1e;">
          <v-col class="text_field_col">
            <v-text-field
              v-on:keyup.enter="sendMessage()"
              v-model="message.data"
              id="chat-input"
              class="text_field"
              light
              rounded
              background-color="white" placeholder="Type a message" hide-details />
          </v-col>
        </v-row>
      </v-card>
    </vue-drag-resize>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import VueDragResize from 'vue-drag-resize';
import ChatItem from './ChatItem.vue';
import MessageBubble from './MessageBubble.vue';
import { chatMessage } from '../../../../assets/js/webrtc';
import CloseButton from '../CloseButton.vue';

export default {
  name: 'Chat',
  components: {
    ChatItem, MessageBubble, CloseButton, VueDragResize,
  },
  data: () => ({
    message: {
      userID: 'everyone-id',
      data: '',
    },
    top: 0.5 * window.innerHeight,
    left: 0.05 * window.innerHeight,
    width: 0.45 * window.innerHeight,
    height: 0.2 * window.innerHeight,
    isScreenActive: false,
  }),
  computed: {
    ...mapGetters('$_userdata', [
      'participants',
      'messages',
      'userID',
      'username',
    ]),
    ...mapGetters('$_canvas', [
      'isChatDraggableActive',
      'isChatBehaviorActive',
    ]),
  },
  methods: {
    ...mapActions('$_userdata', ['commitAddMessage', 'commitSetChatActive']),
    ...mapActions('$_canvas', [
      'commitSetChatBoxActive',
    ]),
    closeChatComponent() {
      this.commitSetChatBoxActive();
    },
    onDeactivated() {
      this.isScreenActive = false;
    },
    onActivated() {
      this.isScreenActive = true;
      window.mingly.board.move_keyboard = false;
      setTimeout(() => {
        this.isScreenActive = false;
      }, 2000);
    },
    checkIfActive(chatID) {
      let isActive = false;

      for (let i = 0; i < this.messages.length; i += 1) {
        if (this.messages[i].chatID === chatID) {
          isActive = this.messages[i].isActive;
        }
      }

      return isActive;
    },
    checkActive() {
      window.mingly.board.move_keyboard = false;
    },
    makeChatActive(id) {
      this.commitSetChatActive(id);
      this.message.userID = id;
    },
    sendMessage() {
      if (this.message.userID === '' || this.message.data === '') {
        return;
      }

      const content = { id: this.userID, data: this.message.data, name: this.username };
      const payload = { chatID: this.message.userID, data: content };
      this.commitAddMessage(payload);
      // send to user(s) via peer connection dc
      if (this.message.userID === 'everyone-id') {
        chatMessage(content.data, 'all');
      } else {
        chatMessage(content.data, payload.chatID);
      }
      // Remove text from the input field
      this.message.data = '';
    },
    getChat(userID) {
      for (let i = 0; i < this.messages.length; i += 1) {
        if (this.messages[i].chatID === userID) {
          return this.messages[i].payload;
        }
      }
      return null;
    },
  },
};
</script>

<style scoped>
  .avatar {
    margin-right: 1vh;
  }
  .box {
    width: 55vh;
    height: 30vh;
  }
  .chat_text {
    padding: 2vh 0 0 0;
    height: 30vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 5vh; /* Adjust this to set the starting point of the first text bubble */
}
  .list_style {
    height: 35vh;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #b9b9b9;
  }
  .text_field {
    padding-right: 1vh;
    padding-left: 1vh;
    padding-top: 0;
  }
  .text_field_col {
    padding-left: 0;
    padding-bottom: 0;
    margin: 1vh;
  }
.close_button {
  position: absolute;
  right: 1vh;
  top: 1vh;
  color: black !important;
  background: transparent;
}
</style>
