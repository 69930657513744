<template>
  <div>
    <v-card class="mx-auto login pa-4" max-width="500">
      <h1 class="text-center pa-2">Sign in</h1>
      <v-card-actions>
        <v-text-field v-model="username"
                      label="Room"
                      required
                      filled
                      rounded
                      hide-details
                      prepend-inner-icon="mdi-account-circle" />
      </v-card-actions>
      <v-card-actions>
        <v-text-field v-model="password"
                      label="Password"
                      type="password"
                      required
                      filled
                      rounded
                      hide-details
                      prepend-inner-icon="mdi-lock"
                      v-on:keydown.enter.prevent="login" />
      </v-card-actions>
      <!--v-row>
        <v-col>
          <v-card-actions>
            <v-checkbox label="Remember me" class="ma-0" />
          </v-card-actions>
        </v-col>
        <v-col>
          <v-card-actions class="justify-end">
            <router-link :to="resetPassword">
              Forgot password?
            </router-link>
          </v-card-actions>
        </v-col>
      </v-row-->
      <v-card-actions class="justify-center">
        <v-btn dark elevation="8"
               large class="btn_width"
               v-on:click="login">Login</v-btn>
      </v-card-actions>
      <!--v-card-actions class="justify-center">
        <router-link :to="createNewAccount">
          Don't have a user? Create a new account
        </router-link>
      </v-card-actions>
      <v-card-actions class="justify-center guest_btn_pos">
        <v-btn dark elevation="8"
               large class="btn_width"
               v-on:click="createGuestName">Join as guest</v-btn>
      </v-card-actions-->
      <div v-if="isError" style="text-align: center;">
        <b style="color: red;">{{ errorMessage }}</b>
      </div>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios';
import { mapActions } from 'vuex';

export default {
  name: 'LoginBox',
  data() {
    return {
      username: '',
      password: '',
      isError: false,
      errorMessage: '',
    };
  },
  computed: {
    resetPassword() {
      return '';
    },
    createNewAccount() {
      return '';
    },
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('room') && urlParams.get('password')) {
      this.username = urlParams.get('room');
      this.password = urlParams.get('password');
      this.login();
    }
    if (urlParams.get('key')) {
      if (urlParams.get('key') === '87fd2kd!sdfsdkf009ffsdf11ahildgs') {
        this.username = 'F3007711';
        this.password = 'EnterTheRoom123';
        this.login();
      }
      if (urlParams.get('key') === 'sdjflsdk8301392jsdflsif9822394092') {
        this.username = 'F3009644';
        this.password = 'EnterTheRoom123';
        this.login();
      }
      if (urlParams.get('key') === 'sfsdfisdhf8212e3u219ejfsdfuygWE') {
        this.username = 'MinglyFriends';
        this.password = 'Friends2022';
        this.login();
      }
      if (urlParams.get('key') === 'sdfjklsdghdfsuiv1203182fmdslkf') {
        this.username = 'MinglyASMeeting';
        this.password = 'TheMeetingRoom9879';
        this.login();
      }
      if (urlParams.get('key') === 'lkjhfgsdshfsigj329fsdrtf8sdf3') {
        this.username = 'MinglyWorld';
        this.password = 'aGreatPlaceToBe1297';
        this.login();
      }
      if (urlParams.get('key') === 'hpsncy18bntn332892fd71vfdg') {
        this.username = 'ChristiansRoom';
        this.password = 'theroomIuse2022';
        this.login();
      }
      if (urlParams.get('key') === 'resdphg12kdf1hcbv09v') {
        this.username = 'ChristophersRoom';
        this.password = 'EnterTheRoom123';
        this.login();
      }
      if (urlParams.get('key') === 'uapejfhc6390vjsmcc63') {
        this.username = 'AsbjørnsRoom';
        this.password = 'EnterTheRoom123';
        this.login();
      }
      if (urlParams.get('key') === '9zch1nds1lash1msad3p') {
        this.username = 'ElisabethsRoom';
        this.password = 'EnterTheRoom123';
        this.login();
      }
      if (urlParams.get('key') === 'kkjvhwi1r7ccsnld9ldf7vjtgap') {
        this.username = 'PhilippsRoom';
        this.password = 'EnterTheRoom123';
        this.login();
      }
      if (urlParams.get('key') === 'kapoidhft29846dj27dfjw9') {
        this.username = 'AndersGsRoom';
        this.password = 'EnterTheRoom123';
        this.login();
      }
      if (urlParams.get('key') === 'kauennhdg46589wrncvs32') {
        this.username = 'MagnusHsRoom';
        this.password = 'EnterTheRoom123';
        this.login();
      }
      if (urlParams.get('key') === 'sdfljjvhhh28823asj89') {
        this.username = 'HPC_dome';
        this.password = 'RegelbauR618';
        this.login();
      }
      if (urlParams.get('key') === 'asdjasl123ew23e4askod9') {
        this.username = 'Legelisten';
        this.password = 'LegeListenRommet123';
        this.login();
      }
      if (urlParams.get('key') === 'sdflkjfjsdj392093sajsdf') {
        this.username = 'MareksRoom';
        this.password = 'EnterTheRoom123';
        this.login();
      }
      if (urlParams.get('key') === 'hgfsdsjdfsdjfi32390fjsd') {
        this.username = 'PetrasRoom';
        this.password = 'EnterTheRoom123';
        this.login();
      }
      if (urlParams.get('key') === 'fsjdlksd8383usidojsad8') {
        this.username = 'ChristiansTeaching';
        this.password = 'EnterTheRoom123';
        this.login();
      }
      if (urlParams.get('key') === 'fd29929jasdasd111df') {
        this.username = 'FinansiellStyring';
        this.password = 'EnterTheRoom123';
        this.login();
      }
      if (urlParams.get('key') === 'fd29929jasdajsdlfjskdfj111df') {
        this.username = 'MinglyBINettstudies';
        this.password = 'EnterTheRoom123';
        this.login();
      }
      if (urlParams.get('key') === 'jdklsjf2318bntn332892fd71vfdg') {
        this.username = 'MinglyTesting';
        this.password = 'EnterTheRoom123';
        this.login();
      }
      if (urlParams.get('key') === 'jdklsjf231sdfklsd11112892fd71vfdg') {
        this.username = 'MinglyBI';
        this.password = 'EnterTheRoom123';
        this.login();
      }
      if (urlParams.get('key') === 'jdklsjf231sdfkdsfjklsjdf122fd71vfdg') {
        this.username = 'MinglyV1Presentation';
        this.password = 'EnterTheRoom123';
        this.login();
      }
    }
  },
  methods: {
    ...mapActions('$_userdata', ['commitSetRoom', 'commitSetUsername', 'commitMenuItems', 'commitSetBackgroundImage']),
    login() {
      if (this.username === '' && this.password === '') {
        this.isError = true;
        this.errorMessage = 'You need to enter the room name and the password!';
        return;
      }

      if (this.username === '') {
        this.isError = true;
        this.errorMessage = 'You need to enter the room name!';
        return;
      }

      if (this.password === '') {
        this.isError = true;
        this.errorMessage = 'You need to enter the password!';
        return;
      }
      const test = true;

      if (test) {
        console.log('test');
        this.commitSetRoom(this.username);
        // // this is where we run some roomsetting stuff

        // // these should of course be done less ad hoc
        // // if (this.username === 'F300Spring2022-8243') {
        // //   window.mingly.wsurl = 'wss://soup-us-east-2-01.mingly.io:8443';
        // // }
        // // if (this.username === 'F300Spring2022-10523') {
        // //   window.mingly.wsurl = 'wss://soup-us-east-2-02.mingly.io:8443';
        // // }
        if (this.username === 'SafetykleenRoom') {
          const picURL = 'https://www.dropbox.com/s/96veu7bbs4d6ab7/Key%20visual%20with%20brand%20in%20corner.jpg?raw=1';
          this.commitSetBackgroundImage(picURL);
        } else if (this.username === 'HPC_dome') {
          const picURL = 'https://www.dropbox.com/s/upmvxmqvuv8s93k/photo_2022-09-20%2014.27.58.jpeg?raw=1';
          this.commitSetBackgroundImage(picURL);
        } else if (this.username === 'ChristiansRoom') {
          const picURL = 'https://www.dropbox.com/s/2jov5w0pa0se4uu/CHLBackground.jpg?raw=1';
          this.commitSetBackgroundImage(picURL);
        } else if (this.username === 'Legelisten') {
          const picURL = 'https://www.dropbox.com/s/8dcafeflinwwzga/Legelisten%20bakgrunn.png?raw=1';
          this.commitSetBackgroundImage(picURL);
        }
        if (localStorage.getItem('minglyUsername')) {
          const name = localStorage.getItem('minglyUsername');
          this.commitSetUsername(name);
          this.$router.push({ name: 'Canvas', params: { room: this.username } });
        } else {
          this.$router.push({ name: 'EnterName' });
        }
      } else {
        axios.post(`${this.$API_URL}/user/signin`, { identity: this.username, password: this.password })
          .then((response) => {
            if (response.data.error) {
              this.isError = true;
              this.errorMessage = response.data.general;
            } else {
              // eslint-disable-next-line no-lonely-if
              if (response.data.access_token) {
              // window.alert(response.data.access_token);
                localStorage.setItem('access_token', response.data.access_token);
                this.commitSetRoom(this.username);
                // this is where we run some roomsetting stuff

                // these should of course be done less ad hoc
                // if (this.username === 'F300Spring2022-8243') {
                //   window.mingly.wsurl = 'wss://soup-us-east-2-01.mingly.io:8443';
                // }
                // if (this.username === 'F300Spring2022-10523') {
                //   window.mingly.wsurl = 'wss://soup-us-east-2-02.mingly.io:8443';
                // }
                if (this.username === 'SafetykleenRoom') {
                  const picURL = 'https://www.dropbox.com/s/96veu7bbs4d6ab7/Key%20visual%20with%20brand%20in%20corner.jpg?raw=1';
                  this.commitSetBackgroundImage(picURL);
                } else if (this.username === 'HPC_dome') {
                  const picURL = 'https://www.dropbox.com/s/upmvxmqvuv8s93k/photo_2022-09-20%2014.27.58.jpeg?raw=1';
                  this.commitSetBackgroundImage(picURL);
                } else if (this.username === 'ChristiansRoom') {
                  const picURL = 'https://www.dropbox.com/s/2jov5w0pa0se4uu/CHLBackground.jpg?raw=1';
                  this.commitSetBackgroundImage(picURL);
                } else if (this.username === 'Legelisten') {
                  const picURL = 'https://www.dropbox.com/s/8dcafeflinwwzga/Legelisten%20bakgrunn.png?raw=1';
                  this.commitSetBackgroundImage(picURL);
                }
                if (localStorage.getItem('minglyUsername')) {
                  const name = localStorage.getItem('minglyUsername');
                  this.commitSetUsername(name);
                  console.log(`the room name IS: ${this.username}`);
                  this.$router.push({ name: 'Canvas', params: { room: this.username } });
                } else {
                  this.$router.push({ name: 'EnterName' });
                }
              } else {
                // eslint-disable-next-line prefer-template
                console.log('login(): unhandled response data: ' + response.data);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    createGuestName() {
      this.$router.push({ name: 'EnterName' });
    },
  },
};
</script>

<style scoped>
.login {
  margin-top: 24vh;
}
.guest_btn_pos {
  padding-bottom: 1em;
  padding-top: 2em;
}
.btn_width {
  width: 80%;
}
</style>
