// eslint-disable-next-line import/prefer-default-export
export const getBaseState = () => ({
  isChatBehaviorActive: false,
  isChatDraggableActive: true,
  isBroadcastMinglyModeActive: false,
  isBroadcastMenuActive: false,
  isChatBoxActive: false,
  isParticipantActive: false,
  isSettingMenuActive: false,
  isCanvasViewActive: true,
  isEnterVideoActive: false,
  isVideoShareActive: true,
  isScreenShareActive: false,
  isDeafenActive: false,
  isSleepActive: false,
  isMuteActive: false,
  isShareMediaActive: false,
  isShowParticipantVideoActive: false,
  isBroadCastActive: false,
  isGamesActive: false,
  isPollActive: false,
  isAnswerPollActive: false,
  isStatCreatorPollActive: false,
  isStatDisplayPollActive: false,
  isGameInviteActive: false,
  isGameInvitationActive: false,
  isScoreboardGTCActive: false,
  isSideCanvasActive: false,
  isFocusViewActive: false,
  isHostMenuActive: false,
  isHostSettingsActive: false,
  isLockPositionActive: false,
  isMuteAllActive: false,
  isNoVideoAllActive: false,
  isBroadCastMeActive: false,
  isFollowMyViewActive: false,
  isNoHostMenuActive: false,
  isRaiseHandActive: false,
  isRaisedHandOverviewActive: false,
  isAskToReloadActive: false,
  raisedHands: [],
  game: '',
  gameData: {
    Type: '',
    creatorId: '',
    invitedPlayers: [],
    players: [{
      userId: '',
      name: '',
      score: null,
    }],
  },
  user: {
    userID: '',
    videovar: false,
  },
  userBroadCast: {
    userID: '',
    isMe: false,
  },
});
