<template>
  <div class="background">
     <v-container fluid>
          <v-layout row wrap align-center justify-center>
              <v-flex xs12 sm8 md6>
                  <v-card class="elevation-12">
                      <v-toolbar color="#203e5fff" dark>
                          <v-toolbar-title>Contact us</v-toolbar-title>
                      </v-toolbar>
                      <v-card-text>
                          <h2 class="text-center">If you want to reach out to us,
                            please email: </h2>
                          <h2 class="text-center email">contact@mingly.world</h2>
                      </v-card-text>
                  </v-card>
              </v-flex>
          </v-layout>
      </v-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'index',
  computed: {
    ...mapGetters('$_userdata', ['backgroundImageUrl']),
  },
};
</script>

<style scoped>
  .background {
    background: url('https://www.dropbox.com/s/eeaq8lkpwlvhaio/IMG_2775.jpg?raw=1');
    background-size: cover;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .elevation-12 {
    padding: 20px;
  }

  .email {
    color: #203e5fff;
    text-decoration: underline;
  }
</style>
