<template>
  <div class="card-container">
    <v-card class="card-style">
      <div class="box-style">
        {{ text }}
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'TextBox',
  props: {
    text: String,
  },
};
</script>

<style scoped>
  .card-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .box-style {
    padding: 2vh;
    font-size: 1.2em;
    text-align: center;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  }
  .card-style {
    background-color: #203e5fff;
    color: #eac252;
    border-radius: 15px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    overflow: hidden;
    padding: 2em;
    max-width: 80%;
  }
  .v-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.v-card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 10px 20px rgba(0,0,0,0.19), 0px 6px 6px rgba(0,0,0,0.23);
}
</style>
