<template>
  <v-card class="mx-auto" :class="color" max-width="1200" outlined>
    <v-card-title class="text-center">
      <h1 class="pa-2">{{ title }}</h1>
    </v-card-title>
    <v-card-text v-if="description">
      <h2 class="text-center pa-2">{{ description }}</h2>
    </v-card-text>
    <v-card-text v-else>
      <video
        autoplay
        muted
        controls
        loop
        playsinline
        webkit-playsinline
        width="100%"
        height="100%"
        :src="video"></video>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'WhyCard',
  props: {
    title: String,
    description: String,
    video: String,
    color: String,
  },
};
</script>

<style scoped>
.colorstyle1 {
  background-color: #203e5fff;
  color: #eac252;
}
.colorstyle2 {
  background-color: #eac252;
  color: #203e5fff;
}
.spatialTop {
  margin-top: 24vh;
  background-color: #eac252;
  color: #203e5fff;
}
.spatial1 {
  margin-top: 5vh;
  background-color: #203e5fff;
  color: #eac252;
}
.spatial2 {
  margin-top: 5vh;
  background-color: #eac252;
  color: #203e5fff;
}
.v-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.v-card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 10px 20px rgba(0,0,0,0.19), 0px 6px 6px rgba(0,0,0,0.23);
}
</style>
