<template>
    <div>
      <v-card dark max-width="500" min-width="500" class="pa-4">
        <h1 class="text-center pa-2">Please reload the browser</h1>
        <h3 class="pa-2">On Windows: F5 button</h3>
        <h3 class="pa-2">On MacOS: cmd + R</h3>
      </v-card>
    </div>
  </template>
<script>

export default {
  name: 'AskToReload',
};
</script>
