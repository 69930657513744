<template>
  <div class="container scrollable-card">
     <v-card color="#203e5fff" class="mx-auto camera_box pa-4" max-width="600">
        <v-hover v-slot:default="{ hover }">
              <v-img class="image-container" :src="displayedImage" @click="dialog = true">
                <v-icon
                  v-if="hover"
                  large
                  class="edit-icon"
                  @click.stop="dialog = true"
                >
                  mdi-pencil
                </v-icon>
              </v-img>
         </v-hover>
         <v-form color="#eac252" ref="form" v-model="valid" lazy-validation>
             <v-text-field
               class="room-name-input"
               v-model="roomName"
               :counter="20"
               label="Room Name"
               required
             ></v-text-field>
             <v-tooltip bottom>
               <template v-slot:activator="{ on, attrs }">
                 <v-icon small class="mr-2" v-bind="attrs" v-on="on">mdi-help-circle</v-icon>
               </template>
               <span>This is the name of the room</span>
              </v-tooltip>
             <v-select
               v-model="roomSize"
               :items="sizes"
               label="Room Size"
               required
             ></v-select>
             <!-- <v-radio-group v-model="imageType" row>
               <v-radio label="Use an URL" value="url"></v-radio>
               <v-radio label="Use a predefined Mingly picture" value="mingly"></v-radio>
             </v-radio-group> -->
             <v-switch v-model="isProtected" label="Protected room"></v-switch>
         </v-form>
     </v-card>
     <div class="sticky-card">
         <v-card color="#203e5fff" >
           <v-card-actions class="justify-center padding_bot">
             <v-btn class="myButton" color="#eac252" elevation="8"
             @click="navigateTo('editrooms')">Back</v-btn>
             <v-btn class="myButton" color="#eac252" elevation="8"
             @click="saveRoom">Create Room</v-btn>
           </v-card-actions>
         </v-card>
     </div>
     <v-dialog class="dialog-background" v-model="dialog" max-width="600px">
      <v-card class="card-background">
        <v-radio-group v-model="imageType">
          <v-radio label="Use an URL" value="url"></v-radio>
          <v-radio label="Use a predefined Mingly picture" value="mingly"></v-radio>
        </v-radio-group>
        <v-text-field
          v-if="imageType === 'url'"
          v-model="imageUrl"
          label="Image URL"
          required
        ></v-text-field>
        <v-list
          class="list-background"
          v-if="imageType === 'mingly'"
          two-line
          avatar
        >
          <v-list-item
            v-for="(image, index) in minglyImages"
            :key="index"
            @click="selectMinglyImage(image)"
          >
            <v-list-item-avatar>
              <v-img :src="image"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>Image {{ index + 1 }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
    </v-dialog>
   </div>
 </template>
<script>
export default {
  name: 'RoomCreator',
  data() {
    return {
      valid: true,
      roomName: '',
      roomSize: '',
      sizes: ['Small (2-5 people)', 'Medium (5-25 people)', 'Large (25-100 people)'],
      imageType: '',
      imageUrl: '',
      selectedMinglyImage: '',
      dialog: false,
      displayedImage: 'https://www.dropbox.com/s/7b4m2oepjigolnx/Haust.jpg?raw=1',
      minglyImages: [
        'https://www.dropbox.com/s/7b4m2oepjigolnx/Haust.jpg?raw=1',
        'https://www.dropbox.com/scl/fi/t9p7uf27wbjo0xs8d0ru3/Heart-of-the-City-2.jpg?rlkey=8h16bqvhjz7a3llp915wj2ubs&raw=1',
        'https://www.dropbox.com/s/smwkrgszpk97qfm/IMG_0767.jpg?raw=1',
        'https://www.dropbox.com/s/7ngeaysusn7o9u5/IMG_1038.jpg?raw=1',
      ],
      isProtected: false,
    };
  },
  methods: {
    navigateTo(route) {
      this.$router.push(route);
    },
    selectMinglyImage(image) {
      this.selectedMinglyImage = image;
      this.displayedImage = image;
      this.dialog = false; // close the dialog
    },
    saveRoom() {
      if (this.$refs.form.validate()) {
        // Perform save logic
        console.log('saving');
      } else {
        console.log('form is invalid');
      }
    },
  },
};

</script>

<style scoped>
  .camera_box {
    margin-top: 20vh;
  }
  .padding_bot {
    padding-bottom: 2vh;
  }
  .container {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Adjust as needed */
  }
  .vuecardColor {
    color: #eac252 !important;
  }

  .scrollable-card {
    flex: 1;
    overflow-y: auto;
  }

  .sticky-card {
    position: sticky;
    bottom: 0;
    z-index: 1;
    padding-top: 20px; /* Increase the spacing between the cards */
  }
  .v-card ::v-deep * {
    color: #eac252;
  }
  .room-name-input::v-deep input {
    color: #eac252 !important;
  }

  /* Keep original color for buttons */
  .myButton::v-deep .v-btn__content {
    color: #203e5fff !important;
  }
.image-container {
  position: relative;
  width: 420px;
  height: 240px;
  margin: 0 auto;
  background-size: cover;
  background-position: center;
}
.image-container:hover .edit-icon {
  visibility: visible; /* shown when hovering over the image */
}
.dialog-background::v-deep .v-card {
    background-color: #203e5fff !important;
  }
  .card-background {
    background-color: #203e5fff !important;
  }
  .list-background {
    background-color: #203e5fff !important;
  }
</style>
