<template>
  <div class="background">
   <v-row>
     <v-col>
       <RoomsList />
     </v-col>
   </v-row>
 </div>
</template>
<script>
import { mapGetters } from 'vuex';
import RoomsList from './_components/RoomsList.vue';

export default {
  name: 'index',
  components: { RoomsList },
  computed: {
    ...mapGetters('$_userdata', ['backgroundImageUrl']),
  },
};
</script>

<style scoped>
/* .background {
   background: url('https://www.dropbox.com/s/eeaq8lkpwlvhaio/IMG_2775.jpg?raw=1');
   background-size: cover;
} */
.background {
  background: url('https://www.dropbox.com/s/eeaq8lkpwlvhaio/IMG_2775.jpg?raw=1');
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
</style>
