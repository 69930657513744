/* eslint-disable import/extensions */
/* eslint-disable quote-props */
// eslint-disable-next-line import/extensions
import CMD from './wssCMDs.js';
// eslint-disable-next-line import/no-cycle
import {
  sayHiToEveryone, sendStatisticsMS,
  placeBroadCastScreenOnCanvas,
  setupMsCanvasVideo,
  updateConnections,
} from './webrtc.js';

export default class MinglyRTC {
// config is an object that contains websocketservers, their type (mediasoup, webrtc etc)
// stun and turn configurations
// I also need info on send/receive, i.e., if the server is only for receiving or not
// IMPORTANT LIST OF TO DOs
// 1. remove all dependencies of window.mingly (can be difficult)
// 2. In pingMainWS, the killPeer is not included
// 3. redundancies for the websocket connections are not complete
// 4. Broadcasting with mediasoup
// 5. How to use controlWebsocket is not sorted out
// 6. Check out the use of send property on config versus me.server
// 7. Check out webRTC on different servers and webRTC to mediaserver
// 8. Controlling switch between servers (moving from webRTC to mediasoup and vica verse)
// 9. Clean up the config to get info from server etc
  constructor(config, room, controlWebsocket, me, participants) {
    this.me = me; // me object
    this.participants = participants; // the participant objects
    this.wssServers = {}; // the websocket servers
    // this.serverRooms = []; // room names used as server type
    this.config = config;
    this.room = room; // this is the name of the room
    this.uuid = me.uuid; // this is my (participant object) uuid
    this.controlWebsocket = controlWebsocket; // a websocket connection that deals with
    this.videoEncodings = window.mingly.CAM_VIDEO_SIMULCAST_ENCODINGS;
    this.pending = {};
    // this.CAM_VIDEO_SIMULCAST_ENCODINGS = [ // should break this out as well
    //   // { maxBitrate: 96000, scaleResolutionDownBy: 4 },
    //   // { maxBitrate: 680000, scaleResolutionDownBy: 1 },
    //   { maxBitrate: 500000, scaleResolutionDownBy: 1 },
    //   { maxBitrate: 1500000, scaleResolutionDownBy: 1 },
    // ];
    // all communication other than the RTC
    this.setUpservers();
  }

  // initiating methods

  setUpservers() {
    // set up the servers
    // this will establish the connection
    // let i = 0;
    console.log('message from server');
    Object.values(this.config).forEach((server) => {
      const roomName = `${server.wssServer}_${this.room}_${server.type}`;
      this.connectToWebSocket(server.wssServer, roomName, server.type, server.rtcConfig,
        server.send);
      console.log(roomName);
    });
  }

  connectToWebSocket(wsurl, name, type, config, send) {
    const serverConnection = new WebSocket(wsurl);
    // might be a bit more critical to the variables
    // chosen in the server object here
    this.wssServers[name] = {
      room: name,
      config,
      serverConnection,
      device: null,
      routerRtpCapabilities: null,
      recvTransportOptions: null,
      sendTransportOptions: null,
      recvTransport: null,
      sendTransport: null,
      msOnProduceCallback: null,
      myTransporstAreReady: 0,
      nTransportsReady: 0,
      send,
    };
    serverConnection.onmessage = (message) => this.gotMessageFromServer(
      message,
      name,
    ); // this.gotMessageFromServer.bind(this.wssServers[name]);
    serverConnection.onopen = () => this.gotConnectionToWebSocket(
      name,
    ); // this.gotMessageFromServer.bind(this.wssServers[name]);
    serverConnection.onerror = (event) => this.problemsConnectionWebSocket(
      event,
      name,
    );
    serverConnection.onclose = (event) => this.closedTheWebSocket(
      event,
      name,
    );
    // if the type = mediasoup then we need to do a few more things
    if (type === 'mediasoup') {
      try {
        this.wssServers[name].device = new window.mediaServerClient.mediasoupClient.Device();
      } catch (e) {
        if (e.name === 'UnsupportedError') {
          console.log('browser not supported for video calls');
        } // check if correct as used to have else
      }
    }
  }
  // event listners
  // based on websockets

  gotMessageFromServer(message, name) {
    const signal = JSON.parse(message.data);
    // console.log('the name is');
    // console.log(name);
    // console.log('the signal name is');
    // console.log(signal.room);
    // console.log('the ready counter');
    // console.log(this.me.setUpCounter);
    const peerUuid = signal.uuid;
    if (signal.room !== name) return;
    if (this.wssServers[name].device === null && signal.command === CMD.MediaServerIn) {
      console.log('test');
      return;
    }
    if (peerUuid === this.uuid || (signal.dest !== this.uuid && signal.dest !== 'all')) return;
    switch (signal.command) {
      // webRTC based commands
      case CMD.SDP: // should use the definitions in a separate file and remove ''
        // set description etc.
        // check if participant is there, if not add
        console.log('Got SDP message');
        if (!this.participants[peerUuid].pc) {
          this.setupPeerConnection(peerUuid, false);
        }
        this.participants[peerUuid].pc.setRemoteDescription(new RTCSessionDescription(signal.sdp))
          .then(() => {
            if (signal.sdp.type === 'offer') {
              this.participants[peerUuid].pc.createAnswer()
                // probably referring to the wrong this and won't find createdDescription
                .then((description) => this.createdDescription(description, peerUuid))
                .catch((error) => {
                  console.log(error);
                });
            }
          }).catch((error) => {
            let waitTimeCall;
            switch (error.name) {
              case 'InvalidStateError':
                // use a random waiting time to call
                // here we can use lowest uuid instead
                this.participants[peerUuid].failed = true;
                // waitTime for calling back, cancel calling if other party call you first
                waitTimeCall = Math.round(Math.random() * 5000);
                setTimeout(() => {
                  if (this.participants[peerUuid].failed) {
                    // call since failed is still true (you are the first)
                    this.participants[peerUuid].failed = false;
                    this.setupPeerConnection(peerUuid, true);
                  }
                }, waitTimeCall);
                break;
              default:
                console.log(error);
            }
          });
        break;
      case CMD.ICE:
        this.participants[peerUuid].pc.addIceCandidate(new RTCIceCandidate(signal.ice))
          .catch((error) => {
            console.log(error);
          });
        break;
      // mediasoup based commands
      case CMD.MediaServerIn:
        // console.log(signal.routerRtpCapabilities);
        this.wssServers[name].routerRtpCapabilities = {
          routerRtpCapabilities: signal.routerRtpCapabilities,
        };
        // might need some flag to indicate that it is ready (.then)
        // A very wierd type error happens, it does not happen in the main code
        console.log('this is the rooms:');
        console.log(signal.room);
        // eslint-disable-next-line max-len
        this.wssServers[name].device.load(this.wssServers[name].routerRtpCapabilities).then((res) => {
          console.log('MS device ready');
          console.log(res);
          // only create send transport if I will send media on it
          if (this.wssServers[name].send) {
            console.log('creating a transport');
            this.createTransport('send', name);
          }
          console.log('creating a another transport');
          this.createTransport('recv', name);
        }).catch((error) => {
          console.log(error);
        });
        break;
      case CMD.CreateTransport:
        console.log('Setting up transport for MS');
        // eslint-disable-next-line no-case-declarations
        const { id } = signal;
        // eslint-disable-next-line no-case-declarations
        const { iceParameters } = signal;
        // eslint-disable-next-line no-case-declarations
        const { iceCandidates } = signal;
        // eslint-disable-next-line no-case-declarations
        const { dtlsParameters } = signal;
        // eslint-disable-next-line no-case-declarations
        const { direction } = signal;
        console.log(direction);
        // eslint-disable-next-line no-case-declarations
        const transportOptions = {
          id,
          iceParameters,
          iceCandidates,
          dtlsParameters,
        };
        this.setUpTransport(transportOptions, direction, name);
        break;
      case CMD.ProduceReady:
        // eslint-disable-next-line no-case-declarations
        const proId = signal.id;
        console.log('NEW Transport ready');
        this.wssServers[name].msOnProduceCallback({ proId }); // had to do it this way...
        if (window.mingly.msBroadCastOnCanvas[this.me.uuid] !== undefined) {
          this.wssServers[name].myTransporstAreReady += 1;
          // might want to change how screen sharing with audio works
          if (window.mingly.msBroadCastOnCanvas[this.me.uuid].type === 'ms_canvas_screen') {
            window.mingly.board.placeingObject.placingFunction = placeBroadCastScreenOnCanvas;
            window.mingly.board.placeingObject.isPlacing = true;
            this.wssServers[name].myTransporstAreReady = 0; // this looks dangerous?
            // maybe this works if you cannot "start mingly broadcasting"
          }
          // OLD CODE BASE HER:
          // Broadcasting not implemented yet!!!
          // mingly.myTransporstAreReady += 1;
          // mingly.log(mingly.myTransporstAreReady);
          // if (mingly.msBroadCastType === 'ms_canvas_screen') {
          //   mingly.log(signal.type);
          //   // could do the place canvas here
          //   board.placeingObject.placingFunction = placeBroadCastScreenOnCanvas;
          //   board.placeingObject.isPlacing = true;
          //   mingly.myTransporstAreReady = 0;
          // } else {
          //   mingly.log(signal.type);
          //   if (mingly.myTransporstAreReady === 2) {
          //     mingly.log('check if screen or not');
          //     if (mingly.msBroadCastType === 'ms_canvas_screen_and_screenaudio') {
          //       console.log('in MS video Canvas audio');
          //       // could do the place canvas here
          //       board.placeingObject.placingFunction = placeBroadCastScreenOnCanvas;
          //       board.placeingObject.isPlacing = true;
          //     } else {
          //       serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.Broadcast },
          // me.asJSON(), { 'type': mingly.msBroadCastType, 'dest': 'all' })));
          //       if (mingly.board.isExternal) {
          //         const dat = {
          //           uuid: me.uuid,
          //         };
          //         if (window.mingly.onMSBroadcast) {
          //           try {
          //             window.mingly.onMSBroadcast(dat);
          //           }
          //           catch {
          //             window.mingly.log('did not do onMSBroadcast');
          //           }
          //         }
          //       } else {
          //         setupMsVideo(me);
          //       }
          //     }
          //     mingly.myTransporstAreReady = 0;
          //   }
          // }
        } else {
          // this should be the mediaserver your are sending
          // your main stuff to (video and audio)
          this.wssServers[name].myTransporstAreReady += 1;
          this.me.setUpCounter += 1;
          window.mingly.log('THE SETUPCOUNTER');
          window.mingly.log(this.me.setUpCounter);
          window.mingly.log('addding to mediaservers');
          window.mingly.log(signal.type);
          window.mingly.log(this.wssServers[name].myTransporstAreReady);
          if (this.wssServers[name].myTransporstAreReady === 2) {
            // should pass a function in here (siHiToEveryone())
            this.me.readyForMessages = true;
            this.me.server.readyState = 1; // not sure if we should replace readyForMessages?
            this.updateServerReadyState(this.me.server.readyState, name);
            // careful with this one if we do multiple transports on one
            // server here
            // Also not sure if main signal server is not ready?
            this.wssServers[name].myTransporstAreReady = 0;
          }
          if (this.me.setUpCounter === 3) {
            this.me.readyForMessages = true;
            console.log('saying hi to everyone');
            sayHiToEveryone();
          }
        }
        break;
      case CMD.SubscribeToTrack:
      // this is the reply from the server
        console.log('In cmdSubscribeToTrack');
        // eslint-disable-next-line no-case-declarations
        const peerId = signal.mediaPeerId;
        // eslint-disable-next-line no-case-declarations
        const consumerParameters = {
          producerId: signal.producerId,
          id: signal.consumerId,
          kind: signal.kind,
          rtpParameters: signal.rtpParameters,
          type: signal.type,
          producerPaused: signal.producerPaused,
        };
        // need to figure when I send broadcast screen
        // if (signal.type === 'ms_canvas_screen' || signal.type
        // === 'ms_canvas_screen_and_screenaudio') {
        //   window.mingly.msBroadCastOnCanvas[signal.uuid] = {

        //   };
        // }
        console.log(consumerParameters);
        this.setUpConsumption(peerId, name, consumerParameters, signal.mediaTag, signal.ms);
        break;
      // general commands
      case CMD.PingParticipant:
        break;
      case CMD.CloseRTC:
        break;
      case CMD.Ping:
        break;
      case CMD.Pong:
        break;
      case CMD.ServerReadyStateChange:
        // change the readState of the participant
        this.participants[signal.uuid].server.readyState = signal.serverReadyState;
        updateConnections();
        break;
      default:
        console.log('in switch default');
        console.log(signal.command);
        break;
    }
  }

  gotConnectionToWebSocket(name) {
    // Check for inconsistencies
    setInterval(() => {
      window.mingly.log('checking for inconsitencies');
      this.checkForInconsitencies(name);
    }, 5000);
    // eslint-disable-next-line quote-props
    const msg = {
      command: CMD.OpenConnection,
      room: name,
      uuid: this.uuid,
      type: this.me.server.type, // mediasoup or webRTC
    };
    if (this.me.server.type === 'webRTC' && this.wssServers[name].type === 'webRTC') {
      this.me.setUpCounter += 1;
      console.log('adding too due to connection');
      if (this.me.setUpCounter === 2) {
        // this.me.readyForMessages = true;
        console.log('saying hi to everyone webRTC style');
        sayHiToEveryone();
      }
    }
    this.wssServers[name].serverConnection.send(JSON.stringify(msg));
  }

  problemsConnectionWebSocket(event, name) {
    console.log('THERE IS AN ERROR TO WEBSOCKET!');
    console.log(`Error to websocket: ${event}`);
    console.log(JSON.stringify(event));
    console.log(this.room);
    console.log(name);
  }

  closedTheWebSocket(event, name) {
    console.log('WEBSOCKET CLOSED!!!');
    console.log(`WebSocket connection closed with code: ${event.code}`);
    console.log(JSON.stringify(event, ['message', 'arguments', 'type', 'name']));
    console.log(this.room);
    console.log(name);
    // Check if closure was unintentional and attempt a restart
    if (window.mingly.onServerDownReconnect) {
      try {
        localStorage.setItem('displayName', window.mingly.board.me.name);
        window.mingly.onServerDownReconnect();
      } catch {
        window.mingly.log('did not trigger change video');
      }
    }
    if (event.code === 1006 || event.code === 1001 || event.code === 1011) {
      console.log('Attempting WebSocket restart...');
      // Wait for a brief period before reconnecting (e.g., 5 seconds)
      setTimeout(() => this.connectToServer(name), 5000);
    } else {
      console.log('WebSocket connection closed. No automatic restart.');
    }
  }
  // event listeners based on webRTC

  setupPeerConnection(peerUuid, initCall) {
    // should compare my uuid with peerUuid
    // to see the lowest uuid to initiate call
    // might do this at a higher level
    const { type } = this.me.server;
    if (type !== 'webRTC') {
      console.log('I am not a webRTC type');
      return;
    }
    const name = `${this.participants[peerUuid].server.url}_${this.room}_${this.participants[peerUuid].server.type}`;
    this.participants[peerUuid].pc = new RTCPeerConnection(
      this.wssServers[name].config,
    );
    this.participants[peerUuid].pc.onicecandidate = (event) => this.gotIceCandidate(
      event,
      peerUuid,
    );

    this.participants[peerUuid].pc.ontrack = (event) => this.gotTrackPtoP(
      event,
      peerUuid,
    );

    // eslint-disable-next-line max-len
    this.participants[peerUuid].pc.oniceconnectionstatechange = (event) => this.checkPeerDisconnect(
      event,
      peerUuid,
    );

    this.participants[peerUuid].pc.negotiationneeded = (event) => this.renegotiate(
      event,
      peerUuid,
    );
    this.addStream(window.mingly.streamBlackSilence, 'webRTC', peerUuid);
    if (initCall) {
      // eslint-disable-next-line max-len
      this.participants[peerUuid].pc.createOffer().then((description) => this.createdDescription(
        description, peerUuid,
      )).catch((error) => {
        console.log(error);
      });
    }
  }

  gotIceCandidate(event, peerUuid) {
    console.log('Ice candidate');
    const name = `${this.participants[peerUuid].server.url}_${this.room}_${this.participants[peerUuid].server.type}`;
    if (event.candidate != null) {
      const msg = {
        'command': CMD.ICE,
        'room': name,
        'uuid': this.uuid,
        'dest': peerUuid,
        'ice': event.candidate,
      };
      this.wssServers[name].serverConnection.send(JSON.stringify(msg));
    }
  }

  checkPeerDisconnect(event, peerUuid) {
    const participant = this.participants[peerUuid];
    if (participant && participant.peerConnection) {
      const state = participant.peerConnection.iceConnectionState;
      window.mingly.log(`checkPeerDisconnect: connection with peer ${peerUuid} ${state}`);
      if (state === 'failed' || state === 'closed' || state === 'disconnected') {
        window.mingly.log(`checkPeerDisconnect: state in [failed|closed|disconnected] (${state}). Removing connection.`);
        // SEND A PING HERE
        this.pingMainWS(peerUuid);
        // pingParticipant(peerUuid);
        // need to check if we want to keep the participant or not, so the ping should be
        // sent ot the main websocket server (taking care of positions etc.)
      }
    } else {
      window.mingly.log(`checkPeerDisconnect: unable to get participants listing of peer ${peerUuid}`);
    }
  }
  // trying async function

  async createdDescription(description, peerUuid) {
    const name = `${this.participants[peerUuid].server.url}_${this.room}_${this.participants[peerUuid].server.type}`;
    // async approach
    await this.participants[peerUuid].pc.setLocalDescription(description);
    const msg = {
      'command': CMD.SDP,
      'sdp': description,
      'uuid': this.uuid,
      'dest': peerUuid,
      'room': this.wssServers[name].room,
    };
    this.wssServers[name].serverConnection.send(JSON.stringify(msg));
  }

  renegotiate(event, peerUuid) {
    console.log('closed websocket');
    console.log(event, peerUuid);
    console.log(this.room);
    const participant = this.participants[peerUuid];
    // eslint-disable-next-line max-len
    participant.peerConnection.createOffer().then((description) => this.createdDescription(description, peerUuid))
      .catch((error) => {
        console.log(error);
      });
  }

  gotTrackPtoP(event, peerUuid) {
    // this component should be changed to videoElement once we are done testing
    const participant = this.participants[peerUuid];
    if (!participant.videoElement) {
      participant.videoElement = document.createElement('video');
      participant.videoElement.setAttribute('id', `vid_${participant.uuid}`); // not sure we need this
      participant.videoElement.setAttribute('playsinline', '');
      const vidContainer = document.createElement('div');
      vidContainer.setAttribute('id', `remoteVideo_Test_${participant.uuid}`);
      vidContainer.setAttribute('class', 'videoContainer');
      vidContainer.appendChild(participant.videoElement);
      document.getElementById('videos').appendChild(vidContainer);
    }
    // not sure we really will use this anymore, but this has to be checked
    // and benchmarked. Also changed the name here
    // mediastream realated
    if (event.streams && event.streams[0]) {
      // eslint-disable-next-line prefer-destructuring
      participant.videoElement.srcObject = event.streams[0];
    } else {
      if (!participant.peerStreams) {
        participant.peerStreams = new MediaStream();
        // The ipad part for volume change -- this is quite experimental at the moments
        participant.videoElement.srcObject = participant.peerStreams;

        // todo: We need to do the folloing: Save the video track,
        // add all Gain stuff to audioelement rejoin into new stream
      }
      // don't really like the referrence to window.mingly.board here!
      if (event.track.kind === 'audio' && window.mingly.board.isIpad) {
        // todo: here we split the peerStreams but it does not work. Keep it for now to work on it
        participant.setupAudioCtx(event.track);
        // participant.setupAudioCtx(event);
      } else {
        participant.peerStreams.addTrack(event.track);
      }
    }
    // vidElement.setAttribute('autoplay', '');
    // participant.videovar = 1;
    participant.videoElement.srcObject.volume = 0;
    participant.videoElement.onplaying = function () {
      participant.isPlayingVideo = true;
    };

    participant.videoElement.onpause = function () {
      participant.isPlayingVideo = false;
    };

    // participant.resumeVideo();
    if (participant.videoElement.paused) {
      participant.videoElement.play();
    }
  }

  // methods
  // sayHiToEveryone() {
  //   const { mingly } = window;
  //   const { board } = mingly;
  //   const { room } = board;
  //   if (this.me.usepicture) {
  //     // only send image over websocket first time to easen on the flow
  //     // eslint-disable-next-line prefer-object-spread
  //     this.controlWebsocket.send(JSON.stringify
  // (Object.assign({ 'command': CMD.HandShake }, this.me.asJSON(),
  // { 'dest': 'all', 'avatar': localStorage.getItem('imgData') })));
  //   } else {
  //     // eslint-disable-next-line prefer-object-spread
  //     this.controlWebsocket.send(JSON.stringify(Object.assign(
  // { 'command': CMD.HandShake }, this.me.asJSON(), { 'dest': 'all' })));
  //   }
  //   // serverConnection.send(JSON.stringify({'signal_position':pos }))
  //   this.me.moveToRandomPosition();
  //   if (board.doIntroLocation && room.mediaserver) {
  //     board.drawVideo(this.me);
  //     board.doIntroAnimation();
  //   }
  //   this.me.hasMoved = true;
  // }

  pingMainWS(peerUuid) {
    const participant = this.participants[peerUuid];
    // eslint-disable-next-line prefer-object-spread
    this.controlWebsocket.send(JSON.stringify(Object.assign({ 'command': CMD.PingParticipant }, this.me.asJSON(), { 'areYouThere': true }, { 'dest': participant.uuid })));
    participant.isThere = false;
    setTimeout(function () {
      if (participant.isThere) {
        // SHOULD ONE RECONNECT IF THERE?
        // NEED TO RUN A TEST TO SEE IF CLOSE ENOUGH, IF SO RECONNECT
        if (!window.mingly.board.room.mediaserver) {
          window.mingly.log('Participant still here! Trying to connect again');
          participant.peerConnection.close();
          if (participant.peerStreams) {
            delete participant.peerStreams;
          }
          if (participant.vidContainer) {
            delete participant.vidContainer;
          }
          this.setupPeerConnection(participant.uuid, true);
        }
      } else {
        window.mingly.log('participant not here anymore, killing...');
        // killPeer(participant.uuid, true);
      }
    }, 5000); // WAIT 5 SEC FOR EXECUTION
  }

  addTrack(track, type, destination) {
  // destination is more general than peerUuid as it might be going
  // to the mediaserver or CPaaS etc.
  // could of course just read of the type from me.server.type, but maybe
  // we do more servers etc at some point
    switch (type) {
      case 'webRTC':
        console.log('Adding the track');
        this.participants[destination].pc.addTrack(track);
        break;
      case 'mediasoup':
        break;
      default:
        console.log('Adding the track wrong place');
        break;
    }
  }

  addStream(stream, type, destination) {
    Object.values(stream.getTracks()).forEach((track) => {
      this.addTrack(track, type, destination);
    });
  }

  swapStream(peerUuid, stream, part = 'all') {
    // HERE WE CAN ADD MEDIA SERVERS AND OTHER WAYS OF SWAPIING THE STREAMS
    // WE MIGHT HAVE TO INCLUDE INPUT THAT JUST INDICATES WHICH STREAM TO
    // USE IF WE USE MEDIA SERVERS
    const participant = this.participants[peerUuid];
    // THIS IS THE VANILLA WEBRTC VERSION:
    switch (part) {
      case 'audio': {
        const audioTrack = stream.getAudioTracks()[0];
        participant.pc.getSenders().map((sender) => sender.replaceTrack(
          stream.getTracks().find((t) => t.kind === 'audio'), audioTrack,
        ));
        break;
      }

      case 'video': {
        const videoTrack = stream.getVideoTracks()[0];
        participant.pc.getSenders().map((sender) => sender.replaceTrack(
          stream.getTracks().find((t) => t.kind === 'video'), videoTrack,
        ));
        break;
      }

      case 'all':
        participant.pc.getSenders().map((sender) => sender.replaceTrack(
          stream.getTracks().find((t) => t.kind === sender.track.kind), stream,
        ));
        break;

      default: // same as all some maybe not great
        participant.pc.getSenders().map((sender) => sender.replaceTrack(
          stream.getTracks().find((t) => t.kind === sender.track.kind), stream,
        ));
    }
  }

  consumeStream(uuid, type) {
    console.log('This is when i ask to consume a stream');
    // this is asking for a track or stream
    // mayeb split up into asking for track and asking for stream
    // the logic will be different based on webRTC and mediaservers
    console.log(uuid, type);
    console.log(this.room);
  }
  // mediasoup functions

  createTransport(direction, name) {
    // ask the server to create a server-side transport object and send
    // us back the info we need to create a client-side transport
    // most of what will happen only happens when there is a reply
    // 'create-transport'
    const msg = {
      'command': CMD.CreateTransport,
      'uuid': this.me.uuid,
      'direction': direction,
      'room': name,
      'dest': 'server',
    };
    this.wssServers[name].serverConnection.send(JSON.stringify(msg));
  }

  setUpTransport(transportOptions, direction, name) {
    console.log(this.room);
    console.log(transportOptions, direction);
    // this takes the transportOptions from the server to create the trasnport
    // note: make sure server send back direction
    // for iceServers, this should be input rather than hard-wired here and moved to server side!
    // look here for the solutioons https://docs.xirsys.com/?pg=secure-calls-xirsys-api
    // eslint-disable-next-line no-param-reassign
    transportOptions.iceServers = [
      {
        username: 'TtqoRCtM6cikkHvDfXtYdfPZJJFzUNzsIoqe6DYlopYrhB-VPHNXdS9JGGDHMTrNAAAAAF9Y6i1jaGhleWVyZGFobGxhcnNlbg==',
        credential: 'e433e0b4-f2aa-11ea-b7fe-0242ac140004',
        urls: [
          'turn:eu-turn7.xirsys.com:80?transport=udp',
          'turn:eu-turn7.xirsys.com:3478?transport=udp',
          'turn:eu-turn7.xirsys.com:80?transport=tcp',
          'turn:eu-turn7.xirsys.com:3478?transport=tcp',
          'turns:eu-turn7.xirsys.com:443?transport=tcp',
          'turns:eu-turn7.xirsys.com:5349?transport=tcp',
        ],
      }];
    if (direction === 'recv') {
      this.wssServers[name].recvTransportOptions = transportOptions;
      // eslint-disable-next-line max-len
      this.wssServers[name].recvTransport = this.wssServers[name].device.createRecvTransport(transportOptions);
      this.wssServers[name].nTransportsReady += 1; // want both to be up and running
      // before doing anything
      if (this.wssServers[name].nTransportsReady === 2) {
        console.log('NEW Do stuff here with the stream');
        this.setUpStreamMS(name);
      }
      console.log('NEW got past first Receive');
      // eslint-disable-next-line no-unused-vars
      this.wssServers[name].recvTransport.on('connect', async ({ dtlsParameters }, callback, errback) => {
        // the handling of the callback and errback are not good
        console.log('NEW connected receive transport');
        console.log(dtlsParameters);
        const msg = {
          'command': CMD.dtlsParameters,
          'room': name,
          'uuid': this.me.uuid,
          'dtlsPars': dtlsParameters,
          'transportId': this.wssServers[name].recvTransportOptions.id,
          'dest': 'server',
        };
        this.wssServers[name].serverConnection.send(JSON.stringify(msg));
        callback();
      });
    } else if (direction === 'send') {
      this.wssServers[name].sendTransportOptions = transportOptions;
      // eslint-disable-next-line max-len
      this.wssServers[name].sendTransport = this.wssServers[name].device.createSendTransport(transportOptions);
      this.wssServers[name].nTransportsReady += 1; // want both to be up and running
      if (this.wssServers[name].nTransportsReady === 2) {
        console.log('Do stuff here with the stream');
        this.setUpStreamMS(name);
      }
      console.log('got past first Send');
      // eslint-disable-next-line no-unused-vars
      this.wssServers[name].sendTransport.on('connect', async ({ dtlsParameters }, callback, errback) => {
        // the handling of the callback and errback are not good
        console.log('NEW connected send transport');
        console.log(dtlsParameters);
        const msg = {
          'command': CMD.dtlsParameters,
          'room': name,
          'uuid': this.me.uuid,
          'dtlsPars': dtlsParameters,
          'transportId': this.wssServers[name].sendTransportOptions.id,
          'dest': 'server',
        };
        this.wssServers[name].serverConnection.send(JSON.stringify(Object.assign(msg)));
        callback();
      });
      // on produce event. Should be be setup after hearing back from server on connect?
      this.wssServers[name].sendTransport.on('produce',
        // eslint-disable-next-line no-unused-vars
        async ({ kind, rtpParameters, appData }, callback, errback) => {
        // the handling of the callback and errback are not good
        // send to server
        //  Unsure if this is the way to go
          this.wssServers[name].msOnProduceCallback = callback;
          console.log('NEW in produce');
          console.log(kind);
          const paused = 'paused';
          const msg = {
            'command': CMD.OnTransportProduce,
            'room': name,
            'uuid': this.me.uuid,
            'transportId': this.wssServers[name].sendTransportOptions.id,
            'kind': kind,
            'rtpParameters': rtpParameters,
            'paused': paused,
            'appData': appData,
            'dest': 'server',
          };
          this.wssServers[name].serverConnection.send(JSON.stringify(Object.assign(msg)));
        // .then(callback)
        // .catch(errback);
        });
    } else {
      console.log('NEW Bad direction for mediaserver');
    }
  }

  setUpStreamMS(name) {
    // THIS FUNCTION IS JUST FOR BASELINE VIDEO AND AUDIO
    // const { mingly } = window;
    // const { me } = window.mingly.board;
    // might want to use the mediastream in the me object (although the same)
    this.sendTrackMediaServer(window.mingly.streamLowRes.getVideoTracks()[0], name, 'video');
    this.sendTrackMediaServer(window.mingly.streamLowRes.getAudioTracks()[0], name, 'audio');
  }

  sendTrackMediaServer(track, name, type) {
    // type 'cam-video' - need to use type
    // do we need the type - can always check
    console.log('In the sendTrackMediServer');
    const dat = { mediaTag: {} };
    dat.mediaTag = type;
    dat.serverName = name;
    if (type === 'video' || type === 'ms_video') {
      this.wssServers[name].sendTransport.produce({
        track, // changed from track: track, track,
        encodings: this.camEncodings(),
        appData: dat,
      }).then((producer) => {
        this.me.producerMS.push(producer);
      }).catch((error) => {
        console.log(error);
      });
    } else {
      console.log('In the right send track ');
      console.log(name);
      this.wssServers[name].sendTransport.produce({
        track,
        appData: dat,
      }).then((producer) => {
        this.me.producerMS.push(producer);
        console.log('Her er det noen in produce');
      }).catch((error) => {
        console.log(error);
      });
    }
  }

  subscribeToTrack(peerUuid, mediaTag, ms = true) {
    // uuid is the uuid of the particpant we want a track from
    // mediaTag is the kind of media
    console.log('in subsribe to track');
    console.log(this.participants[peerUuid]);
    console.log(this.participants[peerUuid].server);
    // check if we have transports up and running
    // if not, then conenct //here we check if nTransportsReady = 1 or 2
    const name = `${this.participants[peerUuid].server.url}_${this.room}_${this.participants[peerUuid].server.type}`;
    // check if we have transports up and running
    // if not, then conenct //here we check if nTransportsReady = 1 or 2
    if (this.wssServers[name] === undefined) {
      // connect to server
      console.log('Reconnecting to server');
      this.connectToServer(name);
    }
    if ((this.wssServers[name].send === true
       && this.wssServers[name].nTransportsReady < 2)
       || (this.wssServers[name].send === false
        && this.wssServers[name].nTransportsReady < 1)) {
      // the transports are not ready
      console.log('Tansports Not Read');
      return;
    }
    if (this.wssServers[name].type === 'webRTC') {
      console.log('should be PC not mediasoup');
      return;
    }
    console.log('NEW subscribing to track', peerUuid, mediaTag);

    // Should check if we have a recvTransport first (should have),
    // but there is a risk you don't so it should be check before
    // using this function. Could create it if it does not exist,
    // but it have to the by async so makes it more messy.
    // We need to as sever for the subscription

    // Check if we already subscribe
    // Need to implement something similar on servers side
    let noPending = true;
    const pendings = this.participants[peerUuid].pendingMS;
    Object.values(pendings).forEach((pending) => {
      if (pending === mediaTag) {
        console.log('Pending Media Track');
        noPending = false;
      }
    });
    const consumers = this.participants[peerUuid].consumerMS;
    let notSubcriber = true;
    Object.values(consumers).forEach((consumer) => {
      if (consumer.appData.peerUuid === peerUuid && consumer.appData.mediaTag === mediaTag) {
        console.log('Already subscribing to this track');
        notSubcriber = false;
      }
    });

    // This could be a potential for issues: Could do something like:
    // If not this.wssServers[name].device then setTimeOut 1000
    // then run code. If still not, then do a more thorough check..
    if (notSubcriber && noPending) {
      console.log(this.participants[peerUuid]);
      console.log(name);
      console.log(this.wssServers[name].device);
      pendings.push(mediaTag);
      const { rtpCapabilities } = this.wssServers[name].device;
      const msg = {
        'command': CMD.SubscribeToTrack,
        'uuid': this.me.uuid,
        'room': name,
        'mediaPeerId': peerUuid,
        'mediaTag': mediaTag,
        'rtpCapabilities': rtpCapabilities,
        'dest': 'server',
        'ms': ms,
      };
      this.wssServers[name].serverConnection.send(JSON.stringify(Object.assign(msg)));
    }
  }

  setUpConsumption(peerUuid, name, consumerParameters, mediaTag, ms) {
    // this function is called when server get back to us with details after
    // calling subcribeToTrack
    const server = this.wssServers[name];
    window.mingly.log('NEW in setupConsumption');
    window.mingly.log(peerUuid);
    let participant = this.participants[peerUuid];

    if (peerUuid === this.me.uuid) {
      participant = this.me;
    }
    // should do some checks if the client can consume the media
    server.recvTransport.consume({
      ...consumerParameters,
      appData: {
        peerUuid,
        mediaTag,
        'serverName': name,
      },
    }).then((consumer) => {
      window.mingly.log('NEW add consumption here');
      // storing the consumer object (do we need to?)
      if (!participant.consumerMS) {
        participant.consumerMS = consumer;
      } else {
        // not sure about multiple 'consumers' here either
        participant.consumerMS.push(consumer);
      }
      // remove pending
      const pendings = [];
      const N = participant.pendingMS.length;
      for (let i = 0; i < N; i += 1) {
        if (participant.pendingMS[i] !== mediaTag) {
          pendings.push(participant.pendingMS[i]);
        }
      }
      participant.pendingMS = pendings;
      // if (board.room.testing) {
      sendStatisticsMS(participant, consumer);
      // }
      // On the transport close
      consumer.on('transportclose', () => {
        this.closeConsumer(peerUuid, name, consumer);
        // Assuming closed transport means participant is no longer here
        // Note that this is risky when moving between MS and no MS
        window.mingly.log('Transport Closed');
        // killPeer(participant.uuid);
      });
      consumer.on('trackended', () => {
        // Assuming closed transport means participant is no longer here
        // Note that this is risky when moving between MS and no MS
        this.closeConsumer(peerUuid, name, consumer);
        window.mingly.log('Track ended');
        // killPeer(participant.uuid);
      });
      consumer.on('producerpause', () => {
        consumer.pause();
      });
      // NEED TO FIX REST
      // resuming consumer client side (not sure about this)
      consumer.resume();
      // ask server to resume consumer
      const msg = {
        'command': CMD.ResumeConsumer,
        'uuid': this.me.uuid,
        'room': name,
        'mediaPeerId': peerUuid,
        'mediaTag': mediaTag,
        'consumerId': consumer.id,
        'dest': 'server',
      };
      server.serverConnection.send(JSON.stringify(msg));
      // attach the media to the the participant object (new for now?)
      // storing it as mediaserver stream, want to change this later
      if (ms) {
        console.log('NEW: Here we will add broadcasting things');
        if (participant.msStream) {
          participant.msStream.addTrack(consumer.track);
        } else {
          const stream = new MediaStream();
          stream.addTrack(consumer.track);
          participant.msStream = stream;
          // if (mingly.board.isExternal && mingly.msBroadCastType !== 'ms_canvas_screen'
          //  && mingly.msBroadCastType !== 'ms_canvas_screen_and_screenaudio') {
          //   const dat = {
          //     uuid: participant.uuid,
          //   };
          //   if (window.mingly.onMSBroadcast) {
          //     try {
          //       window.mingly.onMSBroadcast(dat);
          //     }
          //     catch {
          //       window.mingly.log('did not do onMSBroadcast');
          //     }
          //   }
          // } else {
          // create video element and
          console.log('inside the right one');
          console.log(mediaTag);
          if (mediaTag === 'ms_screen' || mediaTag === 'ms_screen_audio') {
            setupMsCanvasVideo(participant);
          } else {
            console.log('not defined type');
          }
          // else {
          //   setupMsVideo(participant);
          // }
          // }
        }
      } else {
        // add to normal "stream"
        if (participant.peerStreams) {
          // need to fix here!
          if (consumer.kind === 'audio' && window.mingly.board.isIpad) {
            console.log('NEW Audio AND Ipad');
            participant.setupAudioCtx(consumer.track);
          } else {
            console.log('NEW Audio AND NOT Ipad');
            participant.peerStreams.addTrack(consumer.track);
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (consumer.kind === 'audio' && window.mingly.board.isIpad) {
            console.log('NEW Audio AND Ipad');
            participant.setupAudioCtx(consumer.track);
          } else {
            const stream = new MediaStream();
            stream.addTrack(consumer.track);
            participant.peerStreams = stream;
          }
        }
        // might want to remove the oneded one here
        // eslint-disable-next-line no-param-reassign
        consumer.track.onended = (event) => {
          if (participant.peerStreams) {
            participant.peerStreams.removeTrack(this);
            console.log('removing track');
            console.log(event);
          }
        };
        if (participant.videovar < 3) {
          if (!participant.videoElement) {
            console.log('in the video creating stuff');
            participant.videoElement = document.createElement('video');
            participant.videoElement.setAttribute('id', `vid_${participant.uuid}`); // not sure we need this
            participant.videoElement.setAttribute('playsinline', '');
            const vidContainer = document.createElement('div');
            vidContainer.setAttribute('id', `remoteVideo_${participant.uuid}`);
            vidContainer.setAttribute('class', 'videoContainer');
            vidContainer.appendChild(participant.videoElement);
            document.getElementById('videos').appendChild(vidContainer);
            participant.videoElement.srcObject = participant.peerStreams;
            participant.videoElement.srcObject.volume = 0;
            participant.videoElement.oncanplay = () => {
              participant.videoElement.play();
              window.mingly.board.drawAvatar(participant);
            };
            participant.videoElement.onplaying = function () {
              participant.isPlayingVideo = true;
            };
            participant.videoElement.onpause = function () {
              participant.isPlayingVideo = false;
            };
          } else {
            try {
              if (window.mingly.audioOutSource && window.adapter.browserDetails.browser === 'chrome') {
                participant.videoElement.setSinkId(window.mingly.audioOutSource);
              }
            } catch {
              window.mingly.log('cannot set sinkId');
            }
            participant.videoElement.srcObject = participant.peerStreams;
            participant.videoElement.srcObject.volume = 0;
            participant.videoElement.oncanplay = () => {
              participant.videoElement.play();
              window.mingly.board.drawAvatar(participant);
            };
            // participant.videoElement.play();
            // participant.videoElement.srcObject.volume = 0;
            // vidContainer.childNodes[0].volume = 0; // check if this is ok to comment out
            participant.videoElement.onplaying = function () {
              participant.isPlayingVideo = true;
            };
            participant.videoElement.onpause = function () {
              participant.isPlayingVideo = false;
            };
          }
          // participant.resumeVideo();
          participant.adjustVolume(this.me);
          // not so keen on havign board here as well...
          // update the connection now
          // updateConnections();
        } else {
          console.log('NEW Should not be media now');
        }
        // mingly.log('Going to call gotRemoteStream');
        // gotRemoteStream(consumer, participant.uuid);
      }
    }).catch((error) => {
      console.log(error);
    });
  }

  closeConsumer(peerUuid, name, consumer) {
    const server = this.wssServers[name];
    const msg = {
      'command': CMD.CloseConsumer,
      'uuid': this.me.uuid,
      'room': name,
      'mediaPeerId': peerUuid,
      'mediaTag': consumer.appData.mediaTag,
      'consumerId': consumer.id,
      'dest': 'server',
    };
    server.serverConnection.send(JSON.stringify(msg));
    if (!consumer.closed) {
      consumer.close();
    }
  }

  pauseConsumer(peerUuid, name, consumer, pause) {
    const server = this.wssServers[name];
    let msg;
    if (pause) {
      consumer.pause();
      msg = {
        'command': CMD.PauseConsumer,
        'uuid': this.me.uuid,
        'room': name,
        'mediaPeerId': peerUuid,
        'mediaTag': consumer.appData.mediaTag,
        'consumerId': consumer.id,
        'dest': 'server',
      };
      // ask server to resume consumer
    } else {
      consumer.resume();
      msg = {
        'command': CMD.ResumeConsumer,
        'uuid': this.me.uuid,
        'room': name,
        'mediaPeerId': peerUuid,
        'mediaTag': consumer.appData.mediaTag,
        'consumerId': consumer.id,
        'dest': 'server',
      };
      // ask server to resume consumer
      // ask server to resume consumer
      // CAREFUL UUID TWICE (in meAS and the other, want peerId)
      // serverConnection.send(JSON.stringify(Object.assign({ 'command': CMD.ResumeConsumer },
      // me.asJSON(), { 'uuid': uuid, 'mediaTag': mediaTag, 'consumerId':
      // consumer.id, 'dest': 'server' })));
    }
    server.serverConnection.send(JSON.stringify(msg));
  }

  pauseProducer(producer, name, pause) {
    const server = this.wssServers[name];
    let msg;
    if (pause) {
      producer.pause();
      msg = {
        'command': CMD.PauseProducer,
        'room': name,
        'uuid': this.me.uuid,
        'mediaTag': producer.appData.mediaTag,
        'dest': 'server',
      };
      // ask server to resume consumer
    } else {
      producer.resume();
      msg = {
        'command': CMD.ResumeProducer,
        'room': name,
        'uuid': this.me.uuid,
        'mediaTag': producer.appData.mediaTag,
        'dest': 'server',
      };
      // ask server to resume consumer
    }
    server.serverConnection.send(JSON.stringify(msg));
  }

  unsubscribeFromTrack(peerUuid, type) {
    // THIS IS WHERE I HAVE TROUBLES!!!
    const participant = window.mingly.board.participants[peerUuid];
    const consumers = participant.consumerMS;
    const name = `${participant.server.url}_${this.room}_${participant.server.type}`;
    const consumer = consumers.find((c) => (c.appData.mediaTag === type));
    this.closeConsumer(peerUuid, name, consumer);
    // remove the consumer
    const part = [];
    for (let i = 0; i < consumers.length; i += 1) {
      if (consumers[i].appData.mediaTag !== type) {
        part.push(consumers[i]);
      }
    }
    participant.consumerMS = part;
  }

  pickTracksPeer(participant) {
    // name = `${this.participants[peerUuid].server.url}_${this.room}
    // _${this.participants[peerUuid].server.type}`;
    // eslint-disable-next-line prefer-destructuring
    const videovar = participant.videovar; // 0=audio only, 1=video and audio, 2= nothing
    const consumers = participant.consumerMS;
    const peerUuid = participant.uuid;
    const audio = consumers.find((c) => (c.appData.mediaTag === 'audio'));
    const video = consumers.find((c) => (c.appData.mediaTag === 'video'));
    const name = `${participant.server.url}_${this.room}_${participant.server.type}`;
    // videovar values:
    // 0 = audio only
    // 1 = audio and video
    // 2 = nothing
    if (videovar === 0) {
      if (audio && audio.paused) {
        this.pauseConsumer(peerUuid, name, audio, false);
      }
      if (video) {
        this.pauseConsumer(peerUuid, name, video, true);
      }
      if (audio === undefined) {
        this.subscribeToTrack(peerUuid, 'audio', false);
      }
      // ask audio only
    } else if (videovar === 1) {
      if (video && video.paused) {
        this.pauseConsumer(peerUuid, name, video, false);
      }
      if (audio && audio.paused) {
        this.pauseConsumer(peerUuid, name, audio, false);
      }
      if (video === undefined) {
        this.subscribeToTrack(peerUuid, 'video', false);
      }
      if (audio === undefined) {
        this.subscribeToTrack(peerUuid, 'audio', false);
      }
      // ask for audio and video
    } else if (videovar === 2) {
      if (video) {
        this.pauseConsumer(peerUuid, name, video, true);
      }
      if (audio) {
        this.pauseConsumer(peerUuid, name, audio, true);
      }
    } else if (videovar === 3) {
      if (video) {
        this.closeConsumer(peerUuid, name, video);
        // should check how removing as below works
        // eslint-disable-next-line no-param-reassign
        participant.consumerMS = participant.consumerMS.filter((c) => c !== video);
      }
      if (audio) {
        this.closeConsumer(peerUuid, name, audio);
        // should check how removing as below works
        // eslint-disable-next-line no-param-reassign
        participant.consumerMS = participant.consumerMS.filter((c) => c !== audio);
      }
      if (participant.consumerMS.length === 0) {
        console.log('no consumers');
        // eslint-disable-next-line no-param-reassign
        delete participant.peerStreams;
      }
    } else {
      console.log('NEW Invalid videovar value');
    }
  }

  closeServer(name) {
    if (this.wssServers[name] === undefined) {
      console.log(name);
      console.log('server you are trying to close is undefined');
      return;
    }
    const server = this.wssServers[name];
    // start closing consumers, producers and transports
    // Closing Producers
    if (server.send) {
      const readyState = 0;
      this.updateServerReadyState(readyState, name);
      // finding the right producers
      for (let i = 0; i < this.me.producerMS.length; i += 1) {
        if (this.me.producerMS[i].appData.serverName === name) {
          // close the producers
          // must implement close producer on client and server side here
          console.log('close, but not close');
        }
      }
    }
    // Closing consumers
    Object.values(this.participants).forEach((participant) => {
      const consumers = participant.consumerMS;
      for (let i = 0; i < consumers.length; i += 1) {
        if (consumers[i].appData.serverName === name) {
          // unsubscribe from participant
          const type = consumers[i].appData.mediaTag;
          this.unsubscribeFromTrack(participant.uuid, type);
        }
      }
    });
    // Close transport
    // Close webscoket
    server.serverConnection.close();
    // Remove websocket
    delete this.wssServers[name];
  }

  connectToServer(name) {
    if (this.wssServers[name] || this.pending[name]) {
      console.log(name);
      console.log('already connected or connecting to the server');
      return;
    }
    // connecting to server
    Object.values(this.config).forEach((server) => {
      const roomName = `${server.wssServer}_${this.room}_${server.type}`;
      if (roomName === name) {
        console.log(`Connecting to ${name}`);
        this.connectToWebSocket(server.wssServer, roomName, server.type, server.rtcConfig,
          server.send);
      }
    });
    // add elsewhere when transports are ready that you can reconnect
    // send message that you are ready (call an update at this point?)
  }

  updateServerReadyState(readyState, name) {
    // check that I am sending on that serer
    const server = this.wssServers[name];
    if (!server.send) return;
    // change my readyState
    if (readyState === 0) {
      this.me.server.readyState = 0;
    } else if (readyState === 1) {
      this.me.server.readyState = 1;
    } else {
      console.log('Invalid readystate');
    }
    // send update
    const msg = {
      'command': CMD.ServerReadyStateChange,
      'room': name,
      'uuid': this.me.uuid,
      'serverReadyState': readyState,
      'dest': 'all',
    };
    server.serverConnection.send(JSON.stringify(Object.assign(msg)));
    updateConnections();
  }

  checkForInconsitencies(name) {
    // the enabled=true muted=true issue
    const { mingly } = window;
    const { board } = mingly;
    const { room } = board;
    let issueRestart = false;
    Object.values(this.participants).forEach((participant) => {
      const consumer = participant.consumerMS.find((c) => (c.appData.mediaTag === 'video'));
      if (consumer === undefined) {
        console.log('No consumer so returning');
        return;
      }
      const { enabled } = consumer.track;
      const { muted } = consumer.track;
      const nameServer = `${participant.server.url}_${this.room}_${participant.server.type}`;
      if (enabled && muted && nameServer === name) {
        // inconsisten combination
        issueRestart = true;
      } else {
        issueRestart = false;
      }
    });
    if (issueRestart && room.nParticipants > 2) {
      // restarting this websocket Connection
      // window.mingly.log(`Closing
      //  websocket due enabled and muted conflict: ${name}`);
      console.log(`Closing
        websocket due enabled and muted conflict: ${name}`);
      // this.closeServer(name);
    }
    // if there is an issueRestart, then I restart the servers
  }
  // To be added: Call balancerServer for additional server capacity
  // Here we should check if the room allows for this
  // Update balancerServer info, and update here
  // A loop that checks regularly for failure conditions, then reconnects in case
  // Some eventlisteners for failure

  camEncodings() {
    return this.videoEncodings;
  }
}
