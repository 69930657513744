/* eslint-disable import/prefer-default-export */
export default {
  OpenConnection: 0,
  HandShake: 1,
  Move: 2,
  Chat: 3,
  EditStream: 4,
  DJControl: 5,
  SDP: 6,
  ICE: 7,
  PingParticipant: 8,
  Leaving: 9,
  ShareScreen: 10,
  NoVideo: 11,
  ChgBackGrnd: 12,
  RemoteVideo: 13,
  CloseRTC: 14,
  ClosePeer: 15,
  ChangeName: 16,
  ChangePPic: 17,
  MediaServerIn: 18,
  CreateTransport: 19,
  dtlsParameters: 20,
  OnTransportProduce: 21,
  SubscribeToTrack: 22,
  ResumeConsumer: 23,
  PauseConsumer: 24,
  PauseProducer: 25,
  ResumeProducer: 26,
  CloseConsumer: 27,
  CloseProducer: 28,
  Broadcast: 29,
  ProduceReady: 30,
  NewPoll: 31,
  PollAnswer: 32,
  PollStats: 33,
  ClosePoll: 34,
  GameInvite: 35,
  QuizAnswer: 36,
  NewQuizQuestion: 37,
  AcceptGame: 38,
  StopGame: 39,
  UpdateGameData: 40,
  Ping: 41,
  Pong: 42,
  ParticipantLeft: 43,
  Muted: 44,
  MoveCursor: 45,
  Sleeping: 46,
  Calling: 47,
  BroadcastOnCanvas: 48,
  UpdateBroadcastOnCanvas: 49,
  TurnGhost: 50,
  localBroadCast: 51,
  BallGame: 52,
  LeadingView: 53,
  AskToFollowView: 54,
  HostMuteAll: 55,
  UpdateHostStates: 56,
  PlaceAndLock: 57,
  HostNoVideo: 58,
  ActivateNoHostMenu: 59,
  RaiseHand: 60,
  HostToHandRaiser: 61,
  NumberOfClients: 62,
  serverLogin: 63,
  serverUpdate: 64,
  ServerReadyStateChange: 65,
  changeGameMenuVisibility: 66,
  forcedMoveFromHost: 67,
  biVideoAnimation1: 68,
  biVideoAnimation2: 69,
};
