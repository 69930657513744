<template>
  <div ref="boardArea">
    <!--canvas id="canvas" class="canvas_size" style="border:1px solid #ff0000"></canvas-->
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
// import adapter from 'webrtc-adapter';
import { start, updateGameData, setAllParameters } from '../../../assets/js/webrtc';

import Canvasboard from '../../../assets/js/board_canvas';

export default {
  name: 'Canvas',
  // data() {
  //   return {
  //     canvasReady: false,
  //   };
  // },
  methods: {
    ...mapActions('$_userdata', [
      'commitSetRoom',
      'commitSetUserID',
      'commitVideoVar',
      'commitHoover',
      'commitAddParticipant',
      'commitRemoveParticipant',
      'commitAddMessage',
      'commitChangeNameParticipant',
      'commitChangeProfilePicture',
      'commitSetPoll',
      'commitPollResults',
      'commitMenuItems',
      'commitSetHost',
    ]),
    ...mapActions('$_canvas', [
      'commitSetShowParticipantVideoActive',
      'commitSetBroadCastActive',
      'commitSetScreenShareActive',
      'commitSetAnswerPollActive',
      'commitSetStatCreatorPollActive',
      'commitSetStatDisplayPollActive',
      'commitSetVideoShareActive',
      'commitSetGameData',
      'commitSetGameInvitationActive',
      'commitAddPlayerData',
      'commitSetMuteActive',
      'commitSetScoreBoardGTCActive',
      'commitSetCanvasViewActive',
      'commitSetEnterVideoActive',
      'commitSetNoHostMenuActive',
      'commitAddRaisedHand',
      'commitRemoveRaisedHand',
      'commitSetRaisedHandOverviewActive',
      'commitRaiseHandActive',
      'commitAskToReload',
    ]),
    turnVideoOff() {
      this.commitSetVideoShareActive();
    },
    turnAudioOff() {
      this.commitSetMuteActive();
    },
    addParticipant(participant) {
      let vid = false;
      if (participant.videovar === 1) {
        vid = true;
      }
      this.commitAddParticipant({
        username: participant.name,
        userID: participant.uuid,
        profilePicture: participant.pic,
        videovar: vid,
        hoover: participant.hoover,
      });
    },
    changeNameParticipant(signal) {
      this.commitChangeNameParticipant({
        id: signal.uuid,
        name: signal.displayName,
      });
    },
    changeProfilePicture(signal) {
      const img = new Image();
      const that = this;
      img.src = `data:image/png;base64,${signal.pic}`;
      img.onload = function () {
        that.commitChangeProfilePicture({
          userID: signal.uuid,
          profilePicture: img,
        });
      };
    },
    changeHostStatus() {
      console.log('change status of host');
      this.commitSetHost();
    },
    raisedHand(dat) {
      console.log('RAIIIIIISING');
      if (dat.type) {
        this.commitAddRaisedHand({
          id: dat.id,
          name: dat.name,
        });
        if (!this.isRaisedHandOverviewActive) {
          this.commitSetRaisedHandOverviewActive();
        }
      } else {
        this.commitRemoveRaisedHand({
          id: dat.id,
          name: dat.name,
        });
        // eslint-disable-next-line eqeqeq
        if (this.raisedHands.length == 0) {
          this.commitSetRaisedHandOverviewActive();
        }
      }
    },
    lowerHand() {
      this.commitRaiseHandActive();
    },
    screenShareChange(ss) {
      console.log(`sharing screen is ${ss}`);
      this.commitSetScreenShareActive();
    },
    focusScreen(participant) {
      this.commitSetShowParticipantVideoActive({
        userID: participant.uuid,
        videovar: participant.videovar,
      });
    },
    serverDisconnected() {
      this.commitAskToReload();
      console.log('server disconnected: I am here!');
    },
    removeParticipant(participant) {
      // should think about host states here
      // however, right now we have one host
      // cohost might be different
      this.commitSetShowParticipantVideoActive({
        userID: participant.uuid,
        videovar: participant.videovar,
        remove: true,
      });
      this.commitRemoveParticipant(
        participant.uuid,
      );
    },
    changeVideovar(participant) {
      let vid = false;
      if (participant.videovar === 1) {
        vid = true;
      }
      this.commitVideoVar({
        username: participant.name,
        userID: participant.uuid,
        profilePicture: participant.pic,
        videovar: vid,
        hoover: participant.hoover,
      });
    },
    msBroadcast(dat) {
      console.log('broadcast!');
      console.log(dat.uuid);
      let thisIsMe = false;
      if (dat.uuid === window.mingly.board.me.uuid) {
        console.log('This is me!');
        thisIsMe = true;
      }
      this.commitSetBroadCastActive({
        userID: dat.uuid,
        isMe: thisIsMe,
      });
    },
    addChatMessage(signal) {
      console.log('add a chat here');
      if (signal.dest === 'all') {
        this.commitAddMessage({
          chatID: 'everyone-id',
          data: {
            id: signal.uuid,
            data: signal.chatmessage,
            name: signal.displayName,
          },
        });
      } else {
        this.commitAddMessage({
          chatID: signal.uuid,
          data: {
            id: signal.uuid,
            data: signal.chatmessage,
            name: signal.displayName,
          },
        });
      }
    },
    noHostMenu(dat) {
      if ((dat && !this.isNoHostMenuActive)
       || (!dat && this.isNoHostMenuActive)) {
        this.commitSetNoHostMenuActive();
      }
    },
    changeHoover(participant) {
      let vid = false;
      if (participant.videovar === 1) {
        vid = true;
      }
      this.commitHoover({
        username: participant.name,
        userID: participant.uuid,
        profilePicture: participant.pic,
        videovar: vid,
        hoover: participant.hoover,
      });
    },
    gotPoll(signal) {
      // console.log('Inside poll anwers', signal.uuid);
      if (this.pollData.isActive) {
        console.log('already a poll active');
      } else {
        console.log('new poll');
        const pollDat = {
          question: signal.pollData.question,
          alternatives: signal.pollData.alternatives,
          creatorId: signal.uuid,
          isAnonymous: signal.pollData.isAnonymous,
          isActive: true,
          onCanvas: signal.pollData.onCanvas,
          quiz: signal.pollData.quiz,
          timed: signal.pollData.timed,
          answerSelf: signal.pollData.answerSelf,
          correctAnswerPos: signal.pollData.correctAnswerPos,
        };
        this.commitSetPoll(pollDat);
        if (signal.uuid === window.mingly.board.me.uuid) {
          console.log('it was you who started the poll');
        } else {
          console.log('check if canvas');
          if (!signal.pollData.onCanvas) {
            this.commitSetAnswerPollActive();
          }
        }
      }
    },
    gotPollAnswer(signal) {
      console.log('Inside poll anwers', signal.uuid);
      this.commitPollResults(window.mingly.pollResults);
    },
    gotPollStats(signal) {
      window.mingly.pollResults = signal.pollData;
      this.commitPollResults(window.mingly.pollResults);
      if (!this.isStatDisplayPollActive) {
        this.commitSetStatDisplayPollActive();
      }
    },
    ClosePoll() {
      // resetting the pollData
      const pollDat = {
        question: '',
        alternatives: [],
        creatorId: '',
        isAnonymous: false,
        isActive: false,
        onCanvas: false,
      };
      this.commitSetPoll(pollDat);
      // removing the answer poll. Note that
      // We should check if answerPoll or
      // Stat is there to make sure we close the correct one
      if (this.isAnswerPollActive) {
        this.commitSetAnswerPollActive();
      }
      if (this.isStatDisplayPollActive) {
        this.commitSetStatDisplayPollActive();
      }
      // need to add stat remover is stats open
    },
    gotGameInvite(signal) {
      this.commitSetGameData(signal.gameData);
      this.commitSetGameInvitationActive();
      console.log(signal.gameData);
      // activiate gameinvitation
    },
    gotQuizAnswer(signal) {
      const correct = signal.quizData.correctAnswer;
      if (correct) {
        // calculate score
        // const score = Math.floor(100000 / signal.quizData.time);
        const score = Math.max(30 - Math.floor(signal.quizData.time / 1000), 0);
        console.log(score);
        // find the user
        for (let i = 0; i < this.gameData.players.length; i += 1) {
          console.log(this.gameData.players[i].userId);
          console.log(signal.uuid);
          if (this.gameData.players[i].userId === signal.uuid) {
            const newScore = this.gameData.players[i].score + score;
            setTimeout(() => {
              this.commitAddPlayerData({
                userId: signal.uuid,
                name: signal.displayName,
                score: newScore,
              });
              updateGameData(this.gameData);
            }, 2000);
          }
        }
      }
    },
    hideOrShowGameMenu() {
      this.commitSetScoreBoardGTCActive();
    },
    stopGame() {
      const gameDat = {
        Type: '',
        creatorId: '',
        invitedPlayers: [],
        players: [{
          userId: '',
          name: '',
          score: null,
        }],
      };
      this.commitSetGameData(gameDat);
      if (this.isScoreboardGTCActive) {
        this.commitSetScoreBoardGTCActive();
      }
    },
    readyCanvas() {
      console.log('Canvas is ready');
      // this.commitSetEnterVideoActive();
      this.$refs.boardArea.hidden = false;
      // this.commitSetCanvasViewActive();
    },
    updateGameData(signal) {
      this.commitSetGameData(signal.gameData);
    },
    gotGameAcceptance(signal) {
      if (signal.accepted) {
        this.commitAddPlayerData({
          userId: signal.uuid,
          name: signal.displayName,
          score: 0,
        });
        updateGameData(this.gameData);
      }
    },
  },
  computed: {
    ...mapGetters('$_userdata', ['username', 'userID', 'host', 'room', 'profilePicture', 'videovar', 'hoover', 'participants', 'pollData', 'backgroundImageUrl']),
    ...mapGetters('$_canvas', ['isShowParticipantVideoActive', 'isAnswerPollActive', 'isStatDisplayPollActive', 'user', 'isScoreboardGTCActive', 'gameData', 'isNoHostMenuActive', 'isRaisedHandOverviewActive', 'raisedHands']),
  },
  mounted() {
    // CHL: I am sure this can be done more vue style
    this.$refs.boardArea.hidden = false;
    // const canvas = document.getElementById('canvas');
    // this.vueCanvas = canvas.getContext('2d');
    // console.log(adapter.browserDetails.browser);
    // if (!this.host) {
    //   this.commitSetNoHostMenuActive();
    // }

    // temporary solution to until we get permanent stor and database
    const testRooms = [
      'TestRoom0',
      'TestRoom1',
      'TestRoom2',
      'TestRoom3',
      'TestRoom4',
      'TestRoom5',
      'TestRoom6',
      'TestRoom7',
      'TestRoom8',
      'TestRoom9',
      'TestRoom10',
      'F300Spring2022-8243',
      'F300Spring2022-10523',
    ];
    let test = false;
    Object.values(testRooms).forEach((testroom) => {
      if (testroom === localStorage.getItem('currentRoom')) {
        test = true;
      }
    });
    if (test && !this.host) {
      this.commitMenuItems({
        broadCastMenu: false,
        shareMediaMenu: false,
        pollMenu: false,
        gameMenu: false,
      });
    }
    const board = new Canvasboard(this.$refs.boardArea); // eslint-disable-line
    window.mingly.fetchRooms();
    window.mingly.showConsole = false;
    // eslint-disable-next-line no-console
    console.log(`room is ${this.room}`);
    window.mingly.onAddParticipant = this.addParticipant;
    window.mingly.onRemoveParticipant = this.removeParticipant;
    window.mingly.onVideovar = this.commitVideoVar;
    window.mingly.onScreenShare = this.screenShareChange;
    window.mingly.onChatMessage = this.addChatMessage;
    window.mingly.onChangeName = this.changeNameParticipant;
    window.mingly.onChangeProfilePic = this.changeProfilePicture;
    board.onHoover = this.changeHoover;
    board.onHooverClick = this.focusScreen;
    window.mingly.onGotPoll = this.gotPoll;
    window.mingly.onGotPollAnswer = this.gotPollAnswer;
    window.mingly.onGotPollStats = this.gotPollStats;
    window.mingly.onClosePoll = this.ClosePoll;
    window.mingly.onGameInvite = this.gotGameInvite;
    window.mingly.onGotGameAcceptance = this.gotGameAcceptance;
    window.mingly.onStopGame = this.stopGame;
    window.mingly.onGotQuizAnswer = this.gotQuizAnswer;
    window.mingly.onUpdateGameData = this.updateGameData;
    window.mingly.onMSBroadcast = this.msBroadcast;
    window.mingly.onReadyCanvas = this.readyCanvas;
    window.mingly.onMeIsHost = this.changeHostStatus;
    window.mingly.onNoHostMenu = this.noHostMenu;
    window.mingly.onRaiseHand = this.raisedHand;
    window.mingly.onLowerHand = this.lowerHand;
    window.mingly.onNoVideo = this.turnVideoOff;
    window.mingly.onMutedMe = this.turnAudioOff;
    window.mingly.onHideShowGame = this.hideOrShowGameMenu;
    window.mingly.onServerDownReconnect = this.serverDisconnected;
    const external = true;
    // if (this.username === 'F300Spring2022-8243') {
    //   window.mingly.wsurl = 'ws://ec2-18-220-110-108.us-east-2.compute.amazonaws.com:8001';
    // }
    // if (this.username === 'F300Spring2022-10523') {
    //   window.mingly.wsurl = null;
    // }

    // this is where the axios call is made
    if (!this.room && localStorage.getItem('currentRoom')) {
      console.log('using local storage');
      this.commitSetRoom(localStorage.getItem('currentRoom'));
    }
    const payload = {
      room: this.room,
      user: this.username,
      userID: 'asdasdas',
    };
    console.log('payload');
    console.log(payload);
    console.log('after');
    axios.post('https://balancer.mingly.world:8443/getRoom', payload).then((response) => {
      console.log(response.data);
      // store the room in localstorage
      localStorage.setItem('currentRoom', this.room);
      const config = response.data;
      if (this.username === config.roomOwner) {
        window.mingly.isHost = true;
        this.commitSetHost();
      }
      config.wsurl = config.servers[0].wssServer;
      config.configRTC = config.servers.slice(1, config.servers.length);
      // config.mutedOnEntry = true;
      // if (config.mutedOnEntry) {
      //   this.commitSetMuteActive();
      // }
      console.log('the config');
      console.log(config);
      setAllParameters(config, window.mingly, board);
      console.log('after setting');
      if (!this.host) {
        let broadCast = true;
        let share = true;
        let poll = true;
        let game = true;
        if ('menues' in config) {
          if ('allow_broadcast' in config.menues) {
            broadCast = config.menues.allow_broadcast;
          }
          if ('allow_change_background_picture' in config.menues) {
            share = config.menues.allow_change_background_picture;
          }
          if ('allow_create_poll' in config.menues) {
            poll = config.menues.allow_create_poll;
          }
          if ('allow_start_game' in config.menues) {
            game = config.menues.allow_start_game;
          }
        }
        this.commitMenuItems({
          broadCastMenu: broadCast,
          shareMediaMenu: share,
          pollMenu: poll,
          gameMenu: game,
        });
      }
      // console.log(window.mingly.board.room);
      // here we setup the room, "give host rights", get servers etc
      start(board, external, null, this.username, this.room);
    }).catch((error) => {
      console.log(error);
      console.log('IN THE BALANCE SERVER NOT WORKING CASE');
      const config = {
        name: this.room,
        owner: 'Christian',
        upperAudioCutoff: 10,
        capacity: 25,
        limitPerServer: 2, // this is an extreme one for testing
        maxNumberBroadCastOnCanvas: 3,
        useXirsys: true,
        mediaserver: true,
        videoWidth: 640,
        videoHeight: 480,
        backgroundPictureURL: 'https://bl3301files.storage.live.com/y4m5TNsLJxVWCntUYFCbwz0-vbWu_2fMaCZVH0R7cmTt0PSHdWPWFfNMGN7xaRVJiLB1aE_FsBxl9BOLy09k0nFJ6-yhQCnkLNYZxRXVcnEVcjL7e4lAF39GBOY22eT4OJIxf1wiZQDHwAEHmRS7WR5OK_ibO-SRh7_fuBCkRMQnomA_EW93qk8p5S5KiRch37v?width=6368&height=5253&cropmode=none',
        menues: {
          allow_broadcast: false,
          allow_change_background_picture: false,
          allow_create_poll: false,
          allow_start_game: false,
        },
        servers: [{
          serverId: 'AAA1',
          wssServer: 'wss://soup2.mingly.world:8443',
          type: 'signaling',
          rtcConfig: null,
          send: false,
        },
        {
          serverId: 'AAA2',
          wssServer: 'wss://soup2.mingly.world:8443',
          type: 'mediasoup',
          rtcConfig: null,
          send: false,
        }],
      };
      config.wsurl = config.servers[0].wssServer;
      config.configRTC = config.servers.slice(1, config.servers.length);
      setAllParameters(config, window.mingly, board);
      start(board, external, null, this.username, this.room);
    });
    // if (this.room.length > 1) {
    //   console.log(`room is ${this.room}`);
    //   localStorage.setItem('currentRoom', this.room);
    //   start(board, external, null, this.username, this.room);
    // } else if (localStorage.getItem('currentRoom')) {
    //   console.log(`room is from local storage ${localStorage.getItem('currentRoom')}`);
    //   start(board, external, null, this.username, localStorage.getItem('currentRoom'));
    // } else {
    //   start(board, external, null, this.username);
    // }
  },
};
</script>

<style scoped>
/*
.canvas_size {
  height: 94vh;
  width: 100%;
}
*/
</style>
