<template>
   <div class="background">
    <v-row>
      <v-col>
        <UserProfile v-if="!showEditProfilePic" @openEditProfilePic="showEditProfilePic=true" />
        <EditProfilePic v-if="showEditProfilePic" @closeEditProfilePic="showEditProfilePic=false" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import UserProfile from './_components/UserProfile.vue';
import EditProfilePic from './_components/EditProfilePic.vue';

export default {
  name: 'index',
  components: { UserProfile, EditProfilePic },
  data() {
    return {
      showEditProfilePic: false,
    };
  },
  computed: {
    ...mapGetters('$_userdata', ['backgroundImageUrl']),
  },
};
</script>

<style scoped>
.background {
  background: url('https://www.dropbox.com/s/eeaq8lkpwlvhaio/IMG_2775.jpg?raw=1');
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
</style>
