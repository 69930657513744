<template>
  <div>
    <vue-drag-resize
      :isActive="isScreenActive"
      :isResizable="false"
      :preventActiveBehavior="false"
      :w="width"
      :h="height"
      :x="left"
      :y="top"
      v-on:deactivated ="onDeactivated"
      v-on:activated ="onActivated"
    >
      <div class="container">
        <v-card
          v-if="!selectedPerson"
          dark
          class="box"
        >
          <CloseButton :method="closeParticipantBox" class="close_button" />
          <v-list>
            <v-list-item>
              <v-avatar size="36" class="avatar">
                <v-img :src="this.profilePicture.src" />
              </v-avatar>
              <div class="own_color">
                {{ username }}
              </div>
            </v-list-item>
            <v-list-item
              v-for="(person, index) in participants.getUsersAndPictures()"
              :key="index"
            >
              <v-avatar size="36" class="avatar">
                <v-img :src="person.picture.src" />
              </v-avatar>
              <div
                :class="getNameClass(person.userID)"
                @mouseover="mouseOver(person.userID)"
                @mouseleave="mouseLeave(person.userID)"
                @click="selectPerson(person.userID)"
              >
                {{ person.username }}
              </div>
            </v-list-item>
          </v-list>
        </v-card>

        <v-card
          v-else
          dark
          class="expanded-card"
        >
          <v-avatar size="36" class="avatar">
            <v-img :src="selectedPerson.picture.src" />
          </v-avatar>
          <div class="username">{{ selectedPerson.username }}</div>
          <div class="button-grid">
            <v-btn
              dark
              :color="getButtonColor(selectedPerson.userID, 'locate')"
              @click="toggleButton(selectedPerson.userID, 'locate')"
            >
              Locate
            </v-btn>
            <v-btn
              dark
              :color="getButtonColor(selectedPerson.userID, 'move')"
              @click="toggleButton(selectedPerson.userID, 'move')"
            >
              Move to
            </v-btn>
            <v-btn
              dark
              :color="getButtonColor(selectedPerson.userID, 'followMovement')"
              @click="toggleButton(selectedPerson.userID, 'followMovement')"
            >
              Follow movement
            </v-btn>
            <v-btn
              dark
              :color="getButtonColor(selectedPerson.userID, 'followView')"
              @click="toggleButton(selectedPerson.userID, 'followView')"
            >
              Follow view
            </v-btn>
          </div>
          <CloseButton :method="deselectPerson" class="close_button" />
        </v-card>
      </div>
    </vue-drag-resize>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import VueDragResize from 'vue-drag-resize';
import CloseButton from './CloseButton.vue';
import { boardAskToFollowView } from '../../../assets/js/webrtc';

export default {
  name: 'ParticipantBox',
  data() {
    return {
      top: 0.15 * window.innerHeight,
      left: 0.02 * window.innerWidth,
      width: 0.4 * window.innerWidth,
      height: 0.4 * window.innerHeight,
      isScreenActive: false,
      buttonStatus: {},
      buttons: {},
      // extendedWidth: 0.34 * window.innerWidth,
      selectedPerson: null,
    };
  },
  components: {
    CloseButton, VueDragResize,
  },
  watch: {
    participants: {
      handler(newParticipants) {
        // Only proceed if newParticipants is an array
        if (Array.isArray(newParticipants)) {
          newParticipants.forEach((participant) => {
            if (!this.buttons[participant.id]) {
              this.$set(this.buttons, participant.id, {
                activate1: false,
                activate2: false,
                activate3: false,
                activate4: false,
              });
            }
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('$_canvas', [
      'commitSetShowParticipantVideoActive',
      'commitSetParticipantActive',
    ]),
    closeParticipantBox() {
      this.commitSetParticipantActive();
    },
    mouseOver(id) {
      window.mingly.board.participants[id].hoover = true;
    },
    mouseLeave(id) {
      window.mingly.board.participants[id].hoover = false;
    },
    onDeactivated() {
      this.isScreenActive = false;
    },
    getNameClass(id) {
      const personButtons = this.buttons[id];
      if (!personButtons) return '';
      return Object.values(personButtons).some((value) => value)
        ? 'name-color-activated'
        : '';
    },
    onActivated() {
      this.isScreenActive = true;
      window.mingly.board.move_keyboard = false;
      setTimeout(() => {
        this.isScreenActive = false;
      }, 2000);
    },
    selectPerson(id) {
      const usersAndPictures = this.participants.getUsersAndPictures();
      this.selectedPerson = usersAndPictures.find((user) => user.userID === id);
    },
    deselectPerson() {
      this.selectedPerson = null;
    },
    toggleButton(id, button) {
      if (!this.buttonStatus[id]) {
        this.$set(this.buttonStatus, id, {});
      }
      this.$set(this.buttonStatus[id], button, !this.buttonStatus[id][button]);
      switch (button) {
        case 'locate':
          this.locateFunction(id); // replace with your actual function
          break;
        case 'move':
          this.moveFunction(id); // replace with your actual function
          break;
        case 'followMovement':
          this.followMovementFunction(id); // replace with your actual function
          break;
        case 'followView':
          this.followViewFunction(id); // replace with your actual function
          break;
        default:
          break;
      }
      if (button === 'locate' || button === 'move') {
        setTimeout(() => {
          this.$set(this.buttonStatus[id], button, false);
        }, 2000);
      }
    },
    getButtonColor(id, button) {
      if (!this.buttonStatus[id] || !this.buttonStatus[id][button]) {
        return '#203e5fff';
      }
      return 'green';
    },
    isAnyButtonActivated(id) {
      if (!this.buttonStatus[id]) return false;
      return Object.values(this.buttonStatus[id]).some((status) => status);
    },
    locateFunction(id) {
      console.log(`locating ${id}`);
      const { mingly } = window;
      const { board } = mingly;
      const participant = board.participants[id];
      window.mingly.board.zoomtoParticipant(participant);
    },
    moveFunction(id) {
      console.log(`moving to ${id}`);
      const { mingly } = window;
      const { board } = mingly;
      const { me } = board;
      const participant = board.participants[id];
      me.moveToClosesetAvailable(participant.x, participant.y);
    },
    followMovementFunction(id) {
      console.log(`follow movement of ${id}`);
      const { mingly } = window;
      const { board } = mingly;
      const { me } = board;
      const participant = board.participants[id];
      me.moveToClosesetAvailable(participant.x, participant.y);
      if (me.followingMove.isFollowing) {
        me.followingMove.isFollowing = false;
        me.followingMove.following = '';
      } else {
        me.followingMove.isFollowing = true;
        me.followingMove.following = participant.uuid;
      }
    },
    followViewFunction(id) {
      console.log(`follow view of ${id}`);
      const { mingly } = window;
      const { board } = mingly;
      const participant = board.participants[id];
      boardAskToFollowView(participant);
    },
  },
  // computed and rest of the code
  computed: {
    ...mapGetters('$_userdata', ['participants', 'username', 'profilePicture', 'videovar', 'hoover'], '$_canvas', [
      'isShowParticipantVideoActive', 'user']),
  },
};
</script>

<style scoped>
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #b9b9b9;
  }
  .box {
    overflow: auto;
    white-space: nowrap;
    transition: width 0.3s ease;
    width: 50%; /* adjust as needed */
  }
  .avatar {
    margin-right: 1vh;
  }
  .own_color {
    color: green;
  }
  .other_part:hover {
    color: red;
  }
  .close_button {
    z-index: 1;
    position: absolute;
    right: 0;
    top: 0;
  }
  .expanded-card {
    transition: width 0.3s ease;
    width: 100%;
    /* position: relative; */
    left: 0;
    top: 0;
    background-color: black;
    padding: 10px;
    height: auto;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.15);
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .container {
  display: flex;
}
/* .expanded-card {
  padding: 2vh;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */
.button-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  width: 100%;
  margin: 20px 0;
}
.button-grid > .v-btn {
  width: 100%; /* Adjust as needed */
}
.name-color-activated {
  color: #eac252;
}
/* .container::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: black;
  z-index: 1;
}
.box, .expanded-card {
  position: relative;
  z-index: 2;
} */
  ::v-deep .vdr.active:before {
    outline: none !important;
  }

  ::v-deep .vdr-stick {
    border: none !important; /* Removes border */
    box-shadow: none !important; /* Removes shadow */
    background: transparent !important; /* Makes the background transparent */
  }
</style>
